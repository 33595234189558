import {EnumRecord} from "../shared/EnumRecord";

export enum ComplexityEnum {
    high = "4 – Highly Complicated",
    complex = "3 – Complex",
    average = "2 – Average",
    simple = "1 – Simple",
    empty = "Unsure",
}

export const ComplexityList: EnumRecord[] = Object.entries(ComplexityEnum)
    .map(([key, value]) => ({key, value} as EnumRecord));
