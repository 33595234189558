import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../../index";
import PageTitle from "../../components/shared/PageTitle";
import {Grid} from '@mui/material';
import ObjectDropDown from "../../components/shared/ObjectDropDown";
import BodyText from "../../components/shared/BodyText";
import {allFlowGroups} from "../../http/flowsAPI";
import SectionTitleLeft from "../../components/shared/SectionTitleLeft";
import {FlowGroup} from "../../domain/entity/structures/aiLab/FlowGroup";
import {AiModelFlow} from "../../domain/entity/structures/aiLab/AiModelFlow";
import {aiModelInputAdapter, flowGroupsInputAdapter} from "../../components/models/aiLab/AiModelUtils";
import {deleteAiModels, getAiModelsByFlow} from "../../http/aiLabAPI";
import {AI_LAB_ROUTE} from "../../utils/consts";
import AiModelsTable from "../../components/models/aiLab/management/AiModelsTable";
import AiModelUpdateDialog from "../../components/models/aiLab/update/AiModelUpdateDialog";
import {getArsDetails} from "../../http/requirementManagementAPI";

const AiLabManagementPage = () => {
    let navigate = useNavigate();
    const {id} = useParams()
    const {appStore} = useContext(Context)
    const [flowGroups, setFlowGroups] = useState<FlowGroup[]>([]);
    const [flowGroup, setFlowGroup] = useState<FlowGroup>({
        id: null as any,
        name: "",
        flows: []
    })
    const [aiModels, setAiModels] = useState<AiModelFlow[]>([]);
    const [aiModelUpdateDialogOpen, setAiModelUpdateDialogOpen] = useState<boolean>(false);
    const [selectedAiModel, setSelectedAiModel] = useState<AiModelFlow>(null as any);

    function loadAiModels(responseFlowGroups: FlowGroup[]) {
        if (id) {
            let selectedFlowGroup = responseFlowGroups.find(e => e.id === id);
            if (selectedFlowGroup)
                setFlowGroup(selectedFlowGroup)
            appStore.addLoading()
            getAiModelsByFlow(id).then(async (response) => {
                const responseModels = response.data.map(e => aiModelInputAdapter(e));
                try {
                    for (const responseModel of responseModels) {
                        response = await getArsDetails(responseModel.ars_id)
                        responseModel.ars_name = response.data.name
                    }
                } catch {
                    appStore.showAppSnackBar('error', "ARS info loading error")
                }
                setAiModels(responseModels)
            }).catch(() => {
                appStore.showAppSnackBar("error", "AI models loading error")
            }).finally(() => appStore.removeLoading())
        }
    }

    useEffect(() => {
        appStore.addLoading()
        allFlowGroups().then(response => {
            const responseFlowGroups = flowGroupsInputAdapter(response.data);
            setFlowGroups(responseFlowGroups);
            loadAiModels(responseFlowGroups);
        }).catch(() => {
            appStore.showAppSnackBar("error", "Flow groups loading error")
        }).finally(() => {
            appStore.removeLoading();
        })

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const title = "AI Model Management"

    function onSelected(item) {
        navigate(AI_LAB_ROUTE + "/" + item.id);
    }

    function onRemoveAiModel(aiModelsForRemoval: AiModelFlow[]) {
        let confirmAction = window.confirm("Are you sure to delete selected AI model(s)?");
        if (!confirmAction)
            return
        appStore.addLoading()
        deleteAiModels({
            ai_model_ids: aiModelsForRemoval.map(e => e.id)
        }).then(() => {
            appStore.showAppSnackBar("success", "AI model(s) is(are) deleted")
            loadAiModels(flowGroups);
        }).catch(() => {
            appStore.showAppSnackBar("error", "AI model(s) deletion error")
        }).finally(() => {
            appStore.removeLoading();
        })
    }

    function onOpenAiModel(aiModel: AiModelFlow) {
        setSelectedAiModel(aiModel)
        setAiModelUpdateDialogOpen(true)
    }

    function onAiModelUpdateDialogClose() {
        setAiModelUpdateDialogOpen(false)
        loadAiModels(flowGroups)
    }

    function onRefresh() {
        setAiModels(aiModels.slice().sort((a, b) => a.order - b.order))
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={1}>
                    <Grid container item xs={6} justifyContent={"flex-end"} alignItems={"center"}>
                        <BodyText>
                            Show AI models of selected group of flows
                        </BodyText>
                    </Grid>
                    <Grid item xs={6}>
                        <ObjectDropDown items={flowGroups}
                                        selectedItem={flowGroup}
                                        onSelectionChange={onSelected}
                                        inputLabel={"Flow Groups"}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {
                        id &&
                        <>
                            <SectionTitleLeft>{flowGroup.flows.map(e => e.name).join(", ")}</SectionTitleLeft>
                            <AiModelsTable data={aiModels}
                                           onRemove={onRemoveAiModel}
                                           refresh={onRefresh}
                                           onOpen={onOpenAiModel}/>
                        </>
                    }
                </Grid>
            </Grid>
            {
                aiModelUpdateDialogOpen &&
                <AiModelUpdateDialog onBack={onAiModelUpdateDialogClose}
                                     ars={null as any}
                                     flowGroup={flowGroup}
                                     aiModelId={selectedAiModel.id}
                />
            }
        </>
    )
}

export default AiLabManagementPage