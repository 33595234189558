import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import {observer} from "mobx-react-lite";
import RsProperties from "./RsProperties";
import RsRightSide from "./RsRightSide";
import {Context} from "../../index";
import {ArsItem} from "../../domain/entity/structures/requirementManagement/ArsItem";
import {isLongStringValueValid} from "../../utils/validationUtils";
import CommentsList from "./CommentsList";
import {RsComment} from "../../domain/entity/structures/requirementManagement/RsComment";
import {Link} from "../../domain/entity/structures/requirementManagement/Link";
import AddLinkDialog from "./AddLinkDialog";
import {LinkTypeEnum} from "../../domain/entity/structures/requirementManagement/LinkTypeEnum";
import {ON_FINISH_MESSAGE} from "../../utils/consts";
import {
    getRsListForLinks,
    isRsValid,
    makePayloadFromRs,
    removeEmptyLogicStatement,
    rsDataImportAdapter
} from "./ProjectUtils";
import {deleteArsItem, retrieveArsItem, updateArsItem} from "../../http/requirementManagementAPI";
import RsCreatedUpdated from "./RsCreatedUpdated";
import AllocateAiModelsDialog from "./AllocateAiModelsDialog";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";
import {getAiModelsByArs} from "../../http/dataProcessingAPI";
import {AiModel} from "../../domain/entity/structures/requirementManagement/AiModel";
import ArsAiModelWidget from "./ArsAiModelWidget";
import {idNameValuesFromObject} from "../../utils/utils";
import ArsAiModelReviewDialog from "./ArsAiModelReviewDialog";
import moment from "moment";
import AiModelCreateDialog from "../models/aiLab/create/AiModelCreateDialog";
import {AiModelTypeEnum} from "../../domain/entity/structures/aiLab/AiModelTypeEnum";
import AiModelUpdateDialog from "../models/aiLab/update/AiModelUpdateDialog";

interface ArsUpdateDialogProps {
    isDialogOpen: boolean
    projectId: string
    arsId: string
    rsList: ArsItem[]
    onRsListReload: () => void
    onLink: (rs_id: string) => void
    onClose: () => void
}

const ArsUpdateDialog = observer((props: ArsUpdateDialogProps) => {
    const {appStore} = useContext(Context)
    const {userStore} = useContext(Context)
    const [newComment, setNewComment] = useState<string>('');
    const [rs, setRs] = useState<ArsItem>({
        rs_id: "",
        category: "",
        complexity: "",
        criticality: "",
        description: "",
        links: [],
        logic_statements: [''],
        name: "",
        project_id: props.projectId,
        requirement_comments: [],
        state: "New",
        subject: "",
    });
    const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
    const [aiModels, setAiModels] = useState<AiModel[]>([]);
    const [allocateAiModelsDialogOpen, setAllocateAiModelsDialogOpen] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [modelReviewDialogOpen, setModelReviewDialogOpen] = useState<boolean>(false);
    const [selectedAiModel, setSelectedAiModel] = useState<IdNameListValues>(null as any);
    const [aiModelCreateDialogOpen, setAiModelCreateDialogOpen] = useState<boolean>(false);
    const [aiModelUpdateDialogOpen, setAiModelUpdateDialogOpen] = useState<boolean>(false);

    function loadAiModels() {
        appStore.addLoading()
        getAiModelsByArs(props.arsId).then((response) => {
            setAiModels(response.data.map((item) => {
                return {
                    id: item.ai_model_id,
                    name: item.ai_model_name,
                    flow_group_id: item.flow_group_id,
                    flows: idNameValuesFromObject(item.flows),
                    type: item.type === 'scheduled' ? AiModelTypeEnum.scheduled : AiModelTypeEnum.stream,
                    version: item.version,
                    updated_by: item.updated_by,
                    updated_time: item.updated_time,
                } as AiModel
            }));
        }).catch(() => {
            appStore.showAppSnackBar('error', "ARS AiModels loading error")
        }).finally(() => appStore.removeLoading())
    }

    useEffect(() => {
        if (props.arsId === null) return
        appStore.addLoading()
        retrieveArsItem(props.arsId).then((response) => {
            setRs(rsDataImportAdapter(response.data));
        }).catch(() => {
            appStore.showAppSnackBar('error', "ARS loading error")
        }).finally(() => appStore.removeLoading())
        loadAiModels();

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.arsId])

    function onSave() {
        removeEmptyLogicStatement(rs);
        if (!isRsValid(rs, appStore))
            return
        appStore.addLoading()
        updateArsItem(makePayloadFromRs(rs)).then(() => {
            appStore.showAppSnackBar("success", "The update was successful")
            props.onClose()
        }).catch(reason => {
            if (reason.response.status === 409)
                appStore.showAppSnackBar("error", reason.response.data.message)
            else
                appStore.showAppSnackBar("error", "The update failed")
        }).finally(() => appStore.removeLoading())
    }

    function onClear() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        appStore.addLoading()
        retrieveArsItem(props.arsId).then((response) => {
            setRs(rsDataImportAdapter(response.data));
            setIsChanged(false)
        }).catch(() => {
            appStore.showAppSnackBar('error', "ARS loading error")
        }).finally(() => appStore.removeLoading())
    }

    function onBack() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onClose()
    }

    function onNewCommentChange(event: React.ChangeEvent<HTMLInputElement>) {
        setNewComment(event.target.value);
    }

    function onAddComment() {
        const newCommentValidationResult = isLongStringValueValid(newComment);
        if (newCommentValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Comment has validation error with message: " + newCommentValidationResult)
            return
        }

        rs.requirement_comments.push({
            number: rs.requirement_comments.length + 1,
            comment: newComment,
            created_by: userStore.user.loginId,
            createdAt: moment().format()
        })
        setNewComment('')
        setRs({...rs})
        setIsChanged(true)
    }

    function onDeleteComment(comment: RsComment) {
        rs.requirement_comments.splice(rs.requirement_comments.indexOf(comment), 1)
        for (let i = 0; i < rs.requirement_comments.length; i++) {
            rs.requirement_comments[i].number = i + 1
        }
        setRs({...rs})
        setIsChanged(true)
    }

    function onAddLinkButtonClick() {
        setAddLinkDialogOpen(true)
    }

    function onDeleteLink(link: Link) {
        rs.links.splice(rs.links.indexOf(link), 1)
        setRs({...rs})
    }

    function onAddLinkDialogClose() {
        setAddLinkDialogOpen(false)
    }

    function onAddLink(linkType: LinkTypeEnum, rsLink: IdNameListValues) {
        rs.links.push({
            link_id: null as any,
            main_rs_id: rs.rs_id,
            link_type: linkType,
            linked_rs_id: rsLink.id,
            name: rsLink.name
        } as Link)
        setIsChanged(true)
    }

    function onDelete() {
        if (aiModels.length > 0) {
            appStore.showAppSnackBar('error', "ARS has associated AiModels and cannot be deleted")
            return
        }

        let confirmAction = window.confirm('Are you sure to delete this ARS?');
        if (!confirmAction)
            return

        appStore.addLoading()
        deleteArsItem(props.arsId).then(() => {
            appStore.showAppSnackBar('success', "ARS has been deleted")
            props.onClose()
        }).catch(() => {
            appStore.showAppSnackBar('error', "ARS deletion error")
        }).finally(() => appStore.removeLoading())
    }

    function onAllocateAiModelsDialogClose() {
        setAllocateAiModelsDialogOpen(false)
        loadAiModels()
    }

    function onReallocateAiModels() {
        setAllocateAiModelsDialogOpen(true)
    }

    function setRsValue(rs: ArsItem) {
        setRs(rs)
        setIsChanged(true)
    }

    function onModelReviewDialogClose() {
        setModelReviewDialogOpen(false)
    }

    function onReviewAiModel() {
        setModelReviewDialogOpen(true)
    }

    function onLink(rs_id: string) {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onClose()
        props.onLink(rs_id)
    }

    function onAiModelCreateDialogClose() {
        setAiModelCreateDialogOpen(false)
        loadAiModels()
    }

    function onAiModelUpdateDialogClose() {
        setAiModelUpdateDialogOpen(false)
        loadAiModels()
    }

    function onCreateAiModel() {
        setAiModelCreateDialogOpen(true)
    }

    function onUpdateAiModel() {
        setAiModelUpdateDialogOpen(true)
    }

    return (
        <>
            <Dialog open={props.isDialogOpen} maxWidth={'xl'} onClose={props.onClose}>
                <DialogTitle>{"AI Requirement Specification (ARS) Item Update"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                        <Grid container item xs={8}
                              alignContent={"flex-start"}>
                            <RsProperties record={rs}
                                          onUpdate={setRsValue}/>
                            <RsCreatedUpdated record={rs}/>
                            <ArsAiModelWidget
                                onCreateAiModel={onCreateAiModel}
                                onUpdateAiModel={onUpdateAiModel}
                                onReviewAiModel={onReviewAiModel}
                                onReallocateAiModels={onReallocateAiModels}
                                aiModels={aiModels.map((item) => {
                                    return {
                                        id: item.id,
                                        name: item.name + ', ' + item.flows.map(e => e.name).join(', ') + ', ' + item.type + ', Version: ' + item.version,
                                    } as IdNameListValues
                                })}
                                selectedAiModel={selectedAiModel}
                                setSelectedAiModel={setSelectedAiModel}
                                isReallocateEnabled={props.rsList.length > 1 && aiModels.length > 1}
                            />
                            <Grid container alignContent={"flex-start"}>
                                <Grid item xs
                                      alignContent={"flex-start"}>
                                    <TextField
                                        margin="dense"
                                        id="item-comment"
                                        label="New comment"
                                        type="text"
                                        fullWidth
                                        inputProps={{maxLength: 500}}
                                        variant="standard"
                                        name='new-comment'
                                        value={newComment}
                                        onChange={onNewCommentChange}
                                    />
                                </Grid>
                                <Grid container item xs="auto">
                                    <Button onClick={onAddComment}
                                            disabled={newComment === ''}>Add</Button>
                                </Grid>
                            </Grid>
                            <CommentsList comments={rs.requirement_comments} onDelete={onDeleteComment}/>
                        </Grid>
                        <Grid item xs={4}>
                            <RsRightSide record={rs}
                                         onUpdate={setRs}
                                         onLink={onLink}
                                         isAddLinkPossible={getRsListForLinks(rs, props.rsList).length > 0}
                                         onAddLinkButtonClick={onAddLinkButtonClick}
                                         onDeleteLink={onDeleteLink}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onBack}>Back</Button>
                    <Button onClick={onClear}>Clear</Button>
                    <Button onClick={onSave}>Save</Button>
                    <Button onClick={onDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
            {
                addLinkDialogOpen &&
                <AddLinkDialog isDialogOpen={addLinkDialogOpen}
                               isDrs={false}
                               rsList={getRsListForLinks(rs, props.rsList)}
                               onAddLink={onAddLink}
                               onClose={onAddLinkDialogClose}/>
            }
            {
                allocateAiModelsDialogOpen &&
                <AllocateAiModelsDialog isDialogOpen={allocateAiModelsDialogOpen}
                                        rsList={props.rsList.filter((item: ArsItem) => (rs.rs_id !== item.rs_id)).map((item) => {
                                            return {
                                                id: item.rs_id,
                                                name: item.name,
                                            } as IdNameListValues
                                        })}
                                        aiModelList={aiModels.map((item) => {
                                            return {
                                                id: item.id,
                                                name: item.name + ', ' + item.flows.map(e => e.name).join(', ') + ', ' + item.type + ', Version: ' + item.version,
                                            } as IdNameListValues
                                        })}
                                        onLoadAiModels={loadAiModels}
                                        onFinish={onAllocateAiModelsDialogClose}
                                        currentArsName={rs.name}/>
            }
            {
                modelReviewDialogOpen &&
                <ArsAiModelReviewDialog isDialogOpen={modelReviewDialogOpen}
                                        onBack={onModelReviewDialogClose}
                                        currentArsName={rs.name}
                                        currentArsId={rs.rs_id}
                                        aiModelId={selectedAiModel.id}
                />
            }
            {
                aiModelCreateDialogOpen &&
                <AiModelCreateDialog onBack={onAiModelCreateDialogClose}
                                     ars={rs}
                />
            }
            {
                aiModelUpdateDialogOpen &&
                <AiModelUpdateDialog onBack={onAiModelUpdateDialogClose}
                                     ars={rs}
                                     flowGroup={null as any}
                                     aiModelId={selectedAiModel.id}
                />
            }
        </>
    )
})

export default ArsUpdateDialog;