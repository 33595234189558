import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../../index";
import {MANAGE_SCENARIOS_ROUTE} from "../../utils/consts";
import PageTitle from "../../components/shared/PageTitle";
import {Alert, Button, Grid} from '@mui/material';
import ObjectDropDown from "../../components/shared/ObjectDropDown";
import BodyText from "../../components/shared/BodyText";
import {Flow} from "../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import {productFlows} from "../../http/flowsAPI";
import SectionTitleLeft from "../../components/shared/SectionTitleLeft";
import ScenariosTable from "../../components/models/dataProcessing/ScenariosTable";
import {ScenarioFlow} from "../../domain/entity/structures/dataProcessing/ScenarioFlow";
import {getScenariosByFlow, updateScenariosByFlow} from "../../http/dataProcessingAPI";
import ScenarioEditDialog from "../../components/models/dataProcessing/ScenarioEditDialog";
import {capitalizeFirstLetter, objectArrayToFlows, removeItems} from "../../utils/utils";
import {getDrsDetails} from "../../http/requirementManagementAPI";
import {ScenarioStatusEnum} from "../../domain/entity/structures/dataProcessing/ScenarioStatusEnum";

const ManageScenariosPage = () => {
    let navigate = useNavigate();
    const {id} = useParams()
    const {appStore} = useContext(Context)
    const [flows, setFlows] = useState<Flow[]>([]);
    const [flow, setFlow] = useState<Flow>({
        id: null as any,
        name: "",
        is_validation: false,
        product_flow_id: "",
        attributes: [],
        columns: []
    })
    const [scenarios, setScenarios] = useState<ScenarioFlow[]>([]);
    const [scenarioEditDialogOpen, setScenarioEditDialogOpen] = useState<boolean>(false);
    const [selectedScenario, setSelectedScenario] = useState<ScenarioFlow>(null as any);
    const [removedItems, setRemovedItems] = useState<string[]>([]);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    function loadScenarios(responseFlows: Flow[]) {
        if (id) {
            let selectedFlow = responseFlows.find(e => e.id === id);
            if (selectedFlow)
                setFlow(selectedFlow)

            appStore.addLoading()
            setIsLoaded(false)
            getScenariosByFlow(id).then(async (response) => {
                const responseScenarios: ScenarioFlow[] = (response.data as ScenarioFlow[]).sort((a, b) => a.order - b.order);
                try {
                    for (const responseScenario of responseScenarios) {
                        response = await getDrsDetails(responseScenario.drs_id)
                        responseScenario.drs_name = response.data.name
                    }
                } catch {
                    appStore.showAppSnackBar('error', "DRS info loading error")
                }
                setScenarios(responseScenarios.map((item) => {
                    return {
                        ...item, status: capitalizeFirstLetter(item.status)
                    }
                }))
                setIsChanged(false)
                setIsLoaded(true)
            }).catch(() => {
                appStore.showAppSnackBar("error", "Scenarios loading error")
            }).finally(() => appStore.removeLoading())
        }
    }

    useEffect(() => {

        appStore.addLoading()
        productFlows().then((response) => {
            const responseFlows = objectArrayToFlows(response.data)
            setFlows(responseFlows);
            if (responseFlows.length === 0)
                appStore.showAppSnackBar("info", "No flow found")
            setFlows(responseFlows)
            loadScenarios(responseFlows);
        }).finally(() => appStore.removeLoading())

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const title = "Data processing - Manage Scenarios";

    function onSelected(item) {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        navigate(MANAGE_SCENARIOS_ROUTE + "/" + item.id);
    }

    function onClearButtonClick() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        loadScenarios(flows)
    }

    function onUpdateListButtonClick() {
        appStore.addLoading()
        const payload = {
            removed_scenario_ids: removedItems,
            updated_attributes: scenarios.map((item) => {
                return {
                    scenario_id: item.scenario_id,
                    flow_id: item.flow_id,
                    order: item.order,
                    status: item.status.toLowerCase(),
                }
            })
        };
        updateScenariosByFlow(payload).then(() => {
            appStore.showAppSnackBar("success", "The update was successful")
            setIsChanged(false)
        }).catch(() => {
            appStore.showAppSnackBar("error", "The update failed")
        }).finally(() => appStore.removeLoading())
    }

    function setScenariosStatusToTesting(scenarioList: ScenarioFlow[]): ScenarioFlow[] {
        return scenarioList.map((item) => ({...item, status: ScenarioStatusEnum.testing}))
    }

    function onRemoveScenario(scenariosForRemoval: ScenarioFlow[]) {
        for (let i = 0; i < scenariosForRemoval.length; i++) {
            removedItems.push(scenariosForRemoval[i].scenario_id)
        }
        let scenarioFlowsSorted = removeItems(scenarios, scenariosForRemoval).sort((a, b) => a.order - b.order)
        for (let i = 0; i < scenarioFlowsSorted.length; i++) {
            scenarioFlowsSorted[i].order = i + 1
        }
        scenarioFlowsSorted = setScenariosStatusToTesting(scenarioFlowsSorted)
        setScenarios(scenarioFlowsSorted)
        setRemovedItems(removedItems.slice())
        setIsChanged(true)
    }

    function onOpenScenario(scenario: ScenarioFlow) {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        setSelectedScenario(scenario)
        setScenarioEditDialogOpen(true)
    }

    function onScenarioEditDialogClose() {
        setScenarioEditDialogOpen(false)
        loadScenarios(flows)
    }

    function onOrderChange() {
        let scenarioList = scenarios.slice().sort((a, b) => a.order - b.order);
        scenarioList = setScenariosStatusToTesting(scenarioList)
        setScenarios(scenarioList)
        setIsChanged(true)
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    {
                        id && scenarios.length > 0 &&
                        <>
                            <Button variant="text"
                                    onClick={onClearButtonClick}>
                                Clear
                            </Button>
                            <Button variant="text"
                                    disabled={!isChanged}
                                    onClick={onUpdateListButtonClick}>
                                Update
                            </Button>
                        </>
                    }
                </Grid>
                <Grid container item xs={12} sm={6} spacing={1}>
                    <Grid container item xs={6} justifyContent={"flex-end"} alignItems={"center"}>
                        <BodyText>
                            Show scenarios of selected Flows
                        </BodyText>
                    </Grid>
                    <Grid item xs={6}>
                        <ObjectDropDown items={flows}
                                        selectedItem={flow}
                                        onSelectionChange={onSelected}
                                        inputLabel={"Flows"}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {
                        id &&
                        <>
                            <SectionTitleLeft>{flow.name}</SectionTitleLeft>
                            {
                                scenarios.length > 0 &&
                                <>
                                    <ScenariosTable data={scenarios}
                                                    onRemove={onRemoveScenario}
                                                    onOrderChange={onOrderChange}
                                                    onOpen={onOpenScenario}/>
                                </>
                            }
                            {
                                scenarios.length === 0 && isLoaded &&
                                <Alert severity={'info'}>
                                    There is no scenario for this flow
                                </Alert>
                            }
                        </>
                    }
                </Grid>
            </Grid>
            {
                scenarioEditDialogOpen &&
                <ScenarioEditDialog isDialogOpen={scenarioEditDialogOpen}
                                    isCreate={false}
                                    scenarioId={selectedScenario ? selectedScenario.scenario_id : undefined}
                                    flowName={flow.name}
                                    onBack={onScenarioEditDialogClose}
                                    drs={null as any}
                />
            }
        </>
    )
}

export default ManageScenariosPage