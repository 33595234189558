import React from 'react';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import {InputLabel} from "@mui/material";
import {EnumRecord} from "../../domain/entity/structures/shared/EnumRecord";

interface EnumSelectFormProps {
    value: string | undefined
    onChange: (value: string) => void
    label?: string
    enumRecords: EnumRecord[]
    required?: boolean
    variant?: "standard" | "outlined" | "filled" | undefined
    disabled?: boolean | undefined
    margin?: 'dense' | 'normal' | 'none'
}

const EnumSelectForm = (props: EnumSelectFormProps) => {

    const handleChange = (event: SelectChangeEvent) => {
        props.onChange(event.target.value as string);
    };

    return (
        <FormControl variant={props.variant}
                     margin={props.margin ? props.margin : "dense"}
                     sx={{minWidth: 200, marginRight: 1}}
                     required={props.required ? props.required : false}
                     disabled={props.disabled}
        >
            {
                props.label &&
                <InputLabel id="enum-select-form-label">{props.label ? props.label : ''}</InputLabel>
            }
            <Select
                labelId="enum-select-form-label"
                id="enum-select-form"
                margin="dense"
                required={props.required ? props.required : false}
                value={props.value}
                label={props.label ? props.label : ''}
                onChange={handleChange}
            >
                {props.enumRecords.map((item) => (
                    <MenuItem key={item.key} value={item.value}>{item.value}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default EnumSelectForm;