import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {ArsItem} from "../../../../domain/entity/structures/requirementManagement/ArsItem";
import {ON_FINISH_MESSAGE} from "../../../../utils/consts";
import {ScriptTestResult} from "../../../../domain/entity/structures/dataProcessing/ScriptTestResult";
import {FlowGroup} from "../../../../domain/entity/structures/aiLab/FlowGroup";
import {AiModelFlow} from "../../../../domain/entity/structures/aiLab/AiModelFlow";
import {AiModelOutputAdapter, isAiModelValid} from "../AiModelUtils";
import {analyticsScriptTest, createNewAiModel} from "../../../../http/aiLabAPI";
import RsInfoDialog from "../../dataProcessing/RsInfoDialog";
import AiModelProperties from "../AiModelProperties";
import SelectCreateFlowGroupWidget from "./SelectCreateFlowGroupWidget";
import AiModelCreateScriptWidget from "./AiModelCreateScriptWidget";
import {AiModelTypeEnum} from "../../../../domain/entity/structures/aiLab/AiModelTypeEnum";

interface Props {
    ars: ArsItem
    onBack: () => void
}

const AiModelCreateDialog = observer((props: Props) => {
    const {appStore} = useContext(Context)
    const [ars, setArs] = useState<ArsItem>(props.ars);
    const [rsInfoDialogOpen, setRsInfoDialogOpen] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isTested, setIsTested] = useState<boolean>(false);
    const [supportingFileDescription, setSupportingFileDescription] = useState<string>('');
    const [aiModel, setAiModel] = useState<AiModelFlow>({
        ars_id: "",
        ars_name: "",
        order: 0,
        updated_time: 0,
        updated_by: "",
        create_time: 0,
        created_by: "",
        version: 0,
        supporting_files: [],
        description: "",
        files: [],
        flow_group: {
            id: "",
            name: "",
            flows: [],
        } as FlowGroup,
        id: "",
        model_update_time: 0,
        model_updated_by: "",
        script_version: 0,
        name: "",
        script: "",
        script_id: "",
        script_name: "",
        type: AiModelTypeEnum.scheduled
    });

    useEffect(() => {
        setArs(props.ars)
        setAiModel({...aiModel, ars_id: props.ars.rs_id})

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function clear() {
        setAiModel({
            ars_id: "",
            ars_name: "",
            order: 0,
            updated_time: 0,
            updated_by: "",
            create_time: 0,
            created_by: "",
            version: 0,
            supporting_files: [],
            description: "",
            files: [],
            flow_group: {
                id: "",
                name: "",
                flows: [],
            } as FlowGroup,
            id: "",
            model_update_time: 0,
            model_updated_by: "",
            script_version: 0,
            name: "",
            script: "",
            script_id: "",
            script_name: "",
            type: ""
        });
        setSupportingFileDescription('')
        setIsTested(false)
        setIsChanged(false)
    }

    function onClear() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        clear();
    }

    function onFinish() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onBack()
    }

    function onArsInfoDialogClose() {
        setRsInfoDialogOpen(false)
    }

    function onUpdateAiModel(value: AiModelFlow) {
        setAiModel(value)
        setIsChanged(true)
    }

    function onCreate() {
        if (!isAiModelValid(aiModel, appStore))
            return
        appStore.addLoading()
        createNewAiModel(AiModelOutputAdapter(aiModel)).then(() => {
            appStore.showAppSnackBar("success", "A new AI model is created successfully")
            setIsChanged(false)
            clear()
        }).catch(() => {
            appStore.showAppSnackBar("error", "The creation of AI model failed")
        }).finally(() => appStore.removeLoading())
    }

    function onArsInfo() {
        setRsInfoDialogOpen(true)
    }

    function onUpdateModelScript(value: AiModelFlow) {
        setIsTested(false)
        onUpdateAiModel(value)
    }

    function onTest() {
        if (!aiModel.script) {
            appStore.showAppSnackBar("error",
                "Model script is absent")
            return
        }
        appStore.addLoading()
        analyticsScriptTest({
            script: aiModel.script,
            flow_ids: aiModel.flow_group.flows.map(item => item.id),
            files: aiModel.files
        }).then((response) => {
            const result = response.data as ScriptTestResult
            setIsTested(result.success_syntax)
            if (result.success_syntax && result.success_run) {
                appStore.showAppSnackBar("success", "The script check was successful")
            } else {
                let message = ''
                if (result.syntax_failure_message != null) {
                    message = "Syntax error: " + result.syntax_failure_message + (result.syntax_failure_line ? (" at line " + result.syntax_failure_line) : "")
                }
                if (result.run_failure_message != null) {
                    message = (message === '' ? '' : "\n") + "Run error: " + result.run_failure_message
                }
                if (message === '') {
                    message = "The script check failed"
                }
                appStore.showAppSnackBar("error", message)
            }
        }).catch(() => {
            appStore.showAppSnackBar("error", "The script check failed")
        }).finally(() => appStore.removeLoading())
    }

    function updateSupportingFileDescription(value: string) {
        setIsChanged(true)
        setSupportingFileDescription(value)
    }

    return (
        <>
            <Dialog open={true} maxWidth={'xl'} onClose={props.onBack}>
                <DialogTitle>Create a New AI Model</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                        <Grid container item xs={3}
                              alignContent={"flex-start"}>
                            <InputLabel onClick={onArsInfo}
                                        sx={{cursor: "pointer"}}>ARS: {ars ? ars.name : ''}</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <SelectCreateFlowGroupWidget record={aiModel}
                                                         onUpdate={onUpdateAiModel}/>
                        </Grid>
                        <Grid item xs={3}>
                            <AiModelProperties record={aiModel}
                                               isCreate={true}
                                               onUpdate={onUpdateAiModel}/>
                        </Grid>
                        <Grid item xs={3}>
                            <AiModelCreateScriptWidget record={aiModel}
                                                       onUpdate={onUpdateAiModel}
                                                       setSupportingFileDescription={updateSupportingFileDescription}
                                                       supportingFileDescription={supportingFileDescription}
                                                       onUpdateScript={onUpdateModelScript}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onFinish}>Finish</Button>
                    <Button onClick={onClear} disabled={!isChanged}>Clear</Button>
                    <Button onClick={onTest}>Test</Button>
                    <Button onClick={onCreate} disabled={!isTested}>Create</Button>
                </DialogActions>
            </Dialog>
            {
                rsInfoDialogOpen &&
                <RsInfoDialog isDialogOpen={rsInfoDialogOpen}
                              onBack={onArsInfoDialogClose}
                              rs={ars}
                />
            }
        </>
    )
})

export default AiModelCreateDialog;