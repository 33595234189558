import {List} from '@mui/material';
import React from 'react';
import {RsComment} from "../../domain/entity/structures/requirementManagement/RsComment";
import CommentsListItem from "./CommentsListItem";

interface CommentsListProps {
    comments: RsComment[]
    onDelete: (comment: RsComment) => void
}

const CommentsList = (props: CommentsListProps) => {
    return (
        <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            maxHeight: 100,
            overflowY: 'auto',
        }}>
            {props.comments.map((item) => {
                return (
                    <CommentsListItem comment={item}
                                      key={'ListItem-' + item.number}
                                      onDelete={props.onDelete}
                    />
                );
            })}
        </List>
    );
};

export default CommentsList;