import {makeAutoObservable} from "mobx";
import {AppSnackbarData} from "../domain/entity/structures/shared/AppSnackbarData";
import {AlertColor} from "@mui/material";

export default class AppStore {
    private _appSnackBarData: AppSnackbarData;
    private _loading: number;

    constructor() {
        this._appSnackBarData = {
            isOpen: false,
            severity: "success",
            message: ''
        }
        this._loading = 0
        makeAutoObservable(this)
    }

    get appSnackBarData(): AppSnackbarData {
        return this._appSnackBarData;
    }

    set appSnackBarData(value: AppSnackbarData) {
        this._appSnackBarData = value;
    }

    get loading(): number {
        return this._loading;
    }

    set loading(value: number) {
        this._loading = value;
    }

    setAppSnackBarData(value: AppSnackbarData) {
        this.appSnackBarData = value
    }

    showAppSnackBar(severity: AlertColor, message: string) {
        this.setAppSnackBarData({isOpen: true, severity: severity, message: message})
    }

    addLoading() {
        this.loading++
    }

    removeLoading() {
        this.loading--
    }

    isLoading(): boolean {
        return this.loading > 0
    }

    resetLoading() {
        this.loading = 0
    }
}