import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

interface ScriptViewDialogProps {
    isDialogOpen: boolean
    script: string
    onBack: () => void
}

const ScriptViewDialog = (props: ScriptViewDialogProps) => {

    function onBackButtonClick() {
        props.onBack()
    }

    return (
        <Dialog open={props.isDialogOpen} onClose={props.onBack} maxWidth={'xl'}>
            <DialogTitle>Script</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{minWidth: 400}}
                    margin="dense"
                    id="script-view"
                    type="text"
                    fullWidth
                    multiline
                    rows={15}
                    inputProps={{readOnly: true}}
                    variant="standard"
                    value={props.script}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onBackButtonClick}>Back</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ScriptViewDialog;