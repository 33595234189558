import {EnumRecord} from "../shared/EnumRecord";

export enum ScheduleTypeEnum {
    oneOffNow = "one_off_now",
    oneOffFuture = "one_off_future",
    oneOffRerun = "one_off_rerun",
    recurring = "recurring",
    oneOffValidate = "one_off_validate",
}

export const ScheduleTypeList: EnumRecord[] = Object.entries(ScheduleTypeEnum)
    .map(([key, value]) => ({key, value} as EnumRecord));
