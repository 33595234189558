import React, {useContext, useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid
} from "@mui/material";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Flow} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import FlowsListSingleSelection from "./FlowsListSingleSelection";
import BodyText from "../../shared/BodyText";
import {DataFilter} from "../../../domain/entity/structures/dataVisualisation/DataFilter";
import ConditionSelector from "../../../domain/entity/structures/dataVisualisation/ConditionSelector";
import {Context} from "../../../index";
import {Expression} from "../../../domain/entity/structures/dataVisualisation/Expression";
import {getExpressionForSelectors} from "../../../utils/utils";
import SelectorsWidget from "./SelectorsWidget";
import {VariableType} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/VariableType";
import {LogicOperand} from "../../../domain/entity/structures/dataVisualisation/LogicOperand";
import SelectorTimestamp from "../../../domain/entity/structures/dataVisualisation/SelectorTimestamp";
import {DATE_TIME_INPUT_FORMAT} from "../../../utils/consts";
import moment from "moment";

interface SelectRawDataProps {
    flows: Flow[]
    dialogOpen: boolean
    onAddRawData: (flowId: string, flowName: string, expression: Expression) => void
    onCancel: () => void
    onFinish: () => void
}

interface SelectRawDataState {
    dataFilters: DataFilter[]
    selectors: ConditionSelector[]
}


const SelectRawData = (props: SelectRawDataProps) => {
    const {appStore} = useContext(Context)
    const [selectedFlow, setSelectedFlow] = useState<Flow>(props.flows[0]);
    const [dataStates, setDataStates] = useState<SelectRawDataState[]>(props.flows.map(() => {
        return {
            dataFilters: [],
            selectors: []
        } as SelectRawDataState
    }));

    useEffect(() => {
        const itemIndex = props.flows.indexOf(selectedFlow);
        const currentDataState = dataStates[itemIndex];
        if (currentDataState.dataFilters.length === 0) {
            let tmpDataFilters = [{
                id: "latest_update_timestamp",
                parameterName: "Latest Update Time",
                variableType: VariableType.timestamp,
                definedValues: [],
            } as DataFilter]
            currentDataState.dataFilters = tmpDataFilters
            currentDataState.selectors = (tmpDataFilters.map((item) => {
                return {
                    dataFilter: item,
                    selector: {
                        endTime: moment().format(DATE_TIME_INPUT_FORMAT),
                        startTime: moment().subtract(1, 'day').set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0
                        }).format(DATE_TIME_INPUT_FORMAT)
                    } as SelectorTimestamp
                }
            }))
            refreshDataStates()
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataStates.length, selectedFlow]);


    function onViewSelectedData() {
        const itemIndex = props.flows.indexOf(selectedFlow);
        const currentDataState = dataStates[itemIndex];
        if (currentDataState === undefined) return
        let expressionForSelectors = getExpressionForSelectors(currentDataState.selectors);
        if (!expressionForSelectors || expressionForSelectors.logic !== LogicOperand.and) {
            appStore.showAppSnackBar("error", "Starttime and Endtime should be set")
            return
        }
        const selector = currentDataState.selectors[0].selector as SelectorTimestamp;
        if (selector.endTime <= selector.startTime) {
            appStore.showAppSnackBar("error", "Endtime should be later than Starttime")
            return
        }
        props.onAddRawData(selectedFlow.id, selectedFlow.name, expressionForSelectors)
    }


    function handleAccordionChange(item: Flow) {
        return function (p1: React.SyntheticEvent, p2: boolean) {
            if (p2) {
                setSelectedFlow(item)
            }
        };
    }

    function refreshDataStates() {
        setDataStates(dataStates.slice())
    }

    return (
        <Dialog open={props.dialogOpen} onClose={props.onCancel}>
            <DialogTitle>Select Raw Data</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Box height={'400px'} sx={{overflowY: "auto", overflowX: "hidden"}}>
                            <FlowsListSingleSelection flows={props.flows}
                                                      selectedFlow={selectedFlow}
                                                      setSelected={setSelectedFlow}/>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box height={'400px'}
                             sx={{overflowY: "auto", overflowX: "hidden", padding: "10px"}}>
                            {
                                props.flows.map(item => {
                                    const itemIndex = props.flows.indexOf(item);
                                    return (
                                        <Accordion expanded={selectedFlow === item}
                                                   key={"accordion-" + item.id}
                                                   onChange={handleAccordionChange(item)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <BodyText>
                                                    {item.name}
                                                </BodyText>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {
                                                    dataStates[itemIndex] !== undefined &&
                                                    <SelectorsWidget selectors={dataStates[itemIndex].selectors}
                                                                     refresh={refreshDataStates}/>
                                                }
                                            </AccordionDetails>
                                        </Accordion>);
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={6}>
                        <Button variant={"text"} onClick={onViewSelectedData}>View selected
                            data</Button>
                    </Grid>
                    <Grid item xs={6} textAlign={"right"}>
                        <Button variant={"text"} onClick={props.onCancel}>Close</Button>
                        <Button variant={"text"} onClick={props.onFinish}>Finish</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};

export default SelectRawData;