import React from 'react';
import {Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

interface ObjectsIdNameSingleSelectListProps<T> {
    objs: T[]
    selectedObj: T
    setSelected: (selectedItem: T) => void
}

export default function ObjectsIdNameSingleSelectList<T>(props: ObjectsIdNameSingleSelectListProps<T>) {

    const handleToggle = (value: T) => () => {
        if (props.selectedObj !== null && (props.selectedObj as any).id === (value as any).id) {
            props.setSelected(null as any);
        } else {
            props.setSelected(value);
        }
    };

    return (
        <List sx={{width: '100%'}}>
            {
                props.objs.map((value) => {
                    const labelId = `flows-checkbox-list-label-${(value as any).id}`;

                    return (
                        <ListItem
                            key={'flows-checkbox-' + (value as any).id}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={props.selectedObj !== null && (props.selectedObj as any).id === (value as any).id}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={(value as any).name}/>
                            </ListItemButton>
                        </ListItem>
                    );
                })
            }
        </List>
    );
};
