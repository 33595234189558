import {Grid, InputLabel} from '@mui/material';
import React from 'react';
import {ExecutionDataObject} from "../../../../domain/entity/structures/dataProcessing/ExecutionDataObject";
import SectionTitleLeft from "../../../shared/SectionTitleLeft";
import moment from "moment/moment";
import {DATE_FORMAT} from "../../../../utils/consts";
import BodyText from "../../../shared/BodyText";

interface Props {
    dataObjects: ExecutionDataObject[]
}

const ExecutionResults = (props: Props) => {

    return (
        <>
            <SectionTitleLeft>Execution results</SectionTitleLeft>
            <Grid container>
                {
                    props.dataObjects.map((item) => {
                        return (
                            <Grid container key={item.dataObject.id}>
                                {
                                    item.scenarioExecutions.map((item2) => (
                                        <Grid container item xs={12}
                                              sx={{bgcolor: item2.status === 'error' ? 'error.main' : 'none'}}
                                              key={item.dataObject.id + ' - ' + item2.scenario_id}>
                                            <Grid item xs={2}>
                                                {
                                                    item.scenarioExecutions.indexOf(item2) === 0 &&
                                                    <InputLabel>{item.dataObject.name}</InputLabel>
                                                }
                                            </Grid>
                                            <Grid item xs={2}>
                                                <InputLabel>{item2.scenario_name}</InputLabel>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <InputLabel>{item2.status}</InputLabel>
                                            </Grid>
                                            <Grid item xs={1}>
                                                {
                                                    item2.execution_duration > 0 &&
                                                    <InputLabel>{item2.execution_duration.toFixed(2) + ' s'}</InputLabel>
                                                }
                                            </Grid>
                                            <Grid item xs={2}>
                                                {
                                                    item2.update_time > 0 &&
                                                    <InputLabel>{moment.unix(item2.update_time).format(DATE_FORMAT)}</InputLabel>
                                                }
                                            </Grid>
                                            <Grid item xs={4}>
                                                <BodyText>{item2.errors === '{}' ? '' : item2.errors}</BodyText>
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    );
};

export default ExecutionResults;