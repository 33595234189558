import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import ObjectDropDown from "../shared/ObjectDropDown";
import BodyText from "../shared/BodyText";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";
import ObjectsIdNameMultiselectList from "../dataVisualisation/historicalDashboard/ObjectsIdNameMultiselectList";
import {Context} from "../../index";
import {allocateScenarios} from "../../http/dataProcessingAPI";

interface AllocateScenariosDialogProps {
    isDialogOpen: boolean
    currentDrsName: string
    rsList: IdNameListValues[]
    scenarioList: IdNameListValues[]
    onFinish: () => void
    onLoadScenarios: () => void
}

const AllocateScenariosDialog = (props: AllocateScenariosDialogProps) => {
    const {appStore} = useContext(Context)
    const [selectedScenarios, setSelectedScenarios] = useState<IdNameListValues[]>([]);
    const [rs, setRs] = useState<IdNameListValues | undefined>(undefined);

    function onUpdate() {
        if (selectedScenarios.length === 0) return
        if (rs === undefined) return

        appStore.addLoading()
        allocateScenarios({
            scenario_ids: selectedScenarios.map((item) => {
                return item.id
            }),
            drs_id: rs.id
        }).then((response) => {
            appStore.showAppSnackBar("success", "Scenarios have been allocated")
            props.onLoadScenarios()
        }).catch(reason => {
            appStore.showAppSnackBar("error", "Scenarios allocation error")
        }).finally(() => appStore.removeLoading())
    }

    function onSelected(item: any) {
        setRs(item)
    }

    function onScenariosSelecionChange() {
        setSelectedScenarios(selectedScenarios.slice())
    }

    return (
        <Dialog open={props.isDialogOpen} fullWidth onClose={props.onFinish}>
            <DialogTitle>{"Allocate Scenarios"}</DialogTitle>
            <DialogContent>
                <BodyText>Current DRS: {props.currentDrsName}</BodyText>
                <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                    <Grid item xs={6}>
                        <ObjectsIdNameMultiselectList objs={props.scenarioList}
                                                      selectedObjs={selectedScenarios}
                                                      refresh={onScenariosSelecionChange}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ObjectDropDown items={props.rsList}
                                        selectedItem={rs === undefined ? '' : rs}
                                        onSelectionChange={onSelected}
                                        inputLabel={"DRS Items"}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onFinish}>Finish</Button>
                <Button disabled={selectedScenarios.length === 0 || rs === undefined}
                        onClick={onUpdate}>Update</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AllocateScenariosDialog;