import React from 'react';
import {DisplayColumn} from "../../../../domain/entity/structures/dataVisualisation/DisplayColumn";
import BodyText from "../../../shared/BodyText";
import {Alert, Button, Grid} from "@mui/material";

interface CreatePieChartWidgetProps {
    selectedColumns: DisplayColumn[]
    onAdd: () => void
}

const CreatePieChartWidget = (props: CreatePieChartWidgetProps) => {
    return (
        <Grid container>
            {
                props.selectedColumns.length === 0 &&
                <Alert severity={'info'}>Select several columns from the columns list</Alert>
            }
            <Grid container item justifyContent={"space-around"}>
                <BodyText>Axis</BodyText>
            </Grid>
            <Grid item xs={12}>
                {
                    props.selectedColumns.length > 0 &&
                    <BodyText
                        key={"line-column-" + props.selectedColumns[0]}>{props.selectedColumns[0].column.columnName}</BodyText>
                }
            </Grid>
            <Grid container item justifyContent={"space-around"}>
                <BodyText>Value</BodyText>
            </Grid>
            <Grid item xs={12}>
                {
                    props.selectedColumns.map((item) => (
                        props.selectedColumns.indexOf(item) > 0 &&
                        <BodyText key={"line-column-" + item.id}>{item.column.columnName}</BodyText>
                    ))
                }
            </Grid>
            <Grid item xs={12}>
                <Button variant="text"
                        fullWidth
                        disabled={props.selectedColumns.length < 2}
                        onClick={props.onAdd}>
                    Add
                </Button>
            </Grid>
        </Grid>
    );
};

export default CreatePieChartWidget;