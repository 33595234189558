import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import EnumSelectForm from "./EnumSelectForm";
import {LinkTypeEnum, LinkTypeList} from "../../domain/entity/structures/requirementManagement/LinkTypeEnum";
import ObjectDropDown from "../shared/ObjectDropDown";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";

interface AddLinkDialogProps {
    isDialogOpen: boolean
    isDrs: boolean
    rsList: IdNameListValues[]
    onAddLink: (linkType: LinkTypeEnum, rsId: IdNameListValues) => void
    onClose: () => void
}

const AddLinkDialog = (props: AddLinkDialogProps) => {
    const [linkType, setLinkType] = useState<LinkTypeEnum | undefined>(undefined);
    const [rs, setRs] = useState<IdNameListValues | undefined>(undefined);

    function onAdd() {
        if (linkType === undefined) return
        if (rs === undefined) return
        props.onAddLink(linkType, rs)
        props.onClose()
    }

    function onLinkTypeSelectChange(value: string) {
        setLinkType(value as LinkTypeEnum);
    }

    function onSelected(item: any) {
        setRs(item)
    }

    return (
        <Dialog open={props.isDialogOpen} fullWidth onClose={props.onClose}>
            <DialogTitle>{"Add Link"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                    <Grid item xs={6}>
                        <EnumSelectForm value={linkType === undefined ? '' : linkType.toString()}
                                        onChange={onLinkTypeSelectChange}
                                        enumRecords={LinkTypeList}
                                        variant="outlined"
                                        required
                                        label={"Link Type"}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ObjectDropDown items={props.rsList}
                                        selectedItem={rs === undefined ? '' : rs}
                                        onSelectionChange={onSelected}
                                        inputLabel={props.isDrs ? "DRS Items" : "ARS Items"}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
                <Button disabled={linkType === undefined || rs === undefined}
                        onClick={onAdd}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddLinkDialog;