import {$requirementHost, axiosConfigJson} from './index';

export const allProjects = async () => {
    return await $requirementHost.get('ui/allprojects')
}

export const getDrsOfProject = async (projectId: string) => {
    return await $requirementHost.get('ui/getdrsofproject?project_id=' + projectId)
}

export const getArsOfProject = async (projectId: string) => {
    return await $requirementHost.get('ui/getarsofproject?project_id=' + projectId)
}

export const createProject = async (payload: any) => {
    return await $requirementHost.post('ui/createproject', payload, axiosConfigJson)
}

export const updateProject = async (payload: any) => {
    return await $requirementHost.post('ui/updateproject', payload, axiosConfigJson)
}

export const getaProject = async (projectId: string) => {
    return await $requirementHost.get('ui/getaproject?project_id=' + projectId)
}

export const deleteProject = async (projectId: string) => {
    return await $requirementHost.delete('ui/deleteproject?project_id=' + projectId)
}

export const retrieveDrsItem = async (drsId: string) => {
    return await $requirementHost.get('ui/retrievedrsitem?drs_id=' + drsId)
}

export const createDrsItem = async (payload: any) => {
    return await $requirementHost.post('ui/createdrsitem', payload, axiosConfigJson)
}

export const updateDrsItem = async (payload: any) => {
    return await $requirementHost.post('ui/updatedrsitem', payload, axiosConfigJson)
}

export const deleteDrsItem = async (drsId: string) => {
    return await $requirementHost.delete('ui/deletedrsitem?drs_id=' + drsId)
}

export const retrieveArsItem = async (arsId: string) => {
    return await $requirementHost.get('ui/retrievearsitem?ars_id=' + arsId)
}

export const createArsItem = async (payload: any) => {
    return await $requirementHost.post('ui/createarsitem', payload, axiosConfigJson)
}

export const updateArsItem = async (payload: any) => {
    return await $requirementHost.post('ui/updatearsitem', payload, axiosConfigJson)
}

export const deleteArsItem = async (arsId: string) => {
    return await $requirementHost.delete('ui/deletearsitem?ars_id=' + arsId)
}

export const getModelReviews = async (drsId: string, scenarioId: string) => {
    return await $requirementHost.get('ui/getmodelreviews?drs_id=' + drsId + '&scenario_id=' + scenarioId)
}

export const saveModelReviews = async (payload: any) => {
    return await $requirementHost.post('ui/savemodelreviews', payload, axiosConfigJson)
}

export const getDrsDetails = async (drsId: string) => {
    return await $requirementHost.get('ui/getdrsdetails?drs_id=' + drsId)
}

export const getArsDetails = async (arsId: string) => {
    return await $requirementHost.get('ui/getarsdetails?ars_id=' + arsId)
}