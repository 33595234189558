import {EnumRecord} from "../shared/EnumRecord";

export enum StateEnum {
    new = "New",
    active = "Active",
    qa = "QA",
    completed = "Completed",
}

export const StateList: EnumRecord[] = Object.entries(StateEnum)
    .map(([key, value]) => ({key, value} as EnumRecord));
