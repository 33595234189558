import {$host, axiosConfigJson, axiosConfigMultipart} from './index';

export const createNewAiModel = async (payload: any) => {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
        if ([
            'ars_id',
            'flow_group_id',
            'ai_model_name',
            'type',
            'ai_model_description',
            'script_name',
            'script',
        ].find(e => e === key))
            formData.append(key, payload[key])
    })
    for (const file of payload.files) {
        formData.append('files', file, file.name)
    }
    formData.append('supporting_files', JSON.stringify(payload.supporting_files))
    return await $host.post('ui/createnewaimodel', formData, axiosConfigMultipart)
}

export const createFlowGroup = async (payload: any) => {
    return await $host.post('ui/createflowgroup', payload, axiosConfigJson)
}

export const analyticsScriptTest = async (payload: any) => {
    const formData = new FormData();
    formData.append('script', payload.script)
    formData.append('flow_ids', JSON.stringify(payload.flow_ids))
    for (const file of payload.files) {
        formData.append('files', file, file.name)
    }
    return await $host.post('ui/analytics_script_test', formData, axiosConfigMultipart)
}

export const getAiModelsByFlow = async (flowGroupId: string) => {
    return await $host.get('ui/getaimodelsbyflow?flow_group_id=' + flowGroupId)
}

export const getOpenSchedules = async () => {
    return await $host.get('ui/getopenschedules')
}

export const getJobExecutionResults = async () => {
    return await $host.get('ui/getjobexecutionresults')
}

export const updateAiModel = async (payload: any) => {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
        if ([
            'ai_model_id',
            'ai_model_name',
            'type',
            'ai_model_description',
            'script_name',
            'script',
        ].find(e => e === key))
            formData.append(key, payload[key])
    })
    for (const file of payload.files) {
        formData.append('files', file, file.name)
    }
    formData.append('supporting_files', JSON.stringify(payload.supporting_files))
    return await $host.post('ui/updateaimodel', formData, axiosConfigMultipart)
}

export const deleteAiModels = async (payload: any) => {
    return await $host.post('ui/deleteaimodels', payload, axiosConfigJson)
}

export const deleteSchedules = async (payload: any) => {
    return await $host.post('ui/deleteschedules', payload, axiosConfigJson)
}

export const retrieveAiModel = async (aiModelId: string) => {
    return await $host.get('ui/retrieveaimodel?ai_model_id=' + aiModelId)
}

export const getScheduleModelByFlow = async (flowGroupId: string) => {
    return await $host.get('ui/getschedulemodelbyflow?flow_group_id=' + flowGroupId)
}

export const validateAiModels = async (payload: any) => {
    return await $host.post('ui/validateaimodels', payload, axiosConfigJson)
}

export const createScheduledJob = async (payload: any) => {
    return await $host.post('ui/createscheduledjob', payload, axiosConfigJson)
}