import React from 'react';
import {Alert, Button, FormControl, FormLabel, Grid} from "@mui/material";
import ObjectsIdNameSingleSelectList from "./ObjectsIdNameSingleSelectList";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";

interface ArsAiModelWidgetProps {
    aiModels: IdNameListValues[]
    selectedAiModel: IdNameListValues
    onCreateAiModel: () => void
    onUpdateAiModel: () => void
    onReallocateAiModels: () => void
    onReviewAiModel: () => void
    setSelectedAiModel: (aiModel: IdNameListValues) => void
    isReallocateEnabled: boolean
}

const ArsAiModelWidget = (props: ArsAiModelWidgetProps) => {
    return (
        <FormControl aria-labelledby={"aiModel-list-label"} fullWidth margin={'dense'}>
            <FormLabel id="aiModel-list-label">Associated AI Models</FormLabel>
            {
                props.aiModels.length > 0 &&
                <ObjectsIdNameSingleSelectList objs={props.aiModels}
                                               selectedObj={props.selectedAiModel}
                                               setSelected={props.setSelectedAiModel}/>
            }
            {
                props.aiModels.length === 0 &&
                <Alert severity={'info'}>No AI Models created</Alert>
            }
            <Grid container>
                <Button disabled={props.selectedAiModel === null}
                        onClick={props.onReviewAiModel}>Review an AiModel</Button>
                <Button disabled={props.selectedAiModel === null}
                        onClick={props.onUpdateAiModel}>Update an AiModel</Button>
                <Button onClick={props.onCreateAiModel}>Create an AiModel</Button>
                <Button onClick={props.onReallocateAiModels}
                        disabled={!props.isReallocateEnabled}>Reallocate AiModels</Button>
            </Grid>
        </FormControl>
    );
};

export default ArsAiModelWidget;