import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../../../index";
import {User} from "../../../domain/entity/structures/adminisrator/userManagement/User";
import {UserProfile} from "../../../domain/entity/structures/adminisrator/userManagement/UserProfile";
import Helmet from "react-helmet";
import PageTitle from "../../../components/shared/PageTitle";
import {Alert, Button, Grid, TextField} from "@mui/material";
import {UserEdit} from "../../../domain/entity/structures/adminisrator/userManagement/UserEdit";
import {
    HOME_ROUTE,
    LOGIN_ROUTE,
    USER_MANAGEMENT_ROUTE,
    USER_ROLES_WITH_ADMINISTRATOR,
    USER_ROLES_WITHOUT_ADMINISTRATOR
} from "../../../utils/consts";
import {createUpdateUser, loadUser} from "../../../http/adminAPI";
import ObjectsIdNameMultiselectList
    from "../../../components/dataVisualisation/historicalDashboard/ObjectsIdNameMultiselectList";
import {RolesEnum} from "../../../domain/entity/structures/requirementManagement/RolesEnum";
import {IdNameListValues} from "../../../domain/entity/structures/shared/IdNameListValues";
import {observer} from "mobx-react-lite";
import {getFormattedDate} from "../../../utils/utils";

const UserPage = observer(() => {
    let navigate = useNavigate();
    const {id} = useParams()
    const {appStore, userStore} = useContext(Context)
    const [newMode, setNewMode] = useState(false);
    const [user, setUser] = useState<UserEdit>({
        user: {
            user_id: "",
            loginId: "",
            password: "",
            profile: {
                company: "",
                contactNumberLandline: "",
                contactNumberMobile: "",
                email: "",
                firstName: "",
                jobTitle: "",
                profession: "",
                role: [],
                surName: ""
            } as UserProfile
        } as User, password: ""
    } as UserEdit);

    const [roles, setRoles] = useState<IdNameListValues[]>(USER_ROLES_WITH_ADMINISTRATOR)
    const [selectedRoles, setSelectedRoles] = useState<IdNameListValues[]>([])

    useEffect(() => {
        if (userStore.isAdministrator())
            setRoles(USER_ROLES_WITH_ADMINISTRATOR)
        else
            setRoles(USER_ROLES_WITHOUT_ADMINISTRATOR)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore]);

    useEffect(() => {
        if (id === 'new') {
            setNewMode(true)
        } else {
            appStore.addLoading()
            loadUser(id as string).then((response) => {
                const loadedUser = response.data;
                setUser({user: loadedUser, password: ''})
                let selected: IdNameListValues[] = []
                roles.forEach((item) => {
                    loadedUser.profile.role.forEach((userRole) => {
                        if (item.id === userRole)
                            selected.push(item)
                    })
                })
                setSelectedRoles(selected)
            }).finally(() => appStore.removeLoading())
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore, id, roles]);

    const title = "User"

    function onMainFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        if (name === 'loginId')
            setUser({...user, user: {...user.user, loginId: target.value}});
        if (name === 'firstPassword')
            setUser({...user, user: {...user.user, password: target.value}});
        if (name === 'secondPassword')
            setUser({...user, password: target.value});
    }

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        setUser({...user, user: {...user.user, profile: {...user.user.profile, [name]: target.value}}});
    }

    function onSaveButtonClick() {
        if (!isUserValid())
            return
        appStore.addLoading()
        const payload = {
            ...user.user, profile: {
                ...user.user.profile, role: selectedRoles.map((item) => {
                    return item.id as RolesEnum
                })
            }
        };
        createUpdateUser(payload).then((response) => {
            appStore.showAppSnackBar("success", "User has been " + (newMode ? "created" : "saved"))
            if (newMode && !userStore.isAdministrator())
                navigate(LOGIN_ROUTE);
            if (!newMode && userStore.isAdministrator())
                navigate(USER_MANAGEMENT_ROUTE);
        }).catch(reason => {
            if (reason.response.status === 500)
                appStore.showAppSnackBar("error", reason.response.data.message)
            else
                appStore.showAppSnackBar("error", reason.response.data.detail)
        }).finally(() => appStore.removeLoading())
    }

    function onCancelButtonClick() {
        if (userStore.isAdministrator())
            navigate(USER_MANAGEMENT_ROUTE)
        else
            navigate(HOME_ROUTE)
    }

    function refreshSelectedRoles() {
        setSelectedRoles(selectedRoles.slice())
    }

    function isUserValid() {
        if (user.user.loginId === '') {
            appStore.showAppSnackBar('error', "Login id must be filled");
            return false
        }
        if (user.user.password === '' && newMode) {
            appStore.showAppSnackBar('error', "Password can't be empty");
            return false
        }
        if (user.password !== user.user.password && newMode) {
            appStore.showAppSnackBar('error', "Both passwords must be the same");
            return false
        }
        return true
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button variant="text"
                            onClick={onSaveButtonClick}>
                        Save
                    </Button>
                    <Button variant="text"
                            onClick={onCancelButtonClick}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="edit-user-1"
                        autoFocus
                        margin="dense"
                        required
                        label="Login id"
                        type="text"
                        disabled={!newMode}
                        fullWidth
                        inputProps={{maxLength: 50}}
                        variant="standard"
                        name={'loginId'}
                        value={user.user.loginId}
                        onChange={onMainFieldChange}
                    />
                    {
                        newMode &&
                        <TextField
                            id="edit-user-2"
                            margin="dense"
                            label="Password"
                            type="password"
                            fullWidth
                            inputProps={{maxLength: 50}}
                            variant="standard"
                            name={'firstPassword'}
                            value={user.user.password}
                            onChange={onMainFieldChange}
                        />
                    }
                    {
                        newMode &&
                        <TextField
                            id="edit-user-3"
                            margin="dense"
                            label="Repeat password"
                            type="password"
                            inputProps={{maxLength: 50}}
                            fullWidth
                            variant="standard"
                            name={'secondPassword'}
                            value={user.password}
                            onChange={onMainFieldChange}
                        />
                    }
                    <TextField
                        id="edit-user-4"
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="standard"
                        name={'email'}
                        value={user.user.profile.email}
                        onChange={onFieldChange}
                    />
                    <ObjectsIdNameMultiselectList objs={roles}
                                                  selectedObjs={selectedRoles}
                                                  refresh={refreshSelectedRoles}/>
                    {
                        !newMode &&
                        <Alert
                            severity={'info'}>{"Latest logout time: " + getFormattedDate(user.user.latestLogoutTime)}</Alert>
                    }

                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="edit-user-5"
                        autoFocus
                        margin="dense"
                        label="First name"
                        type="text"
                        fullWidth
                        variant="standard"
                        name={'firstName'}
                        value={user.user.profile.firstName}
                        onChange={onFieldChange}
                    />
                    <TextField
                        margin="dense"
                        id="edit-user-6"
                        label="Second name"
                        type="text"
                        fullWidth
                        variant="standard"
                        name={'surName'}
                        value={user.user.profile.surName}
                        onChange={onFieldChange}
                    />
                    <TextField
                        id="edit-user-7"
                        margin="dense"
                        label="Profession"
                        type="text"
                        fullWidth
                        variant="standard"
                        name={'profession'}
                        value={user.user.profile.profession}
                        onChange={onFieldChange}
                    />
                    <TextField
                        margin="dense"
                        id="edit-user-8"
                        label="Job title"
                        type="text"
                        fullWidth
                        variant="standard"
                        name={'jobTitle'}
                        value={user.user.profile.jobTitle}
                        onChange={onFieldChange}
                    />
                    <TextField
                        margin="dense"
                        id="edit-user-9"
                        label="Company"
                        type="text"
                        fullWidth
                        variant="standard"
                        name={'company'}
                        value={user.user.profile.company}
                        onChange={onFieldChange}
                    />
                    <TextField
                        margin="dense"
                        id="edit-user-10"
                        label="Contact number mobile"
                        type="tel"
                        fullWidth
                        variant="standard"
                        name={'contactNumberMobile'}
                        value={user.user.profile.contactNumberMobile}
                        onChange={onFieldChange}
                    />
                    <TextField
                        margin="dense"
                        id="edit-user-11"
                        label="Contact number land line"
                        type="tel"
                        fullWidth
                        variant="standard"
                        name={'contactNumberLandline'}
                        value={user.user.profile.contactNumberLandline}
                        onChange={onFieldChange}
                    />
                </Grid>
            </Grid>
        </>
    );
});

export default UserPage;