import {$authHost, axiosConfigJson} from "./index";

export const userList = async () => {
    return await $authHost.get('api/admin/userlist')
}

export const createUpdateUser = async (payload: any) => {
    return await $authHost.post('api/admin/createupdateuser', payload, axiosConfigJson)
}

export const loadUser = async (userId: string) => {
    return await $authHost.get('api/user/?user_id=' + userId)
}

export const deleteUser = async (userId: string) => {
    return await $authHost.delete('api/admin/delete?user_id=' + userId)
}

export const updateUsers = async (payload: any) => {
    return await $authHost.post('api/admin/updateusers', payload, axiosConfigJson)
}