import React, {useContext} from 'react';
import {Alert, Snackbar} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {DEFAULT_SNACKBAR_AUTOHIDE_DURATION} from "../../utils/consts";
import BodyText from "./BodyText";

const AppSnackbar = observer(() => {
    const {appStore} = useContext(Context)

    function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
            return;
        }
        appStore.setAppSnackBarData({...appStore.appSnackBarData, isOpen: false})
    }

    const lines = appStore.appSnackBarData.message.split("\n");
    return (
        <Snackbar open={appStore.appSnackBarData.isOpen}
                  autoHideDuration={DEFAULT_SNACKBAR_AUTOHIDE_DURATION}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  onClose={handleClose}>
            <Alert onClose={handleClose} severity={appStore.appSnackBarData.severity} sx={{width: '100%'}}>
                {
                    lines.map((item) => (
                        <BodyText key={'line-' + lines.indexOf(item)}>{item}</BodyText>
                    ))
                }
            </Alert>
        </Snackbar>
    );
});

export default AppSnackbar;