import React from 'react';
import ConditionSelector from "../../../domain/entity/structures/dataVisualisation/ConditionSelector";
import {Card, CardContent, Grid} from "@mui/material";
import BodyText from "../../shared/BodyText";
import {VariableType} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/VariableType";
import SelectorStringWidget from "./SelectorStringWidget";
import SelectorIntegerWidget from "./SelectorIntegerWidget";
import SelectorFloatWidget from "./SelectorFloatWidget";
import SelectorBooleanWidget from "./SelectorBooleanWidget";
import SelectorTimestampWidget from "./SelectorTimestampWidget";
import SelectorEnumWidget from "./SelectorEnumWidget";

interface SelectorsWidgetProps {
    selectors: ConditionSelector[]
    refresh: () => void
}

const SelectorsWidget = ({selectors, refresh}: SelectorsWidgetProps) => {
    return (
        <Grid container spacing={1}>
            {
                selectors.map((item) => (
                    <Grid xs={12} item key={"flow-filter-" + selectors.indexOf(item)}>
                        <Card variant={"outlined"} sx={{width: "calc(100% - 1em)"}}>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <BodyText>{item.dataFilter.parameterName + ' (' + item.dataFilter.variableType + ')'}</BodyText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {item.dataFilter.variableType === VariableType.bool &&
                                            <SelectorBooleanWidget selector={item}
                                                                   refresh={refresh}/>}
                                        {item.dataFilter.variableType === VariableType.string &&
                                            <SelectorStringWidget selector={item}
                                                                  refresh={refresh}/>}
                                        {item.dataFilter.variableType === VariableType.enum &&
                                            <SelectorEnumWidget selector={item}
                                                                refresh={refresh}/>}
                                        {item.dataFilter.variableType === VariableType.timestamp &&
                                            <SelectorTimestampWidget selector={item}
                                                                     refresh={refresh}/>}
                                        {item.dataFilter.variableType === VariableType.integer &&
                                            <SelectorIntegerWidget selector={item}
                                                                   refresh={refresh}/>}
                                        {item.dataFilter.variableType === VariableType.float &&
                                            <SelectorFloatWidget selector={item}
                                                                 refresh={refresh}/>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            }
        </Grid>
    );
};

export default SelectorsWidget;