import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import PageTitle from "../../../components/shared/PageTitle";
import {observer} from "mobx-react-lite";
import Grid from "@mui/material/Grid";
import {Button} from "@mui/material";
import UsersTable from "../../../components/administrator/userManagement/UsersTable";
import {Context} from "../../../index";
import {User} from "../../../domain/entity/structures/adminisrator/userManagement/User";
import {deleteUser, updateUsers, userList} from "../../../http/adminAPI";
import {useNavigate} from "react-router-dom";
import {HOME_ROUTE, USER_ROUTE} from "../../../utils/consts";
import NewPasswordDialog from "../../../components/administrator/userManagement/NewPasswordDialog";

const UserManagementPage = observer(() => {
    let navigate = useNavigate();
    const {appStore} = useContext(Context)
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [newPasswordDialogOpen, setNewPasswordDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        appStore.addLoading()
        userList().then(response => {
            const data = response.data as User[];
            setUsers(data)
        }).finally(() => {
            appStore.removeLoading();
        })
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddButtonClick() {
        navigate(USER_ROUTE + '/new')
    }

    function onEditButtonClick() {
        navigate(USER_ROUTE + '/' + selectedUsers[0].user_id)
    }

    function onRemoveButtonClick() {
        selectedUsers.forEach((item) => {
            appStore.addLoading()
            deleteUser(item.user_id).then((response) => {
                if (response.status === 204) {
                    appStore.showAppSnackBar("success", "User has been deleted")
                    appStore.addLoading()
                    userList().then(response => {
                        const data = response.data as User[];
                        setUsers(data)
                    }).finally(() => {
                        appStore.removeLoading();
                    })
                } else {
                    appStore.showAppSnackBar("error", response.data.detail)
                }
            }).finally(() => appStore.removeLoading())
        })
        setSelectedUsers([])
    }

    function onCancelButtonClick() {
        navigate(HOME_ROUTE);
    }

    function onResetPasswordButtonClick() {
        setNewPasswordDialogOpen(true)
    }

    function onNewPasswordDialogClose() {
        setNewPasswordDialogOpen(false)
    }

    function onSetPassword(value: string) {
        appStore.addLoading()
        const payload = {
            userIds: selectedUsers.map(item => item.user_id),
            password: value
        }
        updateUsers(payload).then(() => {
            appStore.showAppSnackBar("success", "Password(s) was(were) updated")
        }).catch(() => {
            appStore.showAppSnackBar("error", "Password(s) was(were) not updated")
        }).finally(() => {
            setNewPasswordDialogOpen(false)
            appStore.removeLoading();
        })
    }

    return (
        <>
            <Helmet title={"User Management"}/>
            <PageTitle>
                User Management
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button variant="text"
                            onClick={onAddButtonClick}>
                        Add
                    </Button>
                    <Button variant="text"
                            disabled={selectedUsers.length !== 1}
                            onClick={onEditButtonClick}>
                        Edit
                    </Button>
                    <Button variant="text"
                            disabled={selectedUsers.length === 0}
                            onClick={onRemoveButtonClick}>
                        Remove
                    </Button>
                    <Button variant="text"
                            onClick={onCancelButtonClick}>
                        Cancel
                    </Button>
                    <Button variant="text"
                            disabled={selectedUsers.length === 0}
                            onClick={onResetPasswordButtonClick}>
                        Reset password
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <UsersTable data={users}
                                selectedItems={selectedUsers}
                                onSelectionChange={e => setSelectedUsers(e)}
                    />
                </Grid>
            </Grid>
            {
                newPasswordDialogOpen &&
                <NewPasswordDialog isDialogOpen={newPasswordDialogOpen}
                                   onCancel={onNewPasswordDialogClose}
                                   onSetPassword={onSetPassword}
                />
            }
        </>
    );
});

export default UserManagementPage;