import React, {useState} from 'react';
import {Button, InputLabel} from "@mui/material";
import FileSaver from 'file-saver'
import {AiModelFlow} from "../../../../domain/entity/structures/aiLab/AiModelFlow";
import FileUploader from "../../../administrator/dataFlowManagement/FileUploader";
import ScriptViewDialog from "../../dataProcessing/ScriptViewDialog";

interface Props {
    record: AiModelFlow
    newScript: string
    newScriptName: string
    onUpdateScript: (script: string, name: string) => void
}

const AiModelScriptWidget = (props: Props) => {
    const [scriptViewDialogOpen, setScriptViewDialogOpen] = useState<boolean>(false);

    function processScriptFile(files: FileList) {
        const fileName: string = files[0].name.substring(0, files[0].name.lastIndexOf('.'))
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            let target = e.target;
            if (target !== null) {
                const text: string = target.result as string
                props.onUpdateScript(text, fileName)
            }
        };
        reader.readAsText(files[0]);
    }

    function exportCurrentScript() {
        const blob = new Blob([props.record.script as any], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, props.record.script_name + '.py');
    }

    return (
        <>
            <InputLabel>{"Current script: " + props.record.script_name}</InputLabel>
            <InputLabel>{"Latest version: " + props.record.script_version}</InputLabel>
            <Button variant="text"
                    onClick={() => setScriptViewDialogOpen(true)}>
                View current script
            </Button>
            <Button variant="text"
                    onClick={exportCurrentScript}>
                Export current script
            </Button>
            <InputLabel>{props.newScriptName === '' ? '' : (props.newScriptName + '.py')}</InputLabel>
            <FileUploader processResult={processScriptFile}
                          keyPart={'upload-upload-model-script'}
                          multiple={false}
                          accept={".py"}>
                <Button variant="text" component={"span"}>
                    Upload a new script
                </Button>
            </FileUploader>
            {
                scriptViewDialogOpen &&
                <ScriptViewDialog isDialogOpen={scriptViewDialogOpen}
                                  onBack={() => setScriptViewDialogOpen(false)}
                                  script={props.record.script}/>
            }
        </>
    );
};

export default AiModelScriptWidget;