import {$host, axiosConfigJson, axiosConfigMultipart} from './index';
import NewFlow from "../domain/entity/structures/adminisrator/dataFlowManagement/NewFlow";
import UserMetaDataRecord from "../domain/entity/structures/adminisrator/dataFlowManagement/UserMetaDataRecord";

export const allAllFlows = async () => {
    return await $host.get('ui/allallflows')
}

export const allFlows = async () => {
    return await $host.get('ui/allflows')
}

export const productFlows = async () => {
    return await $host.get('ui/productflows')
}

export const resultFlows = async () => {
    return await $host.get('ui/resultflows')
}

export const allFlowFilters = async () => {
    return await $host.get('ui/allflowfilters')
}

export const flowMAttribute = async (flowId: string) => {
    return await $host.get('ui/flowmattribute?flow_id=' + flowId, axiosConfigJson)
}

export const updateFlowFilters = async (flowFilters: any[]) => {
    const payload = {
        flow_filter_parameters: flowFilters
    }
    return await $host.post('ui/updateflowfilters', payload, axiosConfigJson)
}

export const flowColumns = async (flowId: string) => {
    return await $host.get('ui/flowcolumns?flow_id=' + flowId, axiosConfigJson)
}

export const flowDataObjects = async (flowId: string) => {
    return await $host.get('ui/flowdataobjects?flow_id=' + flowId)
}

export const dataFilterParameters = async (flowId: string) => {
    return await $host.get('ui/datafilterparameters?flow_id=' + flowId, axiosConfigJson)
}

export const updateDataFilters = async (dataFilters: any[], flowId: string) => {
    const payload = {
        data_filter_parameters: dataFilters,
        flow_id: flowId
    }
    return await $host.post('ui/updatedatafilters', payload, axiosConfigJson)
}

export const createFlow = async (newFlow: NewFlow) => {
    const formData = new FormData();
    formData.append('form_data', JSON.stringify({
        flow_name: newFlow.name,
        user_meta_data: newFlow.metadata
    }))
    for (const file of newFlow.dataObjects) {
        formData.append('file', file)
    }
    return await $host.post('ui/createflow', formData, axiosConfigMultipart)
}

export const flowMetaData = async (flowId: string) => {
    return await $host.get('ui/flowmmetadata?flow_id=' + flowId, axiosConfigJson)
}

export const updateUserMetadata = async (flowId: string, records: UserMetaDataRecord[]) => {
    const payload = {
        records: records,
        flow_id: flowId
    }
    return await $host.post('ui/updateusermetadata', payload, axiosConfigJson)
}

export const manageFlow = async (payload: any) => {
    return await $host.post('ui/manageflow', payload, axiosConfigJson)
}

export const dataForFlow = async (payload: any) => {
    const formData = new FormData();
    formData.append('flow_id', payload.flow_id)
    formData.append('flow_name', payload.flow_name)
    for (const file of payload.files) {
        formData.append('files', file, file.name)
    }
    return await $host.post('ui/dataforflow', formData, axiosConfigMultipart)
}

export const deleteFlow = async (flowId: string) => {
    return await $host.delete('ui/deleteflow?flow_id=' + flowId)
}

export const allFlowGroups = async () => {
    return await $host.get('ui/allflowgroups')
}
