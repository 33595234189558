import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface ObjectIdNameDropDownProps<T> {
    objs: T[]
    selectedObj: T
    onSelectionChange: (obj: T) => void
    label: string
    disabled?: boolean
}

export default function ObjectIdNameDropDown<T>({
                                                    objs,
                                                    selectedObj,
                                                    onSelectionChange,
                                                    label,
                                                    disabled
                                                }: ObjectIdNameDropDownProps<T>) {

    const handleChange = (event: SelectChangeEvent<T>) => {
        const value: T = event.target.value as T
        onSelectionChange(value)
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="drop-down-select-label">{label}</InputLabel>
            <Select
                labelId="drop-down-select-label"
                disabled={disabled || false}
                id="drop-down-select"
                value={selectedObj || ('' as any)}
                renderValue={(selected: T) => {
                    return (selected as any).name;
                }}
                label={label}
                onChange={handleChange}
            >
                {objs.map((item: T) => (
                    <MenuItem key={(item as any).id} value={item as any}>
                        {(item as any).name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}