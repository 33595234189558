import React, {useContext} from 'react';
import {Button, Grid, InputLabel, ListItem, TextField} from "@mui/material";
import {
    SupportingFileRecordWithDescription
} from "../../../../domain/entity/structures/dataProcessing/SupportingFileRecordWithDescription";
import FileUploader from "../../../administrator/dataFlowManagement/FileUploader";
import {Context} from "../../../../index";

interface Props {
    record: SupportingFileRecordWithDescription
    onRefresh: () => void
    onFileUpload: (file: File) => void
    onFileRemove: (record: SupportingFileRecordWithDescription) => void
}

const SupportingFilesListItem = (props: Props) => {
    const {appStore} = useContext(Context)
    const labelId = `link-list-item-label-${props.record.file_id}`;

    function processSupportingFile(fileList: FileList) {
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].name !== props.record.file_name) {
                appStore.showAppSnackBar('error', "File name should be the same as for old one")
            } else {
                props.onFileUpload(fileList[i])
            }
        }
    }

    function onRemove() {
        props.onFileRemove(props.record)
    }

    function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        props.record.file_description = target.value
        props.onRefresh()
    }

    return (
        <ListItem
            disablePadding
        >
            <Grid container item xs={12} sm={12} alignContent={"flex-start"}>
                <Grid container item xs={12}>
                    <Grid item xs>
                        <InputLabel
                            id={labelId}>{props.record.file_name + " V." + props.record.file_version}</InputLabel>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <FileUploader processResult={processSupportingFile}
                                      keyPart={props.record.file_id}
                                      multiple={false}
                                      accept={"*"}>
                            <Button variant="text" component={"span"}>
                                Upload
                            </Button>
                        </FileUploader>
                        <Button onClick={onRemove}>Remove</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="dense"
                        label="Description"
                        type="text"
                        fullWidth
                        inputProps={{maxLength: 3000}}
                        variant="standard"
                        value={props.record.file_description}
                        onChange={onDescriptionChange}
                    />
                </Grid>
            </Grid>
        </ListItem>
    )
};

export default SupportingFilesListItem;