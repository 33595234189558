import React from 'react';
import {ListItem, ListItemText} from "@mui/material";
import {IdNameListValues} from "../../../domain/entity/structures/shared/IdNameListValues";

interface Props {
    flow: IdNameListValues
}

const FlowsListItem = (props: Props) => {
    const labelId = `link-list-item-label-${props.flow.id}`;

    return (
        <ListItem
            disablePadding
        >
            <ListItemText id={labelId}
                          primary={props.flow.name}/>
        </ListItem>
    );
};

export default FlowsListItem;