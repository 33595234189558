import {List} from '@mui/material';
import React from 'react';
import {Link} from "../../domain/entity/structures/requirementManagement/Link";
import LinksListItem from "./LinksListItem";

interface LinksListProps {
    links: Link[]
    onDelete: (link: Link) => void
    onLink: (rs_id: string) => void
}

const LinksList = (props: LinksListProps) => {
    return (
        <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            maxHeight: 550,
            overflowY: 'auto',
        }}>
            {props.links.map((item) => {
                return (
                    <LinksListItem link={item}
                                   key={'ListItem-' + item.linked_rs_id}
                                   onLink={props.onLink}
                                   onDelete={props.onDelete}
                    />
                );
            })}
        </List>
    );
};

export default LinksList;