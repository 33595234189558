import {FlowFilter} from "../domain/entity/structures/adminisrator/dataFlowManagement/FlowFilter";
import {Expression} from "../domain/entity/structures/dataVisualisation/Expression";
import {LogicOperand} from "../domain/entity/structures/dataVisualisation/LogicOperand";
import ConditionSelector from "../domain/entity/structures/dataVisualisation/ConditionSelector";
import {VariableType} from "../domain/entity/structures/adminisrator/dataFlowManagement/VariableType";
import SelectorString from "../domain/entity/structures/dataVisualisation/SelectorString";
import {SelectorStringType} from "../domain/entity/structures/dataVisualisation/SelectorStringType";
import SelectorInteger from "../domain/entity/structures/dataVisualisation/SelectorInteger";
import SelectorFloat from "../domain/entity/structures/dataVisualisation/SelectorFloat";
import SelectorBoolean from "../domain/entity/structures/dataVisualisation/SelectorBoolean";
import {SelectorBooleanType} from "../domain/entity/structures/dataVisualisation/SelectorBooleanType";
import SelectorTimestamp from "../domain/entity/structures/dataVisualisation/SelectorTimestamp";
import moment from "moment";
import SelectorEnum from "../domain/entity/structures/dataVisualisation/SelectorEnum";
import {Column} from "../domain/entity/structures/shared/Column";
import {IdNameListValues} from "../domain/entity/structures/shared/IdNameListValues";
import {Flow} from "../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import {DATE_FORMAT} from "./consts";

export function capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}

export function getColor(index: number) {
    const val = (375355 * index + 0x82ca9d) % 0xffffff
    return '#' + val.toString(16);
}

export function objectToArray(data: any) {
    let result: { columnName: string; variableType: string; }[] = []
    Object.keys(data).forEach(function (key) {
        const val = data[key];
        result.push({
            columnName: key,
            variableType: val
        })
    });
    return result;
}

export function objectArrayToFlows(data) {
    let flows = [] as Flow[]
    data.forEach((item) => {
        const {flow_id: flow_id_value, flow_name: flow_name_value, ...rest} = item;
        const flowItem = {id: flow_id_value, name: flow_name_value, ...rest}

        flows.push(flowItem as Flow)
    })
    return flows;
}

export function csvToJson(csv: string): any[] {
    let tmpString = csv.toString()
    tmpString = tmpString.replaceAll("\n\r", '|')
    tmpString = tmpString.replaceAll("\r\n", '|')
    tmpString = tmpString.replaceAll("\n", '|')
    tmpString = tmpString.replaceAll("\r", '|')
    tmpString = tmpString.replaceAll("||", '|')
    const array = tmpString.split("|");
    let result: any = [];
    let headers = array[0].split(",")
    for (let i = 1; i < array.length - 1; i++) {
        let obj = {}
        let str = array[i]
        let s = ''
        let flag = 0
        for (let ch of str) {
            if (ch === '"' && flag === 0) {
                flag = 1
            } else if (ch === '"' && flag === 1) flag = 0
            if (ch === ',' && flag === 0) ch = '|'
            if (ch !== '"') s += ch
        }
        let properties = s.split("|")
        for (let j in headers) {
            obj[headers[j]] = properties[j] === '' ? null : properties[j]
        }
        result.push(obj)
    }
    return result
}

export function dataToFlowFilters(data: any[]): FlowFilter[] {
    return data.map(obj => {
        return {
            id: obj.id,
            parameterName: obj.parameter_name,
            variableType: obj.variable_type,
            flowId: obj.flow_id,
            definedValues: obj.defined_values
        } as FlowFilter
    })
}

function getExpressionForSelector(item: ConditionSelector): Expression {
    let result: Expression = null as any
    switch (item.dataFilter.variableType) {
        case VariableType.string:
            const stringSelector = item.selector as SelectorString
            if (stringSelector.value !== '') {
                result = {
                    condition1: item.dataFilter.parameterName,
                    logic: stringSelector.type === SelectorStringType.contains ? LogicOperand.contains : LogicOperand.startWith,
                    condition2: stringSelector.value
                }
            }
            break;
        case VariableType.integer:
        case VariableType.float:
            const integerSelector = item.selector as SelectorInteger
            let expressions: Expression[] = []
            if (integerSelector.equal !== null && integerSelector.equal as any !== '') {
                expressions.push({
                    condition1: item.dataFilter.parameterName,
                    logic: LogicOperand.equal,
                    condition2: integerSelector.equal
                })
            }
            if (integerSelector.lessThan !== null && integerSelector.lessThan as any !== '') {
                expressions.push({
                    condition1: item.dataFilter.parameterName,
                    logic: LogicOperand.lessThan,
                    condition2: integerSelector.lessThan
                })
            }
            if (integerSelector.greaterThan !== null && integerSelector.greaterThan as any !== '') {
                expressions.push({
                    condition1: item.dataFilter.parameterName,
                    logic: LogicOperand.greaterThan,
                    condition2: integerSelector.greaterThan
                })
            }
            if (expressions.length === 0) result = null as any
            else if (expressions.length === 1) result = expressions[0]
            else if (expressions.length === 2) result = {
                condition1: expressions[1],
                logic: LogicOperand.and,
                condition2: expressions[0]
            }
            else if (expressions.length === 3) result = {
                condition1: expressions[2],
                logic: LogicOperand.and,
                condition2: {
                    expression1: expressions[1],
                    logic: LogicOperand.and,
                    expression2: expressions[0]
                }
            }
            break;
        case VariableType.enum:
            const enumSelector = item.selector as SelectorEnum
            if (enumSelector.selectedValues.length > 0) {
                result = {
                    condition1: item.dataFilter.parameterName,
                    logic: LogicOperand.equal,
                    condition2: enumSelector.selectedValues[0]
                }
                for (let i = 1; i < enumSelector.selectedValues.length; i++) {
                    result = {
                        condition1: result,
                        logic: LogicOperand.or,
                        condition2: {
                            expression1: item.dataFilter.parameterName,
                            logic: LogicOperand.equal,
                            expression2: enumSelector.selectedValues[i]
                        }
                    }
                }
            }
            break;
        case VariableType.timestamp:
            const selectorTimestamp = item.selector as SelectorTimestamp
            let startExpression: Expression = null as any
            let endExpression: Expression = null as any
            if (selectorTimestamp.startTime !== '') {
                const startUtc = moment(selectorTimestamp.startTime).unix();
                startExpression = {
                    condition1: item.dataFilter.parameterName,
                    logic: LogicOperand.greaterThan,
                    condition2: startUtc
                }
            }
            if (selectorTimestamp.endTime !== '') {
                const endUtc = moment(selectorTimestamp.endTime).unix();
                endExpression = {
                    condition1: item.dataFilter.parameterName,
                    logic: LogicOperand.lessThan,
                    condition2: endUtc
                }
            }
            if (startExpression === null)
                result = endExpression
            else if (endExpression === null)
                result = startExpression
            else
                result = {
                    condition1: startExpression,
                    logic: LogicOperand.and,
                    condition2: endExpression
                } as Expression
            break;
        case VariableType.bool:
            const boolSelector = item.selector as SelectorString
            if (boolSelector.value === SelectorBooleanType.true || boolSelector.value === SelectorBooleanType.false) {
                result = {
                    condition1: item.dataFilter.parameterName,
                    logic: LogicOperand.equal,
                    condition2: boolSelector.value
                }
            }
            break;
        case VariableType.array:
            break;
    }
    return result
}

export function getExpressionForSelectors(selectors: ConditionSelector[]): Expression {
    if (selectors.length === 0)
        return null as any
    if (selectors.length === 1) return getExpressionForSelector(selectors[0])
    let currentExpression: Expression = null as any
    selectors.forEach((item) => {
        const expressionForSelector = getExpressionForSelector(item);
        if (expressionForSelector !== null) {
            if (currentExpression === null) {
                currentExpression = expressionForSelector
            } else {
                currentExpression = {
                    condition1: currentExpression,
                    logic: LogicOperand.and,
                    condition2: expressionForSelector
                }
            }
        }
    })
    return currentExpression
}

export function initSelector(selector: ConditionSelector): ConditionSelector {
    let result: ConditionSelector = selector
    switch (selector.dataFilter.variableType) {
        case VariableType.string:
            result = {
                ...result,
                selector: {value: "", type: "contains"} as SelectorString
            }
            break;
        case VariableType.float:
            result = {
                ...result,
                selector: {equal: null as any, greaterThan: null as any, lessThan: null as any} as SelectorFloat
            }
            break;
        case VariableType.enum:
            result = {
                ...result,
                selector: {selectedValues: [], values: []} as SelectorEnum
            }
            break;
        case VariableType.timestamp:
            result = {
                ...result,
                selector: {endTime: "", startTime: ""} as SelectorTimestamp
            }
            break;
        case VariableType.bool:
            result = {
                ...result,
                selector: {value: "" as any} as SelectorBoolean
            }
            break;
        case VariableType.array:
            break;
        case VariableType.integer:
            result = {
                ...result,
                selector: {
                    equal: null as any,
                    greaterThan: null as any,
                    lessThan: null as any
                } as SelectorInteger
            }
            break;
    }
    return result
}

export function getColumnsList(data: any[]) {
    let result: string[] = []
    const firstRecord = data[0];
    for (let columnName in firstRecord) {
        result.push(columnName)
    }
    return result
}

export function columnsToObject(columns: Column[]) {
    let tmpColumns = {}
    columns.forEach((itemColumn) => {
        tmpColumns[itemColumn.columnName] = itemColumn.variableType
    })
    return tmpColumns;
}

export function columnsFromObject(columns: any) {
    let tmpColumns: Column[] = []
    for (const key in columns) {
        tmpColumns.push({columnName: key, variableType: columns[key]} as Column)
    }
    return tmpColumns;
}

export function columnsFromArray(columns: any[]) {
    return columns.map((item) => {
        return {
            columnName: item.name,
            variableType: item.type
        } as Column
    });
}

export function moveArrayElement(array, from, to) {
    if (to === from) return array;

    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k !== to; k += increment) {
        array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
}

export function getFirstLetter(value: string | undefined): string | null {
    if (value === undefined) return null
    return value.substring(0, 1);
}

export function removeItem(arr, item) {
    return arr.filter(f => f !== item)
}

export function removeItems(arr, items) {
    let result = arr
    items.forEach((item) => {
        result = removeItem(result, item)
    })
    return result
}

export function idNameValuesFromObject(items: any) {
    return Object.keys(items).map((key) => ({id: key, name: items[key]} as IdNameListValues));
}

export function idNameValuesToObject(values: IdNameListValues[]) {
    let result = {}
    values.forEach((item) => {
        result[item.id] = item.name
    })
    return result;
}

export function getFormattedDate(value: number | undefined): string {
    return value ? moment.unix(value).format(DATE_FORMAT) : '';
}
