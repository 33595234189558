import React, {useContext, useState} from 'react';
import {Grid, InputLabel, ListItemText} from "@mui/material";
import {ScenarioFlow} from "../../../../domain/entity/structures/dataProcessing/ScenarioFlow";
import RsInfoDialog from "../RsInfoDialog";
import {getDrsDetails} from "../../../../http/requirementManagementAPI";
import {rsDataImportAdapter} from "../../../requirementManagement/ProjectUtils";
import {DrsItem} from "../../../../domain/entity/structures/requirementManagement/DrsItem";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";

interface Props {
    scenario: ScenarioFlow
    redrawScenarios: () => void
}

const ScenariosListItem = observer((props: Props) => {
    const {appStore} = useContext(Context)
    const [drsInfoDialogOpen, setDrsInfoDialogOpen] = useState<boolean>(false);
    const [drs, setDrs] = useState<DrsItem>(null as any);
    const labelId = `checkbox-list-label-${props.scenario.scenario_id}`;

    function onDrsInfoDialogClose() {
        setDrsInfoDialogOpen(false)
    }

    function onDrsInfo() {
        if (drs === null) {
            appStore.addLoading()
            getDrsDetails(props.scenario.drs_id).then((response) => {
                setDrs(rsDataImportAdapter(response.data));
                setDrsInfoDialogOpen(true)
            }).catch(() => {
                appStore.showAppSnackBar('error', "DRS loading error")
            }).finally(() => appStore.removeLoading())
        } else
            setDrsInfoDialogOpen(true)
    }

    return (
        <>
            <Grid container item xs={12}
                  alignContent={"flex-start"}>
                <Grid item xs={7}
                      alignContent={"flex-start"}>
                    <ListItemText id={labelId} primary={props.scenario.scenario_name + ", " +
                        props.scenario.status + ", " +
                        "Order " + props.scenario.order + ", " +
                        "Version " + props.scenario.version}/>
                </Grid>
                <Grid item xs={5}
                      alignContent={"flex-start"}>
                    <InputLabel onClick={onDrsInfo}
                                sx={{cursor: "pointer"}}>DRS: {props.scenario.drs_name ? props.scenario.drs_name : ''}</InputLabel>
                </Grid>
            </Grid>
            {
                drsInfoDialogOpen &&
                <RsInfoDialog isDialogOpen={drsInfoDialogOpen}
                              onBack={onDrsInfoDialogClose}
                              rs={drs}
                />
            }
        </>
    );
})

export default ScenariosListItem;