import {RolesEnum} from "../domain/entity/structures/requirementManagement/RolesEnum";
import {IdNameListValues} from "../domain/entity/structures/shared/IdNameListValues";

export const DATA_FLOW_MANAGEMENT_ROUTE = '/admin/data-flow-management'
export const HISTORICAL_DATA_ANALYSIS_DASHBOARD_ROUTE = '/historical-data-analysis-dashboard'
export const REALTIME_DASHBOARD_ROUTE = '/realtime-dashboard'
export const HOME_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const USER_MANAGEMENT_ROUTE = '/admin/user-management'
export const USER_ROUTE = '/user'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const RESET_PASSWORD_ROUTE = '/reset-password'
export const DATA_VISUALISATION_ROUTE = '/data-visualisation'
export const REQUIREMENT_MANAGEMENT_ROUTE = '/requirement-management'
export const CREATE_PROJECT_ROUTE = '/create-project'
export const UPDATE_PROJECT_ROUTE = '/update-project'
export const MANAGE_SCENARIOS_ROUTE = '/manage-scenario'
export const EXECUTE_MODELS_ROUTE = '/execute-models'
export const AI_LAB_ROUTE = '/ai-lab'
export const EXECUTION_MANAGEMENT_ROUTE = '/execution-management'

export const ENUM = 'enum'

export const DEFAULT_SNACKBAR_AUTOHIDE_DURATION = 6000

export const MANDATORY_FIELDS_ERROR = "Fields marked with * are mandatory.";
export const DATAOBJECT = 'dataobject'
export const A4_WIDTH = 210
export const A4_HEIGHT = 297
export const TABLE_RECORDS_LIMIT = 20;
export const LINE_AND_BAR_CHART_RECORDS_LIMIT = 10;
export const PIE_CHART_RECORDS_LIMIT = 5;
export const DARK_BACKGROUND = '#3A3F60';
export const HEADER_FOR_DARK_BACKGROUND = '#FEFEFE';
export const TEXT_DARK_BACKGROUND = '#E1E2E7';
export const LIGHT_BACKGROUND = '#FFFFFF';
export const HEADER_FOR_LIGHT_BACKGROUND = '#575757';
export const TEXT_FOR_LIGHT_BACKGROUND = '#D4D4D4';
export const USER_ROLES_WITHOUT_ADMINISTRATOR = [
    {id: RolesEnum.dataScientist, name: "Data scientist"} as IdNameListValues,
    {id: RolesEnum.dataEngineer, name: "Data engineer"} as IdNameListValues,
    {id: RolesEnum.subjectMatterExpert, name: "Subject matter expert"} as IdNameListValues,
    {id: RolesEnum.businessOwner, name: "Business owner"} as IdNameListValues,
];
export const USER_ROLES_WITH_ADMINISTRATOR = [
    {id: RolesEnum.dataScientist, name: "Data scientist"} as IdNameListValues,
    {id: RolesEnum.dataEngineer, name: "Data engineer"} as IdNameListValues,
    {id: RolesEnum.subjectMatterExpert, name: "Subject matter expert"} as IdNameListValues,
    {id: RolesEnum.businessOwner, name: "Business owner"} as IdNameListValues,
    {id: RolesEnum.administrator, name: "Administrator"},
];
export const ON_FINISH_MESSAGE = "Are you sure you want to leave current page?\n" +
    "You have unsaved changes.\n" +
    "You can discard your changes, or cancel to continue editing."

export const DATE_FORMAT = 'YYYY/MM/DD HH:mm'
export const DATE_TIME_INPUT_FORMAT = 'YYYY-MM-DDTHH:mm'
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD'
