import React from 'react';
import Typography from "@mui/material/Typography";

interface Props {
    children?: React.ReactNode
}

const MinorFooterText = ({children}: Props) => {
    return (
        <Typography variant="h6" color={"#AEAACB"}>
            {children}
        </Typography>
    )
};

export default MinorFooterText;