import React from 'react';
import {IconButton, ListItem, ListItemText} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

interface LogicStatementsListItemProps {
    logicStatementIndex: number
    logicStatement: string
    onDelete: (index: number) => void
}

const LogicStatementsListItem = (props: LogicStatementsListItemProps) => {
    const labelId = `logic-statement-list-item-label-${props.logicStatementIndex}`;

    function onDelete() {
        props.onDelete(props.logicStatementIndex)
    }

    return (
        <ListItem
            disablePadding
        >
            <ListItemText id={labelId} primary={props.logicStatement}/>
            <IconButton onClick={onDelete}>
                <ClearIcon/>
            </IconButton>
        </ListItem>
    );
};

export default LogicStatementsListItem;