import React from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {getColor, getColumnsList} from "../../../../utils/utils";

interface BarChartWidgetProps {
    data: any[]
    id: string
}

const BarChartWidget = (props: BarChartWidgetProps) => {
    const columnsList = getColumnsList(props.data);
    return (
        <ResponsiveContainer height={300} id={props.id}>
            <BarChart data={props.data}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={columnsList[0]}/>
                <YAxis/>
                <Tooltip/>
                <Legend wrapperStyle={{position: 'relative'}}/>
                {
                    columnsList.map((item) => (
                        columnsList.indexOf(item) > 0 &&
                        <Bar type="monotone"
                             dataKey={item}
                             fill={getColor(columnsList.indexOf(item))}
                             key={"bar-chart-" + columnsList.indexOf(item)}/>
                    ))
                }
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarChartWidget;