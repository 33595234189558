import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";

interface ObjectDropDownProps<T> {
    inputLabel: string
    items: T[]
    selectedItem: T
    onSelectionChange: (item: T) => void
    required?: boolean
    variant?: "standard" | "outlined" | "filled" | undefined
}

export default function ObjectDropDown<T>(props: ObjectDropDownProps<T>) {
    const handleChange = (event: SelectChangeEvent<T>) => {
        const item: T = event.target.value as T
        props.onSelectionChange(item)
    };

    return (
        <FormControl fullWidth
                     required={props.required === undefined ? false : props.required}
                     variant={props.variant}
                     margin={"dense"}
        >
            <InputLabel id="object-select-label">{props.inputLabel}</InputLabel>
            <Select
                labelId="object-select-label"
                id="object-select"
                value={props.items.indexOf(props.selectedItem) === -1 ? '' as any : props.selectedItem}
                renderValue={(selected: T) => {
                    return (selected as any).name;
                }}
                label={props.inputLabel}
                onChange={handleChange}
            >
                {props.items.map((item: T) => (
                    <MenuItem key={(item as any).id} value={item as any}
                              sx={(item as any).name === '' ? {display: 'none'} : {}}>
                        {(item as any).name + ((item as any).hasOwnProperty('description') && (item as any).description !== null ? ' (' + (item as any).description + ')' : '')}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}