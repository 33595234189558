import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../../index";
import {allHistoricalDashboards} from "../../../http/visualisationAPI";
import Grid from "@mui/material/Grid";
import ObjectDropDown from "../ObjectDropDown";
import {Button} from "@mui/material";
import CancelButton from "../CancelButton";
import {HistoricalDashboard} from "../../../domain/entity/structures/dataVisualisation/HistoricalDashboard";
import {useNavigate} from "react-router-dom";
import {HISTORICAL_DATA_ANALYSIS_DASHBOARD_ROUTE} from "../../../utils/consts";

const HistoricalDataVisualisationDashboards = () => {
    let navigate = useNavigate();
    const {appStore} = useContext(Context)
    const [historicalDashboards, setHistoricalDashboards] = useState<HistoricalDashboard[]>([{
        id: "0",
        name: "",
        description: '',
        data_elements: [],
        display_entities: []
    }]);

    useEffect(() => {
        appStore.addLoading()
        allHistoricalDashboards().then(response => {
            setHistoricalDashboards(response.data as HistoricalDashboard[]);
        }).finally(() => {
            appStore.removeLoading();
        })
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onCreateButtonClick() {
        navigate(HISTORICAL_DATA_ANALYSIS_DASHBOARD_ROUTE + '/new');
    }

    function onSelected(item: HistoricalDashboard) {
        navigate(HISTORICAL_DATA_ANALYSIS_DASHBOARD_ROUTE + '/' + item.id);
    }

    return (
        <Grid container item xs={12} sm={6}>
            <Grid item xs={12}>
                <ObjectDropDown items={historicalDashboards}
                                selectedItem={'' as any}
                                onSelectionChange={onSelected}
                                inputLabel={"Historical Data Visualisation Dashboards"}/>
            </Grid>
            <Grid item xs={12} textAlign={"center"} marginTop={2}>
                <Button variant="outlined" key="create-new-historical-dashboard-button" onClick={onCreateButtonClick}>
                    Create a new dashboard
                </Button>
                <CancelButton/>
            </Grid>
        </Grid>
    );
};

export default HistoricalDataVisualisationDashboards;