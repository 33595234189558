import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {Flow} from "../../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import {FlowGroup} from "../../../../domain/entity/structures/aiLab/FlowGroup";
import {allFlows} from "../../../../http/flowsAPI";
import {objectArrayToFlows} from "../../../../utils/utils";
import {Context} from "../../../../index";
import {createFlowGroup} from "../../../../http/aiLabAPI";
import FlowsMultiselectDropDown
    from "../../../administrator/filterConfiguration/filtersToQueryDataFlows/FlowsMultiselectDropDown";
import {isNameValidGeneral} from "../../../../utils/validationUtils";

interface Props {
    existingGroups: FlowGroup[]
    onCreateFlowGroup: (id: string) => void
    onClose: () => void
}

const CreateFlowGroupDialog = (props: Props) => {
    const {appStore} = useContext(Context)
    const [name, setName] = useState<string>('');
    const [flows, setFlows] = useState<Flow[]>([]);
    const [selectedFlows, setSelectedFlows] = useState<Flow[]>([]);

    useEffect(() => {
        appStore.addLoading()
        allFlows().then(response => {
            setFlows(objectArrayToFlows(response.data));
        }).finally(() => {
            appStore.removeLoading();
        })
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function isGroupValid() {
        const nameValidationResult = isNameValidGeneral(name, true, 200);
        if (nameValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Group name has validation error with message: " + nameValidationResult)
            return false
        }
        return true;
    }

    function onCreate() {
        if (!isGroupValid())
            return
        appStore.addLoading()
        const payload = {
            name: name,
            flow_ids: selectedFlows.map(e => e.id)
        }
        createFlowGroup(payload).then(response => {
            appStore.showAppSnackBar('success', "Flow group has been created")
            props.onCreateFlowGroup(response.data.flow_group_id)
        }).catch(reason => {
            if (reason.response.status === 400)
                appStore.showAppSnackBar("error", reason.response.data.message)
            else
                appStore.showAppSnackBar('error', "Flow group creation error")
        }).finally(() => {
            appStore.removeLoading();
            props.onClose()
        })
    }

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        setName(target.value);
    }

    function refreshSelectedFlows() {
        setSelectedFlows(selectedFlows.slice())
    }

    return (
        <Dialog open={true} fullWidth onClose={props.onClose}>
            <DialogTitle>{"Create a flow group"}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    required
                    variant="standard"
                    inputProps={{maxLength: 200}}
                    name='name'
                    value={name}
                    onChange={onFieldChange}
                />
                <FlowsMultiselectDropDown flows={flows}
                                          variant={"standard"}
                                          selectedFlows={selectedFlows}
                                          onSelectionChange={refreshSelectedFlows}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
                <Button disabled={name === '' || selectedFlows.length === 0}
                        onClick={onCreate}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateFlowGroupDialog;