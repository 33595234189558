import {List} from '@mui/material';
import React from 'react';
import LogicStatementsListItem from "./LogicStatementsListItem";

interface LogicStatementsListProps {
    logicStatements: string[]
    onDelete: (index: number) => void
}

const LogicStatementsList = (props: LogicStatementsListProps) => {
    function prepareLogicStatementsList() {
        let result: { key: number, value: string }[] = []
        for (let i = 0; i < props.logicStatements.length; i++) {
            result.push({key: i, value: props.logicStatements[i]})
        }
        return result
    }

    return (
        <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            maxHeight: 100,
            overflowY: 'auto',
        }}>
            {prepareLogicStatementsList().map((item) => {
                return (
                    <LogicStatementsListItem logicStatementIndex={item.key}
                                             key={'ListItem-' + item.key}
                                             logicStatement={item.value}
                                             onDelete={props.onDelete}
                    />
                );
            })}
        </List>
    );
};

export default LogicStatementsList;