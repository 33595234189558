import {List} from '@mui/material';
import React from 'react';
import SupportingFilesListItem from "./SupportingFilesListItem";
import {
    SupportingFileRecordWithDescription
} from "../../../../domain/entity/structures/dataProcessing/SupportingFileRecordWithDescription";

interface Props {
    records: SupportingFileRecordWithDescription[]
    onRefresh: () => void
    onFileUpload: (file: File) => void
    onFileRemove: (record: SupportingFileRecordWithDescription) => void
}

const SupportingFilesList = (props: Props) => {
    return (
        <>
            <List sx={{
                width: '100%',
                bgcolor: 'background.paper',
                maxHeight: 350,
                overflowY: 'auto',
            }}>
                {props.records.map((item) => {
                    return (
                        <SupportingFilesListItem record={item}
                                                 onRefresh={props.onRefresh}
                                                 onFileUpload={props.onFileUpload}
                                                 onFileRemove={props.onFileRemove}
                                                 key={'ListItem-' + item.file_id}
                        />
                    );
                })}
            </List>
        </>
    )
}

export default SupportingFilesList;