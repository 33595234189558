import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import BodyText from "../shared/BodyText";
import {Context} from "../../index";
import {AiModel} from "../../domain/entity/structures/requirementManagement/AiModel";
import {ModelReview} from "../../domain/entity/structures/dataProcessing/ModelReview";
import {ON_FINISH_MESSAGE} from "../../utils/consts";
import moment from "moment";
import EnumSelectForm from "./EnumSelectForm";
import {QualityLevelList} from "../../domain/entity/structures/requirementManagement/QualityLevelEnum";
import {getModelReviews, saveModelReviews} from "../../http/requirementManagementAPI";
import ModelReviewTable from "./ModelReviewTable";
import {getFormattedDate, idNameValuesFromObject, removeItem} from "../../utils/utils";
import {isLongStringValueValid} from "../../utils/validationUtils";
import SectionTitleLeft from "../shared/SectionTitleLeft";
import {getAiModelForReview} from "../../http/dataProcessingAPI";
import {AiModelTypeEnum} from "../../domain/entity/structures/aiLab/AiModelTypeEnum";

interface ArsAiModelReviewDialogProps {
    isDialogOpen: boolean
    currentArsId: string
    currentArsName: string
    aiModelId: string
    onBack: () => void
}

const ArsAiModelReviewDialog = (props: ArsAiModelReviewDialogProps) => {
    const {appStore} = useContext(Context)
    const {userStore} = useContext(Context)
    const [modelReviews, setModelReviews] = useState<ModelReview[]>([]);
    const [aiModel, setAiModel] = useState<AiModel>({
        id: "",
        name: "",
        flow_group_id: "",
        flows: [],
        type: '',
        version: 1,
        updated_by: '',
        updated_time: 0,
        model_description: '',
        script_name: '',
        script_version: '',
    });
    const [qualityLevel, setQualityLevel] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isListChanged, setIsListChanged] = useState<boolean>(false);
    const [removedIds, setRemovedIds] = useState<String[]>([]);

    function loadModelReviews() {
        appStore.addLoading()
        getModelReviews(props.currentArsId, props.aiModelId).then((response) => {
            setModelReviews(response.data as ModelReview[])
        }).catch(reason => {
            appStore.showAppSnackBar('error', "Model reviews loading error")
        }).finally(() => appStore.removeLoading())
    }

    useEffect(() => {
        appStore.addLoading()
        getAiModelForReview(props.aiModelId).then((response) => {
            setAiModel({
                id: response.data.id,
                name: response.data.name,
                flows: idNameValuesFromObject(response.data.flows),
                type: response.data.type === 'scheduled' ? AiModelTypeEnum.scheduled : AiModelTypeEnum.stream,
                version: response.data.version,
                updated_by: response.data.updated_by,
                updated_time: response.data.updated_time,
                model_description: response.data.model_description,
                script_name: response.data.script_name,
                script_version: response.data.script_version,
            } as AiModel)
        }).catch(reason => {
            appStore.showAppSnackBar('error', "AiModel loading error")
        }).finally(() => appStore.removeLoading())
        loadModelReviews();

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onSave() {
        appStore.addLoading()
        saveModelReviews({
            newRecords: modelReviews.filter(e => e.review_id === undefined),
            removed_ids: removedIds,
            ars_id: props.currentArsId,
            aiModel_id: props.aiModelId,
        }).then((response) => {
            appStore.showAppSnackBar("success", "Model reviews have been saved")
            setIsListChanged(false)
            loadModelReviews()
        }).catch(reason => {
            appStore.showAppSnackBar("error", "Model reviews save error")
        }).finally(() => appStore.removeLoading())
    }

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        setComment(target.value);
        setIsChanged(true)
    }

    function onRemoveModelReview(modelReview: ModelReview) {
        if (modelReview.review_id !== '') {
            removedIds.push(modelReview.review_id as any)
            setRemovedIds(removedIds.slice())
        }
        setModelReviews(removeItem(modelReviews, modelReview))
        setIsListChanged(true)
    }

    function onClear() {
        setIsChanged(false)
        setComment('')
        setQualityLevel('')
    }

    function isValid() {
        const commentValidationResult = isLongStringValueValid(comment, false);
        if (commentValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Comment has validation error with message: " + commentValidationResult)
            return false
        }
        if (qualityLevel === '') {
            appStore.showAppSnackBar("error",
                "Quality level is not defined")
            return false
        }
        return true
    }

    function onAdd() {
        if (!isValid()) return

        // @ts-ignore
        const lastNumber = (modelReviews === undefined || modelReviews.length === 0) ? 0 : (modelReviews[modelReviews.length - 1].number + 1);
        modelReviews.push({
            comment: comment,
            model_id: props.aiModelId,
            number: lastNumber,
            quality_level: qualityLevel,
            requirement_id: props.currentArsId,
            created_by: userStore.user.loginId,
            create_time: moment().unix()
        })
        onClear()
        setIsListChanged(true)
    }

    function setQualityLevelValue(value: string) {
        setQualityLevel(value)
        setIsChanged(true)
    }

    function onBackButtonClick() {
        if (isChanged || isListChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onBack()
    }

    return (
        <Dialog open={props.isDialogOpen} maxWidth={'xl'} onClose={props.onBack}>
            <DialogTitle>{"Model Review"}</DialogTitle>
            <DialogContent>
                <SectionTitleLeft>{props.currentArsName}</SectionTitleLeft>
                <BodyText>AiModel name: {aiModel.name}</BodyText>
                <BodyText>Description: {aiModel.model_description}</BodyText>
                <BodyText>Type: {aiModel.type} Version: {aiModel.version}</BodyText>
                <BodyText>Updated by: {aiModel.updated_by} Update
                    time: {getFormattedDate(aiModel.updated_time)}</BodyText>
                <BodyText>Script name: {aiModel.script_name} Script version: {aiModel.script_version}</BodyText>
                <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                    <Grid item xs={12}>
                        <ModelReviewTable data={modelReviews}
                                          onRemove={onRemoveModelReview}/>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <EnumSelectForm value={qualityLevel}
                                        onChange={setQualityLevelValue}
                                        enumRecords={QualityLevelList}
                                        variant="standard"
                                        required
                                        label={"Quality levels"}/>
                    </Grid>
                    <Grid container item xs>
                        <Grid item xs>
                            <TextField
                                margin="dense"
                                id="item-comment"
                                label="Comment"
                                type="text"
                                fullWidth
                                multiline
                                required
                                rows={2}
                                variant="standard"
                                name='comment'
                                value={comment}
                                onChange={onFieldChange}
                            />
                        </Grid>
                        <Grid container alignItems={"center"} item xs={"auto"}>
                            <Button disabled={comment === '' || qualityLevel === ''}
                                    onClick={onAdd}>Add</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBackButtonClick}>Back</Button>
                <Button disabled={!isChanged}
                        onClick={onClear}>Clear</Button>
                <Button disabled={!isListChanged}
                        onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ArsAiModelReviewDialog;