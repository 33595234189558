import React from 'react';
import {Alert, Button, FormControl, FormLabel, Grid} from "@mui/material";
import ObjectsIdNameSingleSelectList from "./ObjectsIdNameSingleSelectList";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";

interface DrsScenarioWidgetProps {
    scenarios: IdNameListValues[]
    selectedScenario: IdNameListValues
    onCreateScenario: () => void
    onUpdateScenario: () => void
    onReallocateScenarios: () => void
    onReviewScenario: () => void
    setSelectedScenario: (scenario: IdNameListValues) => void
    isReallocateEnabled: boolean
}

const DrsScenarioWidget = (props: DrsScenarioWidgetProps) => {

    return (
        <FormControl aria-labelledby={"scenario-list-label"} fullWidth margin={'dense'}>
            <FormLabel id="scenario-list-label">Associated data processing scenarios</FormLabel>
            {
                props.scenarios.length > 0 &&
                <ObjectsIdNameSingleSelectList objs={props.scenarios}
                                               selectedObj={props.selectedScenario}
                                               setSelected={props.setSelectedScenario}/>
            }
            {
                props.scenarios.length === 0 &&
                <Alert severity={'info'}>No scenarios created</Alert>
            }
            <Grid container>
                <Button disabled={props.selectedScenario === null}
                        onClick={props.onReviewScenario}>Review a Scenario</Button>
                <Button disabled={props.selectedScenario === null}
                        onClick={props.onUpdateScenario}>Update a Scenario</Button>
                <Button onClick={props.onCreateScenario}>Create a Scenario</Button>
                <Button onClick={props.onReallocateScenarios} disabled={!props.isReallocateEnabled}>Reallocate
                    Scenarios</Button>
            </Grid>
        </FormControl>
    );
};

export default DrsScenarioWidget;