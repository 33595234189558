import {Card, CardContent, CardHeader, Grid, IconButton, Menu, MenuItem} from '@mui/material';
import React, {useState} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {DisplayEntityWithData} from "../../../../domain/entity/structures/dataVisualisation/DisplayEntityWithData";
import {CSVLink} from "react-csv";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface Props {
    entity: DisplayEntityWithData
    onExportToPdf: (entity: DisplayEntityWithData) => void
    editMode: boolean
    onDelete: (entity: DisplayEntityWithData) => void
    onMoveUp: (entity: DisplayEntityWithData) => void
    onMoveDown: (entity: DisplayEntityWithData) => void
    isFirst: boolean
    isLast: boolean
    children?: React.ReactNode
}

const EntityCard = ({
                        children,
                        entity,
                        onExportToPdf,
                        onDelete,
                        editMode,
                        onMoveUp,
                        onMoveDown,
                        isFirst,
                        isLast
                    }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function onExportDataToPdf() {
        setAnchorEl(null);
        onExportToPdf(entity)
    }

    function onRemove() {
        setAnchorEl(null);
        onDelete(entity)
    }

    return (
        <Grid item xs={12}>
            <Card variant={"outlined"} sx={{width: "calc(100% - 1em)"}}>
                <CardHeader
                    action={
                        <>
                            {
                                editMode &&
                                <IconButton onClick={() => onMoveDown(entity)} disabled={isLast}>
                                    <ArrowDownwardIcon/>
                                </IconButton>
                            }
                            {
                                editMode &&
                                <IconButton onClick={() => onMoveUp(entity)} disabled={isFirst}>
                                    <ArrowUpwardIcon/>
                                </IconButton>
                            }
                            <IconButton onClick={handleMenu}>
                                <ArrowDropDownIcon/>
                            </IconButton>
                            <Menu
                                id={"menu-entity-card-" + entity.displayEntity.display_id}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem>
                                    <CSVLink style={{
                                        color: "inherit",
                                        textDecoration: 'none'
                                    }}
                                             data={entity.csvData}
                                             headers={entity.csvHeaders}
                                             onClick={() => setAnchorEl(null)}
                                             filename={"export"}>
                                        Export to CSV
                                    </CSVLink>
                                </MenuItem>
                                <MenuItem onClick={onExportDataToPdf}>Export to PDF</MenuItem>
                                {
                                    editMode &&
                                    <MenuItem onClick={onRemove}>Remove</MenuItem>
                                }
                            </Menu>
                        </>
                    }
                    title={entity.title}
                />
                <CardContent>
                    {children}
                </CardContent>
            </Card>
        </Grid>
    );
};

export default EntityCard;