import React from 'react';
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import {Flow} from "../../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Props {
    flows: Flow[]
    selectedFlows: Flow[]
    onSelectionChange: (flows: Flow[]) => void
    variant: 'standard' | 'outlined' | 'filled'
    required?: boolean
}

const FlowsMultiselectDropDown = (props: Props) => {

    const handleToggle = (value: Flow) => () => {
        const currentIndex = props.selectedFlows.indexOf(value);
        if (currentIndex === -1) {
            props.selectedFlows.push(value);
        } else {
            props.selectedFlows.splice(currentIndex, 1);
        }
        props.onSelectionChange(props.selectedFlows.slice());
    };

    return (
        <FormControl fullWidth
                     required={props.required === undefined ? false : props.required}
                     variant={props.variant}
                     margin={"dense"}
        >
            <InputLabel id="flows-multiple-checkbox-label">Flow list</InputLabel>
            <Select
                id="flows-multiple-checkbox"
                multiple
                value={props.flows}
                variant={props.variant}
                margin={"dense"}
                label={'Flow list'}
                renderValue={(selected) => {
                    let result: string = ''
                    props.selectedFlows.forEach((item) => {
                        if (result !== '') result = result + ', '
                        result = result + item.name
                    })
                    return result;
                }}
                MenuProps={MenuProps}
            >
                {props.flows.map((flow: Flow) => (
                    <MenuItem key={flow.id} value={flow.name} onClick={handleToggle(flow)}>
                        <Checkbox checked={props.selectedFlows.indexOf(flow) > -1}/>
                        <ListItemText primary={flow.name}/>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FlowsMultiselectDropDown;