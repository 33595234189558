import React from 'react';
import {Button, Grid, InputLabel, TextField} from "@mui/material";
import FileUploader from "../../../administrator/dataFlowManagement/FileUploader";
import {AiModelFlow} from "../../../../domain/entity/structures/aiLab/AiModelFlow";
import {
    SupportingFileRecordWithDescription
} from "../../../../domain/entity/structures/dataProcessing/SupportingFileRecordWithDescription";
import SupportingFilesList from "./SupportingFilesList";

interface Props {
    record: AiModelFlow
    onUpdate: (value: AiModelFlow) => void
    supportingFileDescription: string
    setSupportingFileDescription: (value: string) => void
}

const AiModelSupportingFilesWidget = (props: Props) => {

    function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        props.setSupportingFileDescription(target.value);
    }

    function processNewSupportingFile(fileList: FileList) {
        for (let i = 0; i < fileList.length; i++) {
            props.record.files.push(fileList[i])
            props.record.supporting_files.push({
                file_id: null as any,
                file_name: fileList[i].name,
                file_description: props.supportingFileDescription,
                file_version: 1
            } as SupportingFileRecordWithDescription)
        }
        onRefresh()
    }

    const newSupportingFiles = props.record.supporting_files.filter(e => e.file_id === null)
    const oldSupportingFiles = props.record.supporting_files.filter(e => e.file_id !== null)

    function onRefresh() {
        props.onUpdate({
            ...props.record
        });
    }

    function onOldFileUpload(file: File) {
        props.record.files = props.record.files.filter(e => e.name !== file.name)
        props.record.files.push(file)
        onRefresh()
    }

    function onOldFileRemove(record: SupportingFileRecordWithDescription) {
        props.record.files = props.record.files.filter(e => e.name !== record.file_name)
        props.record.supporting_files = props.record.supporting_files.filter(e => e.file_id !== record.file_id)
        onRefresh()
    }

    return (
        <>
            <SupportingFilesList records={oldSupportingFiles}
                                 onRefresh={onRefresh}
                                 onFileUpload={onOldFileUpload}
                                 onFileRemove={onOldFileRemove}
            />
            <InputLabel>Add new files</InputLabel>
            <TextField
                margin="dense"
                label="Supporting file description"
                type="text"
                fullWidth
                inputProps={{maxLength: 3000}}
                variant="standard"
                name='model_description'
                value={props.supportingFileDescription}
                onChange={onDescriptionChange}
            />
            <FileUploader processResult={processNewSupportingFile}
                          keyPart={'upload-supporting-file'}
                          multiple={false}
                          accept={"*"}>
                <Button variant="text" component={"span"}>
                    Upload
                </Button>
            </FileUploader>
            {
                newSupportingFiles.length > 0 &&
                <Grid container
                      spacing={1}
                      item
                      xs={12}
                      mt={1}
                      alignContent={"flex-start"}
                      sx={{maxHeight: "100px", overflowY: "auto"}}>
                    {
                        newSupportingFiles.map((item) => (
                            <Grid item xs={12} key={newSupportingFiles.indexOf(item)}>
                                <InputLabel>{item.file_name + (item.file_description === '' ? '' : '(' + item.file_description + ')')}</InputLabel>
                            </Grid>
                        ))
                    }
                </Grid>
            }
        </>
    );
};

export default AiModelSupportingFilesWidget;