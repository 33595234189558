import React from 'react';
import ConditionSelector from "../../../domain/entity/structures/dataVisualisation/ConditionSelector";
import {TextField} from "@mui/material";
import BodyText from "../../shared/BodyText";
import SelectorTimestamp from "../../../domain/entity/structures/dataVisualisation/SelectorTimestamp";

interface SelectorTimestampWidgetProps {
    selector: ConditionSelector
    refresh: () => void
}

const SelectorTimestampWidget = ({refresh, selector}: SelectorTimestampWidgetProps) => {
    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        let selectorTimestamp = selector.selector as SelectorTimestamp;
        selector.selector = {...selectorTimestamp, [event.target.name]: event.target.value}
        refresh()
    }

    return (
        <>
            <BodyText>Starttime</BodyText>
            <TextField
                margin="dense"
                id={"timestamp-selector-start-" + selector.dataFilter.parameterName}
                type="datetime-local"
                fullWidth
                variant="outlined"
                name='startTime'
                value={(selector.selector as SelectorTimestamp).startTime}
                onChange={onFieldChange}
            />
            <BodyText>Endtime</BodyText>
            <TextField
                margin="dense"
                id={"timestamp-selector-end-" + selector.dataFilter.parameterName}
                type="datetime-local"
                fullWidth
                variant="outlined"
                name='endTime'
                value={(selector.selector as SelectorTimestamp).endTime}
                onChange={onFieldChange}
            />
        </>
    );
};

export default SelectorTimestampWidget;