import React from 'react';
import {Typography} from "@mui/material";
import {HEADER_FOR_LIGHT_BACKGROUND} from "../../utils/consts";

interface Props {
    children?: React.ReactNode
}

const PageTitle = ({children}: Props) => {
    return (
        <Typography variant="h4" component="div" mt={1} color={HEADER_FOR_LIGHT_BACKGROUND}>
            {children}
        </Typography>
    )
};

export default PageTitle;