import React from 'react';
import {DrsItem} from "../../domain/entity/structures/requirementManagement/DrsItem";
import {ArsItem} from "../../domain/entity/structures/requirementManagement/ArsItem";
import {StateList} from "../../domain/entity/structures/requirementManagement/StateEnum";
import EnumSelectForm from "./EnumSelectForm";
import LinksList from "./LinksList";
import {Link} from "../../domain/entity/structures/requirementManagement/Link";
import BodyText from "../shared/BodyText";
import {Box, Button} from "@mui/material";

interface RsRightSideProps {
    record: DrsItem | ArsItem
    onUpdate: (item: DrsItem | ArsItem) => void
    onDeleteLink: (link: Link) => void
    onAddLinkButtonClick: () => void
    isAddLinkPossible: boolean
    onLink: (rs_id: string) => void
}

const RsRightSide = (props: RsRightSideProps) => {

    function onStateSelectChange(value: string) {
        props.onUpdate({...props.record, state: value});
    }

    return (
        <>
            <EnumSelectForm value={props.record.state}
                            onChange={onStateSelectChange}
                            variant="standard"
                            enumRecords={StateList}
                            label={"State"}/>
            <Box width={"100%"} textAlign={"center"}>
                <BodyText>Linked Items</BodyText>
            </Box>
            <LinksList links={props.record.links}
                       onLink={props.onLink}
                       onDelete={props.onDeleteLink}/>
            <Button disabled={!props.isAddLinkPossible}
                    onClick={props.onAddLinkButtonClick}>Add link with an existing item</Button>
        </>
    );
};

export default RsRightSide;