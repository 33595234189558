import React from 'react';
import {Button} from "@mui/material";

const CancelButton = () => {
    function reload() {
        window.location.reload()
    }

    return (
        <Button variant="text"
                onClick={reload}>Cancel</Button>
    );
};

export default CancelButton;