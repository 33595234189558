import React, {useContext, useEffect, useState} from 'react';
import {FlowGroup} from "../../../../domain/entity/structures/aiLab/FlowGroup";
import {allFlowGroups} from "../../../../http/flowsAPI";
import {Context} from "../../../../index";
import {flowGroupsInputAdapter} from "../AiModelUtils";
import ObjectDropDown from "../../../shared/ObjectDropDown";
import FlowsList from "../FlowsList";

interface Props {
    record: FlowGroup
    onUpdate: (value: FlowGroup) => void
}

const SelectFlowGroupWidget = (props: Props) => {
    const {appStore} = useContext(Context)
    const [flowGroups, setFlowGroups] = useState<FlowGroup[]>([]);

    useEffect(() => {
        appStore.addLoading()
        allFlowGroups().then(response => {
            setFlowGroups(flowGroupsInputAdapter(response.data));
        }).finally(() => {
            appStore.removeLoading();
        })
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSelectionChange(selectedFlowGroup: FlowGroup) {
        props.onUpdate(selectedFlowGroup)
    }

    return (
        <>
            <ObjectDropDown items={flowGroups}
                            variant={"standard"}
                            required={true}
                            selectedItem={props.record}
                            onSelectionChange={handleSelectionChange}
                            inputLabel={"Flow groups"}/>
            {
                props.record.flows.length > 0 &&
                <FlowsList flows={props.record.flows} title={"Selected Flow Group:"}/>
            }
        </>
    );
};

export default SelectFlowGroupWidget;