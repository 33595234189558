import {EnumRecord} from "../shared/EnumRecord";

export enum QualityLevelEnum {
    excellent = "Excellent",
    good = "Good",
    average = "Average",
    poor = "Poor",
}

export const QualityLevelList: EnumRecord[] = Object.entries(QualityLevelEnum)
    .map(([key, value]) => ({key, value} as EnumRecord));
