import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";

interface Props {
    isDialogOpen: boolean
    onSetPassword: (password: string) => void
    onCancel: () => void
}

const NewPasswordDialog = (props: Props) => {

    const [password, setPassword] = useState<string>("");

    function onCancelButtonClick() {
        props.onCancel()
    }

    function onPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        setPassword(event.target.value)
    }

    function onSaveButtonClick() {
        props.onSetPassword(password)
    }

    return (
        <Dialog open={props.isDialogOpen} onClose={props.onSetPassword}>
            <DialogTitle>New password</DialogTitle>
            <DialogContent>
                <TextField
                    id="new-password"
                    margin="dense"
                    label="Password"
                    type="password"
                    fullWidth
                    inputProps={{maxLength: 50}}
                    variant="standard"
                    name={'firstPassword'}
                    value={password}
                    onChange={onPasswordChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelButtonClick}>Cancel</Button>
                <Button
                    onClick={onSaveButtonClick}
                    disabled={password === ""}
                >Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default NewPasswordDialog;