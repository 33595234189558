import React from 'react';
import {Button, Grid, InputLabel, TextField} from "@mui/material";
import FileUploader from "../../../administrator/dataFlowManagement/FileUploader";
import {AiModelFlow} from "../../../../domain/entity/structures/aiLab/AiModelFlow";
import {
    SupportingFileRecordWithDescription
} from "../../../../domain/entity/structures/dataProcessing/SupportingFileRecordWithDescription";

interface Props {
    record: AiModelFlow
    onUpdate: (value: AiModelFlow) => void
    onUpdateScript: (value: AiModelFlow) => void
    supportingFileDescription: string
    setSupportingFileDescription: (value: string) => void
}

const AiModelCreateScriptWidget = (props: Props) => {

    function onDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        props.setSupportingFileDescription(target.value);
    }

    function processScriptFile(files: FileList) {
        const fileName: string = files[0].name.substring(0, files[0].name.lastIndexOf('.'))
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            let target = e.target;
            if (target !== null) {
                const text: string = target.result as string
                props.onUpdateScript({...props.record, script: text, script_name: fileName});
            }
        };
        reader.readAsText(files[0]);
    }

    function processSupportingFile(fileList: FileList) {
        for (let i = 0; i < fileList.length; i++) {
            props.record.files.push(fileList[i])
            props.record.supporting_files.push({
                file_id: null as any,
                file_name: fileList[i].name,
                file_description: props.supportingFileDescription,
                file_version: 1
            } as SupportingFileRecordWithDescription)
        }
        props.onUpdate({
            ...props.record
        });
    }

    return (
        <>
            <InputLabel>Upload AI model here*</InputLabel>
            <FileUploader processResult={processScriptFile}
                          keyPart={'upload-upload-model-script'}
                          multiple={false}
                          accept={".py"}>
                <Button variant="text" component={"span"}>
                    Upload a script*
                </Button>
            </FileUploader>
            <InputLabel>{props.record.script_name === '' ? '' : (props.record.script_name + '.py')}</InputLabel>
            <InputLabel>Add supporting files here</InputLabel>
            <TextField
                margin="dense"
                label="Supporting file description"
                type="text"
                fullWidth
                inputProps={{maxLength: 3000}}
                variant="standard"
                name='model_description'
                value={props.supportingFileDescription}
                onChange={onDescriptionChange}
            />
            {
                props.record.supporting_files.length > 0 &&
                <Grid container
                      spacing={1}
                      item
                      xs={12}
                      mt={1}
                      alignContent={"flex-start"}
                      sx={{maxHeight: "100px", overflowY: "auto"}}>
                    {
                        props.record.supporting_files.map((item) => (
                            <Grid item xs={12} key={props.record.supporting_files.indexOf(item)}>
                                <InputLabel>{item.file_name + (item.file_description === '' ? '' : '(' + item.file_description + ')')}</InputLabel>
                            </Grid>
                        ))
                    }
                </Grid>
            }
            <FileUploader processResult={processSupportingFile}
                          keyPart={'upload-supporting-file'}
                          multiple={false}
                          accept={"*"}>
                <Button variant="text" component={"span"}>
                    Upload
                </Button>
            </FileUploader>
        </>
    );
};

export default AiModelCreateScriptWidget;