import React, {useContext, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField
} from "@mui/material";
import Helmet from "react-helmet";
import {forgotPassword} from "../../../http/userAPI";
import {Context} from "../../../index";
import {useNavigate} from "react-router-dom";
import {LOGIN_ROUTE} from "../../../utils/consts";
import BodyText from "../../../components/shared/BodyText";

const ForgotPasswordPage = () => {
    let navigate = useNavigate();
    const {appStore} = useContext(Context)
    const [loginId, setLoginId] = useState('');
    const [email, setEmail] = useState('');
    const [activeStep, setActiveStep] = useState(0);

    function onSendEmail() {
        forgotPassword(loginId, email).then(() => {
            setActiveStep(1)
        }).catch(reason => {
            appStore.showAppSnackBar("error", reason.response.data.message)
        })
    }

    function onCancel() {
        navigate(LOGIN_ROUTE)
    }

    const title = "Forgot password";

    return (
        <>
            <Helmet title={title}/>
            <Box component={"form"} width={400} ml={"auto"} mr={"auto"} mt={3}>
                <Card>
                    <CardHeader title={title}/>
                    <CardContent>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            <Step key="step 1">
                                <StepLabel>
                                    Enter login id and email
                                </StepLabel>
                                <StepContent>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        label="Login"
                                        type="text"
                                        // error={dashboard.name === ''}
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name='name'
                                        value={loginId}
                                        onChange={e => setLoginId(e.target.value)}
                                    />
                                    <TextField
                                        margin="dense"
                                        label="Email"
                                        type="email"
                                        // error={dashboard.name === ''}
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name='name'
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    <Button variant={"text"} onClick={onSendEmail}>Send email with recovery
                                        link</Button>
                                    <Button variant={"text"} onClick={onCancel}>Cancel</Button>
                                </StepContent>
                            </Step>
                            <Step key="step 2">
                                <StepLabel>
                                    Check email
                                </StepLabel>
                                <StepContent>
                                    <Alert severity="info">
                                        <BodyText>The email with password recovery link was sent. Check your email and
                                            follow link to the password recovery screen.</BodyText>
                                    </Alert>
                                    <Button variant={"text"} onClick={onCancel}>Cancel</Button>
                                </StepContent>
                            </Step>
                            <Step key="step 3">
                                <StepLabel>
                                    Set new password
                                </StepLabel>
                            </Step>
                        </Stepper>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Box>
        </>
    )
};

export default ForgotPasswordPage;