import React, {useState} from 'react'
import Helmet from 'react-helmet'
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, {a11yProps} from "../../../components/administrator/filterConfiguration/TabPanel";
import PageTitle from "../../../components/shared/PageTitle";
import AddNewFlows from "../../../components/administrator/dataFlowManagement/addNewFlows/AddNewFlows";
import ManageFlows from "../../../components/administrator/dataFlowManagement/manageFlows/ManageFlows";
import ManageResultFlows from "../../../components/administrator/dataFlowManagement/manageFlows/ManageResultFlows";


const DataFlowManagement = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <Helmet title={"Administration-Data Flow Configuration"}/>
            <PageTitle>
                Data Flow Configuration
            </PageTitle>
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label="Add New Flows" {...a11yProps(0)} />
                        <Tab label="Manage Flows" {...a11yProps(1)} />
                        <Tab label="Manage Result Flows" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <AddNewFlows/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ManageFlows/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ManageResultFlows/>
                </TabPanel>
            </Box>
        </>
    );
};

export default DataFlowManagement;