import axios, {AxiosRequestConfig} from 'axios';

export const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})
export const axiosConfigJson: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json'
    },
};
export const axiosConfigMultipart: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    },
};

export const $authHost = axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_URL
})
const authInterceptor = config => {
    config.headers.authorization = 'Bearer ' + localStorage.getItem('token')
    return config
}
export const $requirementHost = axios.create({
    baseURL: process.env.REACT_APP_REQUIREMENT_API_URL
})

$authHost.interceptors.request.use(authInterceptor)
$host.interceptors.request.use(authInterceptor)
$requirementHost.interceptors.request.use(authInterceptor)

