import moment from "moment";
import UserMetaDataRecord from "../domain/entity/structures/adminisrator/dataFlowManagement/UserMetaDataRecord";
import {
    VariableType,
    VariableTypeListWithoutArrayEnum
} from "../domain/entity/structures/adminisrator/dataFlowManagement/VariableType";

export function isNumeric(value: string): boolean {
    if (value.match(/^-?\d+$/)) {
        return true
    } else if (value.match(/^\d+\.\d+$/)) {
        return true
    }
    return false;
}

export function isFloat(value: string): boolean {
    return !!value.match(/^\d+\.\d+$/);

}

export function isInteger(value: string): boolean {
    return !!value.match(/^-?\d+$/);

}

export function isNameValid(value: string): string {
    if (value === '')
        return "is empty"
    if (value.length > 50)
        return "length is more than 50 symbols"
    if (!value.search(/.*(@|\$|%|&|\\|\/|:|\*|\?|"|'|<|>|\||~|`|#|\^|\+|=|{|}|\[|\]|;|!).*/))
        return "contains one of the following symbols: @ $ % & \\ / : * ? \" ' < > | ~ ` # ^ + = { } [ ] ; !"
    return ''
}

export function isNameValidGeneral(value: string, isRequired: boolean = true, length: number = 50): string {
    if (value === '' && isRequired)
        return "is empty"
    if (value.search(/.*\p{L}.*\p{L}.*/u))
        return "should contain 2 letters"
    if (value.length > length)
        return "length is more than " + length + " symbols"
    if (!value.search(/.*(\\|\/|:|\?|"|'|<|>|\||~|`|\^|\+|=|{|}|\[|\]|;).*/))
        return "contains one of the following symbols: \\ / : * ? \" ' < > | ~ ` ^ + = { } [ ] ;"
    return ''
}

export function isShortStringValueValid(value: string): string {
    if (value === '')
        return "is empty"
    if (value.length > 25)
        return "length is more than 25 symbols"
    if (value.search(/.*\p{L}.*\p{L}.*/u))
        return "should contain 2 letters"
    if (!value.search(/.*(@|\$|%|&|\\|\/|:|\*|\?|"|'|<|>|\||~|`|#|\^|\+|=|{|}|\[|\]|;|!).*/))
        return "contains one of the following symbols: @ $ % & \\ / : * ? \" ' < > | ~ ` # ^ + = { } [ ] ; !"
    return ''
}

export function isLongStringValueValid(value: string, isRequired: boolean = true, length: number = 2000): string {
    if (isRequired && value === '')
        return "is empty"
    if (value.length > length)
        return "length is more than " + length + " symbols"
    return ''
}

export function isTimestamp(value: string): boolean {
    return moment(value) === null
}

export function isBoolean(value: string): boolean {
    return value.toLowerCase() === "true" || value.toLowerCase() === "false"
}

export function isVariableTypeWithoutArrayEnumValid(value: string): boolean {
    const find = VariableTypeListWithoutArrayEnum.find(item => item.value === value);
    return find !== undefined
}

export function isMetadataRecordValid(metadata: UserMetaDataRecord): string {
    const attributeNameValidationResult = isShortStringValueValid(metadata.attribute_name);
    if (attributeNameValidationResult !== '')
        return attributeNameValidationResult
    const typeValidationResult = isShortStringValueValid(metadata.type);
    if (typeValidationResult !== '')
        return typeValidationResult
    if (!isVariableTypeWithoutArrayEnumValid(metadata.variable_type))
        return 'variable type is incorrect'
    const userValidationResult = isShortStringValueValid(metadata.attribute_name);
    if (userValidationResult !== '')
        return userValidationResult
    switch (metadata.variable_type as VariableType) {
        case VariableType.string:
            const stringValidationResult = isShortStringValueValid(metadata.attribute_name);
            if (stringValidationResult !== '')
                return "string value is incorrect " + stringValidationResult
            break;
        case VariableType.float:
            if (!isFloat(metadata.value))
                return 'float value is incorrect'
            break;
        case VariableType.timestamp:
            if (!isTimestamp(metadata.value))
                return 'timestamp value is incorrect'
            break;
        case VariableType.integer:
            if (!isInteger(metadata.value))
                return 'integer value is incorrect'
            break;
        case VariableType.bool:
            if (!isBoolean(metadata.value))
                return 'boolean value is incorrect'
            break;
        case VariableType.enum:
        case VariableType.array:
        default:
            return ''
    }
    return ''
}


export function isMetadataValid(metadata: UserMetaDataRecord[]): string {
    for (let i = 0; i < metadata.length; i++) {
        const metadataRecordValidationResult = isMetadataRecordValid(metadata[i]);
        if (metadataRecordValidationResult !== '')
            return metadataRecordValidationResult
    }
    return ''
}

export function removeCommaFromNumericValues(result: UserMetaDataRecord[]) {
    result.forEach((item) => {
        if (item.value.indexOf(',') > -1 && isNumeric(item.value.replaceAll(",", ""))) {
            item.value = item.value.replaceAll(",", "")
        }
    })
}

