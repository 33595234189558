import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import {useNavigate} from "react-router-dom";
import {Context} from "../../index";
import {ON_FINISH_MESSAGE, REQUIREMENT_MANAGEMENT_ROUTE} from "../../utils/consts";
import PageTitle from "../../components/shared/PageTitle";
import {Button, Grid} from '@mui/material';
import {createProject} from "../../http/requirementManagementAPI";
import {Project} from "../../domain/entity/structures/requirementManagement/Project";
import ProjectProperties from "../../components/requirementManagement/ProjectProperties";
import {isProjectValid, makePayloadFromProject} from "../../components/requirementManagement/ProjectUtils";

const ProjectCreatePage = () => {
    let navigate = useNavigate();
    const {appStore} = useContext(Context)
    const [project, setProject] = useState<Project>({
        project_id: null as any,
        business_owner: "",
        business_unit: "",
        createdAt: null as any,
        created_by: "",
        description: "",
        name: "",
        updatedAt: null as any,
        updated_by: ""
    });
    const [isChanged, setIsChanged] = useState<boolean>(false);

    useEffect(() => {
        clearControls()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onClearButtonClick() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear controls?");
            if (!confirmAction)
                return
        }
        clearControls()
    }

    const title = "Manage Project - New Project";

    function clearControls() {
        setIsChanged(false)
        setProject({
            project_id: null as any,
            business_owner: "",
            business_unit: "",
            createdAt: null as any,
            created_by: "",
            description: "",
            name: "",
            updatedAt: null as any,
            updated_by: ""
        })
    }

    function onCreateButtonClick() {
        if (!isProjectValid(project, appStore))
            return
        appStore.addLoading()
        createProject(makePayloadFromProject(project)).then((response) => {
            appStore.showAppSnackBar('success', "The project has been created successfully")
            clearControls()
        }).catch(reason => {
            if (reason.response.status === 409)
                appStore.showAppSnackBar("error", reason.response.data.message)
            else
                appStore.showAppSnackBar('error', "The project has not been created")
        }).finally(() => appStore.removeLoading())
    }

    function onFinishButtonClick() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        navigate(REQUIREMENT_MANAGEMENT_ROUTE)
    }

    function setProjectValue(value: Project) {
        setIsChanged(true)
        setProject(value)
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button variant="text"
                            onClick={onFinishButtonClick}>
                        Finish
                    </Button>
                    <Button variant="text"
                            disabled={!isChanged}
                            onClick={onClearButtonClick}>
                        Clear
                    </Button>
                    <Button variant="text"
                            disabled={!isChanged}
                            onClick={onCreateButtonClick}>
                        Create
                    </Button>
                </Grid>
                <Grid container item xs={12} md={6}
                      alignContent={"flex-start"}>
                    <ProjectProperties
                        project={project}
                        setProject={setProjectValue}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ProjectCreatePage;