import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import FlowStore from "./store/FlowStore";
import AppStore from "./store/AppStore";
import UserStore from "./store/UserStore";

interface AppContextInterface {
    flowStore: FlowStore
    appStore: AppStore
    userStore: UserStore
}

const appCtxDefaultValue: AppContextInterface = {
    flowStore: null as any,
    userStore: null as any,
    appStore: null as any
};
export const Context = createContext<AppContextInterface>(appCtxDefaultValue)
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Context.Provider value={{
        flowStore: new FlowStore(),
        appStore: new AppStore(),
        userStore: new UserStore()
    }}>
        <App/>
    </Context.Provider>
);
