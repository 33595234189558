import React from 'react';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Button} from "@mui/material";

interface DropdownMenuButtonProps {
    onClick: (event: React.MouseEvent<HTMLElement>) => void
    disabled: boolean
    text: string
}

const DropdownMenuButton = (props: DropdownMenuButtonProps) => {
    return (
        <Button sx={{textTransform: "none !important"}} color="inherit"
                onClick={props.onClick}
                disabled={props.disabled}
                endIcon={<ArrowDropDownIcon/>}
        >{props.text}</Button>
    );
};

export default DropdownMenuButton;