import React, {useContext, useState} from 'react';
import {Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Link, TextField} from "@mui/material";
import Helmet from "react-helmet";
import {login} from "../../../http/userAPI";
import jwtDecode from "jwt-decode";
import {Context} from "../../../index";
import {useNavigate} from "react-router-dom";
import {FORGOT_PASSWORD_ROUTE, HOME_ROUTE} from "../../../utils/consts";
import {ActiveUser} from "../../../domain/entity/structures/adminisrator/userManagement/ActiveUser";
import BodyText from "../../../components/shared/BodyText";

const LoginPage = () => {
    let navigate = useNavigate();
    const {userStore, appStore} = useContext(Context)
    const [loginId, setLoginId] = useState('');
    const [password, setPassword] = useState('');

    async function onLogin() {
        appStore.addLoading()
        login(loginId, password).then((response) => {
            let token = response.data.token
            const tokenInfo = jwtDecode(token);
            userStore.setIsAuth(true)
            userStore.setUser(tokenInfo as ActiveUser)
            navigate(HOME_ROUTE);
        }).catch((reason) => {
            appStore.showAppSnackBar("error", reason.response.data.message)
        }).finally(() => appStore.removeLoading())

    }

    function onForgot() {
        navigate(FORGOT_PASSWORD_ROUTE);
    }

    // function onCreate() {
    //     navigate(USER_ROUTE + '/new')
    // }

    return (
        <>
            <Helmet title={"Login"}/>
            <Box component={"form"} width={400} ml={"auto"} mr={"auto"} mt={3}>
                <Card>
                    <CardHeader title={"Login"}/>
                    <CardContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Login"
                            type="text"
                            // error={dashboard.name === ''}
                            fullWidth
                            required
                            variant="outlined"
                            name='name'
                            value={loginId}
                            onChange={e => setLoginId(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="Password"
                            type="password"
                            // error={dashboard.name === ''}
                            fullWidth
                            required
                            variant="outlined"
                            name='name'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </CardContent>
                    <CardActions>
                        <Grid container>
                            <Grid item xs={12} textAlign={"center"}>
                                <Button variant={"text"} onClick={onLogin}>Login</Button>
                            </Grid>
                            <Grid container item xs={12} textAlign={"center"}>
                                <Grid item xs={6} textAlign={"center"}>
                                    {/*<Link sx={{cursor: "pointer"}} onClick={onCreate}><BodyText>Create a new*/}
                                    {/*    account</BodyText></Link>*/}
                                </Grid>
                                <Grid item xs={6} textAlign={"center"}>
                                    <Link sx={{cursor: "pointer"}} onClick={onForgot}><BodyText>Forgot
                                        password</BodyText></Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            </Box>
        </>
    )
};

export default LoginPage;