import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {HistoricalDashboard} from "../../domain/entity/structures/dataVisualisation/HistoricalDashboard";
import Grid from "@mui/material/Grid";
import {Alert, Box, Button, TextField} from "@mui/material";
import SelectFlowDialog from "../../components/dataVisualisation/historicalDashboard/SelectFlowDialog";
import {DataObject} from "../../domain/entity/structures/dataVisualisation/DataObject";
import {
    createUpdateHistoricalDashboard,
    deleteDashboard,
    filteredProcessData,
    filteredRawData,
    loadHistoricalDashboard,
    retrieveObjectData
} from "../../http/visualisationAPI";
import {Context} from "../../index";
import {Expression} from "../../domain/entity/structures/dataVisualisation/Expression";
import BodyText from "../../components/shared/BodyText";
import {DisplayEntityWithData} from "../../domain/entity/structures/dataVisualisation/DisplayEntityWithData";
import {DisplayEntityType} from "../../domain/entity/structures/dataVisualisation/DisplayEntityType";
import {DataElementWithData} from "../../domain/entity/structures/dataVisualisation/DataElementWithData";
import {DataElement} from "../../domain/entity/structures/dataVisualisation/DataElement";
import {DataElementType} from "../../domain/entity/structures/dataVisualisation/DataElementType";
import {
    A4_HEIGHT,
    A4_WIDTH,
    DATA_VISUALISATION_ROUTE,
    LINE_AND_BAR_CHART_RECORDS_LIMIT,
    PIE_CHART_RECORDS_LIMIT,
    TABLE_RECORDS_LIMIT
} from "../../utils/consts";
import ObjectIdNameDropDown
    from "../../components/administrator/filterConfiguration/filtersToQueryData/ObjectIdNameDropDown";
import {DisplayColumn} from "../../domain/entity/structures/dataVisualisation/DisplayColumn";
import ObjectsIdNameMultiselectList
    from "../../components/dataVisualisation/historicalDashboard/ObjectsIdNameMultiselectList";
import {Column} from "../../domain/entity/structures/shared/Column";
import CreateDisplayElementAccordion
    from "../../components/dataVisualisation/historicalDashboard/dataDisplayElements/CreateDisplayElementAccordion";
import SectionTitle from "../../components/shared/SectionTitle";
import PageTitle from "../../components/shared/PageTitle";
import {DisplayEntity} from "../../domain/entity/structures/dataVisualisation/DisplayEntity";
import {columnsFromObject, columnsToObject, moveArrayElement} from "../../utils/utils";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DisplayEntitiesList
    from "../../components/dataVisualisation/historicalDashboard/dataDisplayElements/DisplayEntitiesList";
import {isLongStringValueValid, isNameValidGeneral} from "../../utils/validationUtils";
import SelectResultFlowDialog from "../../components/dataVisualisation/historicalDashboard/SelectResultFlowDialog";

const HistoricalDashboardPage = () => {
    let navigate = useNavigate();
    const {id} = useParams()
    const {appStore} = useContext(Context)
    const [dashboard, setDashboard] = useState<HistoricalDashboard>({
        data_elements: [],
        description: '',
        display_entities: [],
        id: null as any,
        name: ''
    });
    const [editMode, setEditMode] = useState(false);
    const [selectFlowDialogOpen, setSelectFlowDialogOpen] = useState(false);
    const [selectResultFlowDialogOpen, setSelectResultFlowDialogOpen] = useState(false);
    const [displayEntities, setDisplayEntities] = useState<DisplayEntityWithData[]>([]);
    const [dataElementsWithData, setDataElementsWithData] = useState<DataElementWithData[]>([]);
    const [selectedDataElementWithData, setSelectedDataElementWithData] = useState<DataElementWithData>(null as any);
    const [displayColumns, setDisplayColumns] = useState<DisplayColumn[]>([]);
    const [displayColumnsForSelectedDataElement, setDisplayColumnsForSelectedDataElement] = useState<DisplayColumn[]>([]);
    const [selectedDisplayColumns, setSelectedDisplayColumns] = useState<DisplayColumn[]>([]);

    useEffect(() => {
        if (id === 'new') {
            setEditMode(true)
        } else {
            appStore.addLoading()
            loadHistoricalDashboard(id as string).then((response) => {
                if (response.status !== 200) {
                    appStore.showAppSnackBar('error', "Dashboard loading error")
                    navigate(DATA_VISUALISATION_ROUTE);
                    return
                }
                const responseDashboard = response.data.dashboard;
                const dataResponse = response.data.data_response;
                setDashboard({
                    data_elements: [],
                    description: responseDashboard.description === null ? '' : responseDashboard.description,
                    display_entities: [],
                    id: responseDashboard.id,
                    name: responseDashboard.name
                } as HistoricalDashboard)
                const dataElements: DataElementWithData[] = responseDashboard.data_elements.map((item) => {
                    return {
                        data: [], dataElement: {
                            cached_object_id: item.cached_object_id,
                            columns: columnsFromObject(item.columns),
                            data_element_type: item.data_element_type,
                            data_query: item.data_query,
                            data_element_id: item.id,
                            flow_id: item.flow_id,
                            name: item.name,
                            object_id: item.object_id
                        } as DataElement, flowName: "", id: item.id, name: item.name
                    } as DataElementWithData
                });
                if (dataElements.length === 0) throw new Error("Empty data elements")
                dataResponse.forEach((item) => {
                    let found = dataElements.find(de => {
                        return de.dataElement.object_id === item.object_id;
                    })
                    if (found !== undefined)
                        found.data = item.data
                })
                setDataElementsWithData(dataElements)
                const displayEntities = responseDashboard.display_entities.map((item) => {
                    const columns = columnsFromObject(item.columns);
                    let csvHeaders: string[] = getCsvHeaders(columns)
                    let csvData: any[] = [];
                    let chartData: any[] = []
                    if (item.data_element_ids.length) {
                        const dataElementId = item.data_element_ids[0]
                        let dataElementWithData = dataElements.find(de => de.dataElement.data_element_id === dataElementId)
                        if (dataElementWithData !== undefined) {
                            csvData = getCsvData(dataElementWithData, columns);
                            switch (item.type as DisplayEntityType) {
                                case DisplayEntityType.table:
                                    chartData.push(csvHeaders)
                                    let tableData = getTableData(dataElementWithData, columns);
                                    chartData.push(tableData)
                                    break;
                                case DisplayEntityType.line:
                                    chartData = getLineChartData(dataElementWithData, columns, LINE_AND_BAR_CHART_RECORDS_LIMIT, false);
                                    break;
                                case DisplayEntityType.bar:
                                    chartData = getBarChartData(dataElementWithData, columns, LINE_AND_BAR_CHART_RECORDS_LIMIT);
                                    break;
                                case DisplayEntityType.pie:
                                    chartData = getPieChartData(dataElementWithData, columns);
                                    break;
                                case DisplayEntityType.radar:
                                    chartData = getBarChartData(dataElementWithData, columns, PIE_CHART_RECORDS_LIMIT);
                                    break;
                            }
                        }
                    }

                    return {
                        csvData: csvData,
                        csvHeaders: csvHeaders,
                        dashboardId: responseDashboard.id,
                        data: chartData,
                        displayEntity: {
                            columns: columns,
                            display_id: item.id,
                            type: item.type,
                            data_element_id: item.data_element_ids[0]
                        } as DisplayEntity,
                        title: ""
                    } as DisplayEntityWithData
                });
                let displayColumnsTmp: DisplayColumn[] = []
                dataElements.forEach((item) => {
                    item.dataElement.columns.map((itemColumn) => {
                        return {
                            column: itemColumn,
                            dataElementId: item.dataElement.data_element_id,
                            id: item.dataElement.data_element_id + itemColumn.columnName,
                            name: itemColumn.columnName
                        } as DisplayColumn
                    }).forEach((item) => {
                        displayColumnsTmp.push(item)
                    })
                })
                setDisplayColumns(displayColumnsTmp)
                setDisplayEntities(displayEntities)
                if (dataElements.length)
                    setSelectedDataElementWithData(dataElements[0])
            }).finally(() => appStore.removeLoading())
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let result: DisplayColumn[] = []
        if (selectedDataElementWithData) {
            displayColumns.forEach((item) => {
                if (item.dataElementId === selectedDataElementWithData.id)
                    result.push(item)
            })
        }
        setDisplayColumnsForSelectedDataElement(result)
        setSelectedDisplayColumns([])
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDataElementWithData]);

    function isValid() {
        const nameValidationResult = isNameValidGeneral(dashboard.name);
        if (nameValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Dashboard's name has validation error with message: " + nameValidationResult)
            return false
        }
        const descriptionValidationResult = isLongStringValueValid(dashboard.description, false);
        if (descriptionValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Dashboard's description has validation error with message: " + descriptionValidationResult)
            return false
        }
        return true
    }

    function onSaveButtonClick() {
        if (!isValid())
            return
        appStore.addLoading()
        const payload = {
            id: dashboard.id,
            name: dashboard.name,
            description: dashboard.description === '' ? null as any : dashboard.description,
            data_elements: dataElementsWithData.map((item) => {
                return {
                    ...item.dataElement, columns: columnsToObject(item.dataElement.columns)
                }
            }),
            display_entities: displayEntities.map((item) => {
                return {
                    ...item.displayEntity, columns: columnsToObject(item.displayEntity.columns)
                }
            })
        }
        createUpdateHistoricalDashboard(payload).then(() => {
            appStore.showAppSnackBar('success', "The dashboard has been created/updated successfully")
            navigate(DATA_VISUALISATION_ROUTE)
        }).catch(() => {
            appStore.showAppSnackBar('error', "The dashboard has not been created/updated")
        }).finally(() => appStore.removeLoading())
    }

    function onClearButtonClick() {
        window.location.reload()
    }

    function onEditButtonClick() {
        setEditMode(!editMode)
    }

    function onDuplicateButtonClick() {
        dashboard.id = null as any
        onSaveButtonClick()
    }

    function onCancelButtonClick() {
        let confirmAction = window.confirm("Are you sure to cancel the changes?");
        if (!confirmAction)
            return
        navigate(DATA_VISUALISATION_ROUTE);
    }

    function addPicture(pdf, currentIndex, length) {
        if (currentIndex === length) {
            pdf.save("chart");
        } else {
            const node: any = document.getElementById('data-entity-picture-' + currentIndex);
            const scaleX = (A4_HEIGHT - 20) / 2 / node.clientWidth
            const scaleY = (A4_WIDTH - 30) / 2 / node.clientHeight
            const scale = Math.min(scaleX, scaleY)
            html2canvas(node as any)
                .then((canvas) => {
                    const dataUrl = canvas.toDataURL("image / png", 1);
                    if (currentIndex % 4 === 0 && currentIndex > 0)
                        pdf.addPage()
                    if (currentIndex % 4 === 0)
                        pdf.addImage(dataUrl, 'PNG', 10, 20, node.clientWidth * scale, node.clientHeight * scale);
                    if (currentIndex % 4 === 1)
                        pdf.addImage(dataUrl, 'PNG', 10 + (A4_HEIGHT - 20) / 2, 20, node.clientWidth * scale, node.clientHeight * scale);
                    if (currentIndex % 4 === 2)
                        pdf.addImage(dataUrl, 'PNG', 10, 20 + (A4_WIDTH - 30) / 2, node.clientWidth * scale, node.clientHeight * scale);
                    if (currentIndex % 4 === 3)
                        pdf.addImage(dataUrl, 'PNG', 10 + (A4_HEIGHT - 20) / 2, 20 + (A4_WIDTH - 30) / 2, node.clientWidth * scale, node.clientHeight * scale);
                    addPicture(pdf, currentIndex + 1, length)
                })
                .catch(function (error) {
                    console.error('Error during PDF export', error);
                }).finally(() => appStore.removeLoading())
        }
    }

    function onExportButtonClick() {
        appStore.addLoading()
        // noinspection JSPotentiallyInvalidConstructorUsage
        const pdf = new jsPDF("l", "mm", "a4");
        pdf.text(dashboard.name, 10, 10)
        pdf.setFontSize(pdf.getFontSize() - 3)
        pdf.text(dashboard.description, 10, 15)
        addPicture(pdf, 0, displayEntities.length)
    }

    function onDeleteButtonClick() {
        let confirmAction = window.confirm("Are you sure to delete this dashboard?");
        if (!confirmAction)
            return
        appStore.addLoading()
        deleteDashboard(dashboard.id).then(() => {
            appStore.showAppSnackBar('success', "The dashboard has been deleted successfully")
            navigate(DATA_VISUALISATION_ROUTE);
        }).catch(() => {
            appStore.showAppSnackBar('error', "The dashboard has not been deleted")
        }).finally(() => appStore.removeLoading())
    }

    const title = "Historical Data Analysis Dashboard - " + (dashboard.name === '' ? 'New' : dashboard.name);

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        setDashboard({...dashboard, [name]: target.value});
    }

    function onAddDataButtonClick() {
        setSelectFlowDialogOpen(true)
    }

    function onSelectFlowDialogCancel() {
        setSelectFlowDialogOpen(false)
    }

    function onAddResultDataButtonClick() {
        setSelectResultFlowDialogOpen(true)
    }

    function onSelectResultFlowDialogCancel() {
        setSelectResultFlowDialogOpen(false)
    }

    function onAddDataObject(flowId: string, flowName: string, dataObjects: DataObject[]) {
        appStore.addLoading()
        retrieveObjectData(flowId, dataObjects).then((response) => {
            const dataObjectsList = response.data.data_by_object_id
            for (let objectId in dataObjectsList) {
                const dataElementData = dataObjectsList[objectId].data;
                const dataElementName = dataObjectsList[objectId].object_name;
                const dataElementId = objectId + "-" + Date.now();
                const dataElementColumns = dataObjectsList[objectId].columns;
                dataElementsWithData.push({
                        dataElement: {
                            cached_object_id: objectId,
                            columns: dataElementColumns.map((item) => {
                                return {columnName: item.name, variableType: item.type} as Column
                            }),
                            data_element_type: DataElementType.dataObject,
                            data_query: null as any,
                            data_element_id: dataElementId,
                            flow_id: response.data.flow_id,
                            name: dataObjectsList[objectId].object_name,
                            object_id: objectId
                        } as DataElement,
                        data: dataElementData,
                        flowName: flowName,
                        name: dataElementName,
                        id: dataElementId
                    } as DataElementWithData
                )
                if (dataElementData !== null && dataElementData.length) {
                    dataElementColumns.forEach((item) => {
                        displayColumns.push({
                                column: {columnName: item.name, variableType: item.type} as Column,
                                id: dataElementId + '-' + item.name,
                                name: item.name,
                                dataElementId: dataElementId
                            } as DisplayColumn
                        )
                    })
                }
            }
            setDataElementsWithData(dataElementsWithData.slice())
            setDisplayColumns(displayColumns.slice())
            if (dataElementsWithData.length > 0 && selectedDataElementWithData === null) {
                setSelectedDataElementWithData(dataElementsWithData[0])
            }
            appStore.showAppSnackBar('success', "Data objects have been added");
        }).finally(() => appStore.removeLoading())
    }

    function onAddProcessedData(flowId: string, flowName: string, expression: Expression) {
        appStore.addLoading()
        filteredProcessData(flowId, expression).then((response) => {
            loadDataset(response.data);
        }).finally(() => appStore.removeLoading())
    }

    function onAddResultData(flowId: string, flowName: string, expression: Expression) {
        appStore.addLoading()
        filteredProcessData(flowId, expression).then((response) => {
            loadDataset(response.data);
        }).finally(() => appStore.removeLoading())
    }

    function loadDataset(dataset: any) {
        const dataElementData = dataset.data;
        const dataElementName = dataset.flow_name;
        const dataElementId = dataset.object_id + "-" + Date.now();
        const columns: Column[] = dataset.columns.map((item: any) => {
            return {columnName: item.name, variableType: item.type} as Column
        });
        dataElementsWithData.push({
                dataElement: {
                    cached_object_id: null as any,
                    columns: columns,
                    data_element_type: DataElementType.dataSet,
                    data_query: dataset.expression,
                    data_element_id: dataElementId,
                    flow_id: dataset.flow_id,
                    name: dataset.flow_name,
                    object_id: dataset.object_id
                } as DataElement,
                data: dataElementData,
                flowName: dataset.flow_name,
                name: dataElementName,
                id: dataElementId
            } as DataElementWithData
        )
        if (dataElementData !== null && dataElementData.length) {
            columns.forEach((item) => {
                displayColumns.push({
                        column: item,
                        id: dataElementId + '-' + item.columnName,
                        name: item.columnName,
                        dataElementId: dataElementId
                    } as DisplayColumn
                )
            })
        }
        setDataElementsWithData(dataElementsWithData.slice())
        setDisplayColumns(displayColumns.slice())
        if (dataElementsWithData.length > 0 && selectedDataElementWithData === null) {
            setSelectedDataElementWithData(dataElementsWithData[0])
        }
        appStore.showAppSnackBar('success', "Dataset has been added");
    }

    function onAddRawData(flowId: string, flowName: string, expression: Expression) {
        appStore.addLoading()
        filteredRawData(flowId, expression).then((response) => {
            loadDataset(response.data);
        }).finally(() => appStore.removeLoading())
    }

    function onExportDisplayEntityToPdf(item: DisplayEntityWithData) {
        appStore.addLoading()
        const node: any = document.getElementById('data-entity-picture-' + displayEntities.indexOf(item));
        const scaleX = (A4_WIDTH - 20) / node.clientWidth
        const scaleY = (A4_HEIGHT - 20) / node.clientHeight
        const scale = Math.min(scaleX, scaleY)
        html2canvas(node as any)
            .then((canvas) => {
                const dataUrl = canvas.toDataURL("image / png", 1);
                // noinspection JSPotentiallyInvalidConstructorUsage
                const pdf = new jsPDF("p", "mm", "a4");
                pdf.addImage(dataUrl, 'PNG', 10, 10, node.clientWidth * scale, node.clientHeight * scale);
                pdf.save("chart");
            })
            .catch(function (error) {
                console.error('Error during PDF export', error);
            }).finally(() => appStore.removeLoading())
    }

    function onDeleteDisplayEntity(entity: DisplayEntityWithData) {
        displayEntities.splice(displayEntities.indexOf(entity), 1)
        setDisplayEntities(displayEntities.slice())
    }

    function onRemoveDataButtonClick() {
        setSelectedDisplayColumns([])
        const selectedIndex = dataElementsWithData.indexOf(selectedDataElementWithData);
        dataElementsWithData.splice(selectedIndex, 1)
        setSelectedDataElementWithData(dataElementsWithData.length ? dataElementsWithData[0] : null as any)
        setDataElementsWithData(dataElementsWithData.slice())
    }

    function refreshSelectedDisplayColumns() {
        setSelectedDisplayColumns(selectedDisplayColumns.slice())
    }

    function getCsvHeaders(columns: Column[]): string[] {
        return columns.map((item) => {
            return item.columnName
        });
    }

    function getCsvData(dataElementWithData: DataElementWithData, columns: Column[]) {
        let tableData: any[] = []

        for (let i = 0; i < dataElementWithData.data.length; i++) {
            let row: any[] = [];
            columns.forEach((item) => {
                row.push(dataElementWithData.data[i][item.columnName])
            })
            tableData.push(row)
        }
        return tableData;
    }

    function getTableData(dataElementWithData: DataElementWithData, columns: Column[]) {
        let tableData: any[] = []

        for (let i = dataElementWithData.data.length > TABLE_RECORDS_LIMIT ? dataElementWithData.data.length - TABLE_RECORDS_LIMIT : 0; i < dataElementWithData.data.length; i++) {
            let row: any[] = [];
            row.push(i)
            let rowData: any[] = [];
            columns.forEach((item) => {
                rowData.push(dataElementWithData.data[i][item.columnName])
            })
            row.push(rowData)
            tableData.push(row)
        }
        if (dataElementWithData.data.length > TABLE_RECORDS_LIMIT)
            appStore.showAppSnackBar('info', "The dataset has too many records(" + dataElementWithData.data.length + "). Records count is reduced to last " + TABLE_RECORDS_LIMIT);
        return tableData;
    }

    function onAddTable() {
        const columns = selectedDisplayColumns.map(item => item.column);
        let chartData: any[] = []
        let tableHeader: string[] = getCsvHeaders(columns)
        chartData.push(tableHeader)
        let tableData = getTableData(selectedDataElementWithData, columns);
        chartData.push(tableData)
        displayEntities.push(
            {
                displayEntity: {
                    display_id: null as any,
                    type: DisplayEntityType.table,
                    columns: selectedDisplayColumns.map((item) => {
                        return item.column
                    }),
                    data_element_id: selectedDataElementWithData.dataElement.data_element_id,
                },
                dashboardId: dashboard.id,
                data: chartData,
                title: selectedDisplayColumns.map((item) => {
                    return item.column.columnName
                }).join(" - "),
                csvHeaders: tableHeader,
                csvData: getCsvData(selectedDataElementWithData, columns)
            },
        )
        setDisplayEntities(displayEntities.slice())
    }

    function getBarChartData(dataElementWithData: DataElementWithData, columns: Column[], recordsLimit: number) {
        let chartData: any[] = []
        for (let i = dataElementWithData.data.length > recordsLimit ? dataElementWithData.data.length - recordsLimit : 0; i < dataElementWithData.data.length; i++) {
            let result: any = {};
            columns.forEach((item) => {
                result[item.columnName] = dataElementWithData.data[i][item.columnName]
            })
            chartData.push(result)
        }
        if (dataElementWithData.data.length > recordsLimit)
            appStore.showAppSnackBar('info', "The dataset has too many records(" + dataElementWithData.data.length + "). Records count is reduced to last " + recordsLimit);
        return chartData;
    }

    function getLineChartData(dataElementWithData: DataElementWithData, columns: Column[], recordsLimit: number, isOrdered: boolean) {
        let chartData: any[] = []
        for (let i = dataElementWithData.data.length > recordsLimit ? dataElementWithData.data.length - recordsLimit : 0; i < dataElementWithData.data.length; i++) {
            let result: any = {};
            columns.forEach((item) => {
                result[item.columnName] = dataElementWithData.data[i][item.columnName]
            })
            chartData.push(result)
        }
        if (isOrdered) {
            const axisColumn = columns[0].columnName
            chartData.sort((a, b) => {
                if (a[axisColumn] < b[axisColumn]) {
                    return -1;
                }
                if (a[axisColumn] > b[axisColumn]) {
                    return 1;
                }
                return 0;
            })
        }
        if (dataElementWithData.data.length > recordsLimit)
            appStore.showAppSnackBar('info', "The dataset has too many records(" + dataElementWithData.data.length + "). Records count is reduced to last " + recordsLimit);
        return chartData;
    }

    function onAddLineChart(isOrdered: boolean) {
        const columns = selectedDisplayColumns.map(item => item.column);
        let chartData = getLineChartData(selectedDataElementWithData, columns, LINE_AND_BAR_CHART_RECORDS_LIMIT, isOrdered);
        displayEntities.push(
            {
                displayEntity: {
                    display_id: null as any,
                    type: DisplayEntityType.line,
                    columns: selectedDisplayColumns.map((item) => {
                        return item.column
                    }),
                    data_element_id: selectedDataElementWithData.dataElement.data_element_id,
                },
                dashboardId: dashboard.id,
                data: chartData,
                title: '',
                csvHeaders: getCsvHeaders(columns),
                csvData: getCsvData(selectedDataElementWithData, selectedDisplayColumns.map(item => item.column))
            },
        )
        setDisplayEntities(displayEntities.slice())
    }

    function onAddBarChart() {
        const columns = selectedDisplayColumns.map(item => item.column);
        let chartData = getBarChartData(selectedDataElementWithData, columns, LINE_AND_BAR_CHART_RECORDS_LIMIT);
        displayEntities.push(
            {
                displayEntity: {
                    display_id: null as any,
                    type: DisplayEntityType.bar,
                    columns: selectedDisplayColumns.map((item) => {
                        return item.column
                    }),
                    data_element_id: selectedDataElementWithData.dataElement.data_element_id,
                },
                dashboardId: dashboard.id,
                data: chartData,
                title: '',
                csvHeaders: getCsvHeaders(columns),
                csvData: getCsvData(selectedDataElementWithData, selectedDisplayColumns.map(item => item.column))
            },
        )
        setDisplayEntities(displayEntities.slice())
    }

    function getPieChartData(dataElementWithData: DataElementWithData, columns: Column[]) {
        let chartData: any[] = []
        const firstColumn = columns[0];

        for (let j = 1; j < columns.length; j++) {
            let pieData: any[] = []
            for (let i = dataElementWithData.data.length > PIE_CHART_RECORDS_LIMIT ? dataElementWithData.data.length - PIE_CHART_RECORDS_LIMIT : 0; i < dataElementWithData.data.length; i++) {
                let result: any = {};
                result["name"] = dataElementWithData.data[i][firstColumn.columnName]
                result["value"] = dataElementWithData.data[i][columns[j].columnName]
                pieData.push(result)
            }
            chartData.push(pieData)
        }
        if (dataElementWithData.data.length > PIE_CHART_RECORDS_LIMIT)
            appStore.showAppSnackBar('info', "The dataset has too many records(" + dataElementWithData.data.length + "). Records count is reduced to last " + PIE_CHART_RECORDS_LIMIT);
        return chartData;
    }

    function onAddPieChart() {
        const columns = selectedDisplayColumns.map(item => item.column);
        let chartData = getPieChartData(selectedDataElementWithData, columns);
        displayEntities.push(
            {
                displayEntity: {
                    display_id: null as any,
                    type: DisplayEntityType.pie,
                    columns: selectedDisplayColumns.map((item) => {
                        return item.column
                    }),
                    data_element_id: selectedDataElementWithData.dataElement.data_element_id,
                },
                dashboardId: dashboard.id,
                data: chartData,
                title: '',
                csvHeaders: getCsvHeaders(columns),
                csvData: getCsvData(selectedDataElementWithData, selectedDisplayColumns.map(item => item.column))
            },
        )
        setDisplayEntities(displayEntities.slice())
    }

    function onAddRadarChart() {
        const columns = selectedDisplayColumns.map(item => item.column);
        let chartData = getBarChartData(selectedDataElementWithData, columns, PIE_CHART_RECORDS_LIMIT);
        displayEntities.push(
            {
                displayEntity: {
                    display_id: null as any,
                    type: DisplayEntityType.radar,
                    columns: selectedDisplayColumns.map((item) => {
                        return item.column
                    }),
                    data_element_id: selectedDataElementWithData.dataElement.data_element_id,
                },
                dashboardId: dashboard.id,
                data: chartData,
                title: '',
                csvHeaders: getCsvHeaders(columns),
                csvData: getCsvData(selectedDataElementWithData, selectedDisplayColumns.map(item => item.column))
            },
        )
        setDisplayEntities(displayEntities.slice())
    }

    function onMoveDownDisplayEntity(entity: DisplayEntityWithData) {
        const index = displayEntities.indexOf(entity);
        if (index === displayEntities.length - 1)
            return
        const result = moveArrayElement(displayEntities, index, index + 1)
        setDisplayEntities(result.slice())
    }

    function onMoveUpDisplayEntity(entity: DisplayEntityWithData) {
        const index = displayEntities.indexOf(entity);
        if (index === 0)
            return
        const result = moveArrayElement(displayEntities, index, index - 1)
        setDisplayEntities(result.slice())
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button variant="text"
                            disabled={dashboard.name === '' || displayEntities.length === 0 || dataElementsWithData.length === 0 || !editMode}
                            onClick={onSaveButtonClick}>
                        Save
                    </Button>
                    <Button variant="text"
                            disabled={!editMode}
                            onClick={onClearButtonClick}>
                        Clear
                    </Button>
                    <Button variant="text"
                            disabled={editMode}
                            onClick={onEditButtonClick}>
                        Edit
                    </Button>
                    <Button variant="text"
                            disabled={dashboard.id === null || true}
                            onClick={onDuplicateButtonClick}>
                        Duplicate
                    </Button>
                    <Button variant="text"
                            onClick={onCancelButtonClick}>
                        Cancel
                    </Button>
                    <Button variant="text"
                            disabled={displayEntities.length === 0}
                            onClick={onExportButtonClick}>
                        Export
                    </Button>
                    <Button variant="text"
                            disabled={dashboard.id === null}
                            onClick={onDeleteButtonClick}>
                        Delete
                    </Button>
                </Grid>
                <Grid container spacing={1} item xs={12} sm={editMode ? 6 : 3} alignContent={"flex-start"}>
                    {/*VIEW MODE*/}
                    {
                        !editMode &&
                        <Grid container item xs={12} spacing={1}>
                            <Grid container item justifyContent={"space-around"}>
                                <BodyText>Name</BodyText>
                            </Grid>
                            <Grid item xs={12}>
                                <BodyText>{dashboard.name}</BodyText>
                            </Grid>
                            <Grid container item justifyContent={"space-around"}>
                                <BodyText>Description</BodyText>
                            </Grid>
                            <Grid item xs={12}>
                                <BodyText>{dashboard.description === null ? '' : dashboard.description}</BodyText>
                            </Grid>
                            <Grid container item>
                                <BodyText>Data elements:</BodyText>
                            </Grid>
                            {
                                dataElementsWithData.map((item) => (
                                    <Grid container item xs={12}
                                          key={"view-data-element-wrapper-" + dataElementsWithData.indexOf(item)}>
                                        <Grid container item justifyContent={"space-around"}
                                              key={"view-data-element-" + dataElementsWithData.indexOf(item)} mt={1}>
                                            <BodyText>{item.dataElement.name + "(" + item.dataElement.data_element_type + ")"}</BodyText>
                                        </Grid>
                                        {
                                            item.dataElement.columns.map((itemColumn) => (
                                                    <Grid item xs={12} ml={2}
                                                          key={"view-data-element-column-" + dataElementsWithData.indexOf(item) + "-" + item.dataElement.columns.indexOf(itemColumn)}>
                                                        <BodyText>{itemColumn.columnName}</BodyText>
                                                    </Grid>
                                                )
                                            )
                                        }
                                    </Grid>
                                ))
                            }
                        </Grid>
                    }
                    {/*EDIT MODE*/}
                    {
                        editMode &&
                        <Grid container item xs={6}
                              alignContent={"flex-start"}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="historical-dashboard-name"
                                label="Name of dashboard"
                                type="text"
                                error={dashboard.name === ''}
                                fullWidth
                                required
                                variant="outlined"
                                name='name'
                                value={dashboard.name}
                                onChange={onFieldChange}
                            />
                            <TextField
                                margin="dense"
                                id="edit-user-metadata-record-name"
                                label="Description"
                                type="text"
                                fullWidth
                                variant="outlined"
                                name='description'
                                value={dashboard.description}
                                onChange={onFieldChange}
                            />
                            <Button variant="text"
                                    fullWidth
                                    onClick={onAddDataButtonClick}>
                                Add input data
                            </Button>
                            <Button variant="text"
                                    fullWidth
                                    onClick={onAddResultDataButtonClick}>
                                Add result data
                            </Button>
                            <ObjectIdNameDropDown objs={dataElementsWithData}
                                                  disabled={dataElementsWithData.length === 0}
                                                  selectedObj={dataElementsWithData.indexOf(selectedDataElementWithData) !== -1 ? selectedDataElementWithData : '' as any}
                                                  onSelectionChange={(item) => {
                                                      setSelectedDataElementWithData(item)
                                                  }}
                                                  label={"Data element"}
                            />
                            <Button variant="text"
                                    fullWidth
                                    disabled={selectedDataElementWithData === null || displayEntities.find(item => item.displayEntity.data_element_id === selectedDataElementWithData.dataElement.data_element_id) !== undefined}
                                    onClick={onRemoveDataButtonClick}>
                                Remove
                            </Button>
                            {
                                dataElementsWithData.length === 0 &&
                                <Alert severity={'info'}>Add data in order to display the columns list</Alert>
                            }
                            <ObjectsIdNameMultiselectList objs={displayColumnsForSelectedDataElement}
                                                          selectedObjs={selectedDisplayColumns}
                                                          refresh={refreshSelectedDisplayColumns}/>
                        </Grid>
                    }
                    {
                        editMode &&
                        <Grid item xs={6} textAlign={"center"}>
                            <BodyText>Data Display Elements</BodyText>
                            <CreateDisplayElementAccordion selectedColumns={selectedDisplayColumns}
                                                           onAddTable={onAddTable}
                                                           onAddLineChart={onAddLineChart}
                                                           onAddBarChart={onAddBarChart}
                                                           onAddPieChart={onAddPieChart}
                                                           onAddRadarChart={onAddRadarChart}/>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={1} item xs={12} sm={editMode ? 6 : 9} alignContent={"flex-start"}
                      overflow={"auto"}>
                    <Box width={"100%"} textAlign={"center"}>
                        <SectionTitle>{dashboard.name}</SectionTitle>
                    </Box>
                    <DisplayEntitiesList
                        displayEntities={displayEntities}
                        editMode={editMode}
                        onExportToPdf={onExportDisplayEntityToPdf}
                        onDelete={onDeleteDisplayEntity}
                        onMoveDown={onMoveDownDisplayEntity}
                        onMoveUp={onMoveUpDisplayEntity}
                    />
                </Grid>
            </Grid>
            <SelectFlowDialog dialogOpen={selectFlowDialogOpen}
                              dialogTitle={"Select Input Flow"}
                              flowType={"input"}
                              onAddDataObject={onAddDataObject}
                              onAddProcessedData={onAddProcessedData}
                              onAddRawData={onAddRawData}
                              onCancel={onSelectFlowDialogCancel}
            />
            <SelectResultFlowDialog dialogOpen={selectResultFlowDialogOpen}
                                    onAddDataObject={onAddDataObject}
                                    onAddResultData={onAddResultData}
                                    onCancel={onSelectResultFlowDialogCancel}
            />
        </>
    )
        ;
};

export default HistoricalDashboardPage;