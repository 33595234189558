import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Context} from "../../../index";
import BodyText from "../../shared/BodyText";
import Box from "@mui/material/Box";
import {Flow} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import {filteredFlow} from "../../../http/visualisationAPI";
import ObjectsIdNameMultiselectList from "./ObjectsIdNameMultiselectList";
import {DataObject} from "../../../domain/entity/structures/dataVisualisation/DataObject";
import {Expression} from "../../../domain/entity/structures/dataVisualisation/Expression";
import SelectResultData from "./SelectResultData";
import ConditionSelector from "../../../domain/entity/structures/dataVisualisation/ConditionSelector";
import {VariableType} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/VariableType";
import {SelectorStringType} from "../../../domain/entity/structures/dataVisualisation/SelectorStringType";
import {DataFilter} from "../../../domain/entity/structures/dataVisualisation/DataFilter";
import SelectorString from "../../../domain/entity/structures/dataVisualisation/SelectorString";
import {getExpressionForSelectors} from "../../../utils/utils";

interface Props {
    dialogOpen: boolean
    onCancel: () => void
    onAddDataObject: (flowId: string, flowName: string, dataObjects: DataObject[]) => void
    onAddResultData: (flowId: string, flowName: string, expression: Expression) => void
}

const SelectResultFlowDialog = (props: Props) => {
    const {appStore} = useContext(Context)
    const [flows, setFlows] = useState<Flow[]>([]);
    const [selectedFlows, setSelectedFlows] = useState<Flow[]>([]);
    const [selectResultDataOpen, setSelectResultDataOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.dialogOpen) {
            onShowResults()
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dialogOpen, appStore]);

    function onSelectResultData() {
        setSelectResultDataOpen(true)
    }

    function onShowResults() {
        let finalSelectors: ConditionSelector[] = []
        finalSelectors.push({
            dataFilter: {
                definedValues: [],
                id: "",
                parameterName: "flow_type",
                variableType: VariableType.string
            } as DataFilter,
            selector: {type: SelectorStringType.starts, value: "result"} as SelectorString
        } as ConditionSelector)
        const expressionForSelectors = getExpressionForSelectors(finalSelectors);
        appStore.addLoading()
        filteredFlow(expressionForSelectors).then((response) => {
            const data = response.data as any
            setSelectedFlows([])
            flows.splice(0)
            for (let key in data) {
                flows.push({attributes: [], columns: [], id: key, name: data[key]} as Flow)
            }
            setFlows(flows.slice());
        }).catch((reason) => {
            appStore.showAppSnackBar("error", reason.response.data.detail)
        }).finally(() => appStore.removeLoading())
    }

    function refreshSelectedFlows() {
        setSelectedFlows(selectedFlows.slice())
    }

    function onSelectResultDataCancel() {
        setSelectResultDataOpen(false)
    }

    function onFinish() {
        setSelectResultDataOpen(false)
        props.onCancel()
    }

    return (
        <>
            <Dialog open={props.dialogOpen} onClose={props.onCancel}>
                <DialogTitle>Select Result flow</DialogTitle>
                <DialogContent>
                    <BodyText>
                        List of Flows
                    </BodyText>
                    <Box height={'300px'} sx={{overflowY: "auto", overflowX: "hidden"}}>
                        <ObjectsIdNameMultiselectList objs={flows}
                                                      selectedObjs={selectedFlows}
                                                      refresh={refreshSelectedFlows}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} onClick={props.onCancel}>Close</Button>
                    <Button variant={"contained"}
                            disabled={selectedFlows.length === 0}
                            onClick={onSelectResultData}>Select result data</Button>
                </DialogActions>
            </Dialog>
            {
                selectResultDataOpen && <SelectResultData dialogOpen={selectResultDataOpen}
                                                          onAddResultData={props.onAddResultData}
                                                          onAddDataObject={props.onAddDataObject}
                                                          flows={selectedFlows}
                                                          onFinish={onFinish}
                                                          onCancel={onSelectResultDataCancel}/>
            }
        </>
    );
};

export default SelectResultFlowDialog;