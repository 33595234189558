import {EnumRecord} from "../../shared/EnumRecord";

export enum VariableType {
    string = "string",
    float = "float",
    enum = "enum",
    timestamp = "timestamp",
    bool = "bool",
    array = "array",
    integer = "integer"
}

export const VariableTypeList: EnumRecord[] = Object.entries(VariableType)
    .map(([key, value]) => ({key, value} as EnumRecord));

export const VariableTypeListWithoutArray: EnumRecord[] = Object.entries(VariableType)
    .map(([key, value]) => ({key, value} as EnumRecord))
    .filter(item => item.key !== VariableType.array)

export const VariableTypeListWithoutArrayEnum: EnumRecord[] = Object.entries(VariableType)
    .map(([key, value]) => ({key, value} as EnumRecord))
    .filter(item => (item.key !== VariableType.array && item.key !== VariableType.enum))
