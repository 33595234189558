import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import {observer} from "mobx-react-lite";
import RsProperties from "./RsProperties";
import RsRightSide from "./RsRightSide";
import {Context} from "../../index";
import {DrsItem} from "../../domain/entity/structures/requirementManagement/DrsItem";
import {isLongStringValueValid} from "../../utils/validationUtils";
import CommentsList from "./CommentsList";
import {RsComment} from "../../domain/entity/structures/requirementManagement/RsComment";
import {Link} from "../../domain/entity/structures/requirementManagement/Link";
import AddLinkDialog from "./AddLinkDialog";
import {LinkTypeEnum} from "../../domain/entity/structures/requirementManagement/LinkTypeEnum";
import {ON_FINISH_MESSAGE} from "../../utils/consts";
import {
    getRsListForLinks,
    isRsValid,
    makePayloadFromRs,
    removeEmptyLogicStatement,
    rsDataImportAdapter
} from "./ProjectUtils";
import {deleteDrsItem, retrieveDrsItem, updateDrsItem} from "../../http/requirementManagementAPI";
import RsCreatedUpdated from "./RsCreatedUpdated";
import DrsScenarioWidget from "./DrsScenarioWidget";
import {Scenario} from "../../domain/entity/structures/dataProcessing/Scenario";
import {getScenariosByDrs} from "../../http/dataProcessingAPI";
import AllocateScenariosDialog from "./AllocateScenariosDialog";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";
import DrsScenarioReviewDialog from "./DrsScenarioReviewDialog";
import moment from "moment";
import ScenarioEditDialog from "../models/dataProcessing/ScenarioEditDialog";

interface DrsUpdateDialogProps {
    isDialogOpen: boolean
    projectId: string
    drsId: string
    rsList: DrsItem[]
    onRsListReload: () => void
    onLink: (rs_id: string) => void
    onClose: () => void
}

const DrsUpdateDialog = observer((props: DrsUpdateDialogProps) => {
    const {appStore} = useContext(Context)
    const {userStore} = useContext(Context)
    const [newComment, setNewComment] = useState<string>('');
    const [rs, setRs] = useState<DrsItem>({
        rs_id: "",
        category: "",
        complexity: "",
        criticality: "",
        description: "",
        links: [],
        logic_statements: [''],
        name: "",
        project_id: props.projectId,
        requirement_comments: [],
        state: "New",
        subject: "",
    });
    const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
    const [scenarios, setScenarios] = useState<Scenario[]>([]);
    const [allocateScenariosDialogOpen, setAllocateScenariosDialogOpen] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [modelReviewDialogOpen, setModelReviewDialogOpen] = useState<boolean>(false);
    const [selectedScenario, setSelectedScenario] = useState<IdNameListValues>(null as any);
    const [scenarioEditDialogOpen, setScenarioEditDialogOpen] = useState<boolean>(false);
    const [isCreateScenario, setIsCreateScenario] = useState<boolean>(false);

    function loadScenarios() {
        appStore.addLoading()
        getScenariosByDrs(props.drsId).then((response) => {
            setScenarios(response.data);
        }).catch(() => {
            appStore.showAppSnackBar('error', "DRS scenarios loading error")
        }).finally(() => appStore.removeLoading())
    }

    useEffect(() => {
        if (props.drsId === null) return
        appStore.addLoading()
        retrieveDrsItem(props.drsId).then((response) => {
            setRs(rsDataImportAdapter(response.data));
        }).catch(() => {
            appStore.showAppSnackBar('error', "DRS loading error")
        }).finally(() => appStore.removeLoading())
        loadScenarios();

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.drsId])

    function onSave() {
        removeEmptyLogicStatement(rs);
        if (!isRsValid(rs, appStore))
            return
        appStore.addLoading()
        updateDrsItem(makePayloadFromRs(rs)).then(() => {
            appStore.showAppSnackBar("success", "The update was successful")
            props.onClose()
        }).catch(reason => {
            if (reason.response.status === 409)
                appStore.showAppSnackBar("error", reason.response.data.message)
            else
                appStore.showAppSnackBar("error", "The update failed")
        }).finally(() => appStore.removeLoading())
    }

    function onClear() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        appStore.addLoading()
        retrieveDrsItem(props.drsId).then((response) => {
            setRs(rsDataImportAdapter(response.data));
            setIsChanged(false)
        }).catch(() => {
            appStore.showAppSnackBar('error', "DRS loading error")
        }).finally(() => appStore.removeLoading())
    }

    function onBack() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onClose()
    }

    function onNewCommentChange(event: React.ChangeEvent<HTMLInputElement>) {
        setNewComment(event.target.value);
    }

    function onAddComment() {
        const newCommentValidationResult = isLongStringValueValid(newComment);
        if (newCommentValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Comment has validation error with message: " + newCommentValidationResult)
            return
        }

        rs.requirement_comments.push({
            number: rs.requirement_comments.length + 1,
            comment: newComment,
            created_by: userStore.user.loginId,
            createdAt: moment().format()
        })
        setNewComment('')
        setRs({...rs})
        setIsChanged(true)
    }

    function onDeleteComment(comment: RsComment) {
        rs.requirement_comments.splice(rs.requirement_comments.indexOf(comment), 1)
        for (let i = 0; i < rs.requirement_comments.length; i++) {
            rs.requirement_comments[i].number = i + 1
        }
        setRs({...rs})
        setIsChanged(true)
    }

    function onAddLinkButtonClick() {
        setAddLinkDialogOpen(true)
    }

    function onDeleteLink(link: Link) {
        rs.links.splice(rs.links.indexOf(link), 1)
        setRs({...rs})
    }

    function onAddLinkDialogClose() {
        setAddLinkDialogOpen(false)
    }

    function onAddLink(linkType: LinkTypeEnum, rsLink: IdNameListValues) {
        rs.links.push({
            link_id: null as any,
            main_rs_id: rs.rs_id,
            link_type: linkType,
            linked_rs_id: rsLink.id,
            name: rsLink.name
        } as Link)
        setIsChanged(true)
    }

    function onDelete() {
        if (scenarios.length > 0) {
            appStore.showAppSnackBar('error', "DRS has associated scenarios and cannot be deleted")
            return
        }

        let confirmAction = window.confirm('Are you sure to delete this DRS?');
        if (!confirmAction)
            return

        appStore.addLoading()
        deleteDrsItem(props.drsId).then(() => {
            appStore.showAppSnackBar('success', "DRS has been deleted")
            props.onClose()
        }).catch(() => {
            appStore.showAppSnackBar('error', "DRS deletion error")
        }).finally(() => appStore.removeLoading())
    }

    function onAllocateScenariosDialogClose() {
        setAllocateScenariosDialogOpen(false)
        loadScenarios()
    }

    function onReallocateScenarios() {
        setAllocateScenariosDialogOpen(true)
    }

    function setRsValue(rs: DrsItem) {
        setRs(rs)
        setIsChanged(true)
    }

    function onModelReviewDialogClose() {
        setModelReviewDialogOpen(false)
    }

    function onReviewScenario() {
        setModelReviewDialogOpen(true)
    }

    function onLink(rs_id: string) {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onClose()
        props.onLink(rs_id)
    }

    function onScenarioEditDialogClose() {
        setScenarioEditDialogOpen(false)
        loadScenarios()
    }

    function onCreateScenario() {
        setIsCreateScenario(true)
        setScenarioEditDialogOpen(true)
    }

    function onUpdateScenario() {
        setIsCreateScenario(false)
        setScenarioEditDialogOpen(true)
    }

    const currentScenario = selectedScenario ? scenarios.find(e => e.scenario_id === selectedScenario.id) : undefined;
    return (
        <>
            <Dialog open={props.isDialogOpen} maxWidth={'xl'} onClose={props.onClose}>
                <DialogTitle>{"Data Requirement Specification (DRS) Item Update"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                        <Grid container item xs={8}
                              alignContent={"flex-start"}>
                            <RsProperties record={rs}
                                          onUpdate={setRsValue}/>
                            <RsCreatedUpdated record={rs}/>
                            <DrsScenarioWidget
                                onCreateScenario={onCreateScenario}
                                onUpdateScenario={onUpdateScenario}
                                onReviewScenario={onReviewScenario}
                                onReallocateScenarios={onReallocateScenarios}
                                scenarios={scenarios.map((item) => {
                                    return {
                                        id: item.scenario_id,
                                        name: item.scenario_name + ', ' + item.flow_name + ', ' + (item.release ? "Release" : "Testing") + ', Version: ' + item.version + ', Order:' + item.order,
                                    } as IdNameListValues
                                })}
                                selectedScenario={selectedScenario}
                                setSelectedScenario={setSelectedScenario}
                                isReallocateEnabled={props.rsList.length > 1 && scenarios.length > 0}
                            />
                            <Grid container alignContent={"flex-start"}>
                                <Grid item xs
                                      alignContent={"flex-start"}>
                                    <TextField
                                        margin="dense"
                                        id="item-comment"
                                        label="New comment"
                                        type="text"
                                        fullWidth
                                        inputProps={{maxLength: 500}}
                                        variant="standard"
                                        name='new-comment'
                                        value={newComment}
                                        onChange={onNewCommentChange}
                                    />
                                </Grid>
                                <Grid container item xs="auto">
                                    <Button onClick={onAddComment}
                                            disabled={newComment === ''}>Add</Button>
                                </Grid>
                            </Grid>
                            <CommentsList comments={rs.requirement_comments} onDelete={onDeleteComment}/>
                        </Grid>
                        <Grid item xs={4}>
                            <RsRightSide record={rs}
                                         onUpdate={setRs}
                                         onLink={onLink}
                                         isAddLinkPossible={getRsListForLinks(rs, props.rsList).length > 0}
                                         onAddLinkButtonClick={onAddLinkButtonClick}
                                         onDeleteLink={onDeleteLink}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onBack}>Back</Button>
                    <Button onClick={onClear}>Clear</Button>
                    <Button onClick={onSave}>Save</Button>
                    <Button onClick={onDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
            {
                addLinkDialogOpen &&
                <AddLinkDialog isDialogOpen={addLinkDialogOpen}
                               isDrs={true}
                               rsList={getRsListForLinks(rs, props.rsList)}
                               onAddLink={onAddLink}
                               onClose={onAddLinkDialogClose}/>
            }
            {
                allocateScenariosDialogOpen &&
                <AllocateScenariosDialog isDialogOpen={allocateScenariosDialogOpen}
                                         rsList={props.rsList.filter((item: DrsItem) => (rs.rs_id !== item.rs_id)).map((item) => {
                                             return {
                                                 id: item.rs_id,
                                                 name: item.name,
                                             } as IdNameListValues
                                         })}
                                         scenarioList={scenarios.map((item) => {
                                             return {
                                                 id: item.scenario_id,
                                                 name: item.scenario_name + ', ' + item.flow_name + ', ' + (item.release ? "Release" : "Testing") + ', Version: ' + item.version + ', Order:' + item.order,
                                             } as IdNameListValues
                                         })}
                                         onLoadScenarios={loadScenarios}
                                         onFinish={onAllocateScenariosDialogClose}
                                         currentDrsName={rs.name}/>
            }
            {
                modelReviewDialogOpen &&
                <DrsScenarioReviewDialog isDialogOpen={modelReviewDialogOpen}
                                         onBack={onModelReviewDialogClose}
                                         currentDrsName={rs.name}
                                         currentDrsId={rs.rs_id}
                                         scenarioId={selectedScenario.id}
                />
            }
            {
                scenarioEditDialogOpen &&
                <ScenarioEditDialog isDialogOpen={scenarioEditDialogOpen}
                                    isCreate={isCreateScenario}
                                    scenarioId={selectedScenario === null ? undefined : selectedScenario.id}
                                    flowName={currentScenario ? currentScenario.flow_name : undefined}
                                    onBack={onScenarioEditDialogClose}
                                    drs={rs}
                />
            }
        </>
    )
})

export default DrsUpdateDialog;