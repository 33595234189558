import {isLongStringValueValid, isNameValidGeneral} from "../../utils/validationUtils";
import {ArsItem} from "../../domain/entity/structures/requirementManagement/ArsItem";
import {DrsItem} from "../../domain/entity/structures/requirementManagement/DrsItem";
import AppStore from "../../store/AppStore";
import {Project} from "../../domain/entity/structures/requirementManagement/Project";
import {ComplexityList} from "../../domain/entity/structures/requirementManagement/ComplexityEnum";
import {CriticalityList} from "../../domain/entity/structures/requirementManagement/CriticalityEnum";
import {getFirstLetter} from "../../utils/utils";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";

export function isRsValid(rs: ArsItem | DrsItem, appStore: AppStore) {
    const nameValidationResult = isNameValidGeneral(rs.name);
    if (nameValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Name has validation error with message: " + nameValidationResult)
        return false
    }
    if (rs.description !== undefined && rs.description !== null) {
        const descriptionValidationResult = isLongStringValueValid(rs.description, false, 500);
        if (descriptionValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Description has validation error with message: " + descriptionValidationResult)
            return false
        }
    }
    if (rs.logic_statements.length === 0) {
        appStore.showAppSnackBar("error",
            "Logic statements are absent")
        return false
    }
    for (let i = 0; i < rs.logic_statements.length; i++) {
        const logicStatementValidationResult = isLongStringValueValid(rs.logic_statements[i], true, 500);
        if (logicStatementValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Logic statement " + (i + 1) + " has validation error with message: " + logicStatementValidationResult)
            return false
        }
    }
    if (rs.criticality === '') {
        appStore.showAppSnackBar("error",
            "Criticality is empty")
        return false
    }
    const categoryValidationResult = isNameValidGeneral(rs.category);
    if (categoryValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Category has validation error with message: " + categoryValidationResult)
        return false
    }
    const subjectValidationResult = isLongStringValueValid(rs.subject, false);
    if (subjectValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Subject has validation error with message: " + subjectValidationResult)
        return false
    }
    return true
}

export function isProjectValid(project: Project, appStore: AppStore) {
    const nameValidationResult = isNameValidGeneral(project.name);
    if (nameValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Project's name has validation error with message: " + nameValidationResult)
        return false
    }
    if (project.description !== undefined) {
        const descriptionValidationResult = isLongStringValueValid(project.description, false, 500);
        if (descriptionValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Project's description has validation error with message: " + descriptionValidationResult)
            return false
        }
    }
    const businessUnitValidationResult = isNameValidGeneral(project.business_unit);
    if (businessUnitValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Project's business unit has validation error with message: " + businessUnitValidationResult)
        return false
    }
    const businessOwnerValidationResult = isNameValidGeneral(project.business_owner);
    if (businessOwnerValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Project's business owner has validation error with message: " + businessOwnerValidationResult)
        return false
    }
    return true
}

export function transformProjectData(project: Project) {
    return {
        ...project,
        description: project.description === null ? '' : project.description,
    }
}

export function makePayloadFromProject(project: Project) {
    return {...project, description: project.description === '' ? null : project.description}
}

export function rsDataImportAdapter(rs: any): DrsItem | ArsItem {
    const complexityRecord = ComplexityList.find(e => e.value.startsWith(rs.complexity as any));
    const criticalityRecord = CriticalityList.find(e => e.value.startsWith(rs.criticality as any));
    return {
        ...rs,
        criticality: criticalityRecord === undefined ? undefined : criticalityRecord.value,
        complexity: rs.complexity === null ? '' : (complexityRecord === undefined ? undefined : complexityRecord.value),
        description: rs.description === null ? '' : rs.description,
        subject: rs.subject === null ? '' : rs.subject,
        updatedAt: rs.update_time,
    }
}

export function makePayloadFromRs(rs: DrsItem | ArsItem, projectId: string = null as any) {
    return {
        ...rs,
        subject: rs.subject === '' ? null : rs.subject,
        complexity: rs.complexity === '' ? null : getFirstLetter(rs.complexity),
        description: rs.description === '' ? null : rs.description,
        criticality: rs.criticality === '' ? null : getFirstLetter(rs.criticality),
        project_id: projectId === null ? rs.project_id : projectId,
    }
}

export function getRsListForLinks(rs: DrsItem | ArsItem, rsList: ArsItem[] | DrsItem[]) {
    return rsList.filter((item: DrsItem) => (rs.links.find(e => e.linked_rs_id === item.rs_id) === undefined && item.rs_id !== rs.rs_id)).map((item) => {
        return {
            id: item.rs_id,
            name: item.name,
        } as IdNameListValues
    });
}

export function removeEmptyLogicStatement(rs) {
    if (rs.logic_statements.length > 0 && rs.logic_statements[0] === '')
        rs.logic_statements.splice(0, 1)
}