import React from 'react';
import ConditionSelector from "../../../domain/entity/structures/dataVisualisation/ConditionSelector";
import {TextField} from "@mui/material";
import SelectorInteger from "../../../domain/entity/structures/dataVisualisation/SelectorInteger";

interface SelectorIntegerWidgetProps {
    selector: ConditionSelector
    refresh: () => void
}

const SelectorIntegerWidget = ({refresh, selector}: SelectorIntegerWidgetProps) => {
    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        const name = target.name;
        selector.selector = {...selector.selector, [name]: target.value}
        refresh()
    }

    return (
        <>
            <TextField
                margin="dense"
                id={"integer-equal-selector-value-" + selector.dataFilter.parameterName}
                type="number"
                label="= (equal)"
                fullWidth
                variant="outlined"
                name="equal"
                value={(selector.selector as SelectorInteger).equal || ''}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id={"integer-less-than-selector-value-" + selector.dataFilter.parameterName}
                type="number"
                label="< (less than)"
                fullWidth
                variant="outlined"
                name="lessThan"
                value={(selector.selector as SelectorInteger).lessThan || ''}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id={"integer-greater-than-selector-value-" + selector.dataFilter.parameterName}
                type="number"
                label="> (greater than)"
                fullWidth
                variant="outlined"
                name="greaterThan"
                value={(selector.selector as SelectorInteger).greaterThan || ''}
                onChange={onFieldChange}
            />
        </>
    );
};

export default SelectorIntegerWidget;