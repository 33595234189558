import {$host, axiosConfigJson} from './index';
import {Expression} from "../domain/entity/structures/dataVisualisation/Expression";
import moment from "moment";
import {DataObject} from "../domain/entity/structures/dataVisualisation/DataObject";

export const allRealtimeDashboards = async () => {
    return await $host.get('ui/allrealtimedashboards')
}

export const allHistoricalDashboards = async () => {
    return await $host.get('ui/allhistoricaldashboards')
}

export const filteredFlow = async (expression: Expression) => {
    const payload = {
        expression: expression
    }
    return await $host.post('ui/filteredflow', payload, axiosConfigJson)
}

export const filteredDataObjects = async (flowId: string, startTime: string, endTime: string) => {
    const start = moment(startTime).unix();
    const end = moment(endTime).unix();
    return await $host.get('ui/filtereddataobjects?flow_id=' + flowId +
        '&start_time=' + start + '&end_time=' + end, axiosConfigJson)
}

export const retrieveObjectData = async (flowId: string, dataObjects: DataObject[]) => {
    const objectIds: string[] = dataObjects.map((item) => {
        return '&object_ids=' + item.id
    })
    return await $host.get('ui/retrieveobjectdata?flow_id=' + flowId +
        objectIds.join(""))
}

export const filteredProcessData = async (flowId: string, expression: Expression) => {
    const payload = {
        flow_id: flowId,
        expression: expression
    }
    return await $host.post('ui/filteredprocessdata', payload, axiosConfigJson)
}

export const filteredRawData = async (flowId: string, expression: Expression) => {
    const payload = {
        flow_id: flowId,
        expression: expression
    }
    return await $host.post('ui/filteredrawdata', payload, axiosConfigJson)
}

export const createUpdateHistoricalDashboard = async (payload: any) => {
    return await $host.post('ui/createupdatehistoricaldashboard', payload, axiosConfigJson)
}

export const deleteDashboard = async (id: string) => {
    return await $host.delete('ui/deletedashboard?id=' + id)
}

export const loadHistoricalDashboard = async (id: string) => {
    return await $host.get('ui/loadhistoricaldashboard?id=' + id, axiosConfigJson)
}

export const loadRealtimeDashboard = async (id: string) => {
    return await $host.get('ui/loadrealtimedashboard?id=' + id, axiosConfigJson)
}

export const createUpdateRealtimeDashboard = async (payload: any) => {
    return await $host.post('ui/createupdaterealtimedashboard', payload, axiosConfigJson)
}

export const loadDataToRealtimeDashboard = async (payload: any) => {
    return await $host.post('ui/loaddatatorealtimedashboard', payload, axiosConfigJson)
}

export const updateRealtimeDashboard = async (payload: any) => {
    return await $host.post('ui/updaterealtimedashboard', payload, axiosConfigJson)
}