import React, {useState} from 'react';
import {Button, Grid, InputLabel, TextField} from "@mui/material";
import {ScenarioFlow} from "../../../domain/entity/structures/dataProcessing/ScenarioFlow";
import FileUploader from "../../administrator/dataFlowManagement/FileUploader";
import ScriptViewDialog from "./ScriptViewDialog";
import moment from "moment";
import {DATE_FORMAT} from "../../../utils/consts";
import FileSaver from 'file-saver'
import {SupportingFileRecord} from "../../../domain/entity/structures/dataProcessing/SupportingFileRecord";
import {ScenarioStatusEnum} from "../../../domain/entity/structures/dataProcessing/ScenarioStatusEnum";

interface ScenarioModelScriptWidgetProps {
    record: ScenarioFlow
    onUpdate: (value: ScenarioFlow) => void
    onUpdateScript: (value: ScenarioFlow) => void
    isCreate: boolean
}

const ScenarioModelScriptWidget = (props: ScenarioModelScriptWidgetProps) => {
    const [scriptViewDialogOpen, setScriptViewDialogOpen] = useState<boolean>(false);

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        props.onUpdate({...props.record, [name]: target.value});
    }

    function processScriptFile(files: FileList) {
        const fileName: string = files[0].name.substring(0, files[0].name.lastIndexOf('.'))
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            let target = e.target;
            if (target !== null) {
                const text: string = target.result as string
                props.onUpdateScript({
                    ...props.record,
                    model_script: text,
                    model_name: fileName,
                    status: ScenarioStatusEnum.testing
                });
            }
        };
        reader.readAsText(files[0]);
    }

    function processSupportingFiles(fileList: FileList) {
        const files: File[] = []
        for (let i = 0; i < fileList.length; i++) {
            files.push(fileList[i])
        }
        props.onUpdate({
            ...props.record, supporting_files: files, supporting_file_records: files.map((item) => {
                return {
                    file_id: null as any,
                    file_name: item.name
                } as SupportingFileRecord
            }),
            status: ScenarioStatusEnum.testing
        });
    }

    function exportCurrentScript() {
        const blob = new Blob([props.record.model_script as any], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, props.record.model_name + '.py');
    }

    function clearSupportingFiles() {
        props.onUpdateScript({...props.record, supporting_file_records: [], supporting_files: []});
    }

    return (
        <>
            <InputLabel>Model name: {props.record.model_name}</InputLabel>
            <TextField
                margin="dense"
                id="item-model-description"
                label="Model description"
                type="text"
                fullWidth
                multiline
                rows={2}
                inputProps={{maxLength: 3000}}
                variant="standard"
                name='model_description'
                value={props.record.model_description}
                onChange={onFieldChange}
            />
            {
                !props.isCreate &&
                <>
                    <InputLabel>Updated by: {props.record.model_updated_by}</InputLabel>
                    <InputLabel>Updated
                        time: {props.record.model_update_time ? moment.unix(props.record.model_update_time).format(DATE_FORMAT) : ''}</InputLabel>
                    <InputLabel>Version: {props.record.model_version}</InputLabel>
                    <Button variant="text"
                            disabled={!props.record.model_name}
                            onClick={() => setScriptViewDialogOpen(true)}
                    >
                        View current script
                    </Button>
                    <Button variant="text"
                            disabled={!props.record.model_name}
                            onClick={exportCurrentScript}
                    >
                        Export current script
                    </Button>
                </>
            }
            <InputLabel>{props.record.model_name === '' ? '' : (props.record.model_name + '.py')}</InputLabel>
            <FileUploader processResult={processScriptFile}
                          keyPart={'upload-upload-model-script'}
                          multiple={false}
                          accept={".py"}>
                <Button variant="text" component={"span"}>
                    Upload model script*
                </Button>
            </FileUploader>
            {
                props.record.supporting_file_records.length > 0 &&
                <Grid container spacing={1} item xs={12} alignContent={"flex-start"} sx={{}}>
                    {
                        props.record.supporting_file_records.map((item) => (
                            <Grid item xs={12}
                                  key={"supporting-file-" + props.record.supporting_file_records.indexOf(item)}>
                                <InputLabel>{item.file_name}</InputLabel>
                            </Grid>
                        ))
                    }
                </Grid>
            }
            <FileUploader processResult={processSupportingFiles}
                          keyPart={'upload-supporting-files'}
                          multiple={true}
                          accept={"*"}>
                <Button variant="text" component={"span"}>
                    Upload supporting files
                </Button>
            </FileUploader>
            <Button variant="text"
                    disabled={props.record.supporting_file_records.length === 0}
                    onClick={clearSupportingFiles}
            >
                Clear supporting files
            </Button>
            {
                scriptViewDialogOpen &&
                <ScriptViewDialog isDialogOpen={scriptViewDialogOpen}
                                  onBack={() => setScriptViewDialogOpen(false)}
                                  script={props.record.model_script}
                />
            }
        </>
    );
};

export default ScenarioModelScriptWidget;