import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../../index";
import {ON_FINISH_MESSAGE, REQUIREMENT_MANAGEMENT_ROUTE, UPDATE_PROJECT_ROUTE} from "../../utils/consts";
import PageTitle from "../../components/shared/PageTitle";
import {Button, Grid} from '@mui/material';
import {
    allProjects,
    createArsItem,
    createDrsItem,
    deleteArsItem,
    deleteDrsItem,
    deleteProject,
    getaProject,
    getArsOfProject,
    getDrsOfProject,
    updateProject
} from "../../http/requirementManagementAPI";
import {Project} from "../../domain/entity/structures/requirementManagement/Project";
import ObjectDropDown from "../../components/shared/ObjectDropDown";
import BodyText from "../../components/shared/BodyText";
import ProjectProperties from "../../components/requirementManagement/ProjectProperties";
import TabPanel, {a11yProps} from "../../components/administrator/filterConfiguration/TabPanel";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RsTable from "../../components/requirementManagement/RsTable";
import {ArsItem} from "../../domain/entity/structures/requirementManagement/ArsItem";
import {DrsItem} from "../../domain/entity/structures/requirementManagement/DrsItem";
import RsCreateDialog from "../../components/requirementManagement/RsCreateDialog";
import {getFirstLetter} from "../../utils/utils";
import DrsUpdateDialog from "../../components/requirementManagement/DrsUpdateDialog";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";
import {
    isProjectValid,
    makePayloadFromProject,
    makePayloadFromRs,
    rsDataImportAdapter,
    transformProjectData
} from "../../components/requirementManagement/ProjectUtils";
import ArsUpdateDialog from "../../components/requirementManagement/ArsUpdateDialog";

const ProjectUpdatePage = () => {
    let navigate = useNavigate();
    const {id} = useParams()
    const {appStore} = useContext(Context)
    const [projects, setProjects] = useState<IdNameListValues[]>([]);
    const [selectedProject, setSelectedProject] = useState<IdNameListValues>(null as any);
    const [project, setProject] = useState<Project>({
        project_id: null as any,
        business_owner: "",
        business_unit: "",
        createdAt: null as any,
        created_by: "",
        description: "",
        name: "",
        updatedAt: null as any,
        updated_by: ""
    });
    const [selectedTab, setSelectedTab] = useState(0);
    const [arsItems, setArsItems] = useState<ArsItem[]>([]);
    const [drsItems, setDrsItems] = useState<DrsItem[]>([]);
    const [createDrsDialogOpen, setCreateDrsDialogOpen] = useState<boolean>(false);
    const [createArsDialogOpen, setCreateArsDialogOpen] = useState<boolean>(false);
    const [updateDrsDialogOpen, setUpdateDrsDialogOpen] = useState<boolean>(false);
    const [updateArsDialogOpen, setUpdateArsDialogOpen] = useState<boolean>(false);
    const [selectedDrsId, setSelectedDrsId] = useState<string>(null as any);
    const [selectedArsId, setSelectedArsId] = useState<string>(null as any);
    const [isChanged, setIsChanged] = useState<boolean>(false);

    useEffect(() => {
        if (id === null) return
        appStore.addLoading()
        getaProject(id as string).then((response) => {
            setProject(transformProjectData(response.data as Project))
            loadDrsList()
            loadArsList()
        }).catch(reason => {
            appStore.showAppSnackBar('error', "Project loading error")
            navigate(REQUIREMENT_MANAGEMENT_ROUTE);
        }).finally(() => appStore.removeLoading())

        loadProjectsList()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    function onUpdateButtonClick() {
        if (!isProjectValid(project, appStore))
            return
        appStore.addLoading()
        updateProject(makePayloadFromProject(project)).then((response) => {
            appStore.showAppSnackBar('success', "The project has been updated successfully")
            setIsChanged(false)
            loadProjectsList()
        }).catch(reason => {
            if (reason.response.status === 409)
                appStore.showAppSnackBar("error", reason.response.data.message)
            else
                appStore.showAppSnackBar('error', "The project has not been updated")
        }).finally(() => appStore.removeLoading())
    }

    function onDiscardChangesButtonClick() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to cancel the changes?");
            if (!confirmAction)
                return
        }
        appStore.addLoading()
        getaProject(id as string).then((response) => {
            setProject(transformProjectData(response.data as Project))
        }).catch(reason => {
            appStore.showAppSnackBar('error', "Project loading error")
            navigate(REQUIREMENT_MANAGEMENT_ROUTE);
        }).finally(() => appStore.removeLoading())
    }

    function onDeleteButtonClick() {
        let confirmAction = window.confirm("Are you sure to delete this project?");
        if (!confirmAction)
            return
        appStore.addLoading()
        deleteProject(project.project_id).then((response) => {
            appStore.showAppSnackBar('success', "The project has been deleted successfully")
            navigate(REQUIREMENT_MANAGEMENT_ROUTE);
        }).catch(reason => {
            appStore.showAppSnackBar('error', "The project has not been deleted")
        }).finally(() => appStore.removeLoading())
    }

    const title = "Manage Project - Update";

    function onSelected(item) {
        navigate(UPDATE_PROJECT_ROUTE + "/" + item.id);
    }

    function onCreateDrsButtonClick() {
        setCreateDrsDialogOpen(true)
    }

    function onCreateArsButtonClick() {
        setCreateArsDialogOpen(true)
    }

    function onFinishButtonClick() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        navigate(REQUIREMENT_MANAGEMENT_ROUTE)
    }

    function onCreateDrsDialogClose() {
        setCreateDrsDialogOpen(false)
    }

    function onCreateArsDialogClose() {
        setCreateArsDialogOpen(false)
    }

    function loadProjectsList() {
        if (id === null) return
        appStore.addLoading()
        allProjects().then((response) => {
            const projects: IdNameListValues[] = response.data.map((item) => {
                return {
                    id: item.project_id,
                    name: item.name
                }
            });
            if (projects.length === 0) {
                appStore.showAppSnackBar("info", "No project found")
            } else {
                setSelectedProject(projects.find(e => e.id === id) as any)
                setProjects(projects)
            }
        }).finally(() => appStore.removeLoading())
    }

    function loadDrsList() {
        if (id === null) return
        appStore.addLoading()
        getDrsOfProject(id as any).then((response) => {
            const rss: any[] = response.data;
            setDrsItems(rss.map((item) => {
                return {
                    ...rsDataImportAdapter(item),
                    updated_by: item.updatedBy,
                    updatedAt: item.updatedTime,
                }
            }))
        }).catch(reason => {
            appStore.showAppSnackBar("error", "DRS items loading error")
        }).finally(() => appStore.removeLoading())
    }

    function loadArsList() {
        if (id === null) return
        appStore.addLoading()
        getArsOfProject(id as any).then((response) => {
            const rss: any[] = response.data;
            setArsItems(rss.map((item) => {
                return {
                    ...rsDataImportAdapter(item),
                    updated_by: item.updatedBy,
                    updatedAt: item.updatedTime,
                }
            }))
        }).catch(reason => {
            appStore.showAppSnackBar("error", "ARS items loading error")
        }).finally(() => appStore.removeLoading())
    }

    function onRemoveDrsItems(rss: DrsItem[]) {
        if (rss.length === 0) {
            loadDrsList()
        } else {
            appStore.addLoading()
            deleteDrsItem(rss[0].rs_id).then((response) => {
                onRemoveDrsItems(rss.splice(1))
            }).catch(reason => {
                appStore.showAppSnackBar("error", "DRS deletion error")
            }).finally(() => appStore.removeLoading())
        }
    }

    function onRemoveArsItems(rss: ArsItem[]) {
        if (rss.length === 0) {
            loadArsList()
        } else {
            appStore.addLoading()
            deleteArsItem(rss[0].rs_id).then((response) => {
                onRemoveArsItems(rss.splice(1))
            }).catch(reason => {
                appStore.showAppSnackBar("error", "ARS deletion error")
            }).finally(() => appStore.removeLoading())
        }
    }

    function onAddDrs(rs: DrsItem) {
        appStore.addLoading()
        createDrsItem(makePayloadFromRs(rs, project.project_id)).then((response) => {
            loadDrsList()
        }).catch(reason => {
            if (reason.response.status === 409)
                appStore.showAppSnackBar("error", reason.response.data.message)
            else
                appStore.showAppSnackBar("error", "DRS item creation error")
        }).finally(() => appStore.removeLoading())
    }

    function onAddArs(rs: ArsItem) {
        appStore.addLoading()
        createArsItem({
            ...rs,
            subject: rs.subject === '' ? null : rs.subject,
            complexity: rs.complexity === '' ? null : getFirstLetter(rs.complexity),
            description: rs.description === '' ? null : rs.description,
            criticality: rs.criticality === '' ? null : getFirstLetter(rs.criticality),
            project_id: project.project_id,
        }).then((response) => {
            loadArsList()
        }).catch(reason => {
            if (reason.response.status === 409)
                appStore.showAppSnackBar("error", reason.response.data.message)
            else
                appStore.showAppSnackBar("error", "ARS item creation error")
        }).finally(() => appStore.removeLoading())
    }

    function onOpenDrsItem(rs: DrsItem) {
        setSelectedDrsId(rs.rs_id)
        setUpdateDrsDialogOpen(true)
    }

    function onOpenArsItem(rs: ArsItem) {
        setSelectedArsId(rs.rs_id)
        setUpdateArsDialogOpen(true)
    }

    function onUpdateDrsDialogClose() {
        setUpdateDrsDialogOpen(false)
        loadDrsList()
    }

    function onUpdateArsDialogClose() {
        setUpdateArsDialogOpen(false)
        loadArsList()
    }

    function setProjectValue(value: Project) {
        setIsChanged(true)
        setProject(value)
    }

    function onArsLink(rs_id: string) {
        setSelectedArsId(rs_id)
        setUpdateArsDialogOpen(true)
    }

    function onDrsLink(rs_id: string) {
        setSelectedDrsId(rs_id)
        setUpdateDrsDialogOpen(true)
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Button variant="text"
                            onClick={onFinishButtonClick}>
                        Finish
                    </Button>
                    <Button variant="text"
                            onClick={onUpdateButtonClick}>
                        Update
                    </Button>
                    <Button variant="text"
                            onClick={onDeleteButtonClick}>
                        Delete
                    </Button>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={1}>
                    <Grid container item xs={6} justifyContent={"flex-end"} alignItems={"center"}>
                        <BodyText>
                            Select a project:
                        </BodyText>
                    </Grid>
                    <Grid item xs={6}>
                        <ObjectDropDown items={projects}
                                        selectedItem={selectedProject === null ? '' : selectedProject}
                                        onSelectionChange={onSelected}
                                        inputLabel={"Projects"}/>
                    </Grid>
                </Grid>
                <Grid container item xs={6}
                      alignContent={"flex-start"}>
                    <ProjectProperties
                        project={project}
                        setProject={setProjectValue}
                    />
                    <Button variant="text"
                            disabled={!isChanged}
                            onClick={onDiscardChangesButtonClick}>
                        Discard changes
                    </Button>
                </Grid>
                <Grid container spacing={1} item xs={12}>
                    <Box sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth">
                                <Tab label="Data Requirement Specification (DRS)" {...a11yProps(0)} />
                                <Tab label="AI Requirement Specification (ARS)" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={selectedTab} index={0}>
                            <Button variant="text"
                                    onClick={onCreateDrsButtonClick}>
                                Create a new DRS Item
                            </Button>
                            <RsTable
                                onRemove={onRemoveDrsItems}
                                onOpen={onOpenDrsItem}
                                data={drsItems}/>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            <Button variant="text"
                                    onClick={onCreateArsButtonClick}>
                                Create a new ARS Item
                            </Button>
                            <RsTable
                                onOpen={onOpenArsItem}
                                onRemove={onRemoveArsItems}
                                data={arsItems}/>
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
            {
                createDrsDialogOpen &&
                <RsCreateDialog isDialogOpen={createDrsDialogOpen}
                                onClose={onCreateDrsDialogClose}
                                onRsListReload={loadDrsList}
                                onAdd={onAddDrs}
                                rsList={drsItems}
                                isDrs={true} projectId={project.project_id}
                />
            }
            {
                createArsDialogOpen &&
                <RsCreateDialog isDialogOpen={createArsDialogOpen}
                                onRsListReload={loadArsList}
                                onAdd={onAddArs}
                                rsList={arsItems}
                                onClose={onCreateArsDialogClose}
                                isDrs={false} projectId={project.project_id}
                />
            }
            {
                updateDrsDialogOpen &&
                <DrsUpdateDialog isDialogOpen={updateDrsDialogOpen}
                                 onRsListReload={loadDrsList}
                                 rsList={drsItems}
                                 onClose={onUpdateDrsDialogClose}
                                 onLink={onDrsLink}
                                 drsId={selectedDrsId} projectId={project.project_id}/>
            }
            {
                updateArsDialogOpen &&
                <ArsUpdateDialog isDialogOpen={updateArsDialogOpen}
                                 onRsListReload={loadArsList}
                                 rsList={arsItems}
                                 onClose={onUpdateArsDialogClose}
                                 onLink={onArsLink}
                                 arsId={selectedArsId} projectId={project.project_id}/>
            }
        </>
    )
}

export default ProjectUpdatePage;