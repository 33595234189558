import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import BodyText from "../shared/BodyText";
import {Context} from "../../index";
import {Scenario} from "../../domain/entity/structures/dataProcessing/Scenario";
import {ModelReview} from "../../domain/entity/structures/dataProcessing/ModelReview";
import {ON_FINISH_MESSAGE} from "../../utils/consts";
import moment from "moment";
import EnumSelectForm from "./EnumSelectForm";
import {QualityLevelList} from "../../domain/entity/structures/requirementManagement/QualityLevelEnum";
import {getModelReviews, saveModelReviews} from "../../http/requirementManagementAPI";
import ModelReviewTable from "./ModelReviewTable";
import {getFormattedDate, removeItem} from "../../utils/utils";
import SectionTitleLeft from "../shared/SectionTitleLeft";
import {getScenarioForReview} from "../../http/dataProcessingAPI";
import {isLongStringValueValid} from "../../utils/validationUtils";

interface DrsScenarioReviewDialogProps {
    isDialogOpen: boolean
    currentDrsId: string
    currentDrsName: string
    scenarioId: string
    onBack: () => void
}

const DrsScenarioReviewDialog = (props: DrsScenarioReviewDialogProps) => {
    const {appStore} = useContext(Context)
    const {userStore} = useContext(Context)
    const [modelReviews, setModelReviews] = useState<ModelReview[]>([]);
    const [scenario, setScenario] = useState<Scenario>({
        scenario_id: "",
        scenario_name: "",
        flow_id: "",
        flow_name: "",
        model_name: "",
        release: false,
        version: 0,
        order: 0,
        updated_by: "",
        update_time: 0
    });
    const [qualityLevel, setQualityLevel] = useState<string>('');
    const [comment, setComment] = useState<string>('');
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isListChanged, setIsListChanged] = useState<boolean>(false);
    const [removedIds, setRemovedIds] = useState<String[]>([]);

    function loadModelReviews() {
        appStore.addLoading()
        getModelReviews(props.currentDrsId, props.scenarioId).then((response) => {
            setModelReviews(response.data as ModelReview[])
        }).catch(reason => {
            appStore.showAppSnackBar('error', "Model reviews loading error")
        }).finally(() => appStore.removeLoading())
    }

    useEffect(() => {

        // setScenario({
        //     scenario_id: "126b0bf4-55b3-11ec-80db-000d3ad124ag",
        //     scenario_name: "Scenario 2",
        //     flow_id: "526b0bf4-55b3-11ec-80db-000d3ad124af",
        //     flow_name: "Flow_XX",
        //     model_name: "Model name",
        //     description: "Description Description Description Description Description Description ",
        //     release: false,
        //     version: 2,
        //     order: 2,
        //     updated_by: "User 1",
        //     update_time: ""
        // });
        appStore.addLoading()
        getScenarioForReview(props.scenarioId).then((response) => {
            setScenario(response.data as Scenario)
        }).catch(reason => {
            appStore.showAppSnackBar('error', "Scenario loading error")
        }).finally(() => appStore.removeLoading())
        loadModelReviews();

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onSave() {
        appStore.addLoading()
        saveModelReviews({
            newRecords: modelReviews.filter(e => e.review_id === undefined),
            removed_ids: removedIds,
            drs_id: props.currentDrsId,
            scenario_id: props.scenarioId,
        }).then((response) => {
            appStore.showAppSnackBar("success", "Model reviews have been saved")
            setIsListChanged(false)
            loadModelReviews()
        }).catch(reason => {
            appStore.showAppSnackBar("error", "Model reviews save error")
        }).finally(() => appStore.removeLoading())
    }

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        setComment(target.value);
        setIsChanged(true)
    }

    function onRemoveModelReview(modelReview: ModelReview) {
        if (modelReview.review_id !== '') {
            removedIds.push(modelReview.review_id as any)
            setRemovedIds(removedIds.slice())
        }
        setModelReviews(removeItem(modelReviews, modelReview))
        setIsListChanged(true)
    }

    function onClear() {
        setIsChanged(false)
        setComment('')
        setQualityLevel('')
    }

    function isValid() {
        const commentValidationResult = isLongStringValueValid(comment, false);
        if (commentValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Comment has validation error with message: " + commentValidationResult)
            return false
        }
        if (qualityLevel === '') {
            appStore.showAppSnackBar("error",
                "Quality level is not defined")
            return false
        }
        return true
    }

    function onAdd() {
        if (!isValid()) return

        // @ts-ignore
        const lastNumber = (modelReviews === undefined || modelReviews.length === 0) ? 0 : (modelReviews[modelReviews.length - 1].number + 1);
        modelReviews.push({
            comment: comment,
            model_id: props.scenarioId,
            number: lastNumber,
            quality_level: qualityLevel,
            requirement_id: props.currentDrsId,
            created_by: userStore.user.loginId,
            create_time: moment().unix()
        })
        onClear()
        setIsListChanged(true)
    }

    function setQualityLevelValue(value: string) {
        setQualityLevel(value)
        setIsChanged(true)
    }

    function onBackButtonClick() {
        if (isChanged || isListChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onBack()
    }

    return (
        <Dialog open={props.isDialogOpen} maxWidth={'xl'} onClose={props.onBack}>
            <DialogTitle>{"Model Review"}</DialogTitle>
            <DialogContent>
                <SectionTitleLeft>{props.currentDrsName}</SectionTitleLeft>
                <BodyText>Scenario name: {scenario.scenario_name}</BodyText>
                <BodyText>Flow name: {scenario.flow_name}</BodyText>
                <BodyText>Model name: {scenario.model_name}</BodyText>
                <BodyText>Status: {scenario.release ? "Release" : "Testing"} Version: {scenario.version} Order: {scenario.order}</BodyText>
                <BodyText>Updated by: {scenario.updated_by} Update
                    time: {getFormattedDate(scenario.update_time)}</BodyText>
                <BodyText>Description: {scenario.description}</BodyText>
                <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                    <Grid item xs={12}>
                        <ModelReviewTable data={modelReviews}
                                          onRemove={onRemoveModelReview}/>
                    </Grid>
                    <Grid item xs={"auto"}>
                        <EnumSelectForm value={qualityLevel}
                                        onChange={setQualityLevelValue}
                                        enumRecords={QualityLevelList}
                                        variant="standard"
                                        required
                                        label={"Quality levels"}/>
                    </Grid>
                    <Grid container item xs>
                        <Grid item xs>
                            <TextField
                                margin="dense"
                                id="item-comment"
                                label="Comment"
                                type="text"
                                fullWidth
                                multiline
                                required
                                rows={2}
                                variant="standard"
                                name='comment'
                                value={comment}
                                onChange={onFieldChange}
                            />
                        </Grid>
                        <Grid container alignItems={"center"} item xs={"auto"}>
                            <Button disabled={comment === '' || qualityLevel === ''}
                                    onClick={onAdd}>Add</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBackButtonClick}>Back</Button>
                <Button disabled={!isChanged}
                        onClick={onClear}>Clear</Button>
                <Button disabled={!isListChanged}
                        onClick={onSave}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default DrsScenarioReviewDialog;