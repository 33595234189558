import React from 'react';
import {InputLabel, TextField} from "@mui/material";
import moment from "moment";
import {DATE_FORMAT} from "../../../utils/consts";
import {AiModelFlow} from "../../../domain/entity/structures/aiLab/AiModelFlow";

interface Props {
    record: AiModelFlow
    onUpdate: (value: AiModelFlow) => void
    isCreate: boolean
}

const AiModelProperties = (props: Props) => {

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        props.onUpdate({...props.record, [name]: target.value});
    }

    return (
        <>
            <TextField
                autoFocus
                margin="dense"
                id="item-name"
                label="AI Model Name"
                type="text"
                // error={props.record.name === ''}
                fullWidth
                required
                variant="standard"
                inputProps={{maxLength: 200}}
                name='name'
                value={props.record.name}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id="item-description"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={2}
                inputProps={{maxLength: 3000}}
                variant="standard"
                name='description'
                value={props.record.description}
                onChange={onFieldChange}
            />
            {
                !props.isCreate &&
                <>
                    <InputLabel>Latest Version: {props.record.version}</InputLabel>
                    <InputLabel>Created by: {props.record.created_by}</InputLabel>
                    <InputLabel>Created
                        time: {props.record.create_time ? moment.unix(props.record.create_time).format(DATE_FORMAT) : ''}</InputLabel>
                    <InputLabel>Updated by: {props.record.updated_by}</InputLabel>
                    <InputLabel>Updated
                        time: {props.record.updated_time ? moment.unix(props.record.updated_time).format(DATE_FORMAT) : ''}</InputLabel>
                </>
            }
        </>
    );
};

export default AiModelProperties;