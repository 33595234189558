import React from 'react';
import {IconButton, ListItem, ListItemText} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import {Link} from "../../domain/entity/structures/requirementManagement/Link";

interface LinksListItemProps {
    link: Link
    onDelete: (link: Link) => void
    onLink: (rs_id: string) => void
}

const LinksListItem = (props: LinksListItemProps) => {
    const labelId = `link-list-item-label-${props.link.linked_rs_id}`;

    function onDelete() {
        props.onDelete(props.link)
    }

    function onLinkClick() {
        props.onLink(props.link.linked_rs_id)
    }

    return (
        <ListItem
            disablePadding
        >
            <ListItemText id={labelId}
                          sx={{cursor: "pointer"}}
                          onClick={onLinkClick}
                          primary={props.link.link_type + ': ' + props.link.name}/>
            <IconButton onClick={onDelete}>
                <ClearIcon/>
            </IconButton>
        </ListItem>
    );
};

export default LinksListItem;