import React from 'react';
import {DisplayColumn} from "../../../../domain/entity/structures/dataVisualisation/DisplayColumn";
import BodyText from "../../../shared/BodyText";
import {Alert, Button, Checkbox, FormControlLabel, Grid} from "@mui/material";

interface CreateLineChartWidgetProps {
    selectedColumns: DisplayColumn[]
    onAdd: (isOrdered: boolean) => void
}

const CreateLineChartWidget = (props: CreateLineChartWidgetProps) => {
    const [isOrdered, setIsOrdered] = React.useState(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsOrdered(event.target.checked);
    };

    return (
        <Grid container>
            {
                props.selectedColumns.length === 0 &&
                <Alert severity={'info'}>Select several columns from the columns list</Alert>
            }
            <Grid container item justifyContent={"space-around"}>
                <BodyText>X Axis</BodyText>
            </Grid>
            {
                props.selectedColumns.length > 0 &&
                <Grid item xs={12}>
                    <BodyText
                        key={"line-column-" + props.selectedColumns[0]}>{props.selectedColumns[0].column.columnName}</BodyText>
                    <FormControlLabel
                        disabled={props.selectedColumns[0].column.variableType !== "number" && props.selectedColumns[0].column.variableType !== "float"}
                        control={<Checkbox checked={isOrdered} onChange={handleChange}/>}
                        label="Ordered"/>
                </Grid>
            }
            <Grid container item justifyContent={"space-around"}>
                <BodyText>Y Axis</BodyText>
            </Grid>
            <Grid item xs={12}>
                {
                    props.selectedColumns.map((item) => (
                        props.selectedColumns.indexOf(item) > 0 &&
                        <BodyText key={"line-column-" + item.id}>{item.column.columnName}</BodyText>
                    ))
                }
            </Grid>
            <Grid item xs={12}>
                <Button variant="text"
                        fullWidth
                        disabled={props.selectedColumns.length < 2}
                        onClick={() => props.onAdd(isOrdered)}>
                    Add
                </Button>
            </Grid>
        </Grid>
    );
};

export default CreateLineChartWidget;