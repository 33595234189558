import React from 'react';
import {InputLabel, styled, TextField, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {ScenarioFlow} from "../../../domain/entity/structures/dataProcessing/ScenarioFlow";
import moment from "moment";
import {DATE_FORMAT} from "../../../utils/consts";

interface ScenarioPropertiesProps {
    record: ScenarioFlow
    onUpdate: (value: ScenarioFlow) => void
    isCreate: boolean
}

const ScenarioProperties = (props: ScenarioPropertiesProps) => {

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        props.onUpdate({...props.record, [name]: target.value});
    }

    const NoMaxWidthTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}} children={props.children}/>
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
        },
    });

    return (
        <>
            <TextField
                autoFocus
                margin="dense"
                id="item-name"
                label="Name"
                type="text"
                // error={props.record.name === ''}
                fullWidth
                required
                variant="standard"
                inputProps={{maxLength: 200}}
                name='scenario_name'
                value={props.record.scenario_name}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id="item-description"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={2}
                inputProps={{maxLength: 3000}}
                variant="standard"
                name='scenario_description'
                value={props.record.scenario_description}
                onChange={onFieldChange}
            />
            <NoMaxWidthTooltip title={"In order to change the status please visit Manage scenarios page"}>
                <InputLabel id="drop-down-select-label">{"Status: " + props.record.status}</InputLabel>
            </NoMaxWidthTooltip>
            {
                !props.isCreate &&
                <>
                    <InputLabel>Order: {props.record.order}</InputLabel>
                    <InputLabel>Version: {props.record.version}</InputLabel>
                    <InputLabel>Created by: {props.record.created_by}</InputLabel>
                    <InputLabel>Created
                        time: {props.record.create_time ? moment.unix(props.record.create_time).format(DATE_FORMAT) : ''}</InputLabel>
                    <InputLabel>Updated by: {props.record.updated_by}</InputLabel>
                    <InputLabel>Updated
                        time: {props.record.update_time ? moment.unix(props.record.update_time).format(DATE_FORMAT) : ''}</InputLabel>
                </>
            }
        </>
    );
};

export default ScenarioProperties;