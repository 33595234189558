import React from 'react';
import {Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

interface Props<T> {
    objs: T[]
    selectedObjs: T[]
    refresh: () => void
}

export default function ObjectsIdNameMultiselectList<T>(props: Props<T>) {

    const handleToggle = (value: T) => () => {
        const currentIndex = props.selectedObjs.indexOf(value);
        if (currentIndex === -1) {
            props.selectedObjs.push(value);
        } else {
            props.selectedObjs.splice(currentIndex, 1);
        }
        props.refresh();
    };

    function onSelectAllButtonClick() {
        if (props.objs.length === props.selectedObjs.length) {
            props.selectedObjs.splice(0, props.selectedObjs.length)
            props.refresh()
        } else {
            props.selectedObjs.splice(0, props.selectedObjs.length)
            props.objs.forEach(e => props.selectedObjs.push(e))
            props.refresh()
        }
    }

    return (
        <>
            {
                props.objs.length > 0 &&
                <Button variant="outlined"
                        sx={{marginLeft: "1em"}}
                        onClick={onSelectAllButtonClick}>
                    {props.objs.length === props.selectedObjs.length ? 'Deselect all' : 'Select all'}
                </Button>
            }
            <List sx={{width: '100%'}}>
                {
                    props.objs.map((value) => {
                        const labelId = `flows-checkbox-list-label-${(value as any).id}`;

                        return (
                            <ListItem
                                key={'flows-checkbox-' + (value as any).id}
                                disablePadding
                            >
                                <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                    <ListItemIcon sx={{minWidth: "unset !important"}}>
                                        <Checkbox
                                            edge="start"
                                            checked={props.selectedObjs.indexOf(value) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{'aria-labelledby': labelId}}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={(value as any).name}/>
                                </ListItemButton>
                            </ListItem>
                        );
                    })
                }
            </List>
        </>
    );
};
