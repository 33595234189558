import {DataObject} from "../domain/entity/structures/dataVisualisation/DataObject";
import {ProductFlow} from "../domain/entity/structures/adminisrator/dataFlowManagement/ProductFlow";

export function dataObjectsInputAdapter(data) {
    return data.map((item) =>
        ({
            flowId: item.flow_id,
            id: item.object_id,
            name: item.object_name,
            creationTimestamp: item.creation_timestamp,
            latestUpdateTimestamp: item.latest_update_timestamp
        } as DataObject));
}

export function productFlowsInputAdapter(data) {
    return data.map((item) => {
        return {
            id: item.flow_id,
            name: item.flow_name,
            validation_flow_id: item.validation_flow_id
        } as ProductFlow
    });
}