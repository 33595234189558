import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {Context} from "../../index";
import {EXECUTE_MODELS_ROUTE} from "../../utils/consts";
import PageTitle from "../../components/shared/PageTitle";
import {Button, Grid} from '@mui/material';
import ObjectDropDown from "../../components/shared/ObjectDropDown";
import {flowDataObjects, productFlows} from "../../http/flowsAPI";
import {ScenarioFlow} from "../../domain/entity/structures/dataProcessing/ScenarioFlow";
import {getScenariosByFlow, processManual} from "../../http/dataProcessingAPI";
import ScenariosList from "../../components/models/dataProcessing/executeModels/ScenariosList";
import SectionTitleLeft from "../../components/shared/SectionTitleLeft";
import {getDrsDetails} from "../../http/requirementManagementAPI";
import ExecutionResults from "../../components/models/dataProcessing/executeModels/ExecutionResults";
import {ExecutionDataObject} from "../../domain/entity/structures/dataProcessing/ExecutionDataObject";
import {dataObjectsInputAdapter, productFlowsInputAdapter} from "../../utils/adapters";
import {ScenarioExecution} from "../../domain/entity/structures/dataProcessing/ScenarioExecution";
import useWebSocket from "react-use-websocket";
import {ProductFlow} from "../../domain/entity/structures/adminisrator/dataFlowManagement/ProductFlow";

const ExecuteModelsPage = () => {
    let navigate = useNavigate();
    const {id} = useParams()
    const {appStore} = useContext(Context)
    const [flows, setFlows] = useState<ProductFlow[]>([]);
    const [flow, setFlow] = useState<ProductFlow>({
        id: null as any,
        name: "",
        validation_flow_id: null as any,
    })
    const [scenarios, setScenarios] = useState<ScenarioFlow[]>([]);
    const [dataObjects, setDataObjects] = useState<ExecutionDataObject[]>([]);

    useEffect(() => {

        appStore.addLoading()
        productFlows().then((response) => {
            const responseFlows = productFlowsInputAdapter(response.data)
            setFlows(responseFlows);
            if (responseFlows.length === 0)
                appStore.showAppSnackBar("info", "No flow found")
            setFlows(responseFlows)
            if (id) {
                let selectedFlow = responseFlows.find(e => e.id === id);
                if (selectedFlow)
                    setFlow(selectedFlow)

                appStore.addLoading()
                getScenariosByFlow(id).then(async (response) => {
                    const responseScenarios: ScenarioFlow[] = response.data;
                    try {
                        for (const responseScenario of responseScenarios) {
                            response = await getDrsDetails(responseScenario.drs_id)
                            responseScenario.drs_name = response.data.name
                        }
                    } catch {
                        appStore.showAppSnackBar('error', "DRS info loading error")
                    }
                    setScenarios(responseScenarios.map((item) => {
                        return {
                            ...item, status: item.status === "release" ? "Release" : "Testing"
                        }
                    }))
                }).finally(() => appStore.removeLoading())
            }
        }).finally(() => appStore.removeLoading())

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const {
        lastJsonMessage,
    } = useWebSocket(process.env.REACT_APP_SOCKET_URL as any, {
        onOpen: () => console.log('opened'),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: () => true,
    });

    useEffect(() => {
            if (lastJsonMessage !== null && dataObjects.length > 0) {
                console.log(lastJsonMessage)
                setDataObjects(dataObjects.map((item) => {
                    if (item.dataObject.id !== (lastJsonMessage as any).object_id)
                        return item
                    return {
                        ...item,
                        scenarioExecutions: item.scenarioExecutions.map((se) => {
                            if (se.scenario_id !== (lastJsonMessage as any).scenario_id)
                                return se
                            return {
                                ...se,
                                status: (lastJsonMessage as any).scenario_status,
                                update_time: (lastJsonMessage as any).update_time,
                                execution_duration: (lastJsonMessage as any).execution_duration,
                                errors: JSON.stringify((lastJsonMessage as any).errors),
                            } as ScenarioExecution
                        })
                    } as ExecutionDataObject
                }))
            }
        },
// eslint-disable-next-line react-hooks/exhaustive-deps
        [lastJsonMessage]);

    const title = "Execute Data Processing Models";

    function onSelected(item) {
        navigate(EXECUTE_MODELS_ROUTE + "/" + item.id);
    }

    function onRunButtonClick() {
        appStore.addLoading()

        flowDataObjects(flow.id).then((response) => {
            const responseDataObjects = dataObjectsInputAdapter(response.data.data_objects)
            const executionDataObjects = responseDataObjects.map((item) => {
                return {
                    dataObject: item,
                    scenarioExecutions: scenarios.map((item) => {
                        return {
                            scenario_id: item.scenario_id,
                            scenario_name: item.scenario_name,
                            status: "",
                            update_time: 0,
                            execution_duration: 0,
                            errors: "",
                        } as ScenarioExecution
                    })
                } as ExecutionDataObject
            })
            setDataObjects(executionDataObjects)

            const payload = {
                flow_id: flow.id,
                flow_name: flow.name
            }
            processManual(payload).then(() => {
                appStore.showAppSnackBar("success", "Scenario(s) executions has been started")
            }).catch(reason => {
                appStore.showAppSnackBar("error", "Error with message: " + reason.data)
            }).finally(() => appStore.removeLoading())

        }).finally(() => appStore.removeLoading())
    }

    function onRefresh() {
        setScenarios(scenarios.slice())
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <ObjectDropDown items={flows}
                                    selectedItem={flow}
                                    onSelectionChange={onSelected}
                                    inputLabel={"Flows"}/>
                </Grid>
                {
                    id &&
                    <>
                        <Grid item xs={12}>
                            <Button variant="text"
                                    disabled={scenarios.length === 0}
                                    onClick={onRunButtonClick}>
                                Run models
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <SectionTitleLeft>{flow.name}</SectionTitleLeft>
                        </Grid>
                        <Grid item xs={12}>
                            <ScenariosList scenarios={scenarios}
                                           redrawScenarios={onRefresh}/>
                        </Grid>
                        {dataObjects.length > 0 &&
                            <Grid item xs={12}>
                                <ExecutionResults dataObjects={dataObjects}/>
                            </Grid>
                        }
                    </>
                }
            </Grid>
        </>
    )
}

export default ExecuteModelsPage