import React, {useContext, useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Button} from "@mui/material";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import {deleteFlow, resultFlows} from "../../../../http/flowsAPI";
import {Flow} from "../../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import ObjectIdNameDropDown from "../../filterConfiguration/filtersToQueryData/ObjectIdNameDropDown";
import {useNavigate} from "react-router-dom";
import {HOME_ROUTE} from "../../../../utils/consts";
import {objectArrayToFlows} from "../../../../utils/utils";


const ManageResultFlows = observer(() => {
    let navigate = useNavigate();
    const {appStore} = useContext(Context)
    const [flows, setFlows] = useState<Flow[]>([]);
    const [selectedFlow, setSelectedFlow] = useState<Flow>(null as any);

    const COLUMN_1 = 3
    const COLUMN_2 = 5
    const COLUMN_3 = 4

    useEffect(() => {
        appStore.addLoading()
        resultFlows().then(response => {
            setFlows(objectArrayToFlows(response.data));
        }).finally(() => {
            appStore.removeLoading();
        })
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSelectionChange(selectedFlow: Flow) {
        setSelectedFlow(selectedFlow)
    }

    function onCancelButtonClick() {
        navigate(HOME_ROUTE);
    }

    function onDeleteButtonClick() {
        let confirmAction = window.confirm('Are you sure to delete this flow?');
        if (!confirmAction)
            return
        appStore.addLoading();
        deleteFlow(selectedFlow.id)
            .then(() => {
                setSelectedFlow(null as any)
                appStore.showAppSnackBar("success", "Flow has been deleted");
                appStore.addLoading()
                resultFlows().then(response => {
                    const flowList = objectArrayToFlows(response.data)
                    setFlows(flowList);
                }).finally(() => appStore.removeLoading())
            }).catch(() => {
            appStore.showAppSnackBar("error", "An error occurred while deleting the flow");
        }).finally(() => appStore.removeLoading())
    }

    return (
        <Grid container spacing={1}>
            <Grid container item xs={COLUMN_1 + COLUMN_2}/>
            <Grid item xs={COLUMN_3}>
                <ObjectIdNameDropDown objs={flows}
                                      selectedObj={selectedFlow}
                                      onSelectionChange={handleSelectionChange}
                                      label={"Flow"}
                />
            </Grid>
            <Grid container item xs={COLUMN_1 + COLUMN_2}/>
            {
                selectedFlow &&
                <Grid item xs={COLUMN_3}>
                    <Button variant="text"
                            onClick={onCancelButtonClick}>
                        Cancel
                    </Button>
                    <Button variant="text"
                            onClick={onDeleteButtonClick}>
                        Delete
                    </Button>
                </Grid>
            }
        </Grid>
    )
})

export default ManageResultFlows;