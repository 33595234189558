import {EnumRecord} from "../shared/EnumRecord";

export enum CriticalityEnum {
    critical = "4 – Critical",
    important = "3 – Important",
    moderate = "2 – Moderate",
    minor = "1 – Minor",
}

export const CriticalityList: EnumRecord[] = Object.entries(CriticalityEnum)
    .map(([key, value]) => ({key, value} as EnumRecord));
