import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {DrsItem} from "../../../domain/entity/structures/requirementManagement/DrsItem";
import {ON_FINISH_MESSAGE} from "../../../utils/consts";
import {getDrsDetails} from "../../../http/requirementManagementAPI";
import {
    createScenario,
    deleteaScenario,
    getModel,
    getRule,
    retrieveScenarioById,
    scriptTest,
    updateScenario
} from "../../../http/dataProcessingAPI";
import {rsDataImportAdapter} from "../../requirementManagement/ProjectUtils";
import {productFlows} from "../../../http/flowsAPI";
import {Flow} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import {ScenarioFlow} from "../../../domain/entity/structures/dataProcessing/ScenarioFlow";
import ObjectDropDown from "../../shared/ObjectDropDown";
import ScenarioProperties from "./ScenarioProperties";
import {isScenarioValid, makePayloadFromScenario, transformScenarioData} from "./ScenarioUtils";
import RsInfoDialog from "./RsInfoDialog";
import ScenarioModelScriptWidget from "./ScenarioModelScriptWidget";
import {ScriptTestResult} from "../../../domain/entity/structures/dataProcessing/ScriptTestResult";
import ScenarioRuleScriptWidget from "./ScenarioRuleScriptWidget";
import {objectArrayToFlows} from "../../../utils/utils";
import {ScenarioStatusEnum} from "../../../domain/entity/structures/dataProcessing/ScenarioStatusEnum";
import {CreateUpdateScenarioResult} from "../../../domain/entity/structures/dataProcessing/CreateUpdateScenarioResult";

interface ScenarioEditDialogProps {
    isDialogOpen: boolean
    isCreate: boolean
    drs: DrsItem
    onBack: () => void
    scenarioId?: string
    flowName?: string
}

const ScenarioEditDialog = observer((props: ScenarioEditDialogProps) => {
    const {appStore} = useContext(Context)
    const [flows, setFlows] = useState<Flow[]>([]);
    const [flow, setFlow] = useState<Flow>({
        id: null as any,
        name: "",
        is_validation: false,
        product_flow_id: "",
        attributes: [],
        columns: []
    })
    const [drs, setDrs] = useState<DrsItem>(props.drs);
    const [drsInfoDialogOpen, setDrsInfoDialogOpen] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isTested, setIsTested] = useState<boolean>(false);
    const [scenario, setScenario] = useState<ScenarioFlow>({
        drs_id: "",
        drs_name: "",
        flow_id: "",
        flow_name: "",
        scenario_name: "",
        scenario_description: "",
        order: 0,
        scenario_id: " ",
        status: ScenarioStatusEnum.testing,
        update_time: 0,
        updated_by: "",
        create_time: 0,
        created_by: "",
        version: 0,
        model_description: "",
        model_name: "",
        model_script: "",
        rule_id: null as any,
        rule_name: "",
        rule_description: "",
        rule_script: "",
        supporting_file_records: [],
        supporting_files: []
    });

    function loadScenario() {
        appStore.addLoading()
        retrieveScenarioById(props.scenarioId as any).then((response) => {
            let resultScenario = transformScenarioData(response.data);
            resultScenario.flow_name = props.flowName
            setScenario(resultScenario);
            if (props.drs === null) {
                appStore.addLoading()
                getDrsDetails(resultScenario.drs_id).then((response) => {
                    setDrs(rsDataImportAdapter(response.data));
                }).catch(() => {
                    appStore.showAppSnackBar('error', "DRS loading error")
                }).finally(() => appStore.removeLoading())
            }

            appStore.addLoading()
            getModel(resultScenario.scenario_id).then((response) => {
                resultScenario = {
                    ...resultScenario,
                    model_id: response.data.model_id,
                    model_name: response.data.model_name,
                    model_description: response.data.model_description ? response.data.model_description : "",
                    model_script: response.data.model_script,
                    model_version: response.data.model_version,
                    model_updated_by: response.data.updated_by,
                    model_update_time: response.data.updated_time,
                    supporting_file_records: response.data.supporting_file_records
                };

                appStore.addLoading()
                getRule(resultScenario.scenario_id).then((response) => {
                    if (response.data !== null) {
                        resultScenario = {
                            ...resultScenario,
                            rule_id: response.data.rule_id,
                            rule_name: response.data.rule_name,
                            rule_description: response.data.rule_description ? response.data.rule_description : '',
                            rule_script: response.data.rule_script,
                            rule_version: response.data.rule_version,
                            rule_updated_by: response.data.updated_by,
                            rule_update_time: response.data.updated_time,
                        }
                    } else {
                        resultScenario = {
                            ...resultScenario,
                            rule_id: null as any,
                            rule_name: '',
                            rule_description: '',
                            rule_script: '',
                        }
                    }
                    setScenario(resultScenario)
                }).catch(() => {
                    appStore.showAppSnackBar('error', "Rule loading error")
                }).finally(() => appStore.removeLoading())
            }).catch(() => {
                appStore.showAppSnackBar('error', "Model loading error")
            }).finally(() => appStore.removeLoading())
        }).catch(() => {
            appStore.showAppSnackBar('error', "Scenario loading error")
        }).finally(() => appStore.removeLoading())
        if (props.drs !== null) {
            setDrs(props.drs)
        }
        setIsTested(true)
    }

    useEffect(() => {
        if (props.isCreate) {
            setDrs(props.drs)
            setScenario({...scenario, drs_id: props.drs.rs_id})

            appStore.addLoading()
            productFlows().then((response) => {
                const flowList = objectArrayToFlows(response.data)
                setFlows(flowList);
            }).finally(() => appStore.removeLoading())

        } else {
            loadScenario();
            setIsChanged(false)
            setIsTested(true)
        }

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isCreate])

    function onClear() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        if (props.isCreate) {
            setScenario({
                drs_id: "",
                drs_name: "",
                flow_id: "",
                flow_name: "",
                scenario_name: "",
                scenario_description: "",
                order: 0,
                scenario_id: "",
                status: ScenarioStatusEnum.testing,
                create_time: 0,
                created_by: "",
                update_time: 0,
                updated_by: "",
                version: 0,
                model_description: "",
                model_name: "",
                model_script: "",
                rule_id: null as any,
                rule_description: "",
                rule_name: "",
                rule_script: "",
                supporting_file_records: [],
                supporting_files: []
            });
            setFlow({
                id: null as any,
                name: "",
                is_validation: false,
                product_flow_id: "",
                attributes: [],
                columns: []
            })
            setIsTested(false)
        } else {
            loadScenario()
        }
        setIsChanged(false)
    }

    function onBack() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onBack()
    }

    function onDelete() {
        let confirmAction = window.confirm('Are you sure to delete this scenario?');
        if (!confirmAction)
            return
        appStore.addLoading()
        deleteaScenario(scenario.scenario_id).then(() => {
            appStore.showAppSnackBar('success', "Scenario has been deleted")
            props.onBack()
        }).catch(() => {
            appStore.showAppSnackBar('error', "Scenario deletion error")
        }).finally(() => appStore.removeLoading())
    }

    function onDrsInfoDialogClose() {
        setDrsInfoDialogOpen(false)
    }

    function onFlowSelected(value: Flow) {
        setFlow(value)
        setIsChanged(true)
        setScenario({...scenario, flow_id: value.id})
    }

    function onUpdateScenario(value: ScenarioFlow) {
        setScenario(value)
        setIsChanged(true)
    }

    function onCreate() {
        if (!isScenarioValid(scenario, appStore))
            return
        appStore.addLoading()
        createScenario(makePayloadFromScenario(scenario)).then((response) => {
            appStore.showAppSnackBar("success", "The scenario has been created")
            const result = response.data as CreateUpdateScenarioResult
            if (result.is_status_changed)
                appStore.showAppSnackBar("info", "Scenarios are in testing status")
            props.onBack()
        }).catch(() => {
            appStore.showAppSnackBar("error", "Create scenario failed")
        }).finally(() => appStore.removeLoading())
    }

    function onUpdate() {
        if (!isScenarioValid(scenario, appStore))
            return
        appStore.addLoading()
        updateScenario(makePayloadFromScenario(scenario)).then((response) => {
            appStore.showAppSnackBar("success", "The update was successful")
            const result = response.data as CreateUpdateScenarioResult
            if (result.is_status_changed)
                appStore.showAppSnackBar("info", "Scenarios are in testing status")
            props.onBack()
        }).catch(() => {
            appStore.showAppSnackBar("error", "The update failed")
        }).finally(() => appStore.removeLoading())
    }

    function onDrsInfo() {
        setDrsInfoDialogOpen(true)
    }

    function onTest() {
        if (!scenario.model_script) {
            appStore.showAppSnackBar("error",
                "Model script is absent")
            return
        }
        appStore.addLoading()
        scriptTest({
            model_script: scenario.model_script,
            flow_id: scenario.flow_id,
            supporting_file_records: scenario.supporting_file_records,
            supporting_files: scenario.supporting_files
        }).then((response) => {
            const result = response.data as ScriptTestResult
            setIsTested(result.success_syntax)
            if (result.success_syntax) {
                appStore.showAppSnackBar("success", "The script check was successful")
            } else {
                let message = ''
                if (result.syntax_failure_message != null) {
                    message = "Syntax error: " + result.syntax_failure_message + (result.syntax_failure_line ? (" at line " + result.syntax_failure_line) : "")
                }
                if (result.run_failure_message != null) {
                    message = (message === '' ? '' : "\n") + "Run error: " + result.run_failure_message
                }
                if (message === '') {
                    message = "The script check failed"
                }
                appStore.showAppSnackBar("error", message)
            }
        }).catch(() => {
            appStore.showAppSnackBar("error", "The script check failed")
        }).finally(() => appStore.removeLoading())
    }

    function onUpdateModelScript(value: ScenarioFlow) {
        setIsTested(false)
        onUpdateScenario(value)
    }

    return (
        <>
            <Dialog open={props.isDialogOpen} maxWidth={'xl'} onClose={props.onBack}>
                <DialogTitle>{props.isCreate ? "Create New Scenario" : "Update scenario"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                        <Grid container item xs={3}
                              alignContent={"flex-start"}>
                            <Grid item xs={12}
                                  alignContent={"flex-start"}>
                                <InputLabel onClick={onDrsInfo}
                                            sx={{cursor: "pointer"}}>DRS: {drs ? drs.name : ''}</InputLabel>
                            </Grid>
                            <Grid item xs={12}
                                  alignContent={"flex-start"}>
                                {
                                    props.isCreate &&
                                    <ObjectDropDown items={flows}
                                                    variant={"standard"}
                                                    required={true}
                                                    selectedItem={flow}
                                                    onSelectionChange={onFlowSelected}
                                                    inputLabel={"Flow"}/>
                                }
                                {
                                    !props.isCreate &&
                                    <InputLabel>Flow: {scenario.flow_name}</InputLabel>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <ScenarioProperties record={scenario}
                                                isCreate={props.isCreate}
                                                onUpdate={onUpdateScenario}/>
                        </Grid>
                        <Grid item xs={3}>
                            <ScenarioModelScriptWidget record={scenario}
                                                       isCreate={props.isCreate}
                                                       onUpdateScript={onUpdateModelScript}
                                                       onUpdate={onUpdateScenario}/>
                        </Grid>
                        <Grid item xs={3}>
                            <ScenarioRuleScriptWidget record={scenario}
                                                      isCreate={props.isCreate}
                                                      onUpdateScript={onUpdateModelScript}
                                                      onUpdate={onUpdateScenario}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onBack}>Back</Button>
                    <Button onClick={onClear} disabled={!isChanged}>Clear</Button>
                    {
                        !props.isCreate &&
                        <Button onClick={onDelete}>Delete</Button>
                    }
                    <Button onClick={onTest}>Test</Button>
                    {
                        !props.isCreate &&
                        <Button onClick={onUpdate} disabled={!isChanged || !isTested}>Update</Button>
                    }
                    {
                        props.isCreate &&
                        <Button onClick={onCreate} disabled={!isTested}>Create</Button>
                    }
                </DialogActions>
            </Dialog>
            {
                drsInfoDialogOpen &&
                <RsInfoDialog isDialogOpen={drsInfoDialogOpen}
                              onBack={onDrsInfoDialogClose}
                              rs={drs}
                />
            }
        </>
    )
})

export default ScenarioEditDialog;