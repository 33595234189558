import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {HEADER_FOR_DARK_BACKGROUND} from "../../../../utils/consts";

interface TableWidgetProps {
    data: any[]
    id: string
}

const TableWidget = (props: TableWidgetProps) => {
    return (
        <TableContainer sx={{height: 300}}>
            <Table stickyHeader size="small" aria-label="a dense table" id={props.id}>
                <TableHead>
                    <TableRow>
                        {
                            props.data[0].map((cell) => (
                                    <TableCell
                                        sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                        key={'table-header-cell-' + props.data[0].indexOf(cell)}
                                    >{cell}</TableCell>
                                )
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(props.data[1] as any[]).map((row) => (
                            <TableRow
                                key={'table-row-' + row[0]}
                                sx={{
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: "ghostwhite",
                                    }
                                }}
                            >
                                {
                                    (row[1] as any[]).map((cell) => (
                                        <TableCell sx={{border: 1}}
                                                   key={'table-cell-' + row[0] + '-' + (row[1] as any[]).indexOf(cell)}
                                        >
                                            {cell}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableWidget;