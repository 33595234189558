import React, {useState} from 'react';
import {Button, InputLabel, TextField} from "@mui/material";
import {ScenarioFlow} from "../../../domain/entity/structures/dataProcessing/ScenarioFlow";
import FileUploader from "../../administrator/dataFlowManagement/FileUploader";
import ScriptViewDialog from "./ScriptViewDialog";
import moment from 'moment';
import {DATE_FORMAT} from "../../../utils/consts";
import FileSaver from 'file-saver'
import {ScenarioStatusEnum} from "../../../domain/entity/structures/dataProcessing/ScenarioStatusEnum";

interface ScenarioRuleScriptWidgetProps {
    record: ScenarioFlow
    onUpdate: (value: ScenarioFlow) => void
    onUpdateScript: (value: ScenarioFlow) => void
    isCreate: boolean
}

const ScenarioRuleScriptWidget = (props: ScenarioRuleScriptWidgetProps) => {
    const [scriptViewDialogOpen, setScriptViewDialogOpen] = useState<boolean>(false);

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        props.onUpdate({...props.record, [name]: target.value});
    }

    function processScriptFile(files: FileList) {
        const fileName: string = files[0].name.substring(0, files[0].name.lastIndexOf('.'))
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            let target = e.target;
            if (target !== null) {
                const text: string = target.result as string
                props.onUpdateScript({
                    ...props.record,
                    rule_script: text,
                    rule_name: fileName,
                    status: ScenarioStatusEnum.testing
                });
            }
        };
        reader.readAsText(files[0]);
    }

    function exportCurrentScript() {
        const blob = new Blob([props.record.rule_script as any], {type: "text/plain;charset=utf-8"});
        FileSaver.saveAs(blob, props.record.rule_name + '.py');
    }

    function clearScript() {
        props.onUpdateScript({
            ...props.record,
            rule_script: '',
            rule_name: '',
            rule_updated_by: '',
            rule_version: undefined,
            rule_update_time: undefined
        });
    }

    return (
        <>
            <InputLabel>Rule name: {props.record.rule_name}</InputLabel>
            <TextField
                margin="dense"
                id="item-rule-description"
                label="Rule description"
                type="text"
                fullWidth
                multiline
                rows={2}
                inputProps={{maxLength: 3000}}
                variant="standard"
                name='rule_description'
                value={props.record.rule_description}
                onChange={onFieldChange}
            />
            {
                !props.isCreate &&
                <>
                    <InputLabel>Updated by: {props.record.rule_updated_by}</InputLabel>
                    <InputLabel>Updated
                        time: {props.record.rule_update_time ? moment.unix(props.record.rule_update_time).format(DATE_FORMAT) : ''}</InputLabel>
                    <InputLabel>Version: {props.record.rule_version}</InputLabel>
                    <Button variant="text"
                            disabled={props.record.rule_name === ''}
                            onClick={() => setScriptViewDialogOpen(true)}
                    >
                        View current script
                    </Button>
                    <Button variant="text"
                            disabled={props.record.rule_name === ''}
                            onClick={exportCurrentScript}
                    >
                        Export current script
                    </Button>
                </>
            }
            <InputLabel>{props.record.rule_name === '' ? '' : (props.record.rule_name + '.py')}</InputLabel>
            <FileUploader processResult={processScriptFile}
                          keyPart={'upload-upload-rule-script'}
                          multiple={false}
                          accept={".py"}>
                <Button variant="text" component={"span"}>
                    Upload rule script
                </Button>
            </FileUploader>
            <Button variant="text"
                    disabled={props.record.rule_name === ''}
                    onClick={clearScript}
            >
                Clear rule script
            </Button>
            {
                scriptViewDialogOpen &&
                <ScriptViewDialog isDialogOpen={scriptViewDialogOpen}
                                  onBack={() => setScriptViewDialogOpen(false)}
                                  script={props.record.rule_script ? props.record.rule_script : ''}
                />
            }
        </>
    );
};

export default ScenarioRuleScriptWidget;