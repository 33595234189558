import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import AppRouter from './components/shared/AppRouter';
import NavBar from "./components/shared/NavBar";
import AppSnackbar from "./components/shared/AppSnackbar";
import AppSpinner from "./components/shared/AppSpinner";
import {Context} from "./index";
import {check} from "./http/userAPI";
import jwtDecode from "jwt-decode";
import {ActiveUser} from "./domain/entity/structures/adminisrator/userManagement/ActiveUser";
import AppFooter from "./components/shared/AppFooter";
import {Box, createTheme, ThemeProvider} from "@mui/material";
import {DARK_BACKGROUND, TEXT_DARK_BACKGROUND, TEXT_FOR_LIGHT_BACKGROUND} from "./utils/consts";
import {observer} from "mobx-react-lite";

const App = observer(() => {
    const {userStore, appStore} = useContext(Context)
    const [isUserChecked, setIsUserChecked] = useState(false);

    useEffect(() => {
        appStore.addLoading()
        check().then((response) => {
            let token = response.data.token
            const tokenInfo = jwtDecode(token);
            userStore.setIsAuth(true)
            userStore.setUser(tokenInfo as ActiveUser)
        }).catch(reason => {
            userStore.setIsAuth(false)
            userStore.setUser({} as ActiveUser)
        }).finally(() => {
            appStore.removeLoading();
            setIsUserChecked(true)
        })
    })
    if (!isUserChecked)
        return <AppSpinner/>

    const theme = createTheme({
        palette: {
            primary: {
                main: DARK_BACKGROUND,
                contrastText: TEXT_DARK_BACKGROUND
            },
            text: {
                primary: TEXT_FOR_LIGHT_BACKGROUND,
                secondary: TEXT_DARK_BACKGROUND
            }
        },
        components: {
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: TEXT_FOR_LIGHT_BACKGROUND
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        color: TEXT_FOR_LIGHT_BACKGROUND
                    }
                }
            }
        }
    });
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter
                basename={process.env.REACT_APP_BASE_NAME}>
                <NavBar/>
                <AppSnackbar/>
                <AppSpinner/>
                <Box width={"100%"} minHeight={"calc(100vh - 250px)"}>
                    <AppRouter/>
                </Box>
                <AppFooter/>
            </BrowserRouter>
        </ThemeProvider>
    )
})

export default App;