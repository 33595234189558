import React, {useContext} from 'react';
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const AppSpinner = observer(() => {
    const {appStore} = useContext(Context)
    return (
        <Box sx={{
            position: "absolute",
            top: '0px',
            right: '0px',
            left: '0px',
            bottom: '0px',
            zIndex: '2000'
        }}
             display={appStore.isLoading() ? 'flex' : 'none'}
             justifyContent={"center"}
             alignItems={"center"}
        >
            <CircularProgress/>
        </Box>
    );
});

export default AppSpinner;