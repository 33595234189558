import React from 'react';
import {Pie, PieChart, ResponsiveContainer} from "recharts";
import {getColor} from "../../../../utils/utils";

interface PieChartWidgetProps {
    data: any[]
    id: string
}

const PieChartWidget = (props: PieChartWidgetProps) => {
    return (
        <ResponsiveContainer height={300} id={props.id}>
            <PieChart width={730} height={250}>
                {
                    props.data.map((item) => (
                        <Pie data={item}
                             dataKey="value"
                             nameKey="name"
                             cx="50%"
                             cy="50%"
                             key={"pie-chart-" + props.data.indexOf(item)}
                             innerRadius={props.data.indexOf(item) === 0 ? 0 : 60 + (props.data.indexOf(item) - 1) * 30}
                             outerRadius={props.data.indexOf(item) === 0 ? 50 : 80 + (props.data.indexOf(item) - 1) * 30}
                             fill={getColor(props.data.indexOf(item))}
                             label/>
                    ))
                }
            </PieChart>
        </ResponsiveContainer>
    );
};

export default PieChartWidget;