import React from 'react';
import {Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {User} from "../../../domain/entity/structures/adminisrator/userManagement/User";
import {HEADER_FOR_DARK_BACKGROUND} from "../../../utils/consts";

interface UsersTableProps {
    data: User[]
    selectedItems: User[]
    onSelectionChange: (records: User[]) => void;
}

const UsersTable = ({data, selectedItems, onSelectionChange}: UsersTableProps) => {

    const handleClick = (event: React.MouseEvent<unknown>, item: User) => {
        const currentIndex = selectedItems.indexOf(item);

        if (currentIndex === -1) {
            selectedItems.push(item);
        } else {
            selectedItems.splice(currentIndex, 1);
        }

        let result: User[] = selectedItems.map((item) => {
            return item
        })
        onSelectionChange(result);
    };

    return (
        <TableContainer sx={{height: 300}}>
            <Table stickyHeader sx={{minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{
                            backgroundColor: "lightgray", border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}/>
                        <TableCell sx={{
                            backgroundColor: "lightgray", border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}>Login id</TableCell>
                        <TableCell sx={{
                            backgroundColor: "lightgray", border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}>First name</TableCell>
                        <TableCell sx={{
                            backgroundColor: "lightgray", border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}>Second name</TableCell>
                        <TableCell sx={{
                            backgroundColor: "lightgray", border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}>Company</TableCell>
                        <TableCell sx={{
                            backgroundColor: "lightgray", border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}>Job title</TableCell>
                        <TableCell sx={{
                            backgroundColor: "lightgray", border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}>Roles</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => {
                            const isItemSelected = selectedItems.indexOf(row) !== -1;
                            const labelId = `user-metadata-table-checkbox-${data.indexOf(row)}`;
                            return (
                                <TableRow
                                    key={data.indexOf(row) + '-' + row.loginId}
                                    sx={{
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: "ghostwhite",
                                        }
                                    }}
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                >
                                    <TableCell padding="checkbox" align="center" sx={{border: 1}}>
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row" sx={{border: 1}}>
                                        {row.loginId}
                                    </TableCell>
                                    <TableCell sx={{border: 1}}>{row.profile.firstName}</TableCell>
                                    <TableCell sx={{border: 1}}>{row.profile.surName}</TableCell>
                                    <TableCell sx={{border: 1}}>{row.profile.company}</TableCell>
                                    <TableCell sx={{border: 1}}>{row.profile.jobTitle}</TableCell>
                                    <TableCell sx={{border: 1}}>{row.profile.role.join(", ")}</TableCell>
                                </TableRow>
                            )
                        }
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UsersTable;