import React, {useState} from 'react';
import {DisplayColumn} from "../../../../domain/entity/structures/dataVisualisation/DisplayColumn";
import CreateDisplayElementAccordionItem from "./CreateDisplayElementAccordionItem";
import CreateTableWidget from "./CreateTableWidget";
import CreateLineChartWidget from "./CreateLineChartWidget";
import CreateBarChartWidget from "./CreateBarChartWidget";
import CreatePieChartWidget from "./CreatePieChartWidget";
import CreateRadarChartWidget from "./CreateRadarChartWidget";

interface CreateDisplayElementAccordionProps {
    selectedColumns: DisplayColumn[]
    onAddTable: () => void
    onAddLineChart: (isOrdered: boolean) => void
    onAddBarChart: () => void
    onAddPieChart: () => void
    onAddRadarChart: () => void
}

const CreateDisplayElementAccordion = (props: CreateDisplayElementAccordionProps) => {
    const [expandedAccordion, setExpandedAccordion] = useState<string | false>("Table");

    return (
        <>
            <CreateDisplayElementAccordionItem title={'Table'}
                                               expanded={expandedAccordion}
                                               onAccordionChange={setExpandedAccordion}>
                <CreateTableWidget onAdd={props.onAddTable}
                                   selectedColumns={props.selectedColumns}/>
            </CreateDisplayElementAccordionItem>
            <CreateDisplayElementAccordionItem title={'Line Chart'}
                                               expanded={expandedAccordion}
                                               onAccordionChange={setExpandedAccordion}>
                <CreateLineChartWidget onAdd={props.onAddLineChart}
                                       selectedColumns={props.selectedColumns}/>
            </CreateDisplayElementAccordionItem>
            <CreateDisplayElementAccordionItem title={'Bar Chart'}
                                               expanded={expandedAccordion}
                                               onAccordionChange={setExpandedAccordion}>
                <CreateBarChartWidget onAdd={props.onAddBarChart}
                                      selectedColumns={props.selectedColumns}/>
            </CreateDisplayElementAccordionItem>
            <CreateDisplayElementAccordionItem title={'Pie Chart'}
                                               expanded={expandedAccordion}
                                               onAccordionChange={setExpandedAccordion}>
                <CreatePieChartWidget onAdd={props.onAddPieChart}
                                      selectedColumns={props.selectedColumns}/>
            </CreateDisplayElementAccordionItem>
            <CreateDisplayElementAccordionItem title={'Radar Chart'}
                                               expanded={expandedAccordion}
                                               onAccordionChange={setExpandedAccordion}>
                <CreateRadarChartWidget onAdd={props.onAddRadarChart}
                                        selectedColumns={props.selectedColumns}/>
            </CreateDisplayElementAccordionItem>
        </>
    );
};

export default CreateDisplayElementAccordion;