import React, {useContext, useState} from 'react';
import Grid from "@mui/material/Grid";
import {Button} from "@mui/material";
import NewFlow from "../../../../domain/entity/structures/adminisrator/dataFlowManagement/NewFlow";
import CancelButton from "../../../shared/CancelButton";
import AddNewFlow, {COLUMN_1, COLUMN_2, COLUMN_3} from "./AddNewFlow";
import {createFlow} from "../../../../http/flowsAPI";
import {Context} from "../../../../index";
import {MANDATORY_FIELDS_ERROR} from "../../../../utils/consts";
import {isNameValid} from "../../../../utils/validationUtils";
import {AxiosError} from "axios";

const AddNewFlows = () => {
    const {appStore} = useContext(Context)

    const [flows, setFlows] = useState<NewFlow[]>([{
        name: "",
        dataObjectFileName: "",
        dataObjects: [],
        metadataFileName: "",
        metadata: [],
        isDataObjectValidationError: false,
        isNameValidationError: false,
        isMetadataValidationError: false
    }]);

    function refreshList() {
        setFlows(flows.slice());
    }

    function onAddMoreButtonClick() {
        flows.push({
            name: "",
            dataObjectFileName: "",
            dataObjects: [],
            metadataFileName: "",
            metadata: [],
            isDataObjectValidationError: false,
            isNameValidationError: false,
            isMetadataValidationError: false
        })
        setFlows(flows.slice())
    }

    function isFlowValid() {
        let result: boolean = true
        flows.forEach(item => {
            if (isNameValid(item.name) !== '') {
                appStore.showAppSnackBar('error', MANDATORY_FIELDS_ERROR);
                item.isNameValidationError = true
                result = false
            } else {
                item.isNameValidationError = false
            }

            if (item.dataObjects.length === 0) {
                appStore.showAppSnackBar('error', MANDATORY_FIELDS_ERROR);
                item.isDataObjectValidationError = true
                result = false
            } else {
                item.isDataObjectValidationError = false
            }

            for (let i = 0; i < item.dataObjects.length; i++) {
                if (!item.dataObjects[i].name.startsWith(item.name + '%%')) {
                    appStore.showAppSnackBar("error",
                        "File name (" + item.dataObjects[i].name + ") should start with flow's name and %% ")
                    result = false
                }
            }
        })
        if (result) setFlows(flows.slice())
        return result;
    }

    async function onSaveCurrentListButtonClick() {
        if (isFlowValid()) {
            for (let i = flows.length - 1; i >= 0; i--) {
                try {
                    appStore.addLoading()
                    await createFlow(flows[i])
                    appStore.showAppSnackBar('success', "Flow " + flows[i].name + " was successfully saved");
                    flows.splice(i, 1)
                } catch (err) {
                    if ((err as AxiosError).response?.status === 400) {
                        appStore.showAppSnackBar("error", ((err as AxiosError).response?.data as any).message)
                    } else
                        appStore.showAppSnackBar('error', "An error occurred while saving the flow " + flows[i].name + ". Please validate the files");
                } finally {
                    appStore.removeLoading()
                }
            }
            if (flows.length === 0)
                setFlows([{
                    name: "",
                    dataObjectFileName: "",
                    dataObjects: [],
                    metadataFileName: "",
                    metadata: [],
                    isDataObjectValidationError: false,
                    isNameValidationError: false,
                    isMetadataValidationError: false
                }])
            else
                setFlows(flows.slice())
        } else {
            refreshList()
        }
    }

    function removeFlow(flow: NewFlow) {
        const flowIndex = flows.indexOf(flow);
        flows.splice(flowIndex, 1)
        setFlows(flows.slice())

        if (flows.length === 0)
            setFlows([{
                name: "",
                dataObjectFileName: "",
                dataObjects: [],
                metadataFileName: "",
                metadata: [],
                isDataObjectValidationError: false,
                isNameValidationError: false,
                isMetadataValidationError: false
            }])
        else
            setFlows(flows.slice())
    }

    return (
        <Grid container spacing={4}>
            <Grid container item xs={12} md={6} spacing={1}>
                {flows.map((item) => (
                    <AddNewFlow key={'AddNewFlow-' + flows.indexOf(item)}
                                keyPart={'AddNewFlow-' + flows.indexOf(item)}
                                flow={item}
                                refreshList={refreshList}
                                removeFlow={removeFlow}/>
                ))
                }
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={COLUMN_1}>
                    </Grid>
                    <Grid item xs={COLUMN_2}>
                        <Button variant="contained"
                                onClick={onAddMoreButtonClick}>
                            Add more
                        </Button>
                    </Grid>
                    <Grid item xs={COLUMN_3}>
                    </Grid>

                </Grid>
            </Grid>
            <Grid container
                  item
                  xs={12}
                  md={6}
                  justifyContent={"flex-end"}
                  alignItems={"flex-end"}
                  spacing={1}>
                <Button variant="contained"
                        onClick={onSaveCurrentListButtonClick}>
                    Save current list
                </Button>
                <CancelButton/>
            </Grid>
        </Grid>
    );
};

export default AddNewFlows;