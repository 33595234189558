import React, {useContext, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField
} from "@mui/material";
import Helmet from "react-helmet";
import {resetPassword} from "../../../http/userAPI";
import {Context} from "../../../index";
import {useNavigate, useParams} from "react-router-dom";
import {LOGIN_ROUTE} from "../../../utils/consts";

const ResetPasswordPage = () => {
    let navigate = useNavigate();
    const {code} = useParams()
    const {appStore} = useContext(Context)
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    function isPasswordValid() {
        if (password === '') {
            appStore.showAppSnackBar('error', "Password can't be empty");
            return false
        }
        if (repeatPassword !== password) {
            appStore.showAppSnackBar('error', "Both passwords must be the same");
            return false
        }
        return true
    }

    function onResetPassword() {
        if (!isPasswordValid())
            return

        appStore.addLoading()
        resetPassword(code, password).then(() => {
            appStore.showAppSnackBar("success", "The password was changed successfully")
            navigate(LOGIN_ROUTE)
        }).catch(reason => {
            appStore.showAppSnackBar("error", reason.response.data.message)
        }).finally(() => appStore.removeLoading())
    }

    function onCancel() {
        navigate(LOGIN_ROUTE)
    }

    const title = "Forgot password";

    return (
        <>
            <Helmet title={title}/>
            <Box component={"form"} width={400} ml={"auto"} mr={"auto"} mt={3}>
                <Card>
                    <CardHeader title={title}/>
                    <CardContent>
                        <Stepper activeStep={2} orientation="vertical">
                            <Step key="step 1">
                                <StepLabel>
                                    Enter login id and email
                                </StepLabel>
                            </Step>
                            <Step key="step 2">
                                <StepLabel>
                                    Check email
                                </StepLabel>
                            </Step>
                            <Step key="step 3">
                                <StepLabel>
                                    Set new password
                                </StepLabel>
                                <StepContent>
                                    <TextField
                                        margin="dense"
                                        label="Password"
                                        type="password"
                                        // error={dashboard.name === ''}
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name='firstPassword'
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <TextField
                                        margin="dense"
                                        label="Repeat password"
                                        type="password"
                                        // error={dashboard.name === ''}
                                        fullWidth
                                        required
                                        variant="outlined"
                                        name='secondPassword'
                                        value={repeatPassword}
                                        onChange={e => setRepeatPassword(e.target.value)}
                                    />
                                    <Button variant={"text"} onClick={onResetPassword}>Reset password</Button>
                                    <Button variant={"text"} onClick={onCancel}>Cancel</Button>
                                </StepContent>
                            </Step>
                        </Stepper>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Box>
        </>
    )
};

export default ResetPasswordPage;