import React from 'react';
import Helmet from "react-helmet";
import PageTitle from "../../components/shared/PageTitle";
import HistoricalDataVisualisationDashboards from "../../components/shared/home/HistoricalDataVisualisationDashboards";

const DataVisualisationPage = () => {
    const title = "Visualisation Dashboard";
    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <HistoricalDataVisualisationDashboards/>
        </>
    )
        ;
};

export default DataVisualisationPage;