import React, {useContext} from 'react';
import Grid from "@mui/material/Grid";
import {Button, IconButton, OutlinedInput, TextField} from "@mui/material";
import NewFlow from "../../../../domain/entity/structures/adminisrator/dataFlowManagement/NewFlow";
import BodyText from "../../../shared/BodyText";
import FileUploader from "../FileUploader";
import UserMetaDataRecord
    from "../../../../domain/entity/structures/adminisrator/dataFlowManagement/UserMetaDataRecord";
import {csvToJson} from "../../../../utils/utils";
import UploadIcon from "@mui/icons-material/Upload";
import {isMetadataValid, isNameValid, removeCommaFromNumericValues} from "../../../../utils/validationUtils";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";

interface Props {
    flow: NewFlow
    keyPart: string
    refreshList: () => void
    removeFlow: (flow: NewFlow) => void
}

export const COLUMN_1 = 4
export const COLUMN_2 = 7
export const COLUMN_3 = 1

const AddNewFlow = observer((props: Props) => {
    const {appStore, userStore} = useContext(Context)

    function onClearButtonClick() {
        props.flow.name = ""
        props.flow.dataObjectFileName = ""
        props.flow.dataObjects = []
        props.flow.metadataFileName = ""
        props.flow.metadata = []
        props.flow.isDataObjectValidationError = false
        props.flow.isNameValidationError = false
        props.refreshList()
    }

    function onNameInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.flow.name = event.target.value
        props.refreshList()
    }

    function processMetadata(files: FileList) {
        const metadataFileName: string = files[0].name
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
            let target = e.target;
            if (target !== null) {
                let result: UserMetaDataRecord[]
                const text: string = target.result as string;
                if (text[0] === '[') {
                    result = JSON.parse(text)
                } else {
                    result = csvToJson(text)
                }
                removeCommaFromNumericValues(result);
                const metadataValidationResult = isMetadataValid(result);
                if (metadataValidationResult === '') {
                    const metadataFileNameValidationResult = isNameValid(metadataFileName);
                    if (metadataFileNameValidationResult === '') {
                        props.flow.metadataFileName = metadataFileName
                        props.flow.isMetadataValidationError = false
                        props.flow.metadata = result.map((item) => {
                            return {...item, user: userStore.user.loginId}
                        })
                    } else {
                        props.flow.isMetadataValidationError = true
                        appStore.showAppSnackBar("error",
                            "The file has not been added because of validation error with message: " + metadataFileNameValidationResult)
                    }
                    props.refreshList()
                } else {
                    appStore.showAppSnackBar("error",
                        "The file has not been added because of validation error with message: " + metadataValidationResult)
                }
            }
        };
        reader.readAsText(files[0]);
    }

    function processDataObject(files: FileList) {
        let filesArray: File[] = []
        for (let i = 0; i < files.length; i++) {
            filesArray.push(files[i])
        }
        props.flow.dataObjectFileName = filesArray.map(e => e.name).join(", ")
        props.flow.dataObjects = filesArray
        props.refreshList()
    }

    function onRemoveButtonClick() {
        props.removeFlow(props.flow)
    }

    return (
        <Grid container item xs={12} spacing={1}>
            <Grid container item xs={COLUMN_1} justifyContent={"flex-end"} alignItems={"center"}>
                <BodyText>Name *</BodyText>
            </Grid>
            <Grid item xs={COLUMN_2}>
                <TextField value={props.flow.name}
                           placeholder={'new flow name'}
                           variant={"outlined"}
                           fullWidth
                           id={props.keyPart + 'flowName'}
                           error={props.flow.isNameValidationError && props.flow.name === ''}
                           inputProps={{maxLength: 50}}
                           onChange={onNameInputChange}/>
            </Grid>
            <Grid item xs={COLUMN_3}>
            </Grid>

            <Grid container item xs={COLUMN_1} justifyContent={"flex-end"} alignItems={"center"}>
                <BodyText>Data object</BodyText>
            </Grid>
            <Grid item xs={COLUMN_2}>
                <OutlinedInput value={props.flow.dataObjectFileName}
                               placeholder={'uploaded data object'}
                               readOnly
                               required
                               fullWidth
                               id={props.keyPart + 'metadataFileName'}
                               error={props.flow.isDataObjectValidationError && props.flow.dataObjectFileName === ''}
                               onChange={onNameInputChange}/>
            </Grid>
            <Grid container item xs={COLUMN_3} justifyContent={"flex-start"} alignItems={"center"}>
                <FileUploader processResult={processDataObject}
                              keyPart={props.keyPart + 'dataObject'}
                              multiple={false}
                              accept={".csv"}>
                    <IconButton component={"span"}>
                        <UploadIcon/>
                    </IconButton>
                </FileUploader>
            </Grid>

            <Grid container item xs={COLUMN_1} justifyContent={"flex-end"} alignItems={"center"}>
                <BodyText>Upload metadata</BodyText>
            </Grid>
            <Grid item xs={COLUMN_2}>
                <OutlinedInput value={props.flow.metadataFileName}
                               placeholder={'uploaded metadata file name'}
                               readOnly
                               fullWidth
                               id={props.keyPart + 'metadataFileName'}
                               error={props.flow.isMetadataValidationError && props.flow.metadataFileName === ''}
                               onChange={onNameInputChange}/>
            </Grid>
            <Grid container item xs={COLUMN_3} justifyContent={"flex-start"} alignItems={"center"}>
                <FileUploader processResult={processMetadata} keyPart={props.keyPart + 'metadata'}
                              accept={".json,.csv"}>
                    <IconButton component={"span"}>
                        <UploadIcon/>
                    </IconButton>
                </FileUploader>
            </Grid>

            <Grid item xs={COLUMN_1} textAlign={"right"}>
            </Grid>
            <Grid item xs={COLUMN_2}>
                <Button variant="outlined"
                        onClick={onClearButtonClick}>
                    Clear
                </Button>
                <Button variant="outlined"
                        sx={{marginLeft: "1em"}}
                        onClick={onRemoveButtonClick}>
                    Remove
                </Button>
            </Grid>
            <Grid item xs={COLUMN_3}>
            </Grid>

        </Grid>
    )
})

export default AddNewFlow;