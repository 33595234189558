import React, {useContext, useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {ArsItem} from "../../../../domain/entity/structures/requirementManagement/ArsItem";
import {ON_FINISH_MESSAGE} from "../../../../utils/consts";
import {ScriptTestResult} from "../../../../domain/entity/structures/dataProcessing/ScriptTestResult";
import {FlowGroup} from "../../../../domain/entity/structures/aiLab/FlowGroup";
import {AiModelFlow} from "../../../../domain/entity/structures/aiLab/AiModelFlow";
import {aiModelInputAdapter, AiModelOutputAdapter, isAiModelValid} from "../AiModelUtils";
import {analyticsScriptTest, deleteAiModels, retrieveAiModel, updateAiModel} from "../../../../http/aiLabAPI";
import RsInfoDialog from "../../dataProcessing/RsInfoDialog";
import AiModelProperties from "../AiModelProperties";
import {getArsDetails} from "../../../../http/requirementManagementAPI";
import {rsDataImportAdapter} from "../../../requirementManagement/ProjectUtils";
import FlowsList from "../FlowsList";
import AiModelSupportingFilesWidget from "./AiModelSupportingFilesWidget";
import AiModelScriptWidget from "./AiModelScriptWidget";

interface Props {
    ars: ArsItem
    flowGroup: FlowGroup
    onBack: () => void
    aiModelId: string
}

const AiModelUpdateDialog = observer((props: Props) => {
    const {appStore} = useContext(Context)
    const [ars, setArs] = useState<ArsItem>(props.ars);
    const [rsInfoDialogOpen, setRsInfoDialogOpen] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isTested, setIsTested] = useState<boolean>(true);
    const [supportingFileDescription, setSupportingFileDescription] = useState<string>('');
    const [newScript, setNewScript] = useState<string>('');
    const [newScriptName, setNewScriptName] = useState<string>('');
    const [aiModel, setAiModel] = useState<AiModelFlow>({
        ars_id: "",
        ars_name: "",
        order: 0,
        updated_time: 0,
        updated_by: "",
        create_time: 0,
        created_by: "",
        version: 0,
        supporting_files: [],
        description: "",
        files: [],
        flow_group: {
            id: "",
            name: "",
            flows: [],
        } as FlowGroup,
        id: "",
        model_update_time: 0,
        model_updated_by: "",
        script_version: 0,
        name: "",
        script: "",
        script_id: "",
        script_name: "",
        type: ""
    });

    function loadAiModel() {
        appStore.addLoading()
        retrieveAiModel(props.aiModelId as any).then((response) => {
            let resultAiModel = aiModelInputAdapter(response.data);
            setAiModel(resultAiModel);
            if (props.ars === null) {
                appStore.addLoading()
                getArsDetails(resultAiModel.ars_id).then((response) => {
                    setArs(rsDataImportAdapter(response.data));
                }).catch(() => {
                    appStore.showAppSnackBar('error', "ARS loading error")
                }).finally(() => appStore.removeLoading())
            }
        }).catch(() => {
            appStore.showAppSnackBar('error', "AI model loading error")
        }).finally(() => appStore.removeLoading())
        if (props.ars !== null) {
            setArs(props.ars)
        }
        setIsTested(true)
    }

    useEffect(() => {
        loadAiModel();
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function onClear() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        loadAiModel()
        setIsChanged(false)
        setNewScript('')
        setNewScriptName('')
    }

    function onBack() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onBack()
    }

    function onDelete() {
        let confirmAction = window.confirm('Are you sure to delete this AI Model?');
        if (!confirmAction)
            return
        appStore.addLoading()
        deleteAiModels({ai_model_ids: [aiModel.id]}).then(() => {
            appStore.showAppSnackBar('success', "AI Model has been deleted")
            props.onBack()
        }).catch(() => {
            appStore.showAppSnackBar('error', "AI Model deletion error")
        }).finally(() => appStore.removeLoading())
    }

    function onArsInfoDialogClose() {
        setRsInfoDialogOpen(false)
    }

    function onUpdateAiModel(value: AiModelFlow) {
        setAiModel(value)
        setIsChanged(true)
    }

    function onUpdateAiModelScripts(value: AiModelFlow) {
        setIsTested(false)
        onUpdateAiModel(value)
    }

    function onUpdate() {
        const newAiModel = {
            ...aiModel,
            script: newScript === '' ? aiModel.script : newScript,
            script_name: newScriptName === '' ? aiModel.script_name : newScriptName
        } as AiModelFlow
        if (!isAiModelValid(newAiModel, appStore))
            return
        appStore.addLoading()
        updateAiModel(AiModelOutputAdapter(newAiModel)).then(() => {
            appStore.showAppSnackBar("success", "The update was successful")
            props.onBack()
        }).catch(() => {
            appStore.showAppSnackBar("error", "The update failed")
        }).finally(() => appStore.removeLoading())
    }

    function onArsInfo() {
        setRsInfoDialogOpen(true)
    }

    function onUpdateScript(script: string, name: string) {
        setIsTested(false)
        setIsChanged(true)
        setNewScript(script)
        setNewScriptName(name)
    }

    function onTest() {
        if (!aiModel.script) {
            appStore.showAppSnackBar("error",
                "Model script is absent")
            return
        }
        appStore.addLoading()
        analyticsScriptTest({
            script: newScript === '' ? aiModel.script : newScript,
            flow_ids: aiModel.flow_group.flows.map(item => item.id),
            files: aiModel.files
        }).then((response) => {
            const result = response.data as ScriptTestResult
            setIsTested(result.success_syntax)
            if (result.success_syntax && result.success_run) {
                appStore.showAppSnackBar("success", "The script check was successful")
            } else {
                let message = ''
                if (result.syntax_failure_message != null) {
                    message = "Syntax error: " + result.syntax_failure_message + (result.syntax_failure_line ? (" at line " + result.syntax_failure_line) : "")
                }
                if (result.run_failure_message != null) {
                    message = (message === '' ? '' : "\n") + "Run error: " + result.run_failure_message
                }
                if (message === '') {
                    message = "The script check failed"
                }
                appStore.showAppSnackBar("error", message)
            }
        }).catch(() => {
            appStore.showAppSnackBar("error", "The script check failed")
        }).finally(() => appStore.removeLoading())
    }

    function updateSupportingFileDescription(value: string) {
        setIsChanged(true)
        setSupportingFileDescription(value)
    }

    return (
        <>
            <Dialog open={true} maxWidth={'xl'} onClose={props.onBack}>
                <DialogTitle>Update AI Model</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                        <Grid container item xs={3}
                              alignContent={"flex-start"}>
                            <Grid item xs={12}>
                                <InputLabel onClick={onArsInfo}
                                            sx={{cursor: "pointer"}}>ARS: {ars ? ars.name : ''}</InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <FlowsList flows={aiModel.flow_group.flows} title={"Flow Group:"}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <AiModelProperties record={aiModel}
                                               isCreate={false}
                                               onUpdate={onUpdateAiModel}/>
                        </Grid>
                        <Grid item xs={3}>
                            <AiModelScriptWidget record={aiModel}
                                                 newScript={newScript}
                                                 newScriptName={newScriptName}
                                                 onUpdateScript={onUpdateScript}/>
                        </Grid>
                        <Grid item xs={3}>
                            <AiModelSupportingFilesWidget record={aiModel}
                                                          onUpdate={onUpdateAiModelScripts}
                                                          setSupportingFileDescription={updateSupportingFileDescription}
                                                          supportingFileDescription={supportingFileDescription}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onDelete}>Delete</Button>
                    <Button onClick={onBack}>Back</Button>
                    <Button onClick={onClear} disabled={!isChanged}>Clear</Button>
                    <Button onClick={onTest} disabled={isTested}>Test</Button>
                    <Button onClick={onUpdate} disabled={!isChanged || !isTested}>Update</Button>
                </DialogActions>
            </Dialog>
            {
                rsInfoDialogOpen &&
                <RsInfoDialog isDialogOpen={rsInfoDialogOpen}
                              onBack={onArsInfoDialogClose}
                              rs={ars}
                />
            }
        </>
    )
})

export default AiModelUpdateDialog;