import React from 'react';
import Typography from "@mui/material/Typography";

interface Props {
    children?: React.ReactNode
}

const MajorFooterText = ({children}: Props) => {
    return (
        <Typography variant="h6" color={"#FFFFFF"}>
            {children}
        </Typography>
    )
};

export default MajorFooterText;