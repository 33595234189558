import React from 'react';
import {DisplayColumn} from "../../../../domain/entity/structures/dataVisualisation/DisplayColumn";
import BodyText from "../../../shared/BodyText";
import {Alert, Button, Grid} from "@mui/material";

interface CreateTableWidgetProps {
    selectedColumns: DisplayColumn[]
    onAdd: () => void
}

const CreateTableWidget = (props: CreateTableWidgetProps) => {
    return (
        <Grid container>
            <Grid container item justifyContent={"space-around"}>
                <BodyText>Columns</BodyText>
            </Grid>
            <Grid item xs={12}>
                {
                    props.selectedColumns.length === 0 &&
                    <Alert severity={'info'}>Select several columns from the columns list</Alert>
                }
                {
                    props.selectedColumns.map((item) => (
                        <BodyText key={"table-column-" + item.id}>{item.column.columnName}</BodyText>
                    ))
                }
            </Grid>
            <Grid item xs={12}>
                <Button variant="text"
                        fullWidth
                        disabled={props.selectedColumns.length < 1}
                        onClick={props.onAdd}>
                    Add
                </Button>
            </Grid>
        </Grid>
    );
};

export default CreateTableWidget;