import React from 'react';
import EntityCard from "./EntityCard";
import {DisplayEntityType} from "../../../../domain/entity/structures/dataVisualisation/DisplayEntityType";
import TableWidget from "./TableWidget";
import LineChartWidget from "./LineChartWidget";
import BarChartWidget from "./BarChartWidget";
import PieChartWidget from "./PieChartWidget";
import {DisplayEntityWithData} from "../../../../domain/entity/structures/dataVisualisation/DisplayEntityWithData";
import RadarChartWidget from "./RadarChartWidget";

interface DisplayEntitiesListProps {
    displayEntities: DisplayEntityWithData[]
    editMode: boolean
    onExportToPdf: (entity: DisplayEntityWithData) => void
    onDelete: (entity: DisplayEntityWithData) => void
    onMoveUp: (entity: DisplayEntityWithData) => void
    onMoveDown: (entity: DisplayEntityWithData) => void
}

const DisplayEntitiesList = (props: DisplayEntitiesListProps) => {
    return (
        <>
            {
                props.displayEntities.map((item) => (
                    <EntityCard key={"display-entity-card-" + props.displayEntities.indexOf(item)}
                                entity={item}
                                editMode={props.editMode}
                                onExportToPdf={props.onExportToPdf}
                                onMoveDown={props.onMoveDown}
                                onMoveUp={props.onMoveUp}
                                isFirst={props.displayEntities.indexOf(item) === 0}
                                isLast={props.displayEntities.indexOf(item) === props.displayEntities.length - 1}
                                onDelete={props.onDelete}>
                        {
                            item.displayEntity.type === DisplayEntityType.table &&
                            <TableWidget data={item.data}
                                         id={"data-entity-picture-" + props.displayEntities.indexOf(item)}
                                         key={"display-entity-" + props.displayEntities.indexOf(item)}/>
                        }
                        {
                            item.displayEntity.type === DisplayEntityType.line &&
                            <LineChartWidget data={item.data}
                                             id={"data-entity-picture-" + props.displayEntities.indexOf(item)}
                                             key={"display-entity-" + props.displayEntities.indexOf(item)}/>
                        }
                        {
                            item.displayEntity.type === DisplayEntityType.bar &&
                            <BarChartWidget data={item.data}
                                            id={"data-entity-picture-" + props.displayEntities.indexOf(item)}
                                            key={"display-entity-" + props.displayEntities.indexOf(item)}/>
                        }
                        {
                            item.displayEntity.type === DisplayEntityType.pie &&
                            <PieChartWidget data={item.data}
                                            id={"data-entity-picture-" + props.displayEntities.indexOf(item)}
                                            key={"display-entity-" + props.displayEntities.indexOf(item)}/>
                        }
                        {
                            item.displayEntity.type === DisplayEntityType.radar &&
                            <RadarChartWidget data={item.data}
                                              id={"data-entity-picture-" + props.displayEntities.indexOf(item)}
                                              key={"display-entity-" + props.displayEntities.indexOf(item)}/>
                        }
                    </EntityCard>
                ))
            }
        </>
    );
};

export default DisplayEntitiesList;