import {InputLabel, List} from '@mui/material';
import React from 'react';
import FlowsListItem from "./FlowsListItem";
import {IdNameListValues} from "../../../domain/entity/structures/shared/IdNameListValues";

interface Props {
    flows: IdNameListValues[],
    title: string
}

const FlowsList = (props: Props) => {
    return (
        <>
            <InputLabel>{props.title}</InputLabel>
            <List sx={{
                width: '100%',
                bgcolor: 'background.paper',
                maxHeight: 350,
                overflowY: 'auto',
            }}>
                {props.flows.map((item) => {
                    return (
                        <FlowsListItem flow={item}
                                       key={'ListItem-' + item.id}
                        />
                    );
                })}
            </List>
        </>
    )
}

export default FlowsList;