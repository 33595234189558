import React from 'react';
import {Checkbox, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface PredefinedValuesMutiselectDropDownProps {
    values: string[]
    selectedValues: string[]
    onSelectionChange: (values: string[]) => void;
}

const PredefinedValuesMultiselectDropDown = ({
                                                 values,
                                                 selectedValues,
                                                 onSelectionChange
                                             }: PredefinedValuesMutiselectDropDownProps) => {

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: {value},
        } = event;
        let valueNames: string[] = typeof value === 'string' ? value.split(',') : value

        onSelectionChange(valueNames)
    };

    return (
        <Select
            id={"predefined-values-multiple-checkbox"}
            multiple
            value={selectedValues}
            onChange={handleChange}
            input={<OutlinedInput fullWidth/>}
            renderValue={(selected) => {
                let result: string = ''
                selectedValues.forEach((item) => {
                    if (result !== '') result = result + ', '
                    result = result + item
                })
                return result;
            }}
            MenuProps={MenuProps}
        >
            {values.map((item: string) => (
                <MenuItem key={item} value={item}>
                    <Checkbox checked={selectedValues.indexOf(item) > -1}/>
                    <ListItemText primary={item}/>
                </MenuItem>
            ))}
        </Select>
    );
};

export default PredefinedValuesMultiselectDropDown;