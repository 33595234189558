import React from 'react';
import {Button, TextField} from "@mui/material";
import {DrsItem} from "../../domain/entity/structures/requirementManagement/DrsItem";
import {ArsItem} from "../../domain/entity/structures/requirementManagement/ArsItem";
import {CriticalityList} from "../../domain/entity/structures/requirementManagement/CriticalityEnum";
import {ComplexityEnum, ComplexityList} from "../../domain/entity/structures/requirementManagement/ComplexityEnum";
import EnumSelectForm from "./EnumSelectForm";
import LogicStatementsList from "./LogicStatementsList";

interface RsPropertiesProps {
    record: DrsItem | ArsItem
    onUpdate: (item: DrsItem | ArsItem) => void
}

const RsProperties = (props: RsPropertiesProps) => {

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        props.onUpdate({...props.record, [name]: target.value});
    }

    function onCriticalitySelectChange(value: string) {
        props.onUpdate({...props.record, criticality: value});
    }

    function onComplexitySelectChange(value: string) {
        props.onUpdate({...props.record, complexity: value === ComplexityEnum.empty ? "" : value});
    }

    function onLogicStatementChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        props.record.logic_statements[0] = target.value
        props.onUpdate({...props.record});
    }

    function onAddMoreStatements() {
        props.record.logic_statements.unshift('')
        props.onUpdate({...props.record});
    }

    function onLogicStatementDelete(index: number) {
        props.record.logic_statements.splice(index + 1, 1)
        props.onUpdate({...props.record});
    }

    return (
        <>
            <TextField
                autoFocus
                margin="dense"
                id="item-name"
                label="Name"
                type="text"
                // error={props.record.name === ''}
                fullWidth
                required
                variant="standard"
                inputProps={{maxLength: 50}}
                name='name'
                value={props.record.name}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id="item-description"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={2}
                inputProps={{maxLength: 500}}
                variant="standard"
                name='description'
                value={props.record.description}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id="item-description"
                label="Logic statements"
                type="text"
                fullWidth
                required
                // error={props.record.logic_statements[0] === ''}
                inputProps={{maxLength: 500}}
                variant="standard"
                name='logic-statement'
                value={props.record.logic_statements[0]}
                onChange={onLogicStatementChange}
            />
            <LogicStatementsList logicStatements={props.record.logic_statements.slice(1)}
                                 onDelete={onLogicStatementDelete}/>
            <Button variant="text"
                    fullWidth
                    disabled={props.record.logic_statements[0] === ''}
                    onClick={onAddMoreStatements}>
                Add more statements
            </Button>
            <EnumSelectForm value={props.record.criticality}
                            onChange={onCriticalitySelectChange}
                            enumRecords={CriticalityList}
                            variant="standard"
                            required
                            label={"Criticality"}/>
            <EnumSelectForm value={props.record.complexity}
                            onChange={onComplexitySelectChange}
                            variant="standard"
                            enumRecords={ComplexityList}
                            label={"Complexity"}/>
            <TextField
                margin="dense"
                id="item-category"
                label="Category"
                type="text"
                // error={props.record.category === ''}
                fullWidth
                required
                variant="standard"
                name='category'
                inputProps={{maxLength: 50}}
                value={props.record.category}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id="item-subject"
                label="Subject"
                type="text"
                fullWidth
                inputProps={{maxLength: 50}}
                variant="standard"
                name='subject'
                value={props.record.subject}
                onChange={onFieldChange}
            />
        </>
    );
};

export default RsProperties;