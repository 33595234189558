import React from 'react';
import Typography from "@mui/material/Typography";
import {TEXT_FOR_LIGHT_BACKGROUND} from "../../utils/consts";

interface Props {
    children?: React.ReactNode
}

const BodyText = ({children}: Props) => {
    return (
        <Typography variant="body1" color={TEXT_FOR_LIGHT_BACKGROUND}>
            {children}
        </Typography>
    )
};

export default BodyText;