import React from 'react';
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {HEADER_FOR_DARK_BACKGROUND} from "../../utils/consts";
import {ModelReview} from "../../domain/entity/structures/dataProcessing/ModelReview";
import ClearIcon from "@mui/icons-material/Clear";
import {getFormattedDate} from "../../utils/utils";

interface ModelReviewTableProps {
    data: ModelReview[]
    onRemove: (modelReview: ModelReview) => void
}

const ModelReviewTable = (props: ModelReviewTableProps) => {

    const handleClick = (event: React.MouseEvent<unknown>, item: ModelReview) => {
        event.stopPropagation()
        props.onRemove(item)
    };

    return (
        <TableContainer sx={{height: 200}}>
            <Table stickyHeader sx={{minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{
                            backgroundColor: "lightgray",
                            border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}>Quality</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Comment</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Created By</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Create time</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row) => {
                            return (
                                <TableRow
                                    key={props.data.indexOf(row) + '-' + row.review_id}
                                    sx={{
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: "ghostwhite",
                                        }
                                    }}
                                    hover
                                    tabIndex={-1}
                                >
                                    <TableCell component="th" scope="row" sx={{border: 1}}>
                                        {row.quality_level}
                                    </TableCell>
                                    <TableCell align="center" sx={{border: 1}}>{row.comment}</TableCell>
                                    <TableCell align="center" sx={{border: 1}}>{row.created_by}</TableCell>
                                    <TableCell align="center"
                                               sx={{border: 1}}>{getFormattedDate(row.create_time)}</TableCell>
                                    <TableCell padding="checkbox" align="center" sx={{border: 1}}>
                                        <IconButton
                                            onClick={(event) => handleClick(event, row)}
                                        >
                                            <ClearIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ModelReviewTable;