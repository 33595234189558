import React from 'react';
import ConditionSelector from "../../../domain/entity/structures/dataVisualisation/ConditionSelector";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import SelectorBoolean from "../../../domain/entity/structures/dataVisualisation/SelectorBoolean";
import {SelectorBooleanType} from "../../../domain/entity/structures/dataVisualisation/SelectorBooleanType";

interface SelectorBooleanWidgetProps {
    selector: ConditionSelector
    refresh: () => void
}

const SelectorBooleanWidget = ({refresh, selector}: SelectorBooleanWidgetProps) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        (selector.selector as SelectorBoolean).value = event.target.value;
        refresh()
    };

    return (
        <>
            <RadioGroup
                row
                name={"boolean-selector-radio-buttons-group-" + selector.dataFilter.parameterName}
                value={(selector.selector as SelectorBoolean).value}
                onChange={handleChange}
            >
                <FormControlLabel value={SelectorBooleanType.true} control={<Radio/>} label="true"/>
                <FormControlLabel value={SelectorBooleanType.false} control={<Radio/>} label="false"/>
            </RadioGroup>
        </>
    );
};

export default SelectorBooleanWidget;