import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {getColor, getColumnsList} from "../../../../utils/utils";

interface LineChartWidgetProps {
    data: any[]
    id: string
}

const LineChartWidget = (props: LineChartWidgetProps) => {
    const columnsList = getColumnsList(props.data);
    return (
        <ResponsiveContainer height={300} id={props.id}>
            <LineChart data={props.data}
                       margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey={columnsList[0]}/>
                <YAxis/>
                <Tooltip/>
                <Legend wrapperStyle={{position: 'relative'}}/>
                {
                    columnsList.map((item) => (
                        columnsList.indexOf(item) > 0 &&
                        <Line type="monotone"
                              dataKey={item}
                              stroke={getColor(columnsList.indexOf(item))}
                              key={"line-chart-" + columnsList.indexOf(item)}/>
                    ))
                }
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LineChartWidget;