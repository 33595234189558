import React from 'react';
import {IconButton, ListItem, ListItemText} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import {RsComment} from "../../domain/entity/structures/requirementManagement/RsComment";
import moment from "moment";
import {DATE_FORMAT} from "../../utils/consts";

interface CommentsListItemProps {
    comment: RsComment
    onDelete: (comment: RsComment) => void
}

const CommentsListItem = (props: CommentsListItemProps) => {
    const labelId = `comment-list-item-label-${props.comment.number}`;

    function onDelete() {
        props.onDelete(props.comment)
    }

    return (
        <ListItem
            disablePadding
        >
            <ListItemText id={labelId} primary={props.comment.number.toString() + ',"'
                + props.comment.comment + '"'
                + (props.comment.created_by === undefined ? '' : (', by ' + props.comment.created_by))
                + (props.comment.createdAt === undefined ? '' : (', ' + moment(props.comment.createdAt).format(DATE_FORMAT)))
            }/>
            <IconButton onClick={onDelete}>
                <ClearIcon/>
            </IconButton>
        </ListItem>
    );
};

export default CommentsListItem;