import React from 'react';
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface Props {
    value: number
    range: number
    onChange: (value: number) => void
}

const OrderSelect = (props: Props) => {

    const handleChange = (event: SelectChangeEvent) => {
        props.onChange(parseInt(event.target.value));
    };

    function getSelectValues() {
        const result: number[] = []
        for (let i = 1; i < props.range + 1; i++) {
            result.push(i)
        }
        return result
    }

    return (
        <Select
            id="order-select"
            value={props.value.toString()}
            variant={"standard"}
            onChange={handleChange}
        >
            {getSelectValues().map((item) => (
                <MenuItem key={item} value={item}>{item}</MenuItem>
            ))}
        </Select>
    )
}

export default OrderSelect;