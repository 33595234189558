import React from 'react';
import {DrsItem} from "../../domain/entity/structures/requirementManagement/DrsItem";
import {ArsItem} from "../../domain/entity/structures/requirementManagement/ArsItem";
import BodyText from "../shared/BodyText";
import {Box, FormControl} from "@mui/material";
import {getFormattedDate} from "../../utils/utils";

interface RsCreatedUpdatedProps {
    record: DrsItem | ArsItem
}

const RsCreatedUpdated = (props: RsCreatedUpdatedProps) => {
    return (
        <FormControl aria-labelledby={"scenario-list-label"} fullWidth margin={'dense'}>
            <Box width={"100%"}>
                <BodyText>{"Created by: " + props.record.created_by +
                    " Create time: " + getFormattedDate(props.record.createdAt)}</BodyText>
            </Box>
            {
                props.record.updated_by !== null &&
                <Box width={"100%"}>
                    <BodyText>{"Updated by: " + props.record.updated_by +
                        " Update time: " + getFormattedDate(props.record.updatedAt)}</BodyText>
                </Box>
            }
        </FormControl>
    );
};

export default RsCreatedUpdated;