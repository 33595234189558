import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import {Context} from "../../index";
import PageTitle from "../../components/shared/PageTitle";
import {Button, Grid} from '@mui/material';
import {getJobExecutionResults} from "../../http/aiLabAPI";
import {executedJobInputAdapter} from "../../components/models/aiLab/scheduledJob/JobUtils";
import ExecutedJobsTable from "../../components/models/aiLab/executionManagement/ExecutedJobsTable";
import {ExecutedJob} from "../../domain/entity/structures/aiLab/ExecutedJob";
import ExecuteJobDialog from "../../components/models/aiLab/executionManagement/ExecuteJobDialog";
import useWebSocket from "react-use-websocket";

const ExecutionManagementPage = () => {
    const {appStore} = useContext(Context)
    const [executedJobs, setExecutedJobs] = useState<ExecutedJob[]>([]);
    const [executeJobDialogOpen, setExecuteJobDialogOpen] = useState<boolean>(false);

    function loadExecutedJobs() {
        appStore.addLoading()
        getJobExecutionResults().then(response => {
            setExecutedJobs(executedJobInputAdapter(response.data));
        }).catch(() => {
            appStore.showAppSnackBar("error", "Executed jobs loading error")
        }).finally(() => {
            appStore.removeLoading();
        })
    }

    useEffect(() => {
        loadExecutedJobs();

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        lastJsonMessage,
    } = useWebSocket(process.env.REACT_APP_SOCKET_URL as any, {
        onOpen: () => console.log('opened'),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: () => true,
    });

    useEffect(() => {
            if (lastJsonMessage !== null && (lastJsonMessage as any).message_type === "job_list_update") {
                console.log(lastJsonMessage)
                loadExecutedJobs();
            }
        },
// eslint-disable-next-line react-hooks/exhaustive-deps
        [lastJsonMessage]);

    const title = "Execution results"

    function onCreate() {
        setExecuteJobDialogOpen(true)
    }

    function onExecuteJobDialogClose() {
        setExecuteJobDialogOpen(false)
        loadExecutedJobs()
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button onClick={onCreate}>Execute new job</Button>
                </Grid>
                <Grid item xs={12}>
                    <ExecutedJobsTable data={executedJobs}/>
                </Grid>
            </Grid>
            {
                executeJobDialogOpen &&
                <ExecuteJobDialog onBack={onExecuteJobDialogClose}/>
            }
        </>
    )
}

export default ExecutionManagementPage