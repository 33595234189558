import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BodyText from "../../../shared/BodyText";

interface Props {
    title: string
    expanded: string | false
    onAccordionChange: (value: string | false) => void
    children?: React.ReactNode
}

const CreateDisplayElementAccordionItem = ({
                                               children,
                                               title,
                                               expanded,
                                               onAccordionChange
                                           }: Props) => {
    function onChange(event: React.SyntheticEvent, isExpanded: boolean) {
        if (isExpanded)
            onAccordionChange(isExpanded ? title : false)
    }

    return (
        <Accordion expanded={expanded === title} onChange={onChange}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls={title + "bh-content"}
                id={title + "bh-header"}
            >
                <BodyText>{title}</BodyText>
            </AccordionSummary>
            <AccordionDetails sx={{textAlign: "left"}}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default CreateDisplayElementAccordionItem;