import React, {useContext, useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Flow} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import FlowsListSingleSelection from "./FlowsListSingleSelection";
import BodyText from "../../shared/BodyText";
import {DATAOBJECT, DATE_TIME_INPUT_FORMAT} from "../../../utils/consts";
import {Context} from "../../../index";
import {DataObject} from "../../../domain/entity/structures/dataVisualisation/DataObject";
import {filteredDataObjects} from "../../../http/visualisationAPI";
import moment from "moment";
import {Expression} from "../../../domain/entity/structures/dataVisualisation/Expression";
import {SelectProcessedDataState} from "../../../domain/entity/structures/dataVisualisation/SelectProcessedDataState";
import {dataObjectsInputAdapter} from "../../../utils/adapters";
import ObjectsIdNameMultiselectList from "./ObjectsIdNameMultiselectList";

interface Props {
    flows: Flow[]
    dialogOpen: boolean
    onAddDataObject: (flowId: string, flowName: string, dataObjects: DataObject[]) => void
    onAddProcessedData: (flowId: string, flowName: string, expression: Expression) => void
    onCancel: () => void
    onFinish: () => void
}

const SelectProcessedData = (props: Props) => {
    const {appStore} = useContext(Context)
    const [selectedFlow, setSelectedFlow] = useState<Flow>(props.flows[0]);
    const [dataStates, setDataStates] = useState<SelectProcessedDataState[]>([]);

    useEffect(() => {
        setSelectedFlow(props.flows[0])
        setDataStates(props.flows.map((item) => {
            return {
                dataFilters: [],
                selectors: [],
                dataObjects: [],
                selectedDataObjects: [],
                startTime: moment(Date.now()).subtract(1, 'days').local().format(DATE_TIME_INPUT_FORMAT),
                endTime: moment(Date.now()).local().format(DATE_TIME_INPUT_FORMAT),
                flow: item,
                option: DATAOBJECT
            } as SelectProcessedDataState
        }))
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.flows.length]);


    function onViewSelectedData() {
        const itemIndex = props.flows.indexOf(selectedFlow);
        const currentDataState = dataStates[itemIndex];
        if (!currentDataState) return
        props.onAddDataObject(selectedFlow.id, selectedFlow.name, currentDataState.selectedDataObjects)
    }

    function onFinish() {
        props.onFinish()
    }

    function handleAccordionChange(item: Flow) {
        return function (p1: React.SyntheticEvent, p2: boolean) {
            if (p2) {
                setSelectedFlow(item)
            }
        };
    }

    function refreshDataStates() {
        setDataStates(dataStates.slice())
    }

    function onTimeChange(event: React.ChangeEvent<HTMLInputElement>) {
        const time = (event.target as HTMLInputElement).value;
        const name = (event.target as HTMLInputElement).name;
        const itemIndex = props.flows.indexOf(selectedFlow);
        if (dataStates[itemIndex] === undefined) return
        dataStates[itemIndex] = {...dataStates[itemIndex], [name]: time, dataObjects: []}
        refreshDataStates()
    }

    function isStartEndDatesValid(startTime: string, endTime: string) {
        const start = moment(startTime).unix();
        if (isNaN(start))
            return false
        const end = moment(endTime).unix();
        if (isNaN(end))
            return false
        return start <= end;
    }

    function onGoButtonClick() {
        const itemIndex = props.flows.indexOf(selectedFlow);
        const currentDataState = dataStates[itemIndex];
        if (currentDataState === undefined) return
        if (currentDataState.dataObjects.length === 0) {
            if (!isStartEndDatesValid(currentDataState.startTime, currentDataState.endTime)) {
                appStore.showAppSnackBar('error', "Please enter valid start-time and end-time (start-time should be earlier than end-time)")
                return
            }
            appStore.addLoading()
            filteredDataObjects(selectedFlow.id, currentDataState.startTime, currentDataState.endTime).then((response) => {
                const data: any[] = response.data.data_objects
                if (data.length === 0) {
                    appStore.showAppSnackBar("warning", "No data objects found for this flow and time range")
                }
                currentDataState.dataObjects = dataObjectsInputAdapter(data)
                setDataStates(dataStates.slice())
            }).catch((reason) => {
                if (reason.response.status === 404)
                    appStore.showAppSnackBar("error", reason.response.data.message.message)
                else
                    appStore.showAppSnackBar("error", "No data objects found for this flow and time range")
            }).finally(() => appStore.removeLoading())
        }
    }

    return (
        <Dialog open={props.dialogOpen} onClose={props.onCancel} fullWidth maxWidth={'xl'}>
            <DialogTitle>Select Processed Data</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Box height={'400px'} sx={{overflowY: "auto", overflowX: "hidden"}}>
                            <FlowsListSingleSelection flows={props.flows}
                                                      selectedFlow={selectedFlow}
                                                      setSelected={(value) => {
                                                          setSelectedFlow(value)
                                                      }}/>
                        </Box>
                    </Grid>
                    <Grid item xs={9}>
                        <Box height={'450px'}
                             sx={{overflowY: "auto", overflowX: "hidden", padding: "10px"}}>
                            {
                                props.flows.map(item => {
                                    const itemIndex = props.flows.indexOf(item);
                                    return (
                                        <Accordion expanded={selectedFlow === item}
                                                   key={"accordion-" + item.id}
                                                   onChange={handleAccordionChange(item)}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <BodyText>
                                                    {item.name}
                                                </BodyText>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {
                                                    dataStates[itemIndex] !== undefined &&
                                                    <Grid container spacing={1}>
                                                        <Grid item xs/>
                                                        <Grid container item xs={"auto"} spacing={1}
                                                              direction={"column"}>
                                                            <Grid container item xs={"auto"} spacing={1}>
                                                                <Grid container item xs={3} alignItems={"center"}
                                                                      justifyContent={"flex-end"}>
                                                                    <BodyText>Start time</BodyText>
                                                                </Grid>
                                                                <Grid item xs={9}>
                                                                    <TextField
                                                                        margin="dense"
                                                                        id={item.id + "-select-data-object-start-time"}
                                                                        type="datetime-local"
                                                                        variant="outlined"
                                                                        required
                                                                        name={"startTime"}
                                                                        value={dataStates[itemIndex].startTime}
                                                                        onChange={onTimeChange}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container item xs={"auto"} spacing={1}>
                                                                <Grid container item xs={3} alignItems={"center"}
                                                                      justifyContent={"flex-end"}>
                                                                    <BodyText>End time</BodyText>
                                                                </Grid>
                                                                <Grid item xs={9}>
                                                                    <TextField
                                                                        margin="dense"
                                                                        id={item.id + "-select-data-object-start-time"}
                                                                        type="datetime-local"
                                                                        variant="outlined"
                                                                        required
                                                                        name={"endTime"}
                                                                        value={dataStates[itemIndex].endTime}
                                                                        onChange={onTimeChange}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container item xs={"auto"} alignItems={"center"}
                                                              justifyContent={"flex-start"}>
                                                            <IconButton color="primary" aria-label="load data objects"
                                                                        onClick={onGoButtonClick}
                                                                        sx={{marginLeft: "1em"}}>
                                                                Go
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs/>
                                                    </Grid>
                                                }
                                                {
                                                    dataStates[itemIndex] !== undefined && dataStates[itemIndex].dataObjects.length > 0 &&
                                                    <Box height={'150px'} sx={{overflowY: "auto", overflowX: "hidden"}}>
                                                        <ObjectsIdNameMultiselectList
                                                            objs={dataStates[itemIndex].dataObjects}
                                                            selectedObjs={dataStates[itemIndex].selectedDataObjects}
                                                            refresh={refreshDataStates}/>
                                                    </Box>
                                                }

                                            </AccordionDetails>
                                        </Accordion>);
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container>
                    <Grid item xs={6}>
                        <Button variant={"text"} onClick={onViewSelectedData}>View selected
                            data</Button>
                    </Grid>
                    <Grid item xs={6} textAlign={"right"}>
                        <Button variant={"text"} onClick={props.onCancel}>Close</Button>
                        <Button variant={"text"} onClick={onFinish}>Finish</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
};

export default SelectProcessedData;