import {List, ListItem} from '@mui/material';
import React from 'react';
import ScenariosListItem from "./ScenariosListItem";
import {ScenarioFlow} from "../../../../domain/entity/structures/dataProcessing/ScenarioFlow";

interface Props {
    scenarios: ScenarioFlow[]
    redrawScenarios: () => void
}

const ScenariosList = (props: Props) => {

    return (
        <List sx={{
            width: '100%',
            bgcolor: 'background.paper',
            maxHeight: 400,
        }}>
            {props.scenarios.map((item) => {
                return (
                    <ListItem
                        key={'ListItem-' + item.scenario_id}
                        disablePadding
                    >
                        <ScenariosListItem scenario={item}
                                           redrawScenarios={props.redrawScenarios}
                        />
                    </ListItem>
                );
            })}
        </List>
    );
};

export default ScenariosList;