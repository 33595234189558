import React, {useContext, useEffect, useRef, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Alert, Button, IconButton, OutlinedInput, TextField} from "@mui/material";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import {allFlows, dataForFlow, deleteFlow, manageFlow} from "../../../../http/flowsAPI";
import {Flow} from "../../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import ObjectIdNameDropDown from "../../filterConfiguration/filtersToQueryData/ObjectIdNameDropDown";
import FileUploader from "../FileUploader";
import BodyText from "../../../shared/BodyText";
import UploadIcon from "@mui/icons-material/Upload";
import UpdateFlow from "../../../../domain/entity/structures/adminisrator/dataFlowManagement/UpdateFlow";
import {isNameValid} from "../../../../utils/validationUtils";
import {useNavigate} from "react-router-dom";
import {HOME_ROUTE, ON_FINISH_MESSAGE} from "../../../../utils/consts";
import {objectArrayToFlows} from "../../../../utils/utils";


const ManageFlows = observer(() => {
    let navigate = useNavigate();
    const {appStore} = useContext(Context)
    const [flows, setFlows] = useState<Flow[]>([]);
    const [selectedFlow, setSelectedFlow] = useState<Flow>(null as any);
    const [flow, setFlow] = useState<UpdateFlow>({
        id: "",
        dataObjects: null as any,
        dataObjectFileName: "",
        is_validation: false,
        name: "",
        product_flow_id: undefined
    });
    const [isNameEditable, setIsNameEditable] = React.useState(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const inputNameReference = useRef();

    const COLUMN_1 = 3
    const COLUMN_2 = 5
    const COLUMN_3 = 4

    useEffect(() => {
        appStore.addLoading()
        allFlows().then(response => {
            setFlows(objectArrayToFlows(response.data));
        }).finally(() => {
            appStore.removeLoading();
        })
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function loadFlow(selectedFlow: Flow) {
        setFlow({
            dataObjects: [],
            dataObjectFileName: "",
            id: selectedFlow.id,
            is_validation: selectedFlow.is_validation ? selectedFlow.is_validation : false,
            name: selectedFlow.name,
            product_flow_id: selectedFlow.product_flow_id
        })
        setIsChanged(false)
        setIsNameEditable(false)
    }

    function handleSelectionChange(selectedFlow: Flow) {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }

        setSelectedFlow(selectedFlow)
        loadFlow(selectedFlow);
    }

    function onCancelButtonClick() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }

        navigate(HOME_ROUTE);
    }

    function onClearButtonClick() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        loadFlow(selectedFlow)
    }

    function onDeleteButtonClick() {
        let confirmAction = window.confirm('Are you sure to delete this flow?');
        if (!confirmAction)
            return
        appStore.addLoading();
        deleteFlow(flow.id)
            .then(() => {
                setSelectedFlow(null as any)
                setIsChanged(false)
                setIsNameEditable(false)
                appStore.showAppSnackBar("success", "Flow has been deleted");
                appStore.addLoading()
                allFlows().then(response => {
                    const flowList = objectArrayToFlows(response.data)
                    setFlows(flowList);
                }).finally(() => appStore.removeLoading())
            }).catch(() => {
            appStore.showAppSnackBar("error", "An error occurred while deleting the flow");
        }).finally(() => appStore.removeLoading())
    }

    function onNameInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        setFlow({...flow, name: event.target.value})
        setIsChanged(true)
    }

    function processDataObject(files: FileList) {
        let filesArray: File[] = []
        for (let i = 0; i < files.length; i++) {
            filesArray.push(files[i])
        }
        setFlow({...flow, dataObjectFileName: filesArray.map(e => e.name).join(", "), dataObjects: filesArray})
        setIsChanged(true)
    }

    function isFlowValid() {
        const nameValidationResult = isNameValid(flow.name);
        if (nameValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Flow name has validation error with message: " + nameValidationResult)
            return false
        }

        for (let i = 0; i < flow.dataObjects.length; i++) {
            if (!flow.dataObjects[i].name.startsWith(flow.name + '%%')) {
                appStore.showAppSnackBar("error",
                    "File name (" + flow.dataObjects[i].name + ") should start with flow's name and %% ")
                return false
            }
        }
        if (flow.is_validation && !flow.product_flow_id) {
            appStore.showAppSnackBar("error",
                "Product flow must be selected for the validation flow")
            return false
        }
        return true
    }

    function onSaveButtonClick() {
        if (!isFlowValid())
            return

        appStore.addLoading();
        manageFlow({
            flow_id: flow.id,
            flow_name: flow.name,
            is_validation: flow.is_validation,
            product_flow_id: flow.product_flow_id ? flow.product_flow_id : null
        }).then(() => {
            appStore.addLoading()
            allFlows().then(response => {
                const flowList = objectArrayToFlows(response.data)
                setFlows(flowList);
                const sameFlow = flowList.find(e => e.id === flow.id)
                if (sameFlow)
                    setSelectedFlow(sameFlow)
            }).finally(() => {
                appStore.removeLoading();
            })
            if (flow.dataObjects.length > 0) {
                appStore.addLoading();
                dataForFlow({
                    flow_id: flow.id,
                    flow_name: flow.name,
                    files: flow.dataObjects,
                }).then(() => {
                    appStore.showAppSnackBar("success", "Flow has been saved");
                    setIsChanged(false)
                    setIsNameEditable(false)
                }).catch(() => {
                    appStore.showAppSnackBar("error", "An error occurred while saving the flow");
                }).finally(() => appStore.removeLoading())
            } else {
                appStore.showAppSnackBar("success", "Flow has been saved");
                setIsChanged(false)
                setIsNameEditable(false)
            }
        }).catch(() => {
            appStore.showAppSnackBar("error", "An error occurred while saving the flow");
        }).finally(() => appStore.removeLoading())
    }

    function onEditButtonClick() {
        setIsNameEditable(true)
        const timeout = setTimeout(() => {
            if (inputNameReference.current)
                (inputNameReference.current as any).focus();
        }, 100);

        return () => {
            clearTimeout(timeout);
        };
    }

    return (
        <Grid container spacing={1}>
            <Grid container item xs={COLUMN_1 + COLUMN_2}>
            </Grid>
            <Grid item xs={COLUMN_3}>
                <ObjectIdNameDropDown objs={flows}
                                      selectedObj={selectedFlow}
                                      onSelectionChange={handleSelectionChange}
                                      label={"Flow"}
                />
            </Grid>
            {
                selectedFlow &&
                <>
                    <Grid container item xs={COLUMN_1} justifyContent={"flex-end"} alignItems={"center"}>
                        <BodyText>Name *</BodyText>
                    </Grid>
                    <Grid item xs={COLUMN_2}>
                        <TextField value={flow.name}
                                   variant={"outlined"}
                                   inputRef={inputNameReference}
                                   fullWidth
                                   id={'flowName'}
                                   disabled={!isNameEditable}
                                   inputProps={{maxLength: 50}}
                                   onChange={onNameInputChange}/>
                    </Grid>
                    <Grid container item xs={COLUMN_3} alignItems={"center"}>
                        <Button variant="text"
                                disabled={isNameEditable}
                                onClick={onEditButtonClick}>
                            Edit
                        </Button>
                    </Grid>

                    <Grid container item xs={COLUMN_1}/>
                    <Grid item xs={COLUMN_2}>
                        <Alert severity="info">
                            Note: incoming data file names have to be consistent with the flow name
                        </Alert>
                    </Grid>
                    <Grid item xs={COLUMN_3}/>

                    <Grid container item xs={COLUMN_1} justifyContent={"flex-end"} alignItems={"center"}>
                        <BodyText>Data object(s)</BodyText>
                    </Grid>
                    <Grid item xs={COLUMN_2}>
                        <OutlinedInput value={flow.dataObjectFileName}
                                       placeholder={'uploaded data object'}
                                       readOnly
                                       fullWidth
                                       id={'metadataFileName'}
                                       onChange={onNameInputChange}/>
                    </Grid>
                    <Grid container item xs={COLUMN_3} justifyContent={"flex-start"} alignItems={"center"}>
                        <FileUploader processResult={processDataObject}
                                      keyPart={'dataObject'}
                                      multiple={true}
                                      accept={".csv"}>
                            <IconButton component={"span"}>
                                <UploadIcon/>
                            </IconButton>
                        </FileUploader>
                    </Grid>

                    <Grid container item xs={COLUMN_1}/>
                    <Grid item xs={COLUMN_3 + COLUMN_2}>
                        <Button variant="contained"
                                disabled={!isChanged}
                                onClick={onSaveButtonClick}>
                            Save
                        </Button>
                        <Button variant="text"
                                disabled={!isChanged}
                                onClick={onClearButtonClick}>
                            Clear
                        </Button>
                        <Button variant="text"
                                onClick={onCancelButtonClick}>
                            Cancel
                        </Button>
                        <Button variant="text"
                                onClick={onDeleteButtonClick}>
                            Delete
                        </Button>
                    </Grid>
                </>
            }
        </Grid>
    )
})

export default ManageFlows;