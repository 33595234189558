import React, {useContext, useState} from 'react';
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Context} from "../../../../index";
import {ON_FINISH_MESSAGE} from "../../../../utils/consts";
import {FlowGroup} from "../../../../domain/entity/structures/aiLab/FlowGroup";
import {ScheduledJob} from "../../../../domain/entity/structures/aiLab/ScheduledJob";
import {IdNameListValues} from "../../../../domain/entity/structures/shared/IdNameListValues";
import ObjectsIdNameMultiselectList from "../../../dataVisualisation/historicalDashboard/ObjectsIdNameMultiselectList";
import {createScheduledJob, getScheduleModelByFlow} from "../../../../http/aiLabAPI";
import {idNameValuesFromObject} from "../../../../utils/utils";
import {ScheduleTypeEnum} from "../../../../domain/entity/structures/aiLab/ScheduleTypeEnum";
import moment from "moment";
import {createJobOutputAdapter} from "../scheduledJob/JobUtils";
import SelectFlowGroupWidget from "../scheduledJob/SelectFlowGroupWidget";

interface Props {
    onBack: () => void
}

const ExecuteJobDialog = observer((props: Props) => {
    const {appStore} = useContext(Context)
    const [job, setJob] = useState<ScheduledJob>({
        numberOccurrences: 0,
        startDate: 0,
        aiModels: [],
        createTime: 0,
        createdBy: "",
        dayOfWeek: "",
        dayOfMonth: 0,
        endDate: 0,
        executionTime: 0,
        flowGroup: {flows: [], id: "", name: ""},
        id: "",
        interval: 0,
        jobId: "",
        frequency: "",
        scheduleType: ScheduleTypeEnum.oneOffNow,
        minutes: 0
    } as ScheduledJob);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [aiModelsList, setAiModelsList] = useState<IdNameListValues[]>([]);

    function clear() {
        setJob({
            numberOccurrences: 0,
            startDate: moment().unix(),
            aiModels: [],
            createTime: 0,
            createdBy: "",
            dayOfWeek: "",
            dayOfMonth: 0,
            endDate: moment().add(1, "day").unix(),
            executionTime: 0,
            flowGroup: {flows: [], id: "", name: ""},
            id: "",
            interval: 0,
            jobId: "",
            frequency: "",
            scheduleType: ScheduleTypeEnum.oneOffNow,
            minutes: 0
        } as ScheduledJob);
        setAiModelsList([])
        setIsChanged(false)
    }

    function onClear() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        clear();
    }

    function onFinish() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onBack()
    }

    function onUpdateJob(value: ScheduledJob) {
        setJob(value)
        setIsChanged(true)
    }

    function onUpdateFlowGroup(value: FlowGroup) {
        onUpdateJob({...job, flowGroup: value})
        appStore.addLoading()
        getScheduleModelByFlow(value.id).then((response) => {
            setAiModelsList(idNameValuesFromObject(response.data))
        }).catch(() => {
            appStore.showAppSnackBar("error", "Loading of AI models failed")
        }).finally(() => appStore.removeLoading())
    }

    function isJobValid() {
        if (job.scheduleType === "") {
            appStore.showAppSnackBar("error",
                "Please select schedule type")
            return false
        }

        if (job.aiModels.length === 0) {
            appStore.showAppSnackBar("error",
                "At least one of AI models are selected")
            return false
        }

        if (job.flowGroup.id === "") {
            appStore.showAppSnackBar("error",
                "Flow group should be selected")
            return false
        }

        if (job.scheduleType === ScheduleTypeEnum.oneOffRerun) {
            if (job.executionTime === 0) {
                appStore.showAppSnackBar("error",
                    "The execution time should be set")
                return false
            }
            if (job.executionTime > moment().unix()) {
                appStore.showAppSnackBar("error",
                    "The execution time should be in the past")
                return false
            }
        }
        return true;
    }

    function createJob() {
        appStore.addLoading()
        createScheduledJob(createJobOutputAdapter(job)).then(() => {
            appStore.showAppSnackBar("success", "The job was scheduled successfully")
            setIsChanged(false)
            clear()
        }).catch(() => {
            appStore.showAppSnackBar("error", "The schedule creation failed")
        }).finally(() => appStore.removeLoading())
    }

    function onApply() {
        if (!isJobValid())
            return
        createJob()
        props.onBack()
    }

    function onRefresh() {
        onUpdateJob({...job})
    }

    return (
        <>
            <Dialog open={true} onClose={props.onBack}>
                <DialogTitle>Execute Job</DialogTitle>
                <DialogContent sx={{minHeight: "300px", minWidth: "550px"}}>
                    <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                        <Grid container item xs={12}
                              alignContent={"flex-start"}>
                            <InputLabel>Select a flow group</InputLabel>
                            <SelectFlowGroupWidget record={job.flowGroup}
                                                   onUpdate={onUpdateFlowGroup}/>
                            {
                                aiModelsList.length > 0 &&
                                <InputLabel>AI models:</InputLabel>
                            }
                            {
                                aiModelsList.length === 0 && job.flowGroup.id !== '' &&
                                <Alert severity="info" sx={{width: "calc(100% - 32px)", marginTop: 1}}>
                                    No scheduled models for this group. Please add a model with Type = Scheduled to the
                                    group
                                </Alert>
                            }
                            <ObjectsIdNameMultiselectList selectedObjs={job.aiModels}
                                                          refresh={onRefresh}
                                                          objs={aiModelsList}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onFinish}>Finish</Button>
                    <Button onClick={onClear} disabled={!isChanged}>Clear</Button>
                    <Button onClick={onApply} disabled={!isChanged}>Execute</Button>
                </DialogActions>
            </Dialog>
        </>
    )
})

export default ExecuteJobDialog;