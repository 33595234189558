import React from 'react';
import Typography from "@mui/material/Typography";
import {HEADER_FOR_LIGHT_BACKGROUND} from "../../utils/consts";

interface Props {
    children?: React.ReactNode
}

const SectionTitleLeft = ({children}: Props) => {
    return (
        <Typography variant="h5" component="div" gutterBottom color={HEADER_FOR_LIGHT_BACKGROUND}>
            {children}
        </Typography>
    )
};

export default SectionTitleLeft;