import {$authHost} from "./index";

export const login = async (loginId, password) => {
    const response = await $authHost.post('api/user/login', {loginId: loginId, password});
    const token = response.data.token;
    localStorage.setItem('token', token)
    return response
}

export const check = async () => {
    const response = await $authHost.get('api/user/auth');
    const token = response.data.token;
    localStorage.setItem('token', token)
    return response
}

export const logout = async () => {
    return await $authHost.post('api/user/logout')
}

export const forgotPassword = async (loginId, email) => {
    return await $authHost.post('api/user/forgotpassword', {loginId: loginId, email: email})
}

export const resetPassword = async (code, password) => {
    return await $authHost.post('api/user/resetpassword', {code: code, password: password})
}