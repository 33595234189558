import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import BodyText from "../../shared/BodyText";
import {DrsItem} from "../../../domain/entity/structures/requirementManagement/DrsItem";
import {ArsItem} from "../../../domain/entity/structures/requirementManagement/ArsItem";
import {getFormattedDate} from "../../../utils/utils";

interface Props {
    isDialogOpen: boolean
    rs: DrsItem | ArsItem
    onBack: () => void
}

const RsInfoDialog = (props: Props) => {

    function onBackButtonClick() {
        props.onBack()
    }

    return (
        <Dialog open={props.isDialogOpen} onClose={props.onBack}>
            <DialogTitle>{props.rs.name}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1} item xs={12} alignContent={"flex-start"}>
                    <Grid container spacing={1} item xs={8}>
                        <Grid item xs={12}>
                            <BodyText>Description: {props.rs.description}</BodyText>
                        </Grid>
                        <Grid item xs={12}>
                            <BodyText>Logical statements:</BodyText>
                        </Grid>
                        {
                            props.rs.logic_statements.map((item) => (
                                <Grid item xs={12} key={props.rs.logic_statements.indexOf(item)}>
                                    <BodyText>{item}</BodyText>
                                </Grid>
                            ))
                        }
                        <Grid item xs={12}>
                            <BodyText>Updated by: {props.rs.updated_by}</BodyText>
                        </Grid>
                        <Grid item xs={12}>
                            <BodyText>Update time: {getFormattedDate(props.rs.updatedAt)}</BodyText>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} item xs={4}>
                        <Grid item>
                            <BodyText>State: {props.rs.state}</BodyText>
                        </Grid>
                        <Grid item>
                            <BodyText>Criticality: {props.rs.criticality}</BodyText>
                        </Grid>
                        <Grid item>
                            <BodyText>Complexity: {props.rs.complexity}</BodyText>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBackButtonClick}>Back</Button>
            </DialogActions>
        </Dialog>
    )
}

export default RsInfoDialog;