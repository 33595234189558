import React from 'react';
import Helmet from "react-helmet";
import PageTitle from "../../components/shared/PageTitle";

const HomePage = () => {
    return (
        <>
            <Helmet title={"Home"}/>
            <PageTitle>
                Home
            </PageTitle>
        </>
    )
};

export default HomePage;