import React, {useContext, useEffect, useState} from 'react';
import Helmet from "react-helmet";
import {useNavigate} from "react-router-dom";
import {Context} from "../../index";
import {CREATE_PROJECT_ROUTE, UPDATE_PROJECT_ROUTE} from "../../utils/consts";
import PageTitle from "../../components/shared/PageTitle";
import {Button, Grid} from '@mui/material';
import {allProjects} from "../../http/requirementManagementAPI";
import {Project} from "../../domain/entity/structures/requirementManagement/Project";
import ObjectDropDown from "../../components/shared/ObjectDropDown";
import BodyText from "../../components/shared/BodyText";

const RequirementManagementPage = () => {
    let navigate = useNavigate();
    const {appStore} = useContext(Context)
    const [projects, setProjects] = useState<Project[]>([]);

    useEffect(() => {
        appStore.addLoading()
        allProjects().then((response) => {
            const projects: Project[] = response.data;
            if (projects.length === 0)
                appStore.showAppSnackBar("info", "No project found")
            setProjects(projects)
        }).finally(() => appStore.removeLoading())

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const title = "Analysis Context";

    function onCreateProjectButtonClick() {
        navigate(CREATE_PROJECT_ROUTE);
    }

    function onSelected(item) {
        navigate(UPDATE_PROJECT_ROUTE + "/" + item.id);
    }

    return (
        <>
            <Helmet title={title}/>
            <PageTitle>
                {title}
            </PageTitle>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Button variant="text"
                            onClick={onCreateProjectButtonClick}>
                        Create a new project
                    </Button>
                </Grid>
                <Grid container item xs={12} sm={6} spacing={1}>
                    <Grid container item xs={6} justifyContent={"flex-end"} alignItems={"center"}>
                        <BodyText>
                            Select a project for review and update:
                        </BodyText>
                    </Grid>
                    <Grid item xs={6}>
                        <ObjectDropDown items={projects.map((item) => {
                            return {id: item.project_id, name: item.name}
                        })}
                                        selectedItem={'' as any}
                                        onSelectionChange={onSelected}
                                        inputLabel={"Projects"}/>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default RequirementManagementPage