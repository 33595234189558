import HomePage from "./pages/shared/HomePage"
import {
    AI_LAB_ROUTE,
    CREATE_PROJECT_ROUTE,
    DATA_FLOW_MANAGEMENT_ROUTE,
    DATA_VISUALISATION_ROUTE,
    EXECUTE_MODELS_ROUTE,
    EXECUTION_MANAGEMENT_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    HISTORICAL_DATA_ANALYSIS_DASHBOARD_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE,
    MANAGE_SCENARIOS_ROUTE,
    REQUIREMENT_MANAGEMENT_ROUTE,
    RESET_PASSWORD_ROUTE,
    UPDATE_PROJECT_ROUTE,
    USER_MANAGEMENT_ROUTE,
    USER_ROUTE
} from "./utils/consts"
import DataFlowManagement from "./pages/administrator/dataFlowManagement/DataFlowManagement";
import HistoricalDashboardPage from "./pages/dataVisualisation/HistoricalDashboardPage";
import LoginPage from "./pages/administrator/userManagement/LoginPage";
import UserManagementPage from "./pages/administrator/userManagement/UserManagementPage";
import UserPage from "./pages/administrator/userManagement/UserPage";
import ForgotPasswordPage from "./pages/administrator/userManagement/ForgotPasswordPage";
import ResetPasswordPage from "./pages/administrator/userManagement/ResetPasswordPage";
import DataVisualisationPage from "./pages/dataVisualisation/DataVisualisationPage";
import RequirementManagementPage from "./pages/requirementManagement/RequirementManagementPage";
import ProjectCreatePage from "./pages/requirementManagement/ProjectCreatePage";
import ProjectUpdatePage from "./pages/requirementManagement/ProjectUpdatePage";
import ManageScenariosPage from "./pages/dataProcessing/ManageScenariosPage";
import ExecuteModelsPage from "./pages/dataProcessing/ExecuteModelsPage";
import AiLabManagementPage from "./pages/aiLab/AiLabManagementPage";
import ExecutionManagementPage from "./pages/aiLab/ExecutionManagementPage";

export const authRoutes = [
    {
        path: DATA_FLOW_MANAGEMENT_ROUTE,
        Component: DataFlowManagement
    },
    {
        path: HISTORICAL_DATA_ANALYSIS_DASHBOARD_ROUTE + '/:id',
        Component: HistoricalDashboardPage
    },
    {
        path: HOME_ROUTE,
        Component: HomePage
    },
    {
        path: DATA_VISUALISATION_ROUTE,
        Component: DataVisualisationPage
    },
    {
        path: REQUIREMENT_MANAGEMENT_ROUTE + '/:id',
        Component: RequirementManagementPage
    },
    {
        path: REQUIREMENT_MANAGEMENT_ROUTE,
        Component: RequirementManagementPage
    },
    {
        path: CREATE_PROJECT_ROUTE,
        Component: ProjectCreatePage
    },
    {
        path: UPDATE_PROJECT_ROUTE + '/:id',
        Component: ProjectUpdatePage
    },
    {
        path: USER_MANAGEMENT_ROUTE,
        Component: UserManagementPage
    },
    {
        path: MANAGE_SCENARIOS_ROUTE + '/:id',
        Component: ManageScenariosPage
    },
    {
        path: MANAGE_SCENARIOS_ROUTE,
        Component: ManageScenariosPage
    },
    {
        path: EXECUTE_MODELS_ROUTE + '/:id',
        Component: ExecuteModelsPage
    },
    {
        path: EXECUTE_MODELS_ROUTE,
        Component: ExecuteModelsPage
    },
    {
        path: AI_LAB_ROUTE + '/:id',
        Component: AiLabManagementPage
    },
    {
        path: AI_LAB_ROUTE,
        Component: AiLabManagementPage
    },
    {
        path: EXECUTION_MANAGEMENT_ROUTE,
        Component: ExecutionManagementPage
    },
]
export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: LoginPage
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        Component: ForgotPasswordPage
    },
    {
        path: RESET_PASSWORD_ROUTE + '/:code',
        Component: ResetPasswordPage
    },
    {
        path: USER_ROUTE + '/:id',
        Component: UserPage
    },
]