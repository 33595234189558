import React from 'react';
import ConditionSelector from "../../../domain/entity/structures/dataVisualisation/ConditionSelector";
import PredefinedValuesMultiselectDropDown from "./PredefinedValuesMultiselectDropDown";
import SelectorEnum from "../../../domain/entity/structures/dataVisualisation/SelectorEnum";

interface SelectorEnumWidgetProps {
    selector: ConditionSelector
    refresh: () => void
}

const SelectorEnumWidget = ({refresh, selector}: SelectorEnumWidgetProps) => {
    function handleChange(selectedValues: string[]) {
        (selector.selector as SelectorEnum).selectedValues = selectedValues;
        refresh()
    };

    return (
        <>
            {
                <PredefinedValuesMultiselectDropDown values={selector.dataFilter.definedValues}
                                                     selectedValues={(selector.selector as SelectorEnum).selectedValues}
                                                     key={"enum-selector-value-" + selector.dataFilter.parameterName}
                                                     onSelectionChange={handleChange}/>
            }
        </>
    );
};

export default SelectorEnumWidget;