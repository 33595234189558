import React, {useState} from 'react';
import {
    Button,
    Checkbox,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import {DATE_FORMAT, HEADER_FOR_DARK_BACKGROUND} from "../../../utils/consts";
import moment from "moment";
import {ScenarioFlow} from "../../../domain/entity/structures/dataProcessing/ScenarioFlow";
import OrderSelect from "./OrderSelect";

interface ScenariosTableProps {
    data: ScenarioFlow[]
    onRemove: (scenarios: ScenarioFlow[]) => void
    onOpen: (scenario: ScenarioFlow) => void
    onOrderChange: () => void
}

const ScenariosTable = (props: ScenariosTableProps) => {

    const [selectedItems, setSelectedItems] = useState<ScenarioFlow[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event: React.MouseEvent<unknown>, item: ScenarioFlow) => {
        event.stopPropagation()
        const currentIndex = selectedItems.indexOf(item);

        if (currentIndex === -1) {
            selectedItems.push(item);
        } else {
            selectedItems.splice(currentIndex, 1);
        }
        setSelectedItems(selectedItems.slice())
    };

    const handleRowClick = (event: React.MouseEvent<unknown>, item: ScenarioFlow) => {
        props.onOpen(item)
    };

    function onRemoveButtonClick() {
        props.onRemove(selectedItems)
        setSelectedItems([])
        setPage(0)
    }

    return (
        <TableContainer sx={{minHeight: 300}}>
            <Table stickyHeader sx={{minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{
                            backgroundColor: "lightgray",
                            border: 1,
                            color: HEADER_FOR_DARK_BACKGROUND
                        }}>DRS Item Name</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Scenario Name</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Order</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Version</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Updated time</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">
                            <Button variant="outlined"
                                    sx={{color: HEADER_FOR_DARK_BACKGROUND}}
                                    onClick={onRemoveButtonClick}>
                                Remove
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                            ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : props.data
                    ).map((row) => {
                            const isItemSelected = selectedItems.indexOf(row) !== -1;
                            const labelId = `rs-table-checkbox-${props.data.indexOf(row)}`;

                            function onOrderChange(value: number) {
                                const oldValue: number = row.order
                                row.order = value
                                props.data[value - 1].order = oldValue
                                props.onOrderChange()
                            }

                            return (
                                <TableRow
                                    key={props.data.indexOf(row) + '-' + row.scenario_id}
                                    sx={{
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: "ghostwhite",
                                        }
                                    }}
                                    hover
                                    onClick={(event) => handleRowClick(event, row)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                >
                                    <TableCell component="th" scope="row" sx={{border: 1}}>
                                        {row.drs_name}
                                    </TableCell>
                                    <TableCell align="center" sx={{border: 1}}>
                                        <Link sx={{cursor: "pointer"}}>{row.scenario_name}</Link>
                                    </TableCell>
                                    <TableCell align="center"
                                               onClick={(e) => e.stopPropagation()}
                                               sx={{border: 1}}>
                                        <OrderSelect value={row.order} range={props.data.length} onChange={onOrderChange}/>
                                    </TableCell>
                                    <TableCell align="center" sx={{border: 1}}>{row.version}</TableCell>
                                    <TableCell align="center"
                                               sx={{border: 1}}>{moment.unix(row.update_time).format(DATE_FORMAT)}</TableCell>
                                    <TableCell padding="checkbox" align="center" sx={{border: 1}}>
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            onClick={(event) => handleClick(event, row)}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    )
                    }
                    {emptyRows > 0 && (
                        <TableRow style={{height: 34 * emptyRows}}>
                            <TableCell colSpan={8}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={props.data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default ScenariosTable;