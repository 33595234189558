import React from 'react';
import {TextField} from "@mui/material";
import {Project} from "../../domain/entity/structures/requirementManagement/Project";

interface ProjectPropertiesProps {
    project: Project
    setProject: (project: Project) => void
}

const ProjectProperties = (props: ProjectPropertiesProps) => {

    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target;
        const name = target.name;
        props.setProject({...props.project, [name]: target.value});
    }


    return (
        <>
            <TextField
                autoFocus
                margin="dense"
                id="project-name"
                label="Name"
                type="text"
                // error={props.project.name === ''}
                fullWidth
                required
                variant="outlined"
                inputProps={{maxLength: 50}}
                name='name'
                value={props.project.name}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id="project-description"
                label="Description"
                type="text"
                fullWidth
                multiline
                rows={2}
                inputProps={{maxLength: 500}}
                variant="outlined"
                name='description'
                value={props.project.description}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id="project-buisness-unit"
                label="Business unit"
                type="text"
                // error={props.project.business_unit === ''}
                fullWidth
                required
                variant="outlined"
                name='business_unit'
                inputProps={{maxLength: 50}}
                value={props.project.business_unit}
                onChange={onFieldChange}
            />
            <TextField
                margin="dense"
                id="project-buisness-owner"
                label="Business owner"
                type="text"
                // error={props.project.business_owner === ''}
                fullWidth
                required
                inputProps={{maxLength: 50}}
                variant="outlined"
                name='business_owner'
                value={props.project.business_owner}
                onChange={onFieldChange}
            />
        </>
    );
};

export default ProjectProperties;