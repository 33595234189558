import {isLongStringValueValid, isNameValidGeneral} from "../../../utils/validationUtils";
import AppStore from "../../../store/AppStore";
import {ScenarioFlow} from "../../../domain/entity/structures/dataProcessing/ScenarioFlow";

export function isScenarioValid(value: ScenarioFlow, appStore: AppStore) {
    if (value.flow_id === '') {
        appStore.showAppSnackBar("error",
            "Flow is not selected")
        return false
    }

    const nameValidationResult = isNameValidGeneral(value.scenario_name, true, 200);
    if (nameValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Scenario name has validation error with message: " + nameValidationResult)
        return false
    }
    if (value.scenario_description) {
        const validationResult = isLongStringValueValid(value.scenario_description, false, 3000);
        if (validationResult !== '') {
            appStore.showAppSnackBar("error",
                "Scenario description has validation error with message: " + validationResult)
            return false
        }
    }
    if (value.status === '') {
        appStore.showAppSnackBar("error",
            "Status is empty")
        return false
    }
    const modelNameValidationResult = isNameValidGeneral(value.model_name, true, 200);
    if (modelNameValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Model name has validation error with message: " + modelNameValidationResult)
        return false
    }
    if (value.model_description) {
        const validationResult = isLongStringValueValid(value.model_description, false, 3000);
        if (validationResult !== '') {
            appStore.showAppSnackBar("error",
                "Model description has validation error with message: " + validationResult)
            return false
        }
    }
    const modelScriptValidationResult = isLongStringValueValid(value.model_name, true, 3000);
    if (modelScriptValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Model script has validation error with message: " + modelScriptValidationResult)
        return false
    }
    if (value.rule_name) {
        const ruleNameValidationResult = isNameValidGeneral(value.rule_name, false, 200);
        if (ruleNameValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Rule name has validation error with message: " + ruleNameValidationResult)
            return false
        }
    }
    if (value.rule_description) {
        const validationResult = isLongStringValueValid(value.rule_description, false, 3000);
        if (validationResult !== '') {
            appStore.showAppSnackBar("error",
                "Rule description has validation error with message: " + validationResult)
            return false
        }
    }
    if (value.rule_script) {
        const ruleScriptValidationResult = isLongStringValueValid(value.rule_script, false, 3000);
        if (ruleScriptValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Rule script has validation error with message: " + ruleScriptValidationResult)
            return false
        }
    }
    return true
}

export function transformScenarioData(value: any) {
    return {
        ...value,
        status: value.status === "release" ? "Release" : "Testing",
        scenario_name: value.name,
        scenario_description: value.description === null ? '' : value.description,
        model_description: value.model_description ? value.model_description : '',
        model_name: value.model_name ? value.model_name : '',
        rule_description: value.rule_description === null ? '' : value.rule_description,
        rule_name: value.rule_name === null ? '' : value.rule_name,
        supporting_files: [],
        supporting_file_records: value.supporting_file_records === undefined ? [] : value.supporting_file_records,
    }
}

export function makePayloadFromScenario(value: ScenarioFlow) {
    return {
        ...value,
        status: value.status.toLowerCase(),
    }
}