import React, {useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import moment from "moment";
import {DATE_FORMAT, HEADER_FOR_DARK_BACKGROUND} from "../../../../utils/consts";
import {ExecutedJob} from "../../../../domain/entity/structures/aiLab/ExecutedJob";
import {ScheduleTypeEnum} from "../../../../domain/entity/structures/aiLab/ScheduleTypeEnum";
import {JobTypeEnum} from "../../../../domain/entity/structures/aiLab/JobTypeEnum";

interface Props {
    data: ExecutedJob[]
}

const ExecutedJobsTable = (props: Props) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.data.length) : 0;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer sx={{minHeight: 300}}>
            <Table stickyHeader sx={{minWidth: 650}} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}>
                            Flow group</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}>
                            AI Model</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Job type</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Job status</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Error message</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Execution time</TableCell>
                        <TableCell sx={{backgroundColor: "lightgray", border: 1, color: HEADER_FOR_DARK_BACKGROUND}}
                                   align="center">Executed by</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                            ? props.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : props.data
                    ).map((row) => {
                        function getJobTypeValue() {
                            if (row.scheduleType) {
                                if (row.scheduleType === ScheduleTypeEnum.oneOffFuture)
                                    return "Scheduled One-off"
                                if (row.scheduleType === ScheduleTypeEnum.oneOffNow)
                                    return "Manual Execution"
                                if (row.scheduleType === ScheduleTypeEnum.oneOffValidate)
                                    return "Validation"
                                if (row.scheduleType === ScheduleTypeEnum.oneOffRerun)
                                    return "Rerun"
                                if (row.scheduleType === ScheduleTypeEnum.recurring)
                                    return "Scheduled Recurring"
                            } else {
                                if (row.jobType === JobTypeEnum.AUTO)
                                    return "AUTO"
                            }
                            return "Undefined";
                        }

                        return (
                            <TableRow
                                key={props.data.indexOf(row) + '-' + props.data.indexOf(row)}
                                sx={{
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: "ghostwhite",
                                    }
                                }}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                >
                                    <TableCell align="center" sx={{border: 1}}>{row.flowGroupName}</TableCell>
                                <TableCell align="center" sx={{border: 1}}>{row.aiModelName}</TableCell>
                                <TableCell align="center" sx={{border: 1}}>{getJobTypeValue()}</TableCell>
                                <TableCell align="center" sx={{border: 1}}>{row.jobStatus}</TableCell>
                                    <TableCell align="center" sx={{border: 1}}>{row.error ? row.error : ""}</TableCell>
                                    <TableCell align="center"
                                               sx={{border: 1}}>{moment.unix(row.executionTime).format(DATE_FORMAT)}</TableCell>
                                    <TableCell align="center" sx={{border: 1}}>{row.executedBy}</TableCell>
                                </TableRow>
                            )
                        }
                    )
                    }
                    {emptyRows > 0 && (
                        <TableRow style={{height: 34 * emptyRows}}>
                            <TableCell colSpan={8}/>
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={props.data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

export default ExecutedJobsTable;