import React, {useContext, useState} from 'react';
import {
    AI_LAB_ROUTE,
    DATA_FLOW_MANAGEMENT_ROUTE,
    DATA_VISUALISATION_ROUTE,
    EXECUTE_MODELS_ROUTE,
    EXECUTION_MANAGEMENT_ROUTE,
    HOME_ROUTE,
    LOGIN_ROUTE,
    MANAGE_SCENARIOS_ROUTE,
    REQUIREMENT_MANAGEMENT_ROUTE,
    USER_MANAGEMENT_ROUTE,
    USER_ROUTE
} from '../../utils/consts';
import {AppBar, Box, Button, Divider, IconButton, Menu, MenuItem, Toolbar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import DropdownMenuButton from "./DropdownMenuButton";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {ActiveUser} from "../../domain/entity/structures/adminisrator/userManagement/ActiveUser";
import {logout} from "../../http/userAPI";
import PersonIcon from '@mui/icons-material/Person';

const NavBar = observer(() => {
    const {userStore, appStore} = useContext(Context)
    const [anchorElAdministration, setAnchorElAdministration] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [anchorElDataProcessing, setAnchorElDataProcessing] = useState<null | HTMLElement>(null);
    const [anchorElAiLab, setAnchorElAiLab] = useState<null | HTMLElement>(null);

    const handleMenuAdministration = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElAdministration(event.currentTarget);
    };

    const handleCloseAdministration = () => {
        setAnchorElAdministration(null);
    };

    let navigate = useNavigate();
    const onDataFlowManagementClick = () => {
        setAnchorElAdministration(null);
        navigate(DATA_FLOW_MANAGEMENT_ROUTE);
    };

    function onUserManagementClick() {
        setAnchorElAdministration(null);
        navigate(USER_MANAGEMENT_ROUTE);
    }

    const handleHome = () => {
        setAnchorElAdministration(null);
        navigate(HOME_ROUTE);
    };

    function onLogin() {
        navigate(LOGIN_ROUTE);
    }

    function onLogout() {
        setAnchorElUser(null);
        appStore.addLoading()
        logout().then(() => {
            appStore.showAppSnackBar("success", "Success")
        }).finally(() => {
            appStore.removeLoading();
            userStore.setIsAuth(false)
            userStore.setUser({} as ActiveUser)
            localStorage.removeItem('token')
            navigate(LOGIN_ROUTE);
        })
    }

    function handleMenuCloseUser() {
        setAnchorElUser(null);
    }

    function onUserSettingsClick() {
        setAnchorElUser(null);
        navigate(USER_ROUTE + "/current");
    }

    const handleMenuUser = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    function handleDataVisualisation() {
        navigate(DATA_VISUALISATION_ROUTE);
    }

    function handleRequirementManagement() {
        navigate(REQUIREMENT_MANAGEMENT_ROUTE);
    }

    const handleMenuDataProcessing = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElDataProcessing(event.currentTarget);
    };

    const handleMenuAiLab = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElAiLab(event.currentTarget);
    };

    function handleCloseDataProcessing() {
        setAnchorElDataProcessing(null);
    }

    function onManageScenariosClick() {
        setAnchorElDataProcessing(null);
        navigate(MANAGE_SCENARIOS_ROUTE);
    }

    function onExecuteModelsClick() {
        setAnchorElDataProcessing(null);
        navigate(EXECUTE_MODELS_ROUTE);
    }

    function onManageAiModelsClick() {
        setAnchorElAiLab(null);
        navigate(AI_LAB_ROUTE);
    }

    function onExecutionManagementClick() {
        setAnchorElAiLab(null);
        navigate(EXECUTION_MANAGEMENT_ROUTE);
    }

    function handleCloseAiModels() {
        setAnchorElAiLab(null);
    }

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position="static">
                <Toolbar sx={{backgroundColor: "white", color: "black"}}>
                    <img src={"/favicon-32x32.png"} alt={"logo"}/>
                    {/*HOME*/}
                    <Button sx={{textTransform: "none !important"}}
                            onClick={handleHome}
                            disabled={!userStore.isAuth}
                            color="inherit">Home</Button>
                    <Button sx={{textTransform: "none !important"}}
                            disabled={!userStore.isAuth}
                            onClick={handleRequirementManagement}
                            color="inherit">Analysis Context</Button>
                    {/*DATA PROCESSING*/}
                    <DropdownMenuButton
                        disabled={!userStore.isAuth}
                        onClick={handleMenuDataProcessing}
                        text={"Data Processing"}
                    />
                    <Menu
                        id="data-processing-menu-appbar"
                        anchorEl={anchorElDataProcessing}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElDataProcessing)}
                        onClose={handleCloseDataProcessing}
                    >
                        <MenuItem onClick={onManageScenariosClick}>Manage scenarios</MenuItem>
                        <MenuItem onClick={onExecuteModelsClick}>Execute models</MenuItem>
                    </Menu>
                    {/*AI LAB*/}
                    <DropdownMenuButton
                        disabled={!userStore.isAuth}
                        onClick={handleMenuAiLab}
                        text={"AI Lab"}
                    />
                    <Menu
                        id="data-processing-menu-appbar"
                        anchorEl={anchorElAiLab}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElAiLab)}
                        onClose={handleCloseAiModels}
                    >
                        <MenuItem onClick={onManageAiModelsClick}>Manage AI models</MenuItem>
                        <MenuItem onClick={onExecutionManagementClick}>Execution Management</MenuItem>
                    </Menu>
                    <Button sx={{textTransform: "none !important"}}
                            disabled={!userStore.isAuth}
                            onClick={handleDataVisualisation}
                            color="inherit">Data visualisation</Button>
                    {/*ADMINISTRATION*/}
                    <DropdownMenuButton
                        disabled={!userStore.isAuth}
                        onClick={handleMenuAdministration}
                        text={"Administration"}
                    />
                    <Menu
                        id="administration-menu-appbar"
                        anchorEl={anchorElAdministration}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElAdministration)}
                        onClose={handleCloseAdministration}
                    >
                        <MenuItem onClick={onDataFlowManagementClick}>Data Flow Management</MenuItem>
                        <MenuItem disabled={!userStore.isAdministrator()} onClick={onUserManagementClick}>User
                            Management</MenuItem>
                    </Menu>
                    {/*HELP*/}
                    <Button sx={{textTransform: "none !important"}} disabled color="inherit">Help</Button>
                    {/*LOGIN*/}
                    {userStore.isAuth ?
                        <>
                            <IconButton aria-label="person"
                                        sx={{marginLeft: "auto"}}
                                        onClick={handleMenuUser}>
                                <PersonIcon/>
                            </IconButton>
                            <Menu
                                id="user-menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleMenuCloseUser}
                            >
                                <MenuItem disabled>Logged in as {userStore.user.loginId}</MenuItem>
                                <Divider/>
                                <MenuItem onClick={onUserSettingsClick}>Profile</MenuItem>
                                <MenuItem onClick={onLogout}>Logout</MenuItem>
                            </Menu>
                        </>
                        :
                        <Button sx={{textTransform: "none !important", marginLeft: "auto"}}
                                onClick={onLogin}
                                color="inherit">Login</Button>
                    }
                </Toolbar>
            </AppBar>
        </Box>
    );
})

export default NavBar;