import React, {useContext, useEffect, useState} from 'react';
import {Button, InputLabel} from "@mui/material";
import {AiModelFlow} from "../../../../domain/entity/structures/aiLab/AiModelFlow";
import {FlowGroup} from "../../../../domain/entity/structures/aiLab/FlowGroup";
import CreateFlowGroupDialog from "./CreateFlowGroupDialog";
import {allFlowGroups} from "../../../../http/flowsAPI";
import {Context} from "../../../../index";
import {flowGroupsInputAdapter} from "../AiModelUtils";
import ObjectDropDown from "../../../shared/ObjectDropDown";
import FlowsList from "../FlowsList";

interface Props {
    record: AiModelFlow
    onUpdate: (value: AiModelFlow) => void
}

const SelectCreateFlowGroupWidget = (props: Props) => {
    const {appStore} = useContext(Context)
    const [flowGroups, setFlowGroups] = useState<FlowGroup[]>([]);
    const [createFlowGroupDialogOpen, setCreateFlowGroupDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        appStore.addLoading()
        allFlowGroups().then(response => {
            setFlowGroups(flowGroupsInputAdapter(response.data));
        }).finally(() => {
            appStore.removeLoading();
        })
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSelectionChange(selectedFlowGroup: FlowGroup) {
        props.onUpdate({...props.record, flow_group: selectedFlowGroup})
    }

    function onCreateGroupButtonClick() {
        setCreateFlowGroupDialogOpen(true)
    }

    function onCreateFlowGroupDialogClose() {
        setCreateFlowGroupDialogOpen(false)
    }

    function onCreateFlowGroup(id: string) {
        appStore.addLoading()
        allFlowGroups().then(response => {
            const groups: FlowGroup[] = flowGroupsInputAdapter(response.data);
            setFlowGroups(groups);
            const group = groups.find(e => e.id === id);
            if (group) {
                handleSelectionChange(group)
            }
        }).finally(() => {
            appStore.removeLoading();
        })
    }

    return (
        <>
            <ObjectDropDown items={flowGroups}
                            variant={"standard"}
                            required={true}
                            selectedItem={props.record.flow_group}
                            onSelectionChange={handleSelectionChange}
                            inputLabel={"Flow groups"}/>
            {
                props.record.flow_group.flows.length > 0 &&
                <FlowsList flows={props.record.flow_group.flows} title={"Selected Flow Group:"}/>
            }
            <InputLabel>Could not find the group?</InputLabel>
            <Button variant="text"
                    onClick={onCreateGroupButtonClick}>
                Create a new group
            </Button>
            {
                createFlowGroupDialogOpen &&
                <CreateFlowGroupDialog
                    onClose={onCreateFlowGroupDialogClose}
                    onCreateFlowGroup={onCreateFlowGroup}
                    existingGroups={flowGroups}
                />
            }
        </>
    );
};

export default SelectCreateFlowGroupWidget;