import React from 'react';
import ConditionSelector from "../../../domain/entity/structures/dataVisualisation/ConditionSelector";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@mui/material";
import SelectorString from "../../../domain/entity/structures/dataVisualisation/SelectorString";
import {SelectorStringType} from "../../../domain/entity/structures/dataVisualisation/SelectorStringType";

interface SelectorStringWidgetProps {
    selector: ConditionSelector
    refresh: () => void
}

const SelectorStringWidget = ({refresh, selector}: SelectorStringWidgetProps) => {
    function onFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
        (selector.selector as SelectorString).value = event.target.value;
        refresh()
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        (selector.selector as SelectorString).type = event.target.value;
        refresh()
    };

    return (
        <>
            <TextField
                margin="dense"
                id={"string-selector-value-" + selector.dataFilter.parameterName}
                key={"string-selector-value-" + selector.dataFilter.parameterName}
                type="text"
                fullWidth
                variant="outlined"
                name={'value'}
                value={(selector.selector as SelectorString).value}
                onChange={onFieldChange}
            />
            <RadioGroup
                row
                name={"string-selector-radio-buttons-group-" + selector.dataFilter.parameterName}
                value={(selector.selector as SelectorString).type}
                onChange={handleChange}
            >
                <FormControlLabel value={SelectorStringType.contains} control={<Radio/>} label="contains"/>
                <FormControlLabel value={SelectorStringType.starts} control={<Radio/>} label="starts with"/>
            </RadioGroup>
        </>
    );
};

export default SelectorStringWidget;