import {ScheduledJob} from "../../../../domain/entity/structures/aiLab/ScheduledJob";
import {idNameValuesFromObject} from "../../../../utils/utils";
import {ExecutedJob} from "../../../../domain/entity/structures/aiLab/ExecutedJob";


export function validateJobOutputAdapter(value: ScheduledJob) {
    return {
        flow_group_id: value.flowGroup.id,
        ai_model_ids: value.aiModels.map(e => e.id),
    }
}

export function createJobOutputAdapter(value: ScheduledJob) {
    return {
        flow_group_id: value.flowGroup.id,
        ai_model_ids: value.aiModels.map(e => e.id),
        schedule_type: value.scheduleType,
        execution_time: value.executionTime,
        recur_data: {
            frequency: value.frequency === "" ? null : value.frequency,
            day_of_week: value.dayOfWeek === "" ? null : value.dayOfWeek.toLowerCase(),
            day_of_month: value.dayOfMonth,
            interval: value.interval,
            minutes: value.minutes,
            start_date: value.startDate,
            end_date: value.endDate,
        },
    }
}

export function scheduleJobInputAdapter(data) {
    return data.map((item) =>
        ({
            id: item.schedule_id,
            aiModels: idNameValuesFromObject(item.ai_models),
            createTime: item.create_time,
            createdBy: item.created_by,
            dayOfWeek: item.day_of_week,
            dayOfMonth: item.day_of_month,
            endDate: item.end_date,
            executionTime: item.execution_time,
            flowGroup: {
                flows: idNameValuesFromObject(item.flows),
            },
            frequency: item.frequency,
            interval: item.interval,
            jobId: item.job_id,
            scheduleType: item.schedule_type,
        } as ScheduledJob));
}

export function executedJobInputAdapter(data) {
    return data.map((item) =>
        ({
            jobId: item.job_id,
            flowGroupName: item.flow_group_name,
            aiModelName: item.ai_model_name,
            jobType: item.job_type,
            jobStatus: item.job_status,
            scheduleType: item.schedule_type,
            error: item.error,
            executionTime: item.execution_time,
            executedBy: item.user_name
        } as ExecutedJob));
}