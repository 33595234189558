import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Context} from "../../../index";
import BodyText from "../../shared/BodyText";
import Box from "@mui/material/Box";
import {Flow} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import {filteredFlow} from "../../../http/visualisationAPI";
import ObjectsIdNameMultiselectList from "./ObjectsIdNameMultiselectList";
import SelectProcessedData from "./SelectProcessedData";
import {DataObject} from "../../../domain/entity/structures/dataVisualisation/DataObject";
import {Expression} from "../../../domain/entity/structures/dataVisualisation/Expression";
import SelectRawData from "./SelectRawData";

interface Props {
    dialogOpen: boolean
    dialogTitle: string
    flowType: string
    onCancel: () => void
    onAddDataObject: (flowId: string, flowName: string, dataObjects: DataObject[]) => void
    onAddProcessedData: (flowId: string, flowName: string, expression: Expression) => void
    onAddRawData: (flowId: string, flowName: string, expression: Expression) => void
}

const SelectFlowDialog = (props: Props) => {
    const {appStore} = useContext(Context)
    const [flows, setFlows] = useState<Flow[]>([]);
    const [selectedFlows, setSelectedFlows] = useState<Flow[]>([]);
    const [selectProcessedDataOpen, setSelectProcessedDataOpen] = React.useState(false);
    const [selectRawDataOpen, setSelectRawDataOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.dialogOpen) {
            onShowResults()
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dialogOpen, appStore]);

    function onSelectProcessedData() {
        setSelectProcessedDataOpen(true)
    }

    function onSelectRawData() {
        setSelectRawDataOpen(true)
    }

    function onShowResults() {
        const expressionForSelectors = null as any;
        appStore.addLoading()
        filteredFlow(expressionForSelectors).then((response) => {
            const data = response.data as any
            setSelectedFlows([])
            flows.splice(0)
            for (let key in data) {
                flows.push({attributes: [], columns: [], id: key, name: data[key]} as Flow)
            }
            setFlows(flows.slice());
        }).catch((reason) => {
            appStore.showAppSnackBar("error", reason.response.data.detail)
        }).finally(() => appStore.removeLoading())
    }

    function refreshSelectedFlows() {
        setSelectedFlows(selectedFlows.slice())
    }

    function onSelectRawDataCancel() {
        setSelectRawDataOpen(false)
    }

    function onSelectProcessedDataCancel() {
        setSelectProcessedDataOpen(false)
    }

    function onFinish() {
        setSelectProcessedDataOpen(false)
        setSelectRawDataOpen(false)
        props.onCancel()
    }

    return (
        <>
            <Dialog open={props.dialogOpen} onClose={props.onCancel}>
                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <BodyText>
                        List of Flows
                    </BodyText>
                    <Box height={'300px'} sx={{overflowY: "auto", overflowX: "hidden"}}>
                        <ObjectsIdNameMultiselectList objs={flows}
                                                      selectedObjs={selectedFlows}
                                                      refresh={refreshSelectedFlows}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"text"} onClick={props.onCancel}>Close</Button>
                    <Button variant={"contained"}
                            disabled={selectedFlows.length === 0}
                            onClick={onSelectProcessedData}>Select processed data</Button>
                    <Button variant={"text"}
                            disabled={selectedFlows.length === 0}
                            onClick={onSelectRawData}>Select raw data</Button>
                </DialogActions>
            </Dialog>
            {
                selectProcessedDataOpen && <SelectProcessedData dialogOpen={selectProcessedDataOpen}
                                                                onAddProcessedData={props.onAddProcessedData}
                                                                onAddDataObject={props.onAddDataObject}
                                                                flows={selectedFlows}
                                                                onFinish={onFinish}
                                                                onCancel={onSelectProcessedDataCancel}/>
            }
            {
                selectRawDataOpen && <SelectRawData dialogOpen={selectRawDataOpen}
                                                    onAddRawData={props.onAddRawData}
                                                    flows={selectedFlows}
                                                    onFinish={onFinish}
                                                    onCancel={onSelectRawDataCancel}/>
            }
        </>
    );
};

export default SelectFlowDialog;