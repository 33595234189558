import {makeAutoObservable, runInAction} from "mobx";
import {Attribute} from "../domain/entity/structures/adminisrator/dataFlowManagement/Attribute";
import {Column} from "../domain/entity/structures/shared/Column";
import {Flow} from "../domain/entity/structures/adminisrator/dataFlowManagement/Flow";
import {FlowFilter} from "../domain/entity/structures/adminisrator/dataFlowManagement/FlowFilter";

export default class FlowStore {
    private _flows: Flow[];
    private _flowFilters: FlowFilter[];
    private _selectedAttributes: Attribute[];
    private _selectedFlowFilters: FlowFilter[];

    constructor() {
        this._flows = []
        this._flowFilters = []
        this._selectedAttributes = []
        this._selectedFlowFilters = []
        makeAutoObservable(this)
    }

    get flows(): Flow[] {
        return this._flows;
    }

    set flows(value: Flow[]) {
        this._flows = value;
    }

    set flowFilters(value: FlowFilter[]) {
        this._flowFilters = value;
    }

    get selectedAttributes(): Attribute[] {
        return this._selectedAttributes;
    }

    set selectedAttributes(value: Attribute[]) {
        this._selectedAttributes = value;
    }

    get selectedFlowFilters(): FlowFilter[] {
        return this._selectedFlowFilters;
    }

    set selectedFlowFilters(value: FlowFilter[]) {
        this._selectedFlowFilters = value;
    }

    setFlows(data: any[]) {
        this._flows = data.map(obj => {
            return {
                id: obj.id,
                name: obj.name,
                attributes: [],
                columns: []
            } as Flow
        })
    }

    setFlowFilters(data: any[]) {
        this.flowFilters = data.map(obj => {
            return {
                id: obj.id,
                parameterName: obj.parameter_name,
                variableType: obj.variable_type,
                flowId: obj.flow_id,
                definedValues: obj.defined_values
            } as FlowFilter
        })
        this.selectedFlowFilters = this.flowFilters.map(item => {
            return item
        })
    }

    get flowFilters(): FlowFilter[] {
        return this._flowFilters;
    }

    setFlowAttributes(id: string, data: any[]) {
        const flow: Flow = this.findFlowById(id)
        if (flow === null) return
        flow.attributes = data.map(obj => {
            return {
                attributeName: obj.attribute_name,
                variableType: obj.variable_type
            } as Attribute
        })
    }

    findFlowById(id: string): Flow {
        let result: Flow = null as any;
        this.flows.forEach((item) => {
            if (item.id === id) {
                result = item
            }
        })
        return result;
    }

    addSelectedAttributes() {
        function isNotYetAdded(attributeName: string, flowFilters: FlowFilter[]) {
            let result: boolean = true
            flowFilters.forEach(item => {
                if (item.parameterName === attributeName)
                    result = false
            })
            return result;
        }

        this.flows.forEach(flow => {
            flow.attributes.forEach(attr => {
                if (this.selectedAttributes.indexOf(attr) > -1 && isNotYetAdded(attr.attributeName, this.flowFilters)) {
                    let newFlow = {
                        id: null as any,
                        flowId: flow.id,
                        parameterName: attr.attributeName,
                        variableType: attr.variableType,
                        definedValues: []
                    } as FlowFilter;
                    this.flowFilters.push(newFlow)
                    this.flowFilters.forEach(item => {
                        if (item.variableType === attr.variableType && item.parameterName === attr.attributeName) {
                            this.selectedFlowFilters.push(item)
                        }
                    })
                }
            })
        })
        this.selectedAttributes = []
    }

    isSelectedAttributesAbsent() {
        let result: boolean = false
        runInAction(() => {
            result = this.selectedAttributes.length === 0
        })
        return result;
    }

    setSelectedFlowFilters(flowFilters: FlowFilter[]) {
        this._selectedFlowFilters = flowFilters
    }

    redrawFlowFilters() {
        this.flowFilters = this.flowFilters.slice()
    }

    removeFlowFiltersFromlist() {
        this.selectedFlowFilters.forEach(item => {
            let index = this.flowFilters.indexOf(item);
            this.flowFilters.splice(index, 1);
        })
        this.selectedFlowFilters = []
    }

    isSelectedFlowFiltersAbsent() {
        let result: boolean = false
        runInAction(() => {
            result = this.selectedFlowFilters.length === 0
        })
        return result;
    }

    setFlowColumns(id: string, data: Column[]) {
        const flow: Flow = this.findFlowById(id)
        if (flow === null) return
        flow.columns = data
    }

}