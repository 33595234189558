import React, {useContext} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {authRoutes, publicRoutes} from '../../routes';
import {LOGIN_ROUTE} from '../../utils/consts';
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const AppRouter = observer(() => {
    const {userStore} = useContext(Context)
    return (
        <Routes>
            {
                userStore.isAuth &&
                authRoutes.map(({path, Component}) => (
                        <Route key={path} path={path} element={<Component/>}/>
                    )
                )
            }
            {
                publicRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} element={<Component/>}/>
                )
            }
            <Route path="*" element={<Navigate replace to={LOGIN_ROUTE}/>}/>
        </Routes>
    )
})

export default AppRouter;