import React, {ChangeEvent} from "react";

interface Props {
    processResult: (files: FileList) => void
    keyPart: string
    accept: string
    multiple?: boolean
    children?: React.ReactNode
}

const FileUploader = ({processResult, accept, keyPart, multiple, children}: Props) => {
    const showFile = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        let target = e.target;
        //TODO remove ts-ignore
        if (target !== null) {
            // @ts-ignore
            if (target.files.length > 0) {
                // @ts-ignore
                processResult(target.files)
            }
            target.value = ''
        }
    };

    return (
        <div style={{display: 'inline'}}>
            <input
                accept={accept}
                style={{display: 'none'}}
                id={"file-uploader-" + keyPart}
                type="file"
                onChange={showFile}
                multiple={multiple}
            />
            <label htmlFor={"file-uploader-" + keyPart}>
                {children}
            </label>
        </div>
    );
};

export default FileUploader