import {makeAutoObservable} from "mobx";
import {ActiveUser} from "../domain/entity/structures/adminisrator/userManagement/ActiveUser";
import {RolesEnum} from "../domain/entity/structures/requirementManagement/RolesEnum";

export default class UserStore {
    private _isAuth: boolean;
    private _user: ActiveUser;

    constructor() {
        this._isAuth = false
        this._user = {} as ActiveUser
        makeAutoObservable(this)
    }

    setIsAuth(value: boolean) {
        this._isAuth = value
    }

    setUser(value: ActiveUser) {
        this._user = value
    }


    get isAuth(): boolean {
        return this._isAuth;
    }

    get user(): ActiveUser {
        return this._user;
    }

    isAdministrator(): boolean {
        if (this.user.role === undefined)
            return false
        const find = this.user.role.find(item => item === RolesEnum.administrator);
        return find !== undefined
    }
}