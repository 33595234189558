import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import ObjectDropDown from "../shared/ObjectDropDown";
import BodyText from "../shared/BodyText";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";
import ObjectsIdNameMultiselectList from "../dataVisualisation/historicalDashboard/ObjectsIdNameMultiselectList";
import {Context} from "../../index";
import {allocateAiModels} from "../../http/dataProcessingAPI";

interface AllocateAiModelsDialogProps {
    isDialogOpen: boolean
    currentArsName: string
    rsList: IdNameListValues[]
    aiModelList: IdNameListValues[]
    onFinish: () => void
    onLoadAiModels: () => void
}

const AllocateAiModelsDialog = (props: AllocateAiModelsDialogProps) => {
    const {appStore} = useContext(Context)
    const [selectedAiModels, setSelectedAiModels] = useState<IdNameListValues[]>([]);
    const [rs, setRs] = useState<IdNameListValues | undefined>(undefined);

    function onUpdate() {
        if (selectedAiModels.length === 0) return
        if (rs === undefined) return

        appStore.addLoading()
        allocateAiModels({
            ai_model_ids: selectedAiModels.map((item) => {
                return item.id
            }),
            ars_id: rs.id
        }).then((response) => {
            appStore.showAppSnackBar("success", "AiModels have been allocated")
            props.onLoadAiModels()
        }).catch(reason => {
            appStore.showAppSnackBar("error", "AiModels allocation error")
        }).finally(() => appStore.removeLoading())
    }

    function onSelected(item: any) {
        setRs(item)
    }

    function onAiModelsSelecionChange() {
        setSelectedAiModels(selectedAiModels.slice())
    }

    return (
        <Dialog open={props.isDialogOpen} fullWidth onClose={props.onFinish}>
            <DialogTitle>{"Allocate AiModels"}</DialogTitle>
            <DialogContent>
                <BodyText>Current ARS: {props.currentArsName}</BodyText>
                <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                    <Grid item xs={6}>
                        <ObjectsIdNameMultiselectList objs={props.aiModelList}
                                                      selectedObjs={selectedAiModels}
                                                      refresh={onAiModelsSelecionChange}/>
                    </Grid>
                    <Grid item xs={6}>
                        <ObjectDropDown items={props.rsList}
                                        selectedItem={rs === undefined ? '' : rs}
                                        onSelectionChange={onSelected}
                                        inputLabel={"ARS Items"}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onFinish}>Finish</Button>
                <Button disabled={selectedAiModels.length === 0 || rs === undefined}
                        onClick={onUpdate}>Update</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AllocateAiModelsDialog;