import {$host, axiosConfigJson, axiosConfigMultipart} from './index';

export const getScenariosByDrs = async (drsId: string) => {
    return await $host.get('ui/getscenariosbydrs?drs_id=' + drsId)
}

export const allocateScenarios = async (payload: any) => {
    return await $host.post('ui/allocatescenarios', payload, axiosConfigJson)
}

export const getScenarioForReview = async (scenarioId: string) => {
    return await $host.get('ui/getscenarioforreview?scenario_id=' + scenarioId)
}

export const getAiModelsByArs = async (arsId: string) => {
    return await $host.get('ui/getaimodelsbyars?ars_id=' + arsId)
}

export const getAiModelForReview = async (aiModelId: string) => {
    return await $host.get('ui/getaimodelforreview?ai_model_id=' + aiModelId)
}

export const allocateAiModels = async (payload: any) => {
    return await $host.post('ui/allocateaimodels', payload, axiosConfigJson)
}

export const getScenariosByFlow = async (flowId: string) => {
    return await $host.get('ui/getscenariosbyflow?flow_id=' + flowId)
}

export const createScenario = async (payload: any) => {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
        if ([
            'flow_id',
            'model_name',
            'model_description',
            'model_script',
            'rule_name',
            'rule_description',
            'rule_script',
            'scenario_name',
            'drs_id',
            'status',
            'scenario_description',
        ].find(e => e === key))
            formData.append(key, payload[key])
    })
    for (const file of payload.supporting_files) {
        formData.append('supporting_files', file, file.name)
    }
    formData.append('supporting_file_records', JSON.stringify(payload.supporting_file_records))
    return await $host.post('ui/createscenario', formData, axiosConfigMultipart)
}

export const updateScenario = async (payload: any) => {
    const formData = new FormData();
    Object.keys(payload).forEach((key) => {
        if ([
            'scenario_id',
            'model_name',
            'model_description',
            'model_script',
            'rule_name',
            'rule_description',
            'rule_script',
            'scenario_name',
            'status',
            'scenario_description',
        ].find(e => e === key))
            formData.append(key, payload[key])
    })
    for (const file of payload.supporting_files) {
        formData.append('supporting_files', file, file.name)
    }
    formData.append('supporting_file_records', JSON.stringify(payload.supporting_file_records))
    return await $host.post('ui/updatescenario', formData, axiosConfigMultipart)
}

export const scriptTest = async (payload: any) => {
    const formData = new FormData();
    formData.append('model_script', payload.model_script)
    formData.append('rule_script', payload.rule_script)
    formData.append('flow_id', payload.flow_id)
    for (const file of payload.supporting_files) {
        formData.append('supporting_files', file, file.name)
    }
    formData.append('supporting_file_records', JSON.stringify(payload.supporting_file_records))
    return await $host.post('ui/script_test', formData, axiosConfigMultipart)
}

export const retrieveScenarioById = async (scenarioId: string) => {
    return await $host.get('ui/retrievescenariobyid?scenario_id=' + scenarioId)
}

export const getModel = async (scenarioId: string) => {
    return await $host.get('ui/getmodel?scenario_id=' + scenarioId)
}

export const getRule = async (scenarioId: string) => {
    return await $host.get('ui/getrule?scenario_id=' + scenarioId)
}

export const deleteaScenario = async (scenarioId: string) => {
    return await $host.delete('ui/deleteascenario?scenario_id=' + scenarioId)
}

export const updateScenariosByFlow = async (payload: any) => {
    return await $host.post('ui/updatescenariosbyflow', payload, axiosConfigJson)
}

export const processManual = async (payload: any) => {
    return await $host.post('ui/processmanual', payload, axiosConfigJson)
}
export const validateScenarios = async (payload: any) => {
    return await $host.post('ui/validatescenarios', payload, axiosConfigJson)
}
