import React from 'react';
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio} from "@mui/material";
import {Flow} from "../../../domain/entity/structures/adminisrator/dataFlowManagement/Flow";

interface FlowsListSingleSelectionProps {
    flows: Flow[]
    selectedFlow: Flow
    setSelected: (value: Flow) => void
}

const FlowsListSingleSelection = ({flows, selectedFlow, setSelected}: FlowsListSingleSelectionProps) => {

    const handleToggle = (value: Flow) => () => {
        setSelected(value)
    };

    return (
        <List sx={{width: '100%'}}>
            {
                flows.map((value) => {
                    const labelId = `flows-checkbox-list-label-${value.id}`;
                    return (
                        <ListItem
                            key={'flows-checkbox-' + value.id}
                            disablePadding
                        >
                            <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                <ListItemIcon>
                                    <Radio
                                        edge="start"
                                        checked={selectedFlow === value}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.name}/>
                            </ListItemButton>
                        </ListItem>
                    );
                })
            }
        </List>
    );
};

export default FlowsListSingleSelection;