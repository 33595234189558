import React from 'react';
import {Box, Grid} from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import LanguageIcon from '@mui/icons-material/Language';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MajorFooterText from "./MajorFooterText";
import MinorFooterText from "./MinorFooterText";
import {DARK_BACKGROUND} from "../../utils/consts";

const AppFooter = () => {
    return (
        <Box sx={{
            width: '100%',
            backgroundColor: DARK_BACKGROUND,
            height: "400px",
        }}>
            <Box sx={{
                backgroundImage: "url(/footer.png)",
                backgroundSize: "100%",
                width: '100%',
                left: 0,
                right: 0,
                position: "absolute",
                height: "400px",
                opacity: "0.21",
                overflow: "hidden"
            }}/>
            {/*<Container>*/}
            <Grid container textAlign={"center"} mt={2}>
                <Grid item xs={12}>
                    <img src={"/Telemars-Logo-with-Transparent-Background.png"}
                         alt={"TeleMARSLogo"}
                         width={"150px"}
                         height={"150px"}/>
                </Grid>
                <Grid item xs={4} container spacing={1}>
                    <Grid item xs={12}>
                        <MajorFooterText>
                            <LanguageIcon fontSize={"large"}/>
                        </MajorFooterText>
                    </Grid>
                    <Grid item xs={12}>
                        <MajorFooterText>
                            PO Box 328 Ashgrove QLD 4060
                        </MajorFooterText>
                    </Grid>
                    <Grid item xs={12}>
                        <MinorFooterText>
                            Our Address
                        </MinorFooterText>
                    </Grid>
                </Grid>
                <Grid item xs={4} container spacing={1}>
                    <Grid item xs={12}>
                        <MajorFooterText>
                            <MailIcon fontSize={"large"}/>
                        </MajorFooterText>
                    </Grid>
                    <Grid item xs={12}>
                        <MajorFooterText>
                            info@telemars.com.au
                        </MajorFooterText>
                    </Grid>
                    <Grid item xs={12}>
                        <MinorFooterText>
                            Our Mailbox
                        </MinorFooterText>
                    </Grid>
                </Grid>
                <Grid item xs={4} container spacing={1}>
                    <Grid item xs={12}>
                        <MajorFooterText>
                            <PhoneInTalkIcon fontSize={"large"}/>
                        </MajorFooterText>
                    </Grid>
                    <Grid item xs={12}>
                        <MajorFooterText>
                            +61 7 31865480
                        </MajorFooterText>
                    </Grid>
                    <Grid item xs={12}>
                        <MinorFooterText>
                            Our Phone
                        </MinorFooterText>
                    </Grid>
                </Grid>
                <Grid item xs={12} textAlign={"center"} mt={"30px"}>
                    <MinorFooterText>
                        Intellectual Property © 2022 TeleMARS Pty Ltd. All product intellectual property rights
                        reserved.
                    </MinorFooterText>
                </Grid>
            </Grid>
            {/*</Container>*/}
        </Box>
    );
};

export default AppFooter;