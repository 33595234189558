import React from 'react';
import {Legend, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer} from "recharts";
import {getColor, getColumnsList} from "../../../../utils/utils";

interface RadarChartWidgetProps {
    data: any[]
    id: string
}

const RadarChartWidget = (props: RadarChartWidgetProps) => {
    const columnsList = getColumnsList(props.data);
    return (
        <ResponsiveContainer height={300} id={props.id}>
            <RadarChart width={730} height={250} data={props.data}>
                <PolarGrid/>
                <PolarAngleAxis dataKey="subject"/>
                <PolarRadiusAxis domain={['auto', 'auto']}/>
                {
                    columnsList.map((item) => (
                        columnsList.indexOf(item) > 0 &&
                        <Radar dataKey={item}
                               name={item}
                               key={"radar-chart-" + columnsList.indexOf(item)}
                               stroke={getColor(columnsList.indexOf(item))}
                               fill={getColor(columnsList.indexOf(item))}
                               fillOpacity={0.6}/>
                    ))
                }
                <Legend/>
            </RadarChart>
        </ResponsiveContainer>
    );
};

export default RadarChartWidget;