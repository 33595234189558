import React, {useContext, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import {observer} from "mobx-react-lite";
import RsProperties from "./RsProperties";
import RsRightSide from "./RsRightSide";
import {Context} from "../../index";
import {DrsItem} from "../../domain/entity/structures/requirementManagement/DrsItem";
import {ArsItem} from "../../domain/entity/structures/requirementManagement/ArsItem";
import {isLongStringValueValid} from "../../utils/validationUtils";
import CommentsList from "./CommentsList";
import {RsComment} from "../../domain/entity/structures/requirementManagement/RsComment";
import {Link} from "../../domain/entity/structures/requirementManagement/Link";
import AddLinkDialog from "./AddLinkDialog";
import {LinkTypeEnum} from "../../domain/entity/structures/requirementManagement/LinkTypeEnum";
import {ON_FINISH_MESSAGE} from "../../utils/consts";
import {getRsListForLinks, isRsValid, removeEmptyLogicStatement} from "./ProjectUtils";
import {IdNameListValues} from "../../domain/entity/structures/shared/IdNameListValues";
import moment from "moment";

interface RsCreateDialogProps {
    isDialogOpen: boolean
    isDrs: boolean
    projectId: string
    rsList: ArsItem[] | DrsItem[]
    onRsListReload: () => void
    onAdd: (rs: ArsItem | DrsItem) => void
    onClose: () => void
}

const RsCreateDialog = observer((props: RsCreateDialogProps) => {
    const {appStore} = useContext(Context)
    const {userStore} = useContext(Context)
    const [newComment, setNewComment] = useState<string>('');
    const [rs, setRs] = useState<DrsItem | ArsItem>({
        rs_id: "",
        category: "",
        complexity: "",
        criticality: "",
        description: "",
        links: [],
        logic_statements: [''],
        name: "",
        project_id: props.projectId,
        requirement_comments: [],
        state: "New",
        subject: "",
    });
    const [addLinkDialogOpen, setAddLinkDialogOpen] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);

    function clearControls() {
        setRs({
            rs_id: "",
            category: "",
            complexity: "",
            criticality: "",
            description: "",
            links: [],
            logic_statements: [''],
            name: "",
            project_id: "",
            requirement_comments: [],
            state: "New",
            subject: "",
        })
        setIsChanged(false)
    }

    function onCreate() {
        removeEmptyLogicStatement(rs);
        if (!isRsValid(rs, appStore))
            return
        props.onAdd(rs)
        clearControls()
    }

    function onClear() {
        if (isChanged) {
            let confirmAction = window.confirm("Are you sure to clear updates?");
            if (!confirmAction)
                return
        }
        clearControls()
    }

    function onFinish() {
        if (isChanged) {
            let confirmAction = window.confirm(ON_FINISH_MESSAGE);
            if (!confirmAction)
                return
        }
        props.onClose()
    }

    function onNewCommentChange(event: React.ChangeEvent<HTMLInputElement>) {
        setNewComment(event.target.value);
    }

    function onAddComment() {
        const newCommentValidationResult = isLongStringValueValid(newComment);
        if (newCommentValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Comment has validation error with message: " + newCommentValidationResult)
            return
        }

        rs.requirement_comments.push({
            number: rs.requirement_comments.length + 1,
            comment: newComment,
            created_by: userStore.user.loginId,
            createdAt: moment().format()
        })
        setNewComment('')
        setRs({...rs})
        setIsChanged(true)
    }

    function onDeleteComment(comment: RsComment) {
        rs.requirement_comments.splice(rs.requirement_comments.indexOf(comment), 1)
        for (let i = 0; i < rs.requirement_comments.length; i++) {
            rs.requirement_comments[i].number = i + 1
        }
        setRs({...rs})
        setIsChanged(true)
    }

    function onAddLinkButtonClick() {
        setAddLinkDialogOpen(true)
    }

    function onDeleteLink(link: Link) {
        rs.links.splice(rs.links.indexOf(link), 1)
        setRs({...rs})
    }

    function onAddLinkDialogClose() {
        setAddLinkDialogOpen(false)
    }

    function onAddLink(linkType: LinkTypeEnum, rsLink: IdNameListValues) {
        rs.links.push({
            link_id: null as any,
            main_rs_id: rs.rs_id,
            link_type: linkType,
            linked_rs_id: rsLink.id,
            name: rsLink.name
        } as Link)
        setIsChanged(true)
    }

    function setRsValue(rs: DrsItem | ArsItem) {
        setRs(rs)
        setIsChanged(true)
    }

    function onLink() {
    }

    return (
        <>
            <Dialog open={props.isDialogOpen} maxWidth={'xl'} onClose={props.onClose}>
                <DialogTitle>{props.isDrs ? "Drs Item Creation" : "Ars Item Creation"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1} item xs={12} sm={12} alignContent={"flex-start"}>
                        <Grid container item xs={8}
                              alignContent={"flex-start"}>
                            <RsProperties record={rs}
                                          onUpdate={setRsValue}/>
                            <Grid container alignContent={"flex-start"}>
                                <Grid item xs
                                      alignContent={"flex-start"}>
                                    <TextField
                                        margin="dense"
                                        id="item-comment"
                                        label="New comment"
                                        type="text"
                                        fullWidth
                                        inputProps={{maxLength: 500}}
                                        variant="standard"
                                        name='new-comment'
                                        value={newComment}
                                        onChange={onNewCommentChange}
                                    />
                                </Grid>
                                <Grid container item xs="auto">
                                    <Button onClick={onAddComment}
                                            disabled={newComment === ''}>Add</Button>
                                </Grid>
                            </Grid>
                            <CommentsList comments={rs.requirement_comments} onDelete={onDeleteComment}/>
                        </Grid>
                        <Grid item xs={4}>
                            <RsRightSide record={rs}
                                         onUpdate={setRsValue}
                                         onLink={onLink}
                                         isAddLinkPossible={getRsListForLinks(rs, props.rsList).length > 0}
                                         onAddLinkButtonClick={onAddLinkButtonClick}
                                         onDeleteLink={onDeleteLink}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onFinish}>Finish</Button>
                    <Button onClick={onClear}>Clear</Button>
                    <Button onClick={onCreate}>Create</Button>
                </DialogActions>
            </Dialog>
            {
                addLinkDialogOpen &&
                <AddLinkDialog isDialogOpen={addLinkDialogOpen}
                               isDrs={props.isDrs}
                               rsList={getRsListForLinks(rs, props.rsList)}
                               onAddLink={onAddLink}
                               onClose={onAddLinkDialogClose}/>
            }
        </>
    )
})

export default RsCreateDialog;