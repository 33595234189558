import {isLongStringValueValid, isNameValidGeneral} from "../../../utils/validationUtils";
import AppStore from "../../../store/AppStore";
import {AiModelFlow} from "../../../domain/entity/structures/aiLab/AiModelFlow";
import {FlowGroup} from "../../../domain/entity/structures/aiLab/FlowGroup";
import {AiModelTypeEnum} from "../../../domain/entity/structures/aiLab/AiModelTypeEnum";
import {idNameValuesFromObject} from "../../../utils/utils";

export function isAiModelValid(value: AiModelFlow, appStore: AppStore) {
    if (value.flow_group.id === '') {
        appStore.showAppSnackBar("error",
            "Flow group is not selected")
        return false
    }

    const nameValidationResult = isNameValidGeneral(value.name, true, 200);
    if (nameValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "AI model's name has validation error with message: " + nameValidationResult)
        return false
    }
    if (value.description) {
        const validationResult = isLongStringValueValid(value.description, false, 3000);
        if (validationResult !== '') {
            appStore.showAppSnackBar("error",
                "AI model's description has validation error with message: " + validationResult)
            return false
        }
    }
    if (value.type === '') {
        appStore.showAppSnackBar("error",
            "Type is empty")
        return false
    }
    const scriptNameValidationResult = isNameValidGeneral(value.script_name, true, 200);
    if (scriptNameValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Script's name has validation error with message: " + scriptNameValidationResult)
        return false
    }
    const modelScriptValidationResult = isLongStringValueValid(value.script, true, 3000);
    if (modelScriptValidationResult !== '') {
        appStore.showAppSnackBar("error",
            "Model script has validation error with message: " + modelScriptValidationResult)
        return false
    }
    for (let i = 0; i < value.supporting_files.length; i++) {
        if (value.supporting_files[i].file_description) {
            const validationResult = isLongStringValueValid(value.supporting_files[i].file_description, false, 3000);
            if (validationResult !== '') {
                appStore.showAppSnackBar("error",
                    "Supporting file's description has validation error with message: " + validationResult)
                return false
            }
        }
        const scriptNameValidationResult = isNameValidGeneral(value.supporting_files[i].file_name, true, 200);
        if (scriptNameValidationResult !== '') {
            appStore.showAppSnackBar("error",
                "Supporting file's name has validation error with message: " + scriptNameValidationResult)
            return false
        }
    }
    return true
}

export function aiModelInputAdapter(value: any) {
    return {
        ...value,
        type: value.type === 'scheduled' ? AiModelTypeEnum.scheduled : AiModelTypeEnum.stream,
        id: value.ai_model_id,
        name: value.ai_model_name,
        description: value.ai_model_description === null ? '' : value.ai_model_description,
        model_description: value.model_description ? value.model_description : '',
        model_name: value.model_name ? value.model_name : '',
        supporting_files: value.supporting_files ? value.supporting_files.map(item => ({
            ...item,
            file_description: item.file_description ?? ''
        })) : [],
        flow_group: {
            id: value.flow_group_id,
            flows: idNameValuesFromObject(value.flows),
            name: ""
        } as FlowGroup,
        files: [],
        create_time: value.created_time,
    } as AiModelFlow
}

export function AiModelOutputAdapter(value: AiModelFlow) {
    return {
        ...value,
        type: value.type.toLowerCase(),
        flow_group_id: value.flow_group.id,
        ai_model_name: value.name,
        ai_model_description: value.description,
        supporting_files: value.supporting_files.map(e => ({
            file_id: e.file_id,
            file_name: e.file_name,
            file_description: e.file_description === '' ? null : e.file_description
        }))
    }
}

export function flowGroupsInputAdapter(data) {
    return data.map((item) =>
        ({
            id: item.flow_group_id,
            name: item.name,
            flows: idNameValuesFromObject(item.flows)
        } as FlowGroup));
}